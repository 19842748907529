import fetch from "cross-fetch";
import {host, postPayload} from "./data";
import {refreshSyncStatus, setTimeoutRefreshSyncStatus} from "./status";
import {initProductsVolumeCollections} from "./productsVolume";
import {adminTrack} from "./v2/appV2Wizard";

import _debounce from "lodash/debounce";
import _map from "lodash/map";

export const INIT_PRODUCTS = 'INIT_PRODUCTS';
export const CREATE_MAPPING = 'CREATE_MAPPING';
export const INIT_PRODUCTS_FAILED = 'INIT_PRODUCTS_FAILED';
export const EDIT_MAPPING = 'EDIT_MAPPING';
export const SHOW_SOURCE_PRODUCTS = 'SHOW_SOURCE_PRODUCTS';
export const CHOOSE_MAPPING_NAME = 'CHOOSE_MAPPING_NAME';
export const CHOOSE_MAPPING_RULES = 'CHOOSE_MAPPING_RULES';
export const SHOW_DEST_PRODUCTS = 'SHOW_DEST_PRODUCTS';
export const SHOW_MAPPING_RULES = 'SHOW_MAPPING_RULES';
export const SHOW_MAPPING = 'SHOW_MAPPING';
export const SHOW_IMPORT_MAPPINGS = 'SHOW_IMPORT_MAPPINGS';
export const SHOW_EXCLUDED = 'SHOW_EXCLUDED';
export const SHOW_GLOBAL = 'SHOW_GLOBAL';
export const SHOW_GLOBAL_RULES = 'SHOW_GLOBAL_RULES';
export const SHOW_INCLUDE = 'SHOW_INCLUDE';
export const BACK_TO_MAIN = 'BACK_TO_MAIN';
export const CLOSE_REMOVE_MAPPING_MODAL = 'CLOSE_REMOVE_MAPPING_MODAL';
export const CLOSE_CHOOSE_DEST_VARIANTS_MODAL = 'CLOSE_CHOOSE_DEST_VARIANTS_MODAL';
export const CLOSE_CHOOSE_SOURCE_VARIANTS_MODAL = 'CLOSE_CHOOSE_SOURCE_VARIANTS_MODAL';
export const CLOSE_CHOOSE_GLOBAL_VARIANTS_MODAL = 'CLOSE_CHOOSE_GLOBAL_VARIANTS_MODAL';
export const CHOOSE_DEST_VARIANTS_DONE = 'CHOOSE_DEST_VARIANTS_DONE';
export const CHOOSE_SOURCE_VARIANTS_DONE = 'CHOOSE_SOURCE_VARIANTS_DONE';
export const CHOOSE_GLOBAL_VARIANTS_DONE = 'CHOOSE_GLOBAL_VARIANTS_DONE';
export const EDIT_MAPPING_FAILED = 'EDIT_MAPPING_FAILED';
export const SWITCH_SOURCE_DEST_FAILED = 'SWITCH_SOURCE_DEST_FAILED';
export const SWITCH_SOURCE_DEST_SUCCESS = 'SWITCH_SOURCE_DEST_SUCCESS';
export const EDIT_MAPPING_LOADING = 'EDIT_MAPPING_LOADING';
export const SWITCHING_SOURCE_DEST = 'SWITCHING_SOURCE_DEST';
export const DUPLICATING_MAPPING = 'DUPLICATING_MAPPING';
export const DUP_MAPPING_SUCCESS = 'DUP_MAPPING_SUCCESS';
export const DUP_MAPPING_FAILURE = 'DUP_MAPPING_FAILURE';
export const REMOVE_SOURCE_ITEM = 'REMOVE_SOURCE_ITEM';
export const REMOVE_DEST_ITEM = 'REMOVE_DEST_ITEM';
export const REMOVE_INCLUDED_ITEM = 'REMOVE_INCLUDED_ITEM';
export const REMOVE_EXCLUDED_ITEM = 'REMOVE_EXCLUDED_ITEM';
export const REMOVE_GLOBAL_ITEM = 'REMOVE_GLOBAL_ITEM';
export const CHANGE_MAPPING_NAME = 'CHANGE_MAPPING_NAME';
export const CHANGE_MAPPING_TITLE = 'CHANGE_MAPPING_TITLE';
export const CHANGE_MAPPING_SUBTITLE = 'CHANGE_MAPPING_SUBTITLE';
export const CHANGE_SRC_FILTER = 'CHANGE_SRC_FILTER';
export const CHANGE_SRC_FILTER2 = 'CHANGE_SRC_FILTER2';
export const CHANGE_DEST_FILTER = 'CHANGE_DEST_FILTER';
export const CHANGE_DEST_FILTER2 = 'CHANGE_DEST_FILTER2';
export const GLOBAL_FILTER_CHANGE = 'GLOBAL_FILTER_CHANGE';
export const EXCLUDED_FILTER_CHANGE = 'EXCLUDED_FILTER_CHANGE';
export const GLOBAL_FILTER2_CHANGE = 'GLOBAL_FILTER2_CHANGE';
export const EXCLUDED_FILTER2_CHANGE = 'EXCLUDED_FILTER2_CHANGE';
export const CHANGE_SRC_FILTER_OPTION = 'CHANGE_SRC_FILTER_OPTION';
export const CHANGE_SRC_FILTER_OPTION2 = 'CHANGE_SRC_FILTER_OPTION2';
export const CHANGE_DEST_FILTER_OPTION = 'CHANGE_DEST_FILTER_OPTION';
export const CHANGE_DEST_FILTER_OPTION2 = 'CHANGE_DEST_FILTER_OPTION2';
export const GLOBAL_FILTER_OPTION_CHANGE = 'GLOBAL_FILTER_OPTION_CHANGE';
export const EXCLUDED_FILTER_OPTION_CHANGE = 'EXCLUDED_FILTER_OPTION_CHANGE';
export const GLOBAL_FILTER2_OPTION_CHANGE = 'GLOBAL_FILTER2_OPTION_CHANGE';
export const EXCLUDED_FILTER2_OPTION_CHANGE = 'EXCLUDED_FILTER2_OPTION_CHANGE';
export const MAPPINGS_SEARCH_BOX_TEXT_CHANGE = 'MAPPINGS_SEARCH_BOX_TEXT_CHANGE';
export const MAPPING_SEARCH_TYPE_CHANGE = 'MAPPING_SEARCH_TYPE_CHANGE';
export const DESTINATION_DISCOUNT_CHANGE = 'DESTINATION_DISCOUNT_CHANGE';
export const MIN_ITEMS_FOR_DISCOUNT_CHANGE = 'MIN_ITEMS_FOR_DISCOUNT_CHANGE';
export const MAX_ITEMS_FOR_DISCOUNT_CHANGE = 'MAX_ITEMS_FOR_DISCOUNT_CHANGE';
export const CHOOSE_SOURCE_PRODUCT = 'CHOOSE_SOURCE_PRODUCT';
export const CHOOSE_GLOBAL_PRODUCT = 'CHOOSE_GLOBAL_PRODUCT';
export const CHOOSE_DEST_PRODUCT = 'CHOOSE_DEST_PRODUCT';
export const CHOOSE_INCLUDED_PRODUCT = 'CHOOSE_INCLUDED_PRODUCT';
export const CHOOSE_EXCLUDED_PRODUCT = 'CHOOSE_EXCLUDED_PRODUCT';
export const MOVE_DEST_ITEM_UP = 'MOVE_DEST_ITEM_UP';
export const MOVE_GLOBAL_ITEM_UP = 'MOVE_GLOBAL_ITEM_UP';
export const MOVE_DEST_ITEM_DOWN = 'MOVE_DEST_ITEM_DOWN';
export const MOVE_GLOBAL_ITEM_DOWN = 'MOVE_GLOBAL_ITEM_DOWN';
export const SHOW_REMOVE_MAPPING_MODAL = 'SHOW_REMOVE_MAPPING_MODAL';
export const FILTER_MAPPING_BY_PRODUCT = 'FILTER_MAPPING_BY_PRODUCT';
export const SORT_DEST_PRODUCTS = 'SORT_DEST_PRODUCTS';
export const SORT_GLOBAL_PRODUCTS = 'SORT_GLOBAL_PRODUCTS';
export const MOVE_TEMP_DEST_ITEM_UP = 'MOVE_TEMP_DEST_ITEM_UP';
export const MOVE_TEMP_GLOBAL_ITEM_UP = 'MOVE_TEMP_GLOBAL_ITEM_UP';
export const MOVE_TEMP_DEST_ITEM_DOWN = 'MOVE_TEMP_DEST_ITEM_DOWN';
export const MOVE_TEMP_GLOBAL_ITEM_DOWN = 'MOVE_TEMP_GLOBAL_ITEM_DOWN';
export const CHOOSE_DEST_VARIANTS = 'CHOOSE_DEST_VARIANTS';
export const CHOOSE_SOURCE_VARIANTS = 'CHOOSE_SOURCE_VARIANTS';
export const ON_CHOOSE_GLOBAL_VARIANTS = 'ON_CHOOSE_GLOBAL_VARIANTS';
export const EDIT_MAPPING_NAME = 'EDIT_MAPPING_NAME';
export const EDIT_MAPPING_NAME_SUCCESS = 'EDIT_MAPPING_NAME_SUCCESS';
export const MAPPING_NAME_EDITED = 'MAPPING_NAME_EDITED';
export const DEST_VARIANT_CHECKED = 'DEST_VARIANT_CHECKED';
export const SET_ENABLE_MAPPING_LOADING = 'SET_ENABLE_MAPPING_LOADING';
export const SET_ENABLE_MAPPING = 'SET_ENABLE_MAPPING';
export const SOURCE_VARIANT_CHECKED = 'SOURCE_VARIANT_CHECKED';
export const ON_GLOBAL_VARIANT_CHECKED = 'ON_GLOBAL_VARIANT_CHECKED';
export const REMOVE_MAPPING_STARTED = 'REMOVE_MAPPING_STARTED';
export const REMOVE_MAPPING_SUCCESS = 'REMOVE_MAPPING_SUCCESS';
export const REMOVE_MAPPING_ERROR = 'REMOVE_MAPPING_ERROR';
export const CANCEL = 'CANCEL';
export const SWITCHING_MAPPING_PREVIEW = 'SWITCHING_MAPPING_PREVIEW';
export const CHANGE_MAPPING_PREVIEW_MODE = 'CHANGE_MAPPING_PREVIEW_MODE';
export const CHOOSE_DEST_PRODUCTS = 'CHOOSE_DEST_PRODUCTS';
export const SAVE_MAPPING_PROCESSING = 'SAVE_MAPPING_PROCESSING';
export const SAVE_INCLUDED_PROCESSING = 'SAVE_INCLUDED_PROCESSING';
export const SAVE_EXCLUDED_PROCESSING = 'SAVE_EXCLUDED_PROCESSING';
export const SAVE_GLOBALS_PROCESSING = 'SAVE_GLOBALS_PROCESSING';
export const SLIDER_DELETE_GLOBALS = 'SLIDER_DELETE_GLOBALS';
export const SAVE_GLOBALS_SUCCESS = 'SAVE_GLOBALS_SUCCESS';
export const SAVE_GLOBALS_FAILURE = 'SAVE_GLOBALS_FAILURE';
export const MAPPINGS_REORDER_SUCCESS = 'MAPPINGS_REORDER_SUCCESS';
export const MAPPINGS_REORDER_FAILURE = 'MAPPINGS_REORDER_FAILURE';
export const SAVE_MAPPING_SUCCESS = 'SAVE_MAPPING_SUCCESS';
export const SAVE_INCLUDED_SUCCESS = 'SAVE_INCLUDED_SUCCESS';
export const SAVE_EXCLUDED_SUCCESS = 'SAVE_EXCLUDED_SUCCESS';
export const SEARCH_SOURCE_SUCCESS = 'SEARCH_SOURCE_SUCCESS';
export const SEARCH_GLOBAL_SUCCESS = 'SEARCH_GLOBAL_SUCCESS';
export const SEARCH_INCLUDED_SUCCESS = 'SEARCH_INCLUDED_SUCCESS';
export const SEARCH_EXCLUDED_SUCCESS = 'SEARCH_EXCLUDED_SUCCESS';
export const SEARCH_DEST_SUCCESS = 'SEARCH_DEST_SUCCESS';
export const ADD_ALL_TO_SRC_BY_FILTER_SUCCESS = 'ADD_ALL_TO_SRC_BY_FILTER_SUCCESS';
export const ADD_ALL_TO_DEST_BY_FILTER_SUCCESS = 'ADD_ALL_TO_DEST_BY_FILTER_SUCCESS';
export const ADD_ALL_TO_GLOBAL_BY_FILTER_SUCCESS = 'ADD_ALL_TO_GLOBAL_BY_FILTER_SUCCESS';
export const ADD_ALL_TO_EXCLUDED_BY_FILTER_SUCCESS = 'ADD_ALL_TO_EXCLUDED_BY_FILTER_SUCCESS';
export const CREATE_MAPPING_SUCCESS = 'CREATE_MAPPING_SUCCESS';
export const SAVE_MAPPING_FAILURE = 'SAVE_MAPPING_FAILURE';
export const SAVE_INCLUDED_FAILURE = 'SAVE_INCLUDED_FAILURE';
export const SAVE_EXCLUDED_FAILURE = 'SAVE_EXCLUDED_FAILURE';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const ADD_ALL_TO_SRC_BY_FILTER_FAILURE = 'ADD_ALL_TO_SRC_BY_FILTER_FAILURE';
export const ADD_ALL_TO_DEST_BY_FILTER_FAILURE = 'ADD_ALL_TO_DEST_BY_FILTER_FAILURE';
export const ADD_ALL_TO_GLOBAL_BY_FILTER_FAILURE = 'ADD_ALL_TO_GLOBAL_BY_FILTER_FAILURE';
export const ADD_ALL_TO_EXCLUDED_BY_FILTER_FAILURE = 'ADD_ALL_TO_EXCLUDED_BY_FILTER_FAILURE';
export const NAME_EMPTY = 'NAME_EMPTY';
export const ONLY_SOURCE_PROCESSING = 'ONLY_SOURCE_PROCESSING';
export const SHOW_OTHERS_AFTER_GLOBALS_PROCESSING = 'SHOW_OTHERS_AFTER_GLOBALS_PROCESSING';
export const ONLY_SOURCE_FAILURE = 'ONLY_SOURCE_FAILURE';
export const SHOW_OTHERS_AFTER_GLOBALS_FAILURE = 'SHOW_OTHERS_AFTER_GLOBALS_FAILURE';
export const ONLY_SOURCE_SUCCESS = 'ONLY_SOURCE_SUCCESS';
export const ON_SHOW_MAPPING_ON_PRODUCT_CHANGED = 'ON_SHOW_MAPPING_ON_PRODUCT_CHANGED';
export const EXPORTING_MAPPINGS = 'EXPORTING_MAPPINGS';
export const OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT_CHANGE = 'OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT_CHANGE';
export const TOGGLE_OVERRIDE_EXISTING_MAPPINGS_MODAL = 'TOGGLE_OVERRIDE_EXISTING_MAPPINGS_MODAL';
export const ON_SHOW_MAPPING_ON_CART_CHANGED = 'ON_SHOW_MAPPING_ON_CART_CHANGED';
export const ON_SHOW_MAPPING_ABOVE_PRICE_CHANGED = 'ON_SHOW_MAPPING_ABOVE_PRICE_CHANGED';
export const ON_SHOW_MAPPING_BELOW_PRICE_CHANGED = 'ON_SHOW_MAPPING_BELOW_PRICE_CHANGED';
export const ON_SHOW_MAPPING_ABOVE_QUANTITY_CHANGED = 'ON_SHOW_MAPPING_ABOVE_QUANTITY_CHANGED';
export const ON_SHOW_MAPPING_BELOW_QUANTITY_CHANGED = 'ON_SHOW_MAPPING_BELOW_QUANTITY_CHANGED';
export const SHOW_OTHERS_AFTER_GLOBALS_SUCCESS = 'SHOW_OTHERS_AFTER_GLOBALS_SUCCESS';
export const ON_SHOW_GLOBALS_ON_PRODUCT_CHANGED = 'ON_SHOW_GLOBALS_ON_PRODUCT_CHANGED';
export const ON_SHOW_GLOBALS_ON_CART_CHANGED = 'ON_SHOW_GLOBALS_ON_CART_CHANGED';
export const ON_SHOW_GLOBALS_ABOVE_PRICE_CHANGED = 'ON_SHOW_GLOBALS_ABOVE_PRICE_CHANGED';
export const ON_SHOW_GLOBALS_BELOW_PRICE_CHANGED = 'ON_SHOW_GLOBALS_BELOW_PRICE_CHANGED';
export const ON_SHOW_GLOBALS_BELOW_QUANTITY_CHANGED = 'ON_SHOW_GLOBALS_BELOW_QUANTITY_CHANGED';
export const ON_SHOW_GLOBALS_ABOVE_QUANTITY_CHANGED = 'ON_SHOW_GLOBALS_ABOVE_QUANTITY_CHANGED';
export const ON_MAPPING_ABOVE_PRICE_CHANGE = 'ON_MAPPING_ABOVE_PRICE_CHANGE';
export const ON_MAPPING_BELOW_PRICE_CHANGE = 'ON_MAPPING_BELOW_PRICE_CHANGE';
export const ON_MAPPING_ABOVE_QUANTITY_CHANGE = 'ON_MAPPING_ABOVE_QUANTITY_CHANGE';
export const ON_MAPPING_BELOW_QUANTITY_CHANGE = 'ON_MAPPING_BELOW_QUANTITY_CHANGE';
export const ON_GLOBALS_ABOVE_PRICE_CHANGE = 'ON_GLOBALS_ABOVE_PRICE_CHANGE';
export const ON_GLOBALS_BELOW_PRICE_CHANGE = 'ON_GLOBALS_BELOW_PRICE_CHANGE';
export const ON_GLOBALS_ABOVE_QUANTITY_CHANGE = 'ON_GLOBALS_ABOVE_QUANTITY_CHANGE';
export const ON_GLOBALS_BELOW_QUANTITY_CHANGE = 'ON_GLOBALS_BELOW_QUANTITY_CHANGE';
export const DYNAMIC_GROUPING_CHANGE = 'DYNAMIC_GROUPING_CHANGE';
export const MIN_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED = 'MIN_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED';
export const MAX_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED = 'MAX_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED';
export const RANDOMIZE_CHANGE = 'RANDOMIZE_CHANGE';
export const MAPPING_USE_TEXTS_CHANGE = 'MAPPING_USE_TEXTS_CHANGE';
export const PRODUCTS_TOGGLE_VIDEO = 'PRODUCTS_TOGGLE_VIDEO';
export const MOVE_MAPPING_ITEM = 'MOVE_MAPPING_ITEM';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const PRODUCT_SLIDER_DISMISS_DYNAMIC_TOAST = 'PRODUCT_SLIDER_DISMISS_DYNAMIC_TOAST';
export const TOGGLE_ADD_ALL_TO_SRC_BY_FILTER_ACTIVE = 'TOGGLE_ADD_ALL_TO_SRC_BY_FILTER_ACTIVE';
export const TOGGLE_ADD_ALL_TO_GLOBAL_BY_FILTER_ACTIVE = 'TOGGLE_ADD_ALL_TO_GLOBAL_BY_FILTER_ACTIVE';
export const ADD_ANOTHER_SRC_FILTER = 'ADD_ANOTHER_SRC_FILTER';
export const ADD_ANOTHER_DEST_FILTER = 'ADD_ANOTHER_DEST_FILTER';
export const OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT = 'OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT';
export const ADD_ANOTHER_GLOBAL_FILTER_CHANGE = 'ADD_ANOTHER_GLOBAL_FILTER_CHANGE';
export const ADD_ANOTHER_EXCLUDED_FILTER_CHANGE = 'ADD_ANOTHER_EXCLUDED_FILTER_CHANGE';
export const REMOVE_PRODUCT_FILTER = 'REMOVE_PRODUCT_FILTER';
export const MAPPING_SEARCH_BOX_FOCUS = 'MAPPING_SEARCH_BOX_FOCUS';
export const CLOSE_SORT_DEST_PRODUCTS_MODAL = 'CLOSE_SORT_DEST_PRODUCTS_MODAL';
export const CLOSE_SORT_GLOBAL_PRODUCTS_MODAL = 'CLOSE_SORT_GLOBAL_PRODUCTS_MODAL';
export const SORT_DEST_PRODUCTS_DONE = 'SORT_DEST_PRODUCTS_DONE';
export const SORT_GLOBAL_PRODUCTS_DONE = 'SORT_GLOBAL_PRODUCTS_DONE';
export const TOGGLE_ADD_ALL_TO_DEST_BY_FILTER_ACTIVE = 'TOGGLE_ADD_ALL_TO_DEST_BY_FILTER_ACTIVE';
export const TOGGLE_ADD_ALL_TO_EXCLUDED_BY_FILTER_ACTIVE = 'TOGGLE_ADD_ALL_TO_EXCLUDED_BY_FILTER_ACTIVE';
export const START_CREATE_MAPPING = 'START_CREATE_MAPPING';
export const CREATE_MAPPING_FAILURE = 'CREATE_MAPPING_FAILURE';
export const START_SEARCH = 'START_SEARCH';
export const EXPORT_MAPPINGS_STATUS = 'EXPORT_MAPPINGS_STATUS';
export const MAPPING_LOCALE_CHANGE = 'MAPPING_LOCALE_CHANGE';
export const CHANGE_MAPPING_TEXT = 'CHANGE_MAPPING_TEXT';
export const START_ADDING_ALL_TO_SRC_BY_FILTER = 'START_ADDING_ALL_TO_SRC_BY_FILTER';
export const START_ADDING_ALL_TO_DEST_BY_FILTER = 'START_ADDING_ALL_TO_DEST_BY_FILTER';
export const START_ADDING_ALL_TO_GLOBAL_BY_FILTER = 'START_ADDING_ALL_TO_GLOBAL_BY_FILTER';
export const START_ADDING_ALL_TO_EXCLUDED_BY_FILTER = 'START_ADDING_ALL_TO_EXCLUDED_BY_FILTER';
export const IMPORT_MAPPINGS_FAILURE = 'IMPORT_MAPPINGS_FAILURE';
export const IMPORT_STATE = 'IMPORT_STATE';
export const PRE_INIT_PRODUCTS = 'PRE_INIT_PRODUCTS';
export const LOAD_ADMIN_MAPPING_SEARCH = 'LOAD_ADMIN_MAPPING_SEARCH';

export const initProducts = json => ({
    type: INIT_PRODUCTS,
    mappings: json.mappings,
    lastSync: json.lastSync,
    globalProducts: json.globalProducts,
    includedProducts: json.includedProducts,
    excludedProducts: json.excludedProducts,
    isOnlySource: json.onlyOnSourceProducts,
    isShowOthersAfterGlobals: json.showOthersAfterGlobals,
    tags: json.tags,
    vendors: json.vendors,
    product_types: json.product_types,
    customCollections: json.customCollections,
    smartCollections: json.smartCollections,
    currencySymbol: json.currency_symbol,
    globalsConfig: json.globals_config,
    mappingDefaults: json.mapping_defaults,
    importMappingsState: json.import_mappings_state,
    maxProducts: json.recommendationWigdetMaxProducts,
});

export const createMapping = () => ({
    type: CREATE_MAPPING,
});

export const loadAdminMappingSearch = data => ({
    type: LOAD_ADMIN_MAPPING_SEARCH,
    data,
});

export const initProductsFailed = () => ({
    type: INIT_PRODUCTS_FAILED,
});

export const removeMappingStarted = () => ({
    type: REMOVE_MAPPING_STARTED,
});

export const removeMappingSuccess = (json) => ({
    type: REMOVE_MAPPING_SUCCESS,
    mappings: json.mappings,
});

export const removeMappingError = () => ({
    type: REMOVE_MAPPING_ERROR,
});

export const cancel = () => ({
    type: CANCEL,
});

export const switchingMappingPreview = (id, bool) => ({
    type: SWITCHING_MAPPING_PREVIEW,
    id,
    bool,
});

export const changeMappingPreviewMode = (id, bool) => ({
    type: CHANGE_MAPPING_PREVIEW_MODE,
    id,
    bool,
});

export const chooseDestProducts = () => ({
    type: CHOOSE_DEST_PRODUCTS,
});

export const showSourceProducts = () => ({
    type: SHOW_SOURCE_PRODUCTS,
});

export const chooseMappingName = () => ({
    type: CHOOSE_MAPPING_NAME,
});

export const chooseMappingRules = () => ({
    type: CHOOSE_MAPPING_RULES,
});

export const showDestProducts = () => ({
    type: SHOW_DEST_PRODUCTS,
});

export const showMappingRules = () => ({
    type: SHOW_MAPPING_RULES,
});

export const showMapping = () => ({
    type: SHOW_MAPPING,
});

export const showImportMappings = () => ({
    type: SHOW_IMPORT_MAPPINGS,
});

export const showExcluded = () => ({
    type: SHOW_EXCLUDED,
});

export const showGlobal = () => ({
    type: SHOW_GLOBAL,
});

export const showGlobalRules = () => ({
    type: SHOW_GLOBAL_RULES,
});

export const showInclude = () => ({
    type: SHOW_INCLUDE,
});

export const backToMain = () => ({
    type: BACK_TO_MAIN,
});

export const closeRemoveMappingModal = () => ({
    type: CLOSE_REMOVE_MAPPING_MODAL,
});

export const closeChooseDestVariantsModal = () => ({
    type: CLOSE_CHOOSE_DEST_VARIANTS_MODAL,
});

export const closeChooseSourceVariantsModal = () => ({
    type: CLOSE_CHOOSE_SOURCE_VARIANTS_MODAL,
});

export const closeChooseGlobalVariantsModal = () => ({
    type: CLOSE_CHOOSE_GLOBAL_VARIANTS_MODAL,
});

export const chooseDestVariantsDone = () => ({
    type: CHOOSE_DEST_VARIANTS_DONE,
});

export const chooseSourceVariantsDone = () => ({
    type: CHOOSE_SOURCE_VARIANTS_DONE,
});

export const chooseGlobalVariantsDone = () => ({
    type: CHOOSE_GLOBAL_VARIANTS_DONE,
});

export const editMappingFailed = id => ({
    type: EDIT_MAPPING_FAILED,
    id,
});

export const switchSourceDestFailed = id => ({
    type: SWITCH_SOURCE_DEST_FAILED,
    id,
});

export const switchSourceDestSuccess = (id, json) => ({
    type: SWITCH_SOURCE_DEST_SUCCESS,
    id,
    json,
});

export const editMappingLoading = id => ({
    type: EDIT_MAPPING_LOADING,
    id,
});

export const switchingSourceDest = id => ({
    type: SWITCHING_SOURCE_DEST,
    id,
});

export const duplicatingMapping = id => ({
    type: DUPLICATING_MAPPING,
    id,
});

export const dupMappingSuccess = (id, mappings) => ({
    type: DUP_MAPPING_SUCCESS,
    id,
    mappings,
});

export const dupMappingFailure = id => ({
    type: DUP_MAPPING_FAILURE,
    id,
});

export const editMapping = json => ({
    type: EDIT_MAPPING,
    json,
});

export const chooseSourceProduct = (id) => ({
    type: CHOOSE_SOURCE_PRODUCT,
    id,
});

export const chooseGlobalProduct = (id) => ({
    type: CHOOSE_GLOBAL_PRODUCT,
    id,
});

export const chooseDestProduct = (id) => ({
    type: CHOOSE_DEST_PRODUCT,
    id,
});

export const chooseIncludedProduct = (id) => ({
    type: CHOOSE_INCLUDED_PRODUCT,
    id,
});

export const chooseExcludedProduct = (id) => ({
    type: CHOOSE_EXCLUDED_PRODUCT,
    id,
});

export const moveDestItemUp = (id) => ({
    type: MOVE_DEST_ITEM_UP,
    id,
});

export const moveGlobalItemUp = (id) => ({
    type: MOVE_GLOBAL_ITEM_UP,
    id,
});

export const moveDestItemDown = (id) => ({
    type: MOVE_DEST_ITEM_DOWN,
    id,
});

export const moveGlobalItemDown = (id) => ({
    type: MOVE_GLOBAL_ITEM_DOWN,
    id,
});

export const showRemoveMappingModal = (id) => ({
    type: SHOW_REMOVE_MAPPING_MODAL,
    id,
});

export const filterMappingByProduct = id => ({
    type: FILTER_MAPPING_BY_PRODUCT,
    id,
});

export const sortDestProducts = id => ({
    type: SORT_DEST_PRODUCTS,
    id,
});

export const sortGlobalProducts = id => ({
    type: SORT_GLOBAL_PRODUCTS,
    id,
});

export const moveTempDestItemUp = id => ({
    type: MOVE_TEMP_DEST_ITEM_UP,
    id,
});

export const moveTempGlobalItemUp = id => ({
    type: MOVE_TEMP_GLOBAL_ITEM_UP,
    id,
});

export const moveTempDestItemDown = id => ({
    type: MOVE_TEMP_DEST_ITEM_DOWN,
    id,
});

export const moveTempGlobalItemDown = id => ({
    type: MOVE_TEMP_GLOBAL_ITEM_DOWN,
    id,
});

export const chooseDestVariants = id => ({
    type: CHOOSE_DEST_VARIANTS,
    id,
});

export const chooseSourceVariants = id => ({
    type: CHOOSE_SOURCE_VARIANTS,
    id,
});

export const onChooseGlobalVariants = id => ({
    type: ON_CHOOSE_GLOBAL_VARIANTS,
    id,
});

export const editMappingName = id => ({
    type: EDIT_MAPPING_NAME,
    id,
});

export const editMappingNameSuccess = id => ({
    type: EDIT_MAPPING_NAME_SUCCESS,
    id,
});

export const mappingNameEdited = (id, text) => ({
    type: MAPPING_NAME_EDITED,
    id,
    text,
});

export const destVariantChecked = (id, bool) => ({
    type: DEST_VARIANT_CHECKED,
    id,
    bool,
});

export const setEnableMappingLoading = (id, bool) => ({
    type: SET_ENABLE_MAPPING_LOADING,
    id,
    bool,
});

export const setEnableMapping = (id, bool) => ({
    type: SET_ENABLE_MAPPING,
    id,
    bool,
});

export const sourceVariantChecked = (id, bool) => ({
    type: SOURCE_VARIANT_CHECKED,
    id,
    bool,
});

export const onGlobalVariantChecked = (id, bool) => ({
    type: ON_GLOBAL_VARIANT_CHECKED,
    id,
    bool,
});

export const removeSourceItem = (itemId) => ({
    type: REMOVE_SOURCE_ITEM,
    itemId,
});

export const removeDestItem = (itemId) => ({
    type: REMOVE_DEST_ITEM,
    itemId,
});

export const removeIncludedItem = (itemId) => ({
    type: REMOVE_INCLUDED_ITEM,
    itemId,
});

export const removeExcludedItem = (itemId) => ({
    type: REMOVE_EXCLUDED_ITEM,
    itemId,
});

export const removeGlobalItem = (itemId) => ({
    type: REMOVE_GLOBAL_ITEM,
    itemId,
});

export const changeMappingName = (text) => ({
    type: CHANGE_MAPPING_NAME,
    text,
});

export const changeMappingTitle = text => ({
    type: CHANGE_MAPPING_TITLE,
    text,
});

export const changeMappingSubtitle = text => ({
    type: CHANGE_MAPPING_SUBTITLE,
    text,
});

export const changeSrcFilter = (text) => ({
    type: CHANGE_SRC_FILTER,
    text,
});

export const changeSrcFilter2 = (text) => ({
    type: CHANGE_SRC_FILTER2,
    text,
});

export const changeDestFilter = (text) => ({
    type: CHANGE_DEST_FILTER,
    text,
});

export const changeDestFilter2 = (text) => ({
    type: CHANGE_DEST_FILTER2,
    text,
});

export const globalFilterChange = text => ({
    type: GLOBAL_FILTER_CHANGE,
    text,
});

export const excludedFilterChange = text => ({
    type: EXCLUDED_FILTER_CHANGE,
    text,
});

export const globalFilter2Change = text => ({
    type: GLOBAL_FILTER2_CHANGE,
    text,
});

export const excludedFilter2Change = text => ({
    type: EXCLUDED_FILTER2_CHANGE,
    text,
});

export const changeSrcFilterOption = (text) => ({
    type: CHANGE_SRC_FILTER_OPTION,
    text,
});

export const changeSrcFilterOption2 = (text) => ({
    type: CHANGE_SRC_FILTER_OPTION2,
    text,
});

export const changeDestFilterOption = (text) => ({
    type: CHANGE_DEST_FILTER_OPTION,
    text,
});

export const changeDestFilterOption2 = (text) => ({
    type: CHANGE_DEST_FILTER_OPTION2,
    text,
});

export const globalFilterOptionChange = text => ({
    type: GLOBAL_FILTER_OPTION_CHANGE,
    text,
});

export const excludedFilterOptionChange = text => ({
    type: EXCLUDED_FILTER_OPTION_CHANGE,
    text,
});

export const globalFilter2OptionChange = text => ({
    type: GLOBAL_FILTER2_OPTION_CHANGE,
    text,
});

export const excludedFilter2OptionChange = text => ({
    type: EXCLUDED_FILTER2_OPTION_CHANGE,
    text,
});

export const mappingsSearchBoxTextChange = text => ({
    type: MAPPINGS_SEARCH_BOX_TEXT_CHANGE,
    text,
});

export const mappingSearchTypeChange = text => ({
    type: MAPPING_SEARCH_TYPE_CHANGE,
    text,
});

export const destinationDiscountChange = num => ({
    type: DESTINATION_DISCOUNT_CHANGE,
    num,
});

export const minItemsForDiscountChange = num => ({
    type: MIN_ITEMS_FOR_DISCOUNT_CHANGE,
    num,
});

export const maxItemsForDiscountChange = num => ({
    type: MAX_ITEMS_FOR_DISCOUNT_CHANGE,
    num,
});

export const saveMappingProcessing = () => ({
    type: SAVE_MAPPING_PROCESSING,
});

export const saveIncludedProcessing = () => ({
    type: SAVE_INCLUDED_PROCESSING,
});

export const saveExcludedProcessing = () => ({
    type: SAVE_EXCLUDED_PROCESSING,
});

export const saveGlobalsProcessing = () => ({
    type: SAVE_GLOBALS_PROCESSING,
});

export const sliderDeleteGlobals = () => ({
    type: SLIDER_DELETE_GLOBALS,
});

export const saveGlobalsSuccess = () => ({
    type: SAVE_GLOBALS_SUCCESS,
});

export const saveGlobalsFailure = () => ({
    type: SAVE_GLOBALS_FAILURE,
});

export const mappingsReorderSuccess = () => ({
    type: MAPPINGS_REORDER_SUCCESS,
});

export const mappingsReorderFailure = () => ({
    type: MAPPINGS_REORDER_FAILURE,
});

export const saveMappingSuccess = (json) => ({
    type: SAVE_MAPPING_SUCCESS,
    mappings: json.mappings,
});

export const saveIncludedSuccess = () => ({
    type: SAVE_INCLUDED_SUCCESS,
});

export const saveExcludedSuccess = () => ({
    type: SAVE_EXCLUDED_SUCCESS,
});

export const searchSourceSuccess = (json) => ({
    type: SEARCH_SOURCE_SUCCESS,
    products: json.products,
    existingIds: json.existing_ids,
    title: json.title,
});

export const searchGlobalSuccess = (json) => ({
    type: SEARCH_GLOBAL_SUCCESS,
    products: json.products,
    existingIds: json.existing_ids,
    title: json.title,
});

export const searchIncludedSuccess = (json) => ({
    type: SEARCH_INCLUDED_SUCCESS,
    products: json.products,
    existingIds: json.existing_ids,
    title: json.title,
});

export const searchExcludedSuccess = (json) => ({
    type: SEARCH_EXCLUDED_SUCCESS,
    products: json.products,
    existingIds: json.existing_ids,
    title: json.title,
});

export const searchDestSuccess = (json) => ({
    type: SEARCH_DEST_SUCCESS,
    products: json.products,
    existingIds: json.existing_ids,
    title: json.title,
});

export const addAllToSrcByFilterSuccess = (json, isDynamic) => ({
    type: ADD_ALL_TO_SRC_BY_FILTER_SUCCESS,
    products: json.products,
    id: json.id,
    count: json.count,
    filter: json.filter,
    text: json.text,
    filter2: json.filter2,
    text2: json.text2,
    second_filter_present: json.second_filter_present,
    isDynamic: isDynamic,
});

export const addAllToDestByFilterSuccess = (json, isDynamic) => ({
    type: ADD_ALL_TO_DEST_BY_FILTER_SUCCESS,
    products: json.products,
    id: json.id,
    count: json.count,
    filter: json.filter,
    text: json.text,
    filter2: json.filter2,
    text2: json.text2,
    second_filter_present: json.second_filter_present,
    isDynamic: isDynamic,
});

export const addAllToGlobalByFilterSuccess = (json, isDynamic) => ({
    type: ADD_ALL_TO_GLOBAL_BY_FILTER_SUCCESS,
    id: json.id,
    count: json.count,
    products: json.products,
    filter: json.filter,
    text: json.text,
    filter2: json.filter2,
    text2: json.text2,
    second_filter_present: json.second_filter_present,
    isDynamic: isDynamic,
});

export const addAllToExcludedByFilterSuccess = (json, isDynamic) => ({
    type: ADD_ALL_TO_EXCLUDED_BY_FILTER_SUCCESS,
    id: json.id,
    count: json.count,
    products: json.products,
    filter: json.filter,
    text: json.text,
    filter2: json.filter2,
    text2: json.text2,
    second_filter_present: json.second_filter_present,
    isDynamic: isDynamic,
});

export const createMappingSuccess = (json) => ({
    type: CREATE_MAPPING_SUCCESS,
    id: json.id,
    title: json.title,
    image_src: json.image_src,
    mapping_defaults: json.mapping_defaults,
});

export const saveMappingFailure = () => ({
    type: SAVE_MAPPING_FAILURE,
});

export const saveIncludedFailure = () => ({
    type: SAVE_INCLUDED_FAILURE,
});

export const saveExcludedFailure = () => ({
    type: SAVE_EXCLUDED_FAILURE,
});

export const searchFailure = () => ({
    type: SEARCH_FAILURE,
});

export const addAllToSrcByFilterFailure = () => ({
    type: ADD_ALL_TO_SRC_BY_FILTER_FAILURE,
});

export const addAllToDestByFilterFailure = () => ({
    type: ADD_ALL_TO_DEST_BY_FILTER_FAILURE,
});

export const addAllToGlobalByFilterFailure = () => ({
    type: ADD_ALL_TO_GLOBAL_BY_FILTER_FAILURE,
});

export const addAllToExcludedByFilterFailure = () => ({
    type: ADD_ALL_TO_EXCLUDED_BY_FILTER_FAILURE,
});

export const nameEmpty = () => ({
    type: NAME_EMPTY,
});

export const onlyIncludedProcessing = () => ({
    type: ONLY_SOURCE_PROCESSING,
});

export const showOthersAfterGlobalsProcessing = () => ({
    type: SHOW_OTHERS_AFTER_GLOBALS_PROCESSING,
});

export const onlyIncludedFailure = () => ({
    type: ONLY_SOURCE_FAILURE,
});

export const showOthersAfterGlobalsFailure = () => ({
    type: SHOW_OTHERS_AFTER_GLOBALS_FAILURE,
});

export const dismissToast = () => ({
    type: DISMISS_TOAST,
});

export const productSliderDismissDynamicToast = () => ({
    type: PRODUCT_SLIDER_DISMISS_DYNAMIC_TOAST,
});

export const toggleAddAllToSrcByFilterActive = () => ({
    type: TOGGLE_ADD_ALL_TO_SRC_BY_FILTER_ACTIVE,
});

export const toggleAddAllToGlobalByFilterActive = () => ({
    type: TOGGLE_ADD_ALL_TO_GLOBAL_BY_FILTER_ACTIVE,
});

export const addAnotherSrcFilter = () => ({
    type: ADD_ANOTHER_SRC_FILTER,
});

export const addAnotherDestFilter = () => ({
    type: ADD_ANOTHER_DEST_FILTER,
});

export const overrideExistingMappingsOnImport = () => ({
    type: OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT,
});

export const addAnotherGlobalFilterChange = bool => ({
    type: ADD_ANOTHER_GLOBAL_FILTER_CHANGE,
    bool,
});

export const addAnotherExcludedFilterChange = bool => ({
    type: ADD_ANOTHER_EXCLUDED_FILTER_CHANGE,
    bool,
});

export const removeProductFilter = () => ({
    type: REMOVE_PRODUCT_FILTER,
});

export const mappingSearchBoxFocus = () => ({
    type: MAPPING_SEARCH_BOX_FOCUS,
});

export const closeSortDestProductsModal = () => ({
    type: CLOSE_SORT_DEST_PRODUCTS_MODAL,
});

export const closeSortGlobalProductsModal = () => ({
    type: CLOSE_SORT_GLOBAL_PRODUCTS_MODAL,
});

export const sortDestProductsDone = () => ({
    type: SORT_DEST_PRODUCTS_DONE,
});

export const sortGlobalProductsDone = () => ({
    type: SORT_GLOBAL_PRODUCTS_DONE,
});

export const toggleAddAllToDestByFilterActive = () => ({
    type: TOGGLE_ADD_ALL_TO_DEST_BY_FILTER_ACTIVE,
});

export const toggleAddAllToExcludedByFilterActive = () => ({
    type: TOGGLE_ADD_ALL_TO_EXCLUDED_BY_FILTER_ACTIVE,
});

export const startCreateMapping = () => ({
    type: START_CREATE_MAPPING,
});

export const createMappingFailure = () => ({
    type: CREATE_MAPPING_FAILURE,
});

export const startSearch = text => ({
    type: START_SEARCH,
    text,
});

export const exportMappingsStatus = text => ({
    type: EXPORT_MAPPINGS_STATUS,
    text,
});

export const mappingLocaleChange = text => ({
    type: MAPPING_LOCALE_CHANGE,
    text,
});

export const changeMappingText = (attr, text) => ({
    type: CHANGE_MAPPING_TEXT,
    attr,
    text,
});

export const startAddingAllToSrcByFilter = () => ({
    type: START_ADDING_ALL_TO_SRC_BY_FILTER,
});

export const startAddingAllToDestByFilter = () => ({
    type: START_ADDING_ALL_TO_DEST_BY_FILTER,
});

export const startAddingAllToGlobalByFilter = () => ({
    type: START_ADDING_ALL_TO_GLOBAL_BY_FILTER,
});

export const startAddingAllToExcludedByFilter = () => ({
    type: START_ADDING_ALL_TO_EXCLUDED_BY_FILTER,
});

export const importMappingsFailure = () => ({
    type: IMPORT_MAPPINGS_FAILURE,
});

export const importState = json => ({
    type: IMPORT_STATE,
    importMappingsState: json.import_mappings_state,
    mappings: json.mappings,
});

export const onlyIncludedSuccess = (bool) => ({
    type: ONLY_SOURCE_SUCCESS,
    bool,
});

export const onShowMappingOnProductChanged = bool => ({
    type: ON_SHOW_MAPPING_ON_PRODUCT_CHANGED,
    bool,
});

export const exportingMappings = bool => ({
    type: EXPORTING_MAPPINGS,
    bool,
});

export const overrideExistingMappingsOnImportChange = bool => ({
    type: OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT_CHANGE,
    bool,
});

export const toggleOverrideExistingMappingsModal = bool => ({
    type: TOGGLE_OVERRIDE_EXISTING_MAPPINGS_MODAL,
    bool,
});

export const onShowMappingOnCartChanged = bool => ({
    type: ON_SHOW_MAPPING_ON_CART_CHANGED,
    bool,
});

export const onShowMappingAbovePriceChanged = bool => ({
    type: ON_SHOW_MAPPING_ABOVE_PRICE_CHANGED,
    bool,
});

export const onShowMappingBelowPriceChanged = bool => ({
    type: ON_SHOW_MAPPING_BELOW_PRICE_CHANGED,
    bool,
});

export const onShowMappingAboveQuantityChanged = bool => ({
    type: ON_SHOW_MAPPING_ABOVE_QUANTITY_CHANGED,
    bool,
});

export const onShowMappingBelowQuantityChanged = bool => ({
    type: ON_SHOW_MAPPING_BELOW_QUANTITY_CHANGED,
    bool,
});

export const showOthersAfterGlobalsSuccess = (bool) => ({
    type: SHOW_OTHERS_AFTER_GLOBALS_SUCCESS,
    bool,
});

export const onShowGlobalsOnProductChanged = (bool) => ({
    type: ON_SHOW_GLOBALS_ON_PRODUCT_CHANGED,
    bool,
});

export const onShowGlobalsOnCartChanged = (bool) => ({
    type: ON_SHOW_GLOBALS_ON_CART_CHANGED,
    bool,
});

export const onShowGlobalsAbovePriceChanged = (bool) => ({
    type: ON_SHOW_GLOBALS_ABOVE_PRICE_CHANGED,
    bool,
});

export const onShowGlobalsBelowPriceChanged = (bool) => ({
    type: ON_SHOW_GLOBALS_BELOW_PRICE_CHANGED,
    bool,
});

export const onShowGlobalsBelowQuantityChanged = (bool) => ({
    type: ON_SHOW_GLOBALS_BELOW_QUANTITY_CHANGED,
    bool,
});

export const onShowGlobalsAboveQuantityChanged = (bool) => ({
    type: ON_SHOW_GLOBALS_ABOVE_QUANTITY_CHANGED,
    bool,
});

export const onMappingAbovePriceChange = num => ({
    type: ON_MAPPING_ABOVE_PRICE_CHANGE,
    num,
});

export const onMappingBelowPriceChange = num => ({
    type: ON_MAPPING_BELOW_PRICE_CHANGE,
    num,
});

export const onMappingAboveQuantityChange = num => ({
    type: ON_MAPPING_ABOVE_QUANTITY_CHANGE,
    num,
});

export const onMappingBelowQuantityChange = num => ({
    type: ON_MAPPING_BELOW_QUANTITY_CHANGE,
    num,
});

export const onGlobalsAbovePriceChange = num => ({
    type: ON_GLOBALS_ABOVE_PRICE_CHANGE,
    num,
});

export const onGlobalsBelowPriceChange = num => ({
    type: ON_GLOBALS_BELOW_PRICE_CHANGE,
    num,
});

export const onGlobalsAboveQuantityChange = num => ({
    type: ON_GLOBALS_ABOVE_QUANTITY_CHANGE,
    num,
});

export const onGlobalsBelowQuantityChange = num => ({
    type: ON_GLOBALS_BELOW_QUANTITY_CHANGE,
    num,
});

export const dynamicGroupingChange = bool => ({
    type: DYNAMIC_GROUPING_CHANGE,
    bool,
});

export const minItemsForDiscountEnabledChanged = bool => ({
    type: MIN_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED,
    bool,
});

export const maxItemsForDiscountEnabledChanged = bool => ({
    type: MAX_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED,
    bool,
});

export const randomizeChange = bool => ({
    type: RANDOMIZE_CHANGE,
    bool,
});

export const mappingUseTextsChange = bool => ({
    type: MAPPING_USE_TEXTS_CHANGE,
    bool,
});

export const productsToggleVideo = videoName => ({
    type: PRODUCTS_TOGGLE_VIDEO,
    videoName,
});

export const moveMappingItem = (direction, index) => ({
    type: MOVE_MAPPING_ITEM,
    direction,
    index,
});

const moveMappingItemInnerFunction = _debounce(dispatch => dispatch(moveMappingItemApi()), 2500);
export function moveMappingItemAction(direction, index) {
    return (dispatch, getState) => {
        dispatch(moveMappingItem(direction, index));
        moveMappingItemInnerFunction(dispatch);
    }
}

export function addMappingAndSearch() {
    return (dispatch, getState) => {
        dispatch(createMapping());
        dispatch(changeSourceSearchText(''));
    }
}

export const onEnableDisableMapping = id => async (dispatch, getState) => {
    const state = getState();
    dispatch(setEnableMappingLoading(id, true));
    const res = await postPayload('mappings/toggle_enable', {
        id,
    }, state);
    if (res.ok) {
        const json = await res.json();
        dispatch(setEnableMapping(id, json.enabled));
        dispatch(setEnableMappingLoading(id, false));
    } else {
        console.log('onEnableDisableMapping failed');
    }
}

export const mappingPreviewOffer = id => async (dispatch, getState) => {
    const state = getState();
    const m = state.products.mappings.find(m => m.id === id);
    window.open(`https://${state.data.shopName}/products/${m.preview_handle}?zrx_preview_mode=true`, '_blank');
}

export const editMappingNameComplete = id => async (dispatch, getState) => {
    const state = getState();
    const m = state.products.mappings.find(m => m.id === id);
    const res = await postPayload('mappings/edit_name', {
        id,
        name: m.name,
    }, state);
    if (res.ok) {
        dispatch(editMappingNameSuccess(id));
    } else {
        console.log('editMappingNameComplete failed');
    }
}

export function loadProducts() {
    return (dispatch, getState) => {
        const state = getState();
        if (!state.products.ready) {
            fetch(`https://${host}/data/get_products_init_for_admin?shop_name=${state.data.shopName}&token=${state.data.token}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(initProducts(json));
                            dispatch(initProductsVolumeCollections(json))
                        });
                    } else {
                        dispatch(initProductsFailed());
                    }
                })
        }
    }
}

export function editMappingApiCall(id) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(editMappingLoading(id));
        fetch(`https://${host}/mappings/get_mapping?shop_name=${state.data.shopName}&token=${state.data.token}&id=${id}`)
            .then(response => {
                if (response.ok) {
                    response.json().then(json => dispatch(editMapping(json)));
                } else {
                    dispatch(editMappingFailed(id));
                }
            })

    }
}

export function switchSourceDest(id) {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(switchingSourceDest(id));
        const response = await fetch(`https://${host}/mappings/switch_source_dest?shop_name=${state.data.shopName}&token=${state.data.token}&id=${id}`);
        if (response.ok) {
            const json  = await response.json();
            dispatch(switchSourceDestSuccess(id, json));
        } else {
            dispatch(switchSourceDestFailed(id));
        }
    }
}

export function switchMappingPreview(id) {
    return async (dispatch, getState) => {
        const state = getState();
        const mapping = state.products.mappings.find(m => m.id === id);
        dispatch(switchingMappingPreview(id, true));
        const previewMode = !mapping.preview_mode;
        const response = await postPayload('mappings/switch_preview', {
            preview_mode: previewMode,
            id,
        }, state);
        if (response.ok) {
            dispatch(changeMappingPreviewMode(id, previewMode))
            dispatch(switchingMappingPreview(id, false));
            await postPayload('admin_v2/create_store_js', {}, state);
        } else {
            console.log('switchMappingPreview failed');
        }
    }
}

export function dupMapping(id) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(duplicatingMapping(id));
        fetch(`https://${host}/mappings/dup?shop_name=${state.data.shopName}&token=${state.data.token}&id=${id}`)
            .then(response => {
                if (response.ok) {
                    response.json().then(json => dispatch(dupMappingSuccess(id, json.mappings)));
                } else {
                    dispatch(dupMappingFailure(id));
                }
            })

    }
}

export function changeDestSearchText(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startSearch(text));
        return fetch(`https://${host}/data/search_products`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    title: text,
                    existing_ids: _map(state.products.destProducts, 'id'),
                    get_variants: true,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(searchDestSuccess(json)));
                } else {
                    dispatch(searchFailure());
                }
            });
    }
}

export function moveMappingItemApi() {
    return (dispatch, getState) => {
        const state = getState();
        return fetch(`https://${host}/mappings/save_order`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    mapping_ids: state.products.mappings.map(m => m.id),
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(mappingsReorderSuccess(json));
                        dispatch(refreshSyncStatus());
                    });
                } else {
                    dispatch(mappingsReorderFailure());
                }
            });
    }
}

function updateImportState(json) {
    return (dispatch, getState) => {
        dispatch(importState(json));
        if (json.import_mappings_state.status === 'processing') {
            setTimeout(() => dispatch(getImportState()), 5_000)
        }
    }
}

function getImportState() {
    return (dispatch, getState) => {
        const state = getState();
        return fetch(`https://${host}/import_mappings/get_state?shop_name=${state.data.shopName}&token=${state.data.token}`)
            .then(response => {
                if (response.ok) {
                    response.json().then(json => dispatch(updateImportState(json)));
                } else {
                    dispatch(importMappingsFailure());
                }
            })
    }
}

export function uploadImportMappingsSuccess() {
    return (dispatch, getState) => {
        const state = getState();
        return fetch(`https://${host}/import_mappings/start_import`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    override_existing: state.products.overrideExistingMappingsOnImport,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => dispatch(updateImportState(json)));
                    setTimeoutRefreshSyncStatus(dispatch);
                } else {
                    dispatch(importMappingsFailure());
                }
            });
    }
}

export function exportMappings() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(exportingMappings(true));
        const res = await postPayload('export_mappings/export_mappings', {
            email: state.data.ownerEmail,
        }, state);
        if (res.ok) {
            dispatch(exportMappingsStatus('Export was successful. You will receive an email with a link to download CSV in a couple of minutes'));
        } else {
            dispatch(exportMappingsStatus('Export failed, please contact support'));
        }
        dispatch(exportingMappings(false));
    }
}

export function addAllToSrcByFilter() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startAddingAllToSrcByFilter());
        const isDynamic = state.products.isDynamicGrouping;
        return fetch(`https://${host}/data/fetch_dynamic_count`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    filter: state.products.srcFilter,
                    text: state.products.srcFilterOption,
                    second_filter_present: state.products.isAddAnotherSrcFilter,
                    filter2: state.products.srcFilter2,
                    text2: state.products.srcFilterOption2,
                    get_variants: !isDynamic,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(addAllToSrcByFilterSuccess(json, isDynamic)));
                } else {
                    dispatch(addAllToSrcByFilterFailure());
                }
            });
    }
}

export function addAllToDestByFilter() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startAddingAllToDestByFilter());
        const isDynamic = state.products.isDynamicGrouping;
        return fetch(`https://${host}/data/fetch_dynamic_count`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    filter: state.products.destFilter,
                    text: state.products.destFilterOption,
                    second_filter_present: state.products.isAddAnotherDestFilter,
                    filter2: state.products.destFilter2,
                    text2: state.products.destFilterOption2,
                    get_variants: !isDynamic,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(addAllToDestByFilterSuccess(json, isDynamic)));
                } else {
                    dispatch(addAllToDestByFilterFailure());
                }
            });
    }
}

export function addAllToGlobalByFilter() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startAddingAllToGlobalByFilter());
        const isDynamic = state.products.isDynamicGrouping;
        return fetch(`https://${host}/data/fetch_dynamic_count`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    filter: state.products.globalFilter,
                    text: state.products.globalFilterOption,
                    second_filter_present: state.products.isAddAnotherGlobalFilter,
                    filter2: state.products.globalFilter2,
                    text2: state.products.globalFilterOption2,
                    get_variants: !isDynamic,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(addAllToGlobalByFilterSuccess(json, isDynamic)));
                } else {
                    dispatch(addAllToGlobalByFilterFailure());
                }
            });
    }
}

export function addAllToExcludedByFilter() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startAddingAllToExcludedByFilter());
        const isDynamic = state.products.isDynamicGrouping;
        return fetch(`https://${host}/data/fetch_dynamic_count`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    filter: state.products.excludedFilter,
                    text: state.products.excludedFilterOption,
                    second_filter_present: state.products.isAddAnotherExcludedFilter,
                    filter2: state.products.excludedFilter2,
                    text2: state.products.excludedFilterOption2,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(addAllToExcludedByFilterSuccess(json, isDynamic)));
                } else {
                    dispatch(addAllToExcludedByFilterFailure());
                }
            });
    }
}

export function createMappingAction(shopName, token, product_id) {
    return (dispatch, getState) => {
        dispatch(startCreateMapping());
        return fetch(`https://${host}/data/fetch_product`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: shopName,
                    token: token,
                    product_id,
                    get_mapping_defaults: true,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(createMappingSuccess(json)));
                } else {
                    dispatch(createMappingFailure());
                }
            });
    }
}

export function changeSourceSearchText(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startSearch(text));
        return fetch(`https://${host}/data/search_products`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    title: text,
                    existing_ids: _map(state.products.sourceProducts, 'id'),
                    get_variants: true,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(searchSourceSuccess(json)));
                } else {
                    dispatch(searchFailure());
                }
            });

    }
}

export function changeGlobalSearchText(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startSearch(text));
        return fetch(`https://${host}/data/search_products`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    title: text,
                    existing_ids: _map(state.products.globalProducts, 'id'),
                    get_variants: true,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(searchGlobalSuccess(json)));
                } else {
                    dispatch(searchFailure());
                }
            });

    }
}

export function changeIncludedSearchText(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startSearch(text));
        return fetch(`https://${host}/data/search_products`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    title: text,
                    existing_ids: _map(state.products.includedProducts, 'id'),
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(searchIncludedSuccess(json)));
                } else {
                    dispatch(searchFailure());
                }
            });

    }
}

export function changeExcludedSearchText(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startSearch(text));
        return fetch(`https://${host}/data/search_products`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    title: text,
                    existing_ids: _map(state.products.excludedProducts, 'id'),
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(searchExcludedSuccess(json)));
                } else {
                    dispatch(searchFailure());
                }
            });

    }
}

export function removeMapping() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(removeMappingStarted());
        return fetch(`https://${host}/mappings/delete`,
            {
                method: 'delete',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    mapping_id: state.products.mappingToRemove,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(removeMappingSuccess(json));
                        dispatch(refreshSyncStatus());
                    });
                } else {
                    dispatch(removeMappingError());
                }
            });

    }
}

export function saveMapping() {
    return (dispatch, getState) => {
        const state = getState();
        if (!state.products.mappingName || state.products.mappingName.length === 0) {
            dispatch(nameEmpty());
            return;
        }
        dispatch(saveMappingProcessing());
        return fetch(`https://${host}/mappings/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    mapping_id: state.products.mappingId,
                    mapping_name: state.products.mappingName,
                    mapping_title: state.products.mappingTitle,
                    mapping_subtitle: state.products.mappingSubtitle,
                    destination_discount: state.products.destinationDiscount,
                    source_products: state.products.sourceProducts,
                    dest_products: state.products.destProducts,
                    on_product_page: state.products.isShowMappingOnProduct,
                    on_cart_page: state.products.isShowMappingOnCart,
                    only_above_price: state.products.isShowMappingAbovePrice,
                    only_above_quantity: state.products.isShowMappingAboveQuantity,
                    only_below_price: state.products.isShowMappingBelowPrice,
                    only_below_quantity: state.products.isShowMappingBelowQuantity,
                    above_price: state.products.mappingAbovePrice,
                    below_price: state.products.mappingBelowPrice,
                    above_quantity: state.products.mappingAboveQuantity,
                    below_quantity: state.products.mappingBelowQuantity,
                    min_items_for_discount_enabled: state.products.minItemsForDiscountEnabled,
                    min_items_for_discount: state.products.minItemsForDiscount,
                    max_items_for_discount_enabled: state.products.maxItemsForDiscountEnabled,
                    max_items_for_discount: state.products.maxItemsForDiscount,
                    randomize_dest_products: state.products.randomizeDestProducts,
                    texts: state.products.mappingTexts,
                    use_texts: state.products.mappingUseTexts,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(saveMappingSuccess(json));
                        dispatch(adminTrack('admin_cross_sell_offer_saved', {
                            mapping_id: state.products.mappingId,
                            mapping_name: state.products.mappingName,
                            on_product_page: state.products.isShowMappingOnProduct,
                            on_cart_page: state.products.isShowMappingOnCart,
                        }));
                        dispatch(refreshSyncStatus());
                    });
                } else {
                    dispatch(saveMappingFailure());
                }
            });

    }
}

export function saveIncluded() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saveIncludedProcessing());
        return fetch(`https://${host}/included_products/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    included_products: state.products.includedProducts,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(saveIncludedSuccess());
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(saveIncludedFailure());
                }
            });

    }
}

export function saveExcluded() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saveExcludedProcessing());
        return fetch(`https://${host}/excluded_products/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    excluded_products: state.products.excludedProducts,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(saveExcludedSuccess());
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(saveExcludedFailure());
                }
            });

    }
}

export function saveGlobals() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saveGlobalsProcessing());
        return fetch(`https://${host}/global_products/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    global_products: state.products.globalProducts,
                    on_product_page: state.products.isShowGlobalsOnProduct,
                    on_cart_page: state.products.isShowGlobalsOnCart,
                    only_above_price: state.products.isShowGlobalsAbovePrice,
                    only_above_quantity: state.products.isShowGlobalsAboveQuantity,
                    only_below_price: state.products.isShowGlobalsBelowPrice,
                    only_below_quantity: state.products.isShowGlobalsBelowQuantity,
                    above_price: state.products.globalsAbovePrice,
                    below_price: state.products.globalsBelowPrice,
                    above_quantity: state.products.globalsAboveQuantity,
                    below_quantity: state.products.globalsBelowQuantity,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(saveGlobalsSuccess());
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(saveGlobalsFailure());
                }
            });

    }
}

export function deleteGlobalsAndSave() {
    return (dispatch, getState) => {
        dispatch(sliderDeleteGlobals());
        dispatch(saveGlobals());
    }
}

export function onlyIncluded(bool) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(onlyIncludedProcessing());
        return fetch(`https://${host}/modify_recommendation_widget`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    only_on_source_products: bool,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(onlyIncludedSuccess(bool));
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(onlyIncludedFailure());
                }
            });

    }
}

export function showOthersAfterGlobals(bool) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(showOthersAfterGlobalsProcessing());
        return fetch(`https://${host}/modify_recommendation_widget`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    show_others_after_globals: bool,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(showOthersAfterGlobalsSuccess(bool));
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(showOthersAfterGlobalsFailure());
                }
            });

    }
}
