import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";
import _find from "lodash/find";

import {
    ACCEPT_WARRANTY_REDEMPTION_REQUEST,
    INIT_WARRANTY_MODERATION,
    ON_WARRANTY_MODERATION_VIEW_REQUEST,
    POPULATE_WARRANTY_MODERATION_ITEMS,
    POPULATE_WARRANTY_MODERATION_ORDER_VARIANTS,
    REJECT_WARRANTY_REDEMPTION_REQUEST,
    WARRANTY_MODERATION_ACCEPT_ERROR,
    WARRANTY_MODERATION_ACCEPT_START,
    WARRANTY_MODERATION_ACCEPT_SUCCESS,
    WARRANTY_MODERATION_CLOSE_ACCEPT_MODAL,
    WARRANTY_MODERATION_CLOSE_CREATE_MANUAL_MODAL,
    WARRANTY_MODERATION_CLOSE_ORDER_MODAL,
    WARRANTY_MODERATION_CLOSE_REJECT_MODAL,
    WARRANTY_MODERATION_CLOSE_REQUEST_MODAL,
    WARRANTY_MODERATION_CREATE_MANUAL,
    WARRANTY_MODERATION_CREATE_MANUAL_ERROR,
    WARRANTY_MODERATION_CREATE_MANUAL_START,
    WARRANTY_MODERATION_CREATE_MANUAL_SUCCESS,
    WARRANTY_MODERATION_DISCOUNT_AMOUNT_CHANGE,
    WARRANTY_MODERATION_DISMISS_TOAST,
    WARRANTY_MODERATION_FILTER_ERROR,
    WARRANTY_MODERATION_FILTER_START,
    WARRANTY_MODERATION_NEXT_PAGE,
    WARRANTY_MODERATION_ORDER_EMAIL_CHANGE,
    WARRANTY_MODERATION_ORDER_NAME_CHANGE,
    WARRANTY_MODERATION_PREVIOUS_PAGE,
    WARRANTY_MODERATION_REJECT_ERROR,
    WARRANTY_MODERATION_REJECT_REASON_CHANGE,
    WARRANTY_MODERATION_REJECT_START,
    WARRANTY_MODERATION_REJECT_SUCCESS,
    WARRANTY_MODERATION_SELECT_VARIANT_ERROR,
    WARRANTY_MODERATION_SELECT_VARIANT_START,
    WARRANTY_MODERATION_SELECTED_VARIANT_CHANGE,
    WARRANTY_MODERATION_TYPE_START,
    WARRANTY_MODERATION_VIEW_ORDER,
    WARRANTY_MODERATION_VIEW_ORDER_START
} from "../actions/warrantyModeration";

const warrantyModeration = (state = { }, action) => {
    let newState = null;
    let item = null;
    let price = null;
    switch (action.type) {
        case INIT_WARRANTY_MODERATION:
            newState = _cloneDeep(state);
            newState.settings = _cloneDeep(action.settings.values);
            newState.currencySymbol = action.currencySymbol;
            newState.page = 0;
            newState.maxPage = 0;
            newState.defaultDiscount = action.defaultDiscount;
            newState.variants = [];
            newState.isCreateManualDisabled = true;
            newState.viewOrderLoading = {};
            break;
        case WARRANTY_MODERATION_TYPE_START:
            newState = _cloneDeep(state);
            newState.type = action.itemType;
            newState.page = 0;
            break;
        case POPULATE_WARRANTY_MODERATION_ITEMS:
            newState = _cloneDeep(state);
            if (newState.type !== action.itemType || newState.page !== action.page) break;
            newState.fetchingItems = false;
            newState.items = _cloneDeep(action.items);
            newState.maxPage = action.maxPage;
            break;
        case POPULATE_WARRANTY_MODERATION_ORDER_VARIANTS:
            newState = _cloneDeep(state);
            newState.fetchingVariants = false;
            newState.fetchingVariantsError = false;
            newState.variants = _cloneDeep(action.variants);
            _forEach(newState.variants, variant => {
                variant.label = `${variant.product_title} - ${variant.variant_title} (${variant.price}${newState.currencySymbol})`;
                variant.value = variant.line_item_id;
            });
            newState.selectedVariants = [newState.variants && newState.variants[0] && newState.variants[0].value];
            price = newState.variants && newState.variants[0] && newState.variants[0].price;
            newState.selectedVariantPrice = price;
            newState.discountAmount = price * newState.settings.percentage / 100;
            newState.orderId = action.orderId;
            newState.orderEmail = action.email;
            newState.isCreateManualDisabled = !newState.orderEmail || !newState.discountAmount;
            break;
        case WARRANTY_MODERATION_SELECTED_VARIANT_CHANGE:
            newState = _cloneDeep(state);
            newState.selectedVariants = action.choice;
            price = _find(newState.variants, {value: action.choice[0]}).price;
            newState.selectedVariantPrice = price;
            newState.discountAmount = price * newState.settings.percentage / 100;
            newState.isCreateManualDisabled = !newState.orderEmail || !newState.discountAmount;
            break;
        case WARRANTY_MODERATION_FILTER_ERROR:
            newState = _cloneDeep(state);
            newState.fetchingItems = false;
            newState.filterError = true;
            newState.viewOrderLoading[action.id] = false;
            break;
        case WARRANTY_MODERATION_SELECT_VARIANT_ERROR:
            newState = _cloneDeep(state);
            newState.fetchingVariants = false;
            newState.fetchingVariantsError = true;
            break;
        case WARRANTY_MODERATION_CREATE_MANUAL_ERROR:
            newState = _cloneDeep(state);
            newState.createManualSpinner = false;
            newState.createManualError = true;
            break;
        case WARRANTY_MODERATION_REJECT_ERROR:
            newState = _cloneDeep(state);
            newState.rejectProcessing = false;
            newState.rejectError = true;
            break;
        case WARRANTY_MODERATION_ACCEPT_ERROR:
            newState = _cloneDeep(state);
            newState.acceptProcessing = false;
            newState.acceptError = true;
            break;
        case WARRANTY_MODERATION_PREVIOUS_PAGE:
            newState = _cloneDeep(state);
            newState.page -= 1;
            break;
        case WARRANTY_MODERATION_NEXT_PAGE:
            newState = _cloneDeep(state);
            newState.page += 1;
            break;
        case WARRANTY_MODERATION_FILTER_START:
            newState = _cloneDeep(state);
            newState.fetchingItems = true;
            newState.filterError = false;
            break;
        case WARRANTY_MODERATION_SELECT_VARIANT_START:
            newState = _cloneDeep(state);
            newState.fetchingVariants = true;
            newState.fetchingVariantsError = false;
            break;
        case WARRANTY_MODERATION_CREATE_MANUAL_START:
            newState = _cloneDeep(state);
            newState.createManualSpinner = true;
            newState.createManualError = false;
            break;
        case WARRANTY_MODERATION_REJECT_START:
            newState = _cloneDeep(state);
            newState.rejectProcessing = true;
            newState.rejectError = false;
            break;
        case WARRANTY_MODERATION_ACCEPT_START:
            newState = _cloneDeep(state);
            newState.acceptProcessing = true;
            newState.acceptError = false;
            break;
        case WARRANTY_MODERATION_VIEW_ORDER_START:
            newState = _cloneDeep(state);
            newState.viewOrderLoading[action.id] = true;
            newState.filterError = false;
            break;
        case WARRANTY_MODERATION_VIEW_ORDER:
            newState = _cloneDeep(state);
            newState.viewOrderLoading[action.id] = false;
            newState.filterError = false;
            newState.order = _cloneDeep(action.json);
            newState.orderModalOpen = true;
            break;
        case WARRANTY_MODERATION_CLOSE_ORDER_MODAL:
            newState = _cloneDeep(state);
            newState.order = null;
            newState.orderModalOpen = false;
            break;
        case ON_WARRANTY_MODERATION_VIEW_REQUEST:
            newState = _cloneDeep(state);
            newState.requestModalOpen = true;
            item = _find(newState.items, {id: action.id});
            newState.requestBody = item.request_body;
            newState.redeemHasImage = item.has_image;
            newState.redeemImageUrl = `https://public.zoorix.com/images/warranty_redemption_line_items/${action.id}`;
            break;
        case REJECT_WARRANTY_REDEMPTION_REQUEST:
            newState = _cloneDeep(state);
            newState.rejectModalOpen = true;
            newState.rejectedItemId = action.id;
            break;
        case ACCEPT_WARRANTY_REDEMPTION_REQUEST:
            newState = _cloneDeep(state);
            newState.acceptModalOpen = true;
            newState.acceptedItemId = action.id;
            item = _find(newState.items, {id: action.id});
            newState.discountAmount = item.price * item.discount_percentage / 100;
            newState.acceptedItemPrice = item.price;
            newState.acceptedItemPercentage = item.discount_percentage;
            break;
        case WARRANTY_MODERATION_CLOSE_REQUEST_MODAL:
            newState = _cloneDeep(state);
            newState.requestModalOpen = false;
            break;
        case WARRANTY_MODERATION_CLOSE_REJECT_MODAL:
            newState = _cloneDeep(state);
            newState.rejectModalOpen = false;
            newState.rejectedItemId = null;
            newState.rejectProcessing = false;
            break;
        case WARRANTY_MODERATION_CLOSE_ACCEPT_MODAL:
            newState = _cloneDeep(state);
            newState.acceptModalOpen = false;
            newState.acceptedItemId = null;
            newState.acceptProcessing = false;
            break;
        case WARRANTY_MODERATION_REJECT_REASON_CHANGE:
            newState = _cloneDeep(state);
            newState.rejectReason = action.text;
            break;
        case WARRANTY_MODERATION_DISCOUNT_AMOUNT_CHANGE:
            newState = _cloneDeep(state);
            newState.discountAmount = action.text;
            if (action.text < 0) {
                newState.discountAmount = 0;
            }
            newState.isCreateManualDisabled = !newState.orderEmail || !newState.discountAmount;
            break;
        case WARRANTY_MODERATION_ORDER_NAME_CHANGE:
            newState = _cloneDeep(state);
            newState.orderName = action.text;
            break;
        case WARRANTY_MODERATION_ORDER_EMAIL_CHANGE:
            newState = _cloneDeep(state);
            newState.orderEmail = action.text;
            newState.isCreateManualDisabled = !newState.orderEmail || !newState.discountAmount;
            break;
        case WARRANTY_MODERATION_DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.rejectToastIsActive = false;
            newState.acceptToastIsActive = false;
            break;
        case WARRANTY_MODERATION_CREATE_MANUAL:
            newState = _cloneDeep(state);
            newState.createManualModal = true;
            break;
        case WARRANTY_MODERATION_CLOSE_CREATE_MANUAL_MODAL:
            newState = _cloneDeep(state);
            newState.createManualModal = false;
            newState.variants = [];
            newState.discountAmount = null;
            newState.orderEmail = null;
            newState.orderName = null;
            newState.isCreateManualDisabled = true;
            break;
        case WARRANTY_MODERATION_REJECT_SUCCESS:
            newState = _cloneDeep(state);
            newState.rejectToastIsActive = true;
            break;
        case WARRANTY_MODERATION_CREATE_MANUAL_SUCCESS:
            newState = _cloneDeep(state);
            newState.acceptToastIsActive = true;
            newState.createManualSpinner = false;
            newState.createManualError = false;
            break;
        case WARRANTY_MODERATION_ACCEPT_SUCCESS:
            newState = _cloneDeep(state);
            newState.acceptToastIsActive = true;
            break;
        default:
            return state;
    }
    return newState;
};

export default warrantyModeration;
