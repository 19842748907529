import React from 'react';
import fetch from "cross-fetch";
import { host } from "../data";
import {initPlans} from "../plans";
import {Badge } from '@shopify/polaris';

import _remove from "lodash/remove";

export const INITIALIZE_STICKY_ATC = 'INITIALIZE_STICKY_ATC';
export const STICKY_ATC_SAVING_SETTINGS = 'STICKY_ATC_SAVING_SETTINGS';
export const STICKY_ATC_SET_ZOORIX_M_PROMO_BADGE = 'STICKY_ATC_SET_ZOORIX_M_PROMO_BADGE';
export const STICKY_ATC_SUBMITTING_SUPPORT = 'STICKY_ATC_SUBMITTING_SUPPORT';
export const STICKY_ATC_LOCALE_CHANGE = 'STICKY_ATC_LOCALE_CHANGE';
export const STICKY_ATC_SELECT_TAB = 'STICKY_ATC_SELECT_TAB';
export const SET_STICKY_ATC_DASHBOARD = 'SET_STICKY_ATC_DASHBOARD';
export const SET_STICKY_ATC_LOCALES = 'SET_STICKY_ATC_LOCALES';
export const SET_STICKY_ATC_STORE_DATA = 'SET_STICKY_ATC_STORE_DATA';
export const SET_STICKY_ATC_SETTINGS = 'SET_STICKY_ATC_SETTINGS';
export const STICKY_ATC_CHANGE_SETTING_TEXT = 'STICKY_ATC_CHANGE_SETTING_TEXT';
export const STICKY_ATC_SUPPORT_TEXT_CHANGE = 'STICKY_ATC_SUPPORT_TEXT_CHANGE';
export const STICKY_ATC_TEXTS_CHANGE = 'STICKY_ATC_TEXTS_CHANGE';
export const STICKY_ATC_CHANGE_SETTING_BOOL = 'STICKY_ATC_CHANGE_SETTING_BOOL';
export const STICKY_ATC_CHANGE_SETTING_COLOR = 'STICKY_ATC_CHANGE_SETTING_COLOR';
export const STICKY_ATC_SAVING_SETTINGS_SUCCESS = 'STICKY_ATC_SAVING_SETTINGS_SUCCESS';
export const STICKY_ATC_DISMISS_TOAST = 'STICKY_ATC_DISMISS_TOAST';

export const initializeStickyAtc = params => ({
    type: INITIALIZE_STICKY_ATC,
    params,
});

export const stickyAtcSavingSettings = bool => ({
    type: STICKY_ATC_SAVING_SETTINGS,
    bool,
});

export const stickyAtcSetZoorixMPromoBadge = bool => ({
    type: STICKY_ATC_SET_ZOORIX_M_PROMO_BADGE,
    bool,
});

export const stickyAtcSubmittingSupport = bool => ({
    type: STICKY_ATC_SUBMITTING_SUPPORT,
    bool,
});

export const stickyAtcLocaleChange = locale => ({
    type: STICKY_ATC_LOCALE_CHANGE,
    locale,
});

export const stickyAtcSelectTab = index => ({
    type: STICKY_ATC_SELECT_TAB,
    index,
});

export const setStickyAtcDashboard = json => ({
    type: SET_STICKY_ATC_DASHBOARD,
    json,
});

export const setStickyAtcLocales = json => ({
    type: SET_STICKY_ATC_LOCALES,
    json,
});

export const setStickyAtcStoreData = json => ({
    type: SET_STICKY_ATC_STORE_DATA,
    json,
});

export const setStickyAtcSettings = settings => ({
    type: SET_STICKY_ATC_SETTINGS,
    settings,
});

export const stickyAtcChangeSettingText = (attribute, text) => ({
    type: STICKY_ATC_CHANGE_SETTING_TEXT,
    attribute,
    text,
});

export const stickyAtcSupportTextChange = (attribute, text) => ({
    type: STICKY_ATC_SUPPORT_TEXT_CHANGE,
    attribute,
    text,
});

export const stickyAtcTextsChange = (attribute, text) => ({
    type: STICKY_ATC_TEXTS_CHANGE,
    attribute,
    text,
});

export const stickyAtcChangeSettingBool = (attribute, text) => ({
    type: STICKY_ATC_CHANGE_SETTING_BOOL,
    attribute,
    text,
});

export const stickyAtcChangeSettingColor = (attribute, hex) => ({
    type: STICKY_ATC_CHANGE_SETTING_COLOR,
    attribute,
    hex,
});

export const stickyAtcSavingSettingsSuccess = () => ({
    type: STICKY_ATC_SAVING_SETTINGS_SUCCESS,
});

export const stickyAtcDismissToast = () => ({
    type: STICKY_ATC_DISMISS_TOAST,
});

export function initStickyAtc(params) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.stickyAtc.initialized) return;
        dispatch(initializeStickyAtc(params));
        dispatch(initPlans());
        fetch(`https://${host}/sticky_atc_admin/fetch_dashboard?shop_name=${params.shop}&token=${params.token}`)
            .then(response => response.json())
            .then(json => {
                dispatch(setStickyAtcDashboard(json));
            });
        fetch(`https://${host}/sticky_atc_admin/fetch_available_locales?shop_name=${params.shop}&token=${params.token}`)
            .then(response => response.json())
            .then(json => {
                dispatch(setStickyAtcLocales(json));
            });
        setTimeout(() => fetchSettings(dispatch, params.shop, params.token), 0);
        setTimeout(() => fetchStoreData(dispatch, params.shop, params.token), 0);
    }
}

export function stickyAtcChangeSaveSettings() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(stickyAtcSavingSettings(true));
        const resp = await fetch(`https://${host}/sticky_atc_admin/save_widget`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.stickyAtc.shop,
                    token: state.stickyAtc.token,
                    settings: state.stickyAtc.settings,
                })
            });
        if (resp.ok) {
            dispatch(stickyAtcSavingSettings(false));
            dispatch(stickyAtcSavingSettingsSuccess());
        } else {
            console.log('stickyAtcChangeSaveSettings error');
        }
    }
}

export function stickyAtcChangeSaveCss() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(stickyAtcSavingSettings(true));
        const resp = await fetch(`https://${host}/sticky_atc_admin/save_custom_css`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.stickyAtc.shop,
                    token: state.stickyAtc.token,
                    custom_css: state.stickyAtc.settings.custom_css,
                    custom_js: state.stickyAtc.settings.custom_js,
                })
            });
        if (resp.ok) {
            dispatch(stickyAtcSavingSettings(false));
            dispatch(stickyAtcSavingSettingsSuccess());
        } else {
            console.log('stickyAtcChangeSaveCss error');
        }
    }
}

export function stickyAtcChangeSaveTexts() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(stickyAtcSavingSettings(true));
        const resp = await fetch(`https://${host}/sticky_atc_admin/save_texts`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.stickyAtc.shop,
                    token: state.stickyAtc.token,
                    texts: state.stickyAtc.settings.texts,
                })
            });
        if (resp.ok) {
            dispatch(stickyAtcSavingSettings(false));
            dispatch(stickyAtcSavingSettingsSuccess());
        } else {
            console.log('stickyAtcChangeSaveTexts error');
        }
    }
}

export function stickyAtcSupportSend() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(stickyAtcSubmittingSupport(true));
        const resp = await fetch(`https://${host}/sticky_atc_admin/submit_support`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.stickyAtc.shop,
                    token: state.stickyAtc.token,
                    store_data: state.stickyAtc.storeData,
                })
            });
        if (resp.ok) {
            dispatch(stickyAtcSubmittingSupport(false));
        } else {
            console.log('stickyAtcSupportSend error');
        }
    }
}

function fetchSettings(dispatch, shop, token) {
    fetch(`https://${host}/sticky_atc_admin/fetch_settings?shop_name=${shop}&token=${token}`)
        .then(response => response.json())
        .then(json => {
            if (json.settings) {
                dispatch(setStickyAtcSettings(json.settings));
            } else {
                setTimeout(() => fetchSettings(dispatch, shop, token), 5000);
            }
        });
}

function fetchStoreData(dispatch, shop, token) {
    fetch(`https://${host}/sticky_atc_admin/fetch_store_data?shop_name=${shop}&token=${token}`)
        .then(response => response.json())
        .then(json => {
            if (json.plan_name) {
                dispatch(setStickyAtcStoreData(json));
            } else {
                setTimeout(() => fetchStoreData(dispatch, shop, token), 5000);
            }
        });
}

export const stickyTabs = state => {
    const tabs = [
        {
            id: 'dashboard',
            content: 'Dashboard',
        },
        {
            id: 'settings',
            content: 'Settings',
        },
        {
            id: 'texts',
            content: 'Texts',
        },
        {
            id: 'css-editor',
            content: 'Code Editor',
        },
        {
            id: 'support',
            content: 'Support',
        },
        {
            id: 'faq',
            content: 'FAQ',
        },
        {
            id: 'zoorix-m',
            content: <span>
                <div className={`sticky-add-to-cart-zoorix-promo ${state.promoShake ? 'zrx-sticky-atc-shake' : ''}`}>
                    Zoorix - Cross Sell & Bundles{state.promoBadge && <Badge status="new"></Badge>}
                </div>
            </span>,
        },
    ];

    if (state.storeData && state.storeData.zoorix_m_installed_and_enabled) {
        _remove(tabs, {id: 'zoorix-m'});
    }

    return tabs;
}
