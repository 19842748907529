import _cloneDeep from "lodash/cloneDeep";

import {
    INITIALIZE_STICKY_ATC,
    SET_STICKY_ATC_DASHBOARD,
    SET_STICKY_ATC_LOCALES,
    SET_STICKY_ATC_SETTINGS,
    SET_STICKY_ATC_STORE_DATA,
    STICKY_ATC_CHANGE_SETTING_BOOL,
    STICKY_ATC_CHANGE_SETTING_COLOR,
    STICKY_ATC_CHANGE_SETTING_TEXT, STICKY_ATC_DISMISS_TOAST,
    STICKY_ATC_LOCALE_CHANGE,
    STICKY_ATC_SAVING_SETTINGS,
    STICKY_ATC_SAVING_SETTINGS_SUCCESS,
    STICKY_ATC_SELECT_TAB, STICKY_ATC_SET_ZOORIX_M_PROMO_BADGE,
    STICKY_ATC_SUBMITTING_SUPPORT,
    STICKY_ATC_SUPPORT_TEXT_CHANGE,
    STICKY_ATC_TEXTS_CHANGE, stickyTabs
} from "../../actions/sticky-atc/stickyAtc";
import {calcLocales} from "../texts";

const stickyAtc = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INITIALIZE_STICKY_ATC:
            newState = _cloneDeep(state);
            newState.initialized = true;
            newState.shop = action.params.shop;
            newState.token = action.params.token;
            newState.selectedTab = 0;
            newState.promoShake = true;
            newState.promoBadge = true;
            newState.storeData = newState.storeData || {
                zoorix_m_installed_and_enabled: true,
            }
            break;
        case STICKY_ATC_SAVING_SETTINGS_SUCCESS:
            newState = _cloneDeep(state);
            newState.toastActive = true;
            newState.toastContent = 'Saved!'
            break;
        case STICKY_ATC_DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastActive = false;
            break;
        case STICKY_ATC_SELECT_TAB:
            newState = _cloneDeep(state);
            newState.selectedTab = action.index;
            if (newState.promoShake) {
                newState.promoShake = stickyTabs(newState)[newState.selectedTab].id !== 'zoorix-m';
            }
            break;
        case STICKY_ATC_SAVING_SETTINGS:
            newState = _cloneDeep(state);
            newState.savingSettings = action.bool;
            break;
        case STICKY_ATC_SET_ZOORIX_M_PROMO_BADGE:
            newState = _cloneDeep(state);
            newState.promoBadge = action.bool;
            break;
        case STICKY_ATC_SUBMITTING_SUPPORT:
            newState = _cloneDeep(state);
            newState.submittingSupport = action.bool;
            if (!action.bool) {
                newState.submittedSupport = true;
            }
            break;
        case SET_STICKY_ATC_SETTINGS:
            newState = _cloneDeep(state);
            newState.settings = _cloneDeep(action.settings);
            if (newState.availableLocales) {
                calcLocales(newState, newState.settings.texts);
            }
            break;
        case SET_STICKY_ATC_STORE_DATA:
            newState = _cloneDeep(state);
            newState.storeData = _cloneDeep(action.json);
            break;
        case STICKY_ATC_LOCALE_CHANGE:
            newState = _cloneDeep(state);
            newState.locale = action.locale;
            break;
        case STICKY_ATC_CHANGE_SETTING_TEXT:
            newState = _cloneDeep(state);
            newState.settings[action.attribute] = action.text;
            break;
        case STICKY_ATC_SUPPORT_TEXT_CHANGE:
            newState = _cloneDeep(state);
            newState.storeData[action.attribute] = action.text;
            break;
        case STICKY_ATC_TEXTS_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.texts[newState.locale][action.attribute] = action.text;
            break;
        case STICKY_ATC_CHANGE_SETTING_BOOL:
            newState = _cloneDeep(state);
            newState.settings[action.attribute] = action.text === 'yes';
            break;
        case STICKY_ATC_CHANGE_SETTING_COLOR:
            newState = _cloneDeep(state);
            newState.settings[action.attribute] = action.hex;
            break;
        case SET_STICKY_ATC_LOCALES:
            newState = _cloneDeep(state);
            newState.availableLocales = _cloneDeep(action.json.locales.data.availableLocales);
            if (newState.settings) {
                calcLocales(newState, newState.settings.texts);
            }
            break;
        case SET_STICKY_ATC_DASHBOARD:
            newState = _cloneDeep(state);
            newState.dashboardData = {
                clicksTotal: action.json.clicksTotal,
                clicksToday: action.json.clicksToday,
                clicksYesterday: action.json.clicksYesterday,
                clicksLast7Days: action.json.clicksLast7Days,
                clicksLast30Days: action.json.clicksLast30Days,
            };
            break;
        default:
            return state;
    }
    return newState;
};

export default stickyAtc;
