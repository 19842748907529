import React from 'react';
import {
    Button,
    Icon,
    TextField,
    TextStyle,
    Modal,
    TextContainer,
    Spinner,
    Card,
    Checkbox,
    Toast,
    Frame, Popover, FormLayout, Select, Tooltip
} from '@shopify/polaris'
import '../../css/ProductsSlider.css'
import {CaretDownMinor, CaretUpMinor, DeleteMajor, ViewMajor, EditMinor, TickMinor} from "@shopify/polaris-icons";
import ChooseMappingRules from "../../containers/products/ChooseMappingRules";
import ChooseGlobalRules from "../../containers/products/ChooseGlobalRules";
import VisibleExcluded from "../../containers/products/VisibleExcluded";
import NoImage from "./NoImage";
import VisibleImportMappings from "../../containers/products/VisibleImportMappings";
import {
    ReplaceMajor, InfoMinor, DuplicateMinor, PlayCircleMajor
} from '@shopify/polaris-icons';
import {videoModal} from "../v2/wizard/AppV2WizardSelectOfferType";

const mappingSearchOptions = [
    { label: 'Source products', value: 'source' },
    { label: 'Destination products', value: 'destination' },
];

const mappingUseTextsOptions = [
    { label: 'Use translations', value: 'yes' },
    { label: 'Use basic texts', value: 'no' },
];

export const addByFilterOptions = [
    { label: 'All products', value: 'all' },
    { label: 'Product type', value: 'product-type' },
    { label: 'Vendor', value: 'vendor' },
    { label: 'Tag', value: 'tag' },
    { label: 'Automated collection', value: 'automated-collection' },
    { label: 'Manual collection', value: 'manual-collection' },
];

export const displayFilterTypeAndValue = (filter_type, filter_value) => <span>
    {addByFilterOptions.find(o => o.value === filter_type).label}
    {filter_type !== 'all' && `: ${filter_value}`}
</span>


const ProductsSlider = ({
    isShowMappings, onAddMapping, mappings, onEditMapping, isShowChooseSourceProducts, cancel,
    sourceProducts, removeSourceItem, searchBoxText, onChangeSourceSearchText, searchedProducts,
    addSourceItem, onShowChooseDest, isShowChooseDestProducts, sourceProductsEmptyError,
    showSourceProducts, destProducts, removeDestItem, addDestItem, destProductsEmptyError,
    onChangeDestSearchText, moveDestItemUp, moveDestItemDown,
    isShowChooseMappingName, showMappingRules, mappingName, onChangeMappingName, onSave,
    saveError, errorEmptyName, showRemoveMappingModal, removeMappingModalOpen, closeRemoveMappingModal,
    removeMapping, removeMappingSpinner, removeMappingError, isSyncing, showSearchSpinner, searchError,
    onFocusSourceSearchText, onFocusDestSearchText, isSavingMapping, isShowMain, onShowMapping, onShowGlobal,
    onShowInclude, backToMain, isShowGlobal, onShowGlobalRules, onChangeGlobalSearchText,
    onFocusGlobalSearchText, addGlobalItem, globalProducts, removeGlobalItem,
    isShowIncluded, addIncludedItem, removeIncludedItem, onSaveIncluded, isSavingIncluded, onChangeIncludedSearchText,
    onFocusIncludedSearchText, includedProducts, isOnlySource, onOnlyIncludedChanged, isSubmittingOnlyIncluded,
    toastIsActive, onDismissToast, isFetchingProduct, onSrcFilterChange, srcFilter,
    srcFilterOptions, onSrcFilterOptionChange, srcFilterOption, addAllToSrcByFilterActive,
    toggleAddAllToSrcByFilterActive, isAddingAllToSrcByFilter, onAddAllToSrcByFilter,
    toggleAddAllToDestByFilterActive, addAllToDestByFilterActive, onDestFilterChange,
    destFilter, destFilterOptions, onDestFilterOptionChange, destFilterOption,
    onAddAllToDestByFilter, isAddingAllToDestByFilter, isAddAnotherSrcFilter,
    onAddAnotherSrcFilterChange, onSrcFilterChange2, srcFilter2, srcFilterOptions2,
    onSrcFilterOptionChange2, srcFilterOption2, isAddAnotherDestFilter, onAddAnotherDestFilterChange,
    onDestFilterChange2, destFilter2, destFilterOptions2, onDestFilterOptionChange2,
    destFilterOption2, isShowOthersAfterGlobals, onShowOthersAfterGlobalsChanged,
    isSubmittingShowOthersAfterGlobals, moveGlobalItemUp, moveGlobalItemDown, chooseMappingRules,
    isShowChooseMappingRules, isShowGlobalRules, currencySymbol, isShowExcluded,
    onShowExcluded, mappingTitle, onChangeMappingTitle, mappingsSearchBoxText,
    onMappingsSearchBoxTextChange, onMappingSearchTypeChange, mappingSearchType, mappingsResults, filterMappingByProduct,
    mappingsProductFilter, removeProductFilter, onMappingSearchBoxFocus, addAllToGlobalByFilterActive,
    toggleAddAllToGlobalByFilterActive, onGlobalFilterChange, globalFilter, globalFilterOptions,
    onGlobalFilterOptionChange, globalFilterOption, isAddAnotherGlobalFilter, onAddAnotherGlobalFilterChange,
    onGlobalFilterChange2, globalFilter2, globalFilterOptions2, onGlobalFilterOptionChange2, globalFilterOption2,
    onAddAllToGlobalByFilter, isAddingAllToGlobalByFilter, destinationDiscount, onDestinationDiscountChange,
    maxProducts, isShowImport, onShowImportMappings, mappingSubtitle, onChangeMappingSubtitle, isDynamicGrouping,
    onDynamicGroupingChange, sortDestProducts,
    sortDestProductsModalOpen, closeSortDestProductsModal, sortDestProductsDone, tempSortDestItem, moveTempDestItemUp,
    moveTempDestItemDown, exceedMaxDestProducts, sortGlobalProducts, sortGlobalProductsModalOpen,
    closeSortGlobalProductsModal, sortGlobalProductsDone, tempSortGlobalItem, moveTempGlobalItemUp,
    moveTempGlobalItemDown, globalProductsLength, excludedProductsLength, minItemsForDiscountEnabled,
    minItemsForDiscount, maxItemsForDiscountEnabled, maxItemsForDiscount, onMinItemsForDiscountEnabledChanged,
    onMinItemsForDiscountChange, onMaxItemsForDiscountEnabledChanged, onMaxItemsForDiscountChange,
    onChooseDestVariants, isChooseDestVariantsModalOpen, tempVariants, chooseDestVariantsDone,
    closeChooseDestVariantsModal, onDestVariantChecked, onChooseSourceVariants, isChooseSourceVariantsModalOpen,
    closeChooseSourceVariantsModal, chooseSourceVariantsDone, onSourceVariantChecked, onChooseGlobalVariants,
    isChooseGlobalVariantsModalOpen, closeChooseGlobalVariantsModal, chooseGlobalVariantsDone,
    onGlobalVariantChecked, moveMappingItem, editMappingLoading, productSearchDone, randomizeDestProducts,
    onRandomizeChange, switchSourceDest, switchingSourceDest, onSaveGlobals, onDeleteGlobals, onDupMapping,
    dupMappingLoading, dynamicToastIsActive, dynamicToastContent, dynamicToastError, onDismissDynamicToast,
    onEnableMapping, enableMappingLoading, previewOffer, products, switchPreview, toggleVideo, editMappingName,
    mappingNameEdit, editMappingNameComplete, mappingUseTextsChange, onLocaleChange, changeMappingText}) => {

    const addAllToSrcByFilterActivator = (
        <Button onClick={toggleAddAllToSrcByFilterActive} disclosure>
            Add by filter
        </Button>
    );

    const addAllToDestByFilterActivator = (
        <Button onClick={toggleAddAllToDestByFilterActive} disclosure>
            Add by filter
        </Button>
    );

    return (
        <Frame className="frame-wrapper">
            {(isSyncing || isFetchingProduct) &&
                <Card>
                    <Card.Section sectioned>
                        <div className="fetch-wrapper">
                            <span className="fetch-msg">We're fetching your products...</span>
                            <span className="fetch-spinner">
                                <Spinner />
                            </span>
                        </div>
                    </Card.Section>
                </Card>
            }
            {isShowChooseMappingRules && <ChooseMappingRules />}
            {isShowGlobalRules && <ChooseGlobalRules />}
            {isShowExcluded && <VisibleExcluded />}
            {isShowImport && <VisibleImportMappings />}
            {isShowMain &&
                <div>
                    <Card>
                        <Card.Section sectioned>
                            <div className="mapping-button">
                                <div className="mapping-wrapper">
                                    <div className="mapping-button-wrapper source-dest">
                                        <Button primary onClick={onShowMapping}>Product Mapping
                                            ({mappings.length})</Button>
                                    </div>
                                    { <span className='play-icon' data-tooltip="Play tuturial" onClick={toggleVideo('productMapping')}><Icon source={PlayCircleMajor} /></span> }
                                </div>
                                {/*<Checkbox label="Show slider only on source products" checked={isOnlySource}*/}
                                {/*    onChange={onOnlyIncludedChanged} disabled={isSubmittingOnlyIncluded} />*/}
                            </div>
                        </Card.Section>
                        {(globalProductsLength > 0) &&  <Card.Section sectioned>
                            <div className="mapping-button">
                                <div className="mapping-wrapper">
                                    <div className="mapping-button-wrapper global">
                                        <Button primary onClick={onShowGlobal}>Sticky ({globalProductsLength})</Button>
                                    </div>
                                    <Tooltip light content="The sticky list enables you to offer specific products across all the product pages and for every item that is in the cart">
                                        <Icon source={InfoMinor} />
                                    </Tooltip>

                                </div>
                                {/*<Checkbox label="Show other products after sticky products" checked={isShowOthersAfterGlobals}*/}
                                {/*    onChange={onShowOthersAfterGlobalsChanged} disabled={isSubmittingShowOthersAfterGlobals} />*/}
                            </div>
                        </Card.Section>}
                        { includedProducts.length ? <Card.Section sectioned>
                            <div className="mapping-button">
                                <div className="mapping-wrapper">
                                    <div className="mapping-button-wrapper limited">

                                        <Button primary onClick={onShowInclude}>Limited
                                            ({includedProducts.length})</Button>
                                    </div>
                                    <Tooltip light content="The limited list enables you to have offers only on specific product pages">
                                        <Icon source={InfoMinor} />
                                    </Tooltip>

                                </div>
                            </div>
                        </Card.Section>  : ''}
                        <Card.Section sectioned>
                            <div className="mapping-button">
                                <div className="mapping-wrapper">
                                    <div className="mapping-button-wrapper excluded">

                                        <Button primary onClick={onShowExcluded}>Exclude Items
                                            ({excludedProductsLength})</Button>
                                    </div>
                                    { <span className='play-icon' data-tooltip="Play tuturial" onClick={toggleVideo('excludeItems')}><Icon source={PlayCircleMajor} /></span> }
                                </div>
                            </div>
                        </Card.Section>
                         <Card.Section sectioned>
                                 <div className="mapping-wrapper">
                                        <div className="import-mapping mapping-button-wrapper"><Button primary onClick={onShowImportMappings}>Import / Export</Button></div>
                                    { <span className='play-icon' data-tooltip="Play tuturial" onClick={toggleVideo('importExport')}><Icon source={PlayCircleMajor} /></span> }
                                </div>
                            </Card.Section>
                    </Card>
                </div>
            }
            {
                isShowGlobal &&
                <div className="mapping-table">
                    <div className="navigation-buttons">
                        <span className="navigation-button">
                            <Button onClick={backToMain}>Back</Button>
                        </span>
                        <span className="filter-wrapper">
                            <Popover
                                active={addAllToGlobalByFilterActive}
                                activator={<Button onClick={toggleAddAllToGlobalByFilterActive} disclosure>Add by filter</Button>}
                                onClose={toggleAddAllToGlobalByFilterActive}
                                ariaHaspopup={false}
                                sectioned
                            >
                                <FormLayout>
                                    <Select options={addByFilterOptions} onChange={onGlobalFilterChange} value={globalFilter} />
                                    {globalFilter !== 'all' && <Select options={globalFilterOptions} onChange={onGlobalFilterOptionChange} value={globalFilterOption} />}
                                    <Checkbox label="Dynamic grouping" checked={isDynamicGrouping}
                                              onChange={onDynamicGroupingChange} />
                                    <Checkbox label="Add another filter" checked={isAddAnotherGlobalFilter} onChange={onAddAnotherGlobalFilterChange} />
                                    {isAddAnotherGlobalFilter &&
                                        <FormLayout>
                                            <Select options={addByFilterOptions} onChange={onGlobalFilterChange2} value={globalFilter2} />
                                            {globalFilter2 !== 'all' && <Select options={globalFilterOptions2} onChange={onGlobalFilterOptionChange2} value={globalFilterOption2} />}
                                        </FormLayout>

                                    }
                                    <Button primary onClick={onAddAllToGlobalByFilter} loading={isAddingAllToGlobalByFilter}>Add</Button>
                                </FormLayout>
                            </Popover>
                        </span>
                        <span className="navigation-button-delete-save-wrapper">
                            {globalProducts && globalProducts.length > 0 && <span className="navigation-button">
                                <Button onClick={onDeleteGlobals}>Delete All</Button>
                            </span>}
                            <span className="navigation-button">
                                <Button onClick={onSaveGlobals}>Save</Button>
                            </span>
                             <span className="navigation-button">
                                <Button onClick={onShowGlobalRules} primary>Next</Button>
                            </span>
                        </span>
                    </div>
                    <div className="search-products">
                        <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                            label="Add sticky products  :"
                            value={searchBoxText} onChange={onChangeGlobalSearchText}
                            onFocus={onFocusGlobalSearchText} />
                        {showSearchSpinner ? <div className="fetch-search-wrapper"><span
                            className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                                size="small" /></span></div> : null}
                        {searchError ? <TextStyle variation="negative">Something went wrong</TextStyle> : null}
                        {searchedProducts && searchedProducts.map((item) => (
                            <div className={'product-container searchable'} key={item.id}
                                onClick={addGlobalItem(item.id)}>
                                <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image"
                                            style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                            <NoImage itemId={item.id} klassName={'item-image'} />
                                        )}
                                </span>
                                <span className="item-title-wrapper">
                                    <span className="item-title searchable">{item.title}</span>
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="source-products">
                        <div className="source-products-title">Sticky products:</div>
                        {globalProducts && globalProducts.map((item, index) => (
                            <div className={'product-container'} key={item.id}>
                                <span className="arrows">
                                    {index > 0 ? <span className={'caret-up'} onClick={moveGlobalItemUp(index)}>
                                        <Icon source={CaretUpMinor} /></span>
                                        : null}
                                    {index < globalProducts.length - 1 ?
                                        <span className={'caret-down'} onClick={moveGlobalItemDown(index)}>
                                            <Icon source={CaretDownMinor} /></span>
                                        : null}
                                </span>
                                {item.dynamic ?
                                <div className="collection-item-wrapper">
                                    <div className="collection-item-name">{displayFilterTypeAndValue(item.filter1_type, item.filter1_value)}</div>
                                    {item.filter2_type && <div>, {displayFilterTypeAndValue(item.filter2_type, item.filter2_value)}</div>}
                                    <span className="collection-item-products">({item.count} products)</span>
                                    <Button onClick={sortGlobalProducts(item.id)}>Sort</Button>
                                </div> :
                                <div className="sticky-item-wrapper">
                                    <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image"
                                             style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                        <NoImage itemId={item.id} klassName={'item-image'} />
                                    )}
                                    </span>
                                    <span className="item-title-wrapper">
                                        <span className="item-title product">{item.title}
                                    </span>
                                    { item.variants && item.variants.length > 1 &&
                                        <span className="select-variants-button">
                                            <Button onClick={onChooseGlobalVariants(item.id)}>Variants</Button>
                                        </span>
                                    }
                                </span>
                                </div>}

                                <span onClick={removeGlobalItem(item.id)} className="remove-item">
                                    <Icon source={DeleteMajor} />
                                </span>
                            </div>))}
                    </div>
                    <Modal open={isChooseGlobalVariantsModalOpen} onClose={closeChooseGlobalVariantsModal}
                           title="Choose sticky variants (for cart page only)"
                           primaryAction={{
                               content: 'Done',
                               onAction: chooseGlobalVariantsDone,
                           }}
                           secondaryActions={[
                               {
                                   content: 'Cancel',
                                   onAction: closeChooseGlobalVariantsModal,
                               },
                           ]}>
                        <Modal.Section>
                            {tempVariants && tempVariants.map(item =>
                                <div className="collection-item-wrapper" key={item.id}>
                                    <Checkbox label="" checked={item.is_shown} onChange={onGlobalVariantChecked(item.id)} />
                                    {item.image_src ? (
                                        <div className="collection-item-image" style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                        <NoImage itemId={item.id} klassName={'item-image'} />
                                    )}
                                    <span>{item.title}</span>
                                </div>)}
                        </Modal.Section>
                    </Modal>
                    <Modal open={sortGlobalProductsModalOpen} onClose={closeSortGlobalProductsModal}
                           title="Sort sticky products"
                           primaryAction={{
                               content: 'Done',
                               onAction: sortGlobalProductsDone,
                           }}
                           secondaryActions={[
                               {
                                   content: 'Cancel',
                                   onAction: closeSortGlobalProductsModal,
                               },
                           ]}>
                        <Modal.Section>
                            {tempSortGlobalItem && tempSortGlobalItem.products && tempSortGlobalItem.products.map((item, index) =>
                                <div className="collection-item-wrapper" key={item.id}>
                                    <span className="arrows-wrapper">
                                    {index > 0 && <span className={'caret-up'} onClick={moveTempGlobalItemUp(index)}>
                                    <Icon source={CaretUpMinor} /></span>}
                                    {index < tempSortGlobalItem.products.length - 1 &&
                                    <span className={'caret-down'} onClick={moveTempGlobalItemDown(index)}>
                                        <Icon source={CaretDownMinor} /></span>}</span>
                                    {item.image_src ? (
                                        <div className="collection-item-image" style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                        <NoImage itemId={item.id} klassName={'item-image'} />
                                    )}
                                    <span>{item.title}</span>
                                </div>)}
                        </Modal.Section>
                    </Modal>
                </div>

            }
            {
                isShowMappings &&
                <div className="mapping-table">
                    <div className="navigation product-mapping">
                        <span className="navigation-button">
                            <Button onClick={backToMain}>Back</Button>
                        </span>
                        <span className="navigation-button">
                            <Button primary onClick={onAddMapping}>Add mapping</Button>
                        </span>
                        {mappings && mappings.length > 1 && <div className="search-mapping">
                            <span className="search-mapping-input">

                                <span className="search-mapping-input-wrapper">
                                    {!productSearchDone && mappingsSearchBoxText && mappingsSearchBoxText !== ''  &&
                                    <span className="search-mapping-spinner"><Spinner size="small" /></span> }
                                    { mappings.length > 100 ? "" : <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                                           label="Search in mappings:" onFocus={onMappingSearchBoxFocus}
                                           value={mappingsSearchBoxText} onChange={onMappingsSearchBoxTextChange}
                                    />}
                                    </span>
                            </span>
                            { mappings.length > 100 ? "" :
                                <span className="where-to-search">
                                <Select label="Where to search:" options={mappingSearchOptions}
                                onChange={onMappingSearchTypeChange} value={mappingSearchType} />
                            </span> }
                            <div className="mapping-search-results">
                                <div className="mapping-search-results-inner">
                                {mappingsProductFilter && <div className="mapping-search-item filtered">
                                    <img alt="search-mapping" className="mapping-search-item-image" src={mappingsProductFilter.image_src} width="20px" height="20px"/>
                                    {mappingsProductFilter.title}
                                    on {mappingSearchType}
                                    <span className="search-mapping-delete" onClick={removeProductFilter} style={{cursor: 'pointer'}}>
                                        <Icon source={DeleteMajor} />
                                </span>
                                </div>}
                                {mappingsResults && mappingsResults.map(item => (
                                    <div className="mapping-search-item" key={item.id} style={{cursor: 'pointer'}} onClick={filterMappingByProduct(item.id)}>
                                        <img alt="search-mapping" className="mapping-search-item-image" src={item.image_src} width="20px" height="20px"/>
                                        <span>{item.title}</span>

                                    </div>
                                ))}
                                </div>
                        </div>
                        </div>}
                    </div>
                    {mappings.map((item, index) => (
                        <div key={item.id} className={`mapping-item${item.show ? '' : ' hidden'}`}>
                            {(!mappingsSearchBoxText || mappingsSearchBoxText === '') && <span className="mappinigs-arrows-wrapper"><span className="arrows">
                                {index > 0 && <span className={'caret-up'} onClick={moveMappingItem('up', index)}>
                                        <Icon source={CaretUpMinor} /></span>}
                                {index < mappings.length - 1 &&
                                    <span className={'caret-down'} onClick={moveMappingItem('down', index)}>
                                        <Icon source={CaretDownMinor} /></span>}
                            </span></span>}
                            <span className="mapping-content-wrapper">
                                <span className="item-name">
                                    <span className="item-index">{index + 1}.</span>
                                    <span> </span>
                                    {products.editMappings && products.editMappings[item.id] ?
                                        <span className="mapping-on-edit-wrapper">
                                            <TextField label={''} onChange={mappingNameEdit(item.id)} value={item.name} autoComplete={false} />
                                            <span onClick={editMappingNameComplete(item.id)}><Icon source={TickMinor} /></span>
                                        </span>  :
                                        <span className="mapping-edit-wrapper">{item.name}<span className="mapping-edit-action" onClick={editMappingName(item.id)}><Icon source={EditMinor} /></span></span>}
                                </span>

                                <span className="mapping-rule-wrapper">
                                     {item.destination_discount > 0 && <span className="mapping-rule">{item.destination_discount}% Discount</span>}
                                    {item.on_product_page && <span className="mapping-rule">Show on product</span>}
                                    {item.on_cart_page && <span className="mapping-rule">Show on cart</span>}
                                    {item.only_above_price && item.above_price >= 0 && <span className="mapping-rule">Above price {item.above_price}{currencySymbol}</span>}
                                    {item.only_below_price && item.below_price > 0 && <span className="mapping-rule">Below price {item.below_price}{currencySymbol}</span>}
                                    {item.only_above_quantity && item.above_quantity >= 0 && <span className="mapping-rule">Above quantity {item.above_quantity}</span>}
                                    {item.only_below_quantity && item.below_quantity > 0 && <span className="mapping-rule">Below quantity {item.below_quantity}</span>}
                                    {item.min_items_for_discount_enabled && <span className="mapping-rule">Min {item.min_items_for_discount} items for discount</span>}
                                    {item.max_items_for_discount_enabled && <span className="mapping-rule">Max {item.max_items_for_discount} items with discount</span>}
                                </span>
                            </span>

                            <span className="mapping-item-buttons">
                                <span className="button-wrapper switch-preview">
                                    <Button onClick={switchPreview(item.id)}
                                            loading={products.switchingPreview[item.id]}
                                            primary={item.preview_mode}
                                            disabled={!item.enabled || products.switchingPreview[item.id]}>
                                        {item.preview_mode ? 'Publish' : 'Unpublish'}
                                    </Button>
                                </span>
                                <span className="button-wrapper enable-disable" >
                                    <Button onClick={onEnableMapping(item.id)} loading={enableMappingLoading[item.id]}
                                            primary={!item.enabled}
                                            disabled={enableMappingLoading[item.id]}>{item.enabled ? 'Disable' : 'Enable'}</Button>
                                </span>
                                <span className="button-wrapper edit-map" >
                                    <Button  onClick={onEditMapping(item.id)} loading={editMappingLoading[item.id]}
                                            disabled={Object.values(editMappingLoading).some(o => o === true)}>Edit</Button>
                                </span>
                                <span className="button-wrapper map-dup" >
                                    <Button  onClick={onDupMapping(item.id)} loading={dupMappingLoading[item.id]}
                                            disabled={Object.values(dupMappingLoading).some(o => o === true)}>
                                        {!dupMappingLoading[item.id] && <Icon
                                            source={DuplicateMinor} />}
                                            </Button>
                                </span>
                                 <span data-tooltip="Switch Source & Destination" className="button-wrapper sd-switch">
                                    <Button  onClick={switchSourceDest(item.id)} loading={switchingSourceDest[item.id]}
                                            disabled={Object.values(switchingSourceDest).some(o => o === true)}>{!switchingSourceDest[item.id] && <Icon
                                        source={ReplaceMajor} />}</Button>
                                </span>
                                <span onClick={showRemoveMappingModal(item.id)} className="remove-item sd-mapping-item">
                                    <Icon source={DeleteMajor} />
                                </span>
                                <span className="view-item sd-mapping-item" onClick={previewOffer(item.id)}>
                                    <Icon source={ViewMajor} />
                                </span>
                            </span>
                        </div>
                    ))}
                    <Modal open={removeMappingModalOpen} onClose={closeRemoveMappingModal}
                        title="Delete product mapping"
                        primaryAction={{
                            content: 'Remove',
                            onAction: removeMapping,
                        }}
                        secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: closeRemoveMappingModal,
                            },
                        ]}>
                        <Modal.Section>
                            {removeMappingSpinner ? (
                                <span className="spinner-wrapper">
                                    <Spinner />
                                </span>
                            ) : <TextContainer>
                                    <p>
                                        Are you sure you want to delete product mapping <b>{mappingName}</b>?
                                    </p>
                                    {removeMappingError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                                </TextContainer>}

                        </Modal.Section>
                    </Modal>
                </div>
            }
            {
                isShowChooseSourceProducts &&
                <div className="mapping-table">
                    <div className="navigation-wrapper">
                        <span className="navigation-button">
                            <Button onClick={cancel}>Cancel</Button>
                        </span>
                        <span className="filter-wrapper">
                            <Popover
                                active={addAllToSrcByFilterActive}
                                activator={addAllToSrcByFilterActivator}
                                onClose={toggleAddAllToSrcByFilterActive}
                                ariaHaspopup={false}
                                sectioned
                            >
                                <FormLayout>
                                    <Select options={addByFilterOptions} onChange={onSrcFilterChange} value={srcFilter} />
                                    {srcFilter !== 'all' && <Select options={srcFilterOptions} onChange={onSrcFilterOptionChange} value={srcFilterOption} />}
                                    <Checkbox label="Dynamic grouping" checked={isDynamicGrouping}
                                              onChange={onDynamicGroupingChange} />
                                    <Checkbox label="Add another filter" checked={isAddAnotherSrcFilter} onChange={onAddAnotherSrcFilterChange} />
                                    {isAddAnotherSrcFilter ? (
                                        <FormLayout>
                                            <Select options={addByFilterOptions} onChange={onSrcFilterChange2} value={srcFilter2} />
                                            {srcFilter2 !== 'all' && <Select options={srcFilterOptions2} onChange={onSrcFilterOptionChange2} value={srcFilterOption2} />}
                                        </FormLayout>

                                    ) : null}
                                    <Button primary onClick={onAddAllToSrcByFilter} loading={isAddingAllToSrcByFilter}>Add</Button>
                                </FormLayout>
                            </Popover>
                        </span>
                        <span className="navigation-button">
                            <Button onClick={onShowChooseDest} primary>Next</Button>
                        </span>
                        {destProducts && destProducts.length > 0 && <span className="navigation-button save">
                            <Button onClick={onSave} loading={isSavingMapping}>Save</Button>
                        </span>}
                    </div>
                    <div className="search-products">
                        {sourceProductsEmptyError ?
                            <TextStyle variation="negative">Source products can't be empty</TextStyle> : null}
                        <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                            label="Add products to source mapping:"
                            value={searchBoxText} onChange={onChangeSourceSearchText}
                            onFocus={onFocusSourceSearchText} />
                        {showSearchSpinner ? <div className="fetch-search-wrapper"><span
                            className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                                size="small" /></span></div> : null}
                        {searchError ? <TextStyle variation="negative">Something went wrong</TextStyle> : null}
                        {searchedProducts && searchedProducts.map((item) => (
                            <div className={'product-container searchable'} key={item.id}
                                onClick={addSourceItem(item.id)}>
                                <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image"
                                            style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                            <NoImage itemId={item.id} klassName={'item-image'} />
                                        )}
                                </span>
                                <span className="item-title-wrapper">
                                    <span className="item-title searchable">{item.title}</span>
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="source-products">
                        <div className="source-products-title">Source products: <span className="field-tip">(product pages of which the offer will appear on)</span></div>
                        {sourceProducts.map((item) => (
                            <div className={'product-container'} key={item.id}>
                                {item.dynamic ?
                                <div className="collection-item-wrapper">
                                    <div className="collection-item-name">
                                        {displayFilterTypeAndValue(item.filter1_type, item.filter1_value)}
                                    </div>
                                    {item.filter2_type && <div>, {displayFilterTypeAndValue(item.filter2_type, item.filter2_value)}</div>}
                                    { (item.filter1_type === "all") ? "" :  <span className="collection-item-products">({item.count} products)</span> }
                                </div> :
                                <div className="source-item-wrapper">
                                    <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image"
                                             style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                        <NoImage itemId={item.id} klassName={'item-image'} />
                                    )}
                                    </span>
                                    <span className="item-title-wrapper">
                                        <span className="item-title product">{item.title}</span>
                                    </span>
                                    {item.variants && item.variants.length > 1 &&
                                    <span className="select-variants-button">
                                        <Button onClick={onChooseSourceVariants(item.id)}>Variants</Button>
                                    </span>}

                                </div>
                                }
                                <span onClick={removeSourceItem(item.id)} className="remove-item">
                                    <Icon source={DeleteMajor} />
                                </span>
                            </div>))}
                    </div>
                    <Modal open={isChooseSourceVariantsModalOpen} onClose={closeChooseSourceVariantsModal}
                           title="Choose source variants (for cart page only)"
                           primaryAction={{
                               content: 'Done',
                               onAction: chooseSourceVariantsDone,
                           }}
                           secondaryActions={[
                               {
                                   content: 'Cancel',
                                   onAction: closeChooseSourceVariantsModal,
                               },
                           ]}>
                        <Modal.Section>
                            {tempVariants && tempVariants.map(item =>
                                <div className="collection-item-wrapper" key={item.id}>
                                    <Checkbox label="" checked={item.is_shown} onChange={onSourceVariantChecked(item.id)} />
                                    {item.image_src ? (
                                        <div className="collection-item-image" style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                        <NoImage itemId={item.id} klassName={'item-image'} />
                                    )}
                                    <span>{item.title}</span>
                                </div>)}
                        </Modal.Section>
                    </Modal>
                </div>
            }
            {
                isShowChooseDestProducts &&
                <div className="mapping-table">
                    <div className="navigation-wrapper">
                        <span className="navigation-button">
                            <Button onClick={cancel}>Cancel</Button>
                        </span>
                        <span className="navigation-button">
                            <Button onClick={showSourceProducts}>Back</Button>
                        </span>
                        <span className="filter-wrapper">
                            <Popover
                                active={addAllToDestByFilterActive}
                                activator={addAllToDestByFilterActivator}
                                onClose={toggleAddAllToDestByFilterActive}
                                ariaHaspopup={false}
                                sectioned
                            >
                                <FormLayout>
                                    <Select options={addByFilterOptions} onChange={onDestFilterChange} value={destFilter} />
                                    {destFilter !== 'all' && <Select options={destFilterOptions} onChange={onDestFilterOptionChange} value={destFilterOption} />}
                                    <Checkbox label="Dynamic grouping" checked={isDynamicGrouping}
                                              onChange={onDynamicGroupingChange} />
                                    <Checkbox label="Add another filter" checked={isAddAnotherDestFilter} onChange={onAddAnotherDestFilterChange} />
                                    {isAddAnotherDestFilter ? (
                                        <FormLayout>
                                            <Select options={addByFilterOptions} onChange={onDestFilterChange2} value={destFilter2} />
                                            {destFilter2 !== 'all' && <Select options={destFilterOptions2} onChange={onDestFilterOptionChange2} value={destFilterOption2} />}
                                        </FormLayout>

                                    ) : null}
                                    <Button primary onClick={onAddAllToDestByFilter} loading={isAddingAllToDestByFilter}>Add</Button>
                                </FormLayout>
                            </Popover>
                        </span>
                        <span className="navigation-button">
                            <Button primary onClick={chooseMappingRules}>Next</Button>
                        </span>
                        {destProducts && destProducts.length > 0 && <span className="navigation-button save">
                            <Button onClick={onSave} loading={isSavingMapping}>Save</Button>
                        </span>}
                    </div>
                    <div>
                        <div className="search-products">
                            {destProductsEmptyError ?
                                <div><TextStyle variation="negative">Destination products can't be empty</TextStyle>
                                </div> : null}
                            <TextField autoComplete={false} placeholder='Search phrase, e.g. Shirts for men'
                                label="Add products destination mapping:" value={searchBoxText}
                                onChange={onChangeDestSearchText} onFocus={onFocusDestSearchText} />
                            {showSearchSpinner ? <div className="fetch-search-wrapper"><span
                                className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                                    size="small" /></span></div> : null}
                            {searchError ? <TextStyle variation="negative">Something went wrong</TextStyle> : null}
                            {searchedProducts && searchedProducts.map((item) => (
                                <div className={'product-container searchable'} key={item.id}
                                    onClick={addDestItem(item.id)}>
                                    <span className="image-wrapper">
                                        {item.image_src ? (
                                            <div className="item-image"
                                                style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                        ) : (
                                                <NoImage itemId={item.id} klassName={'item-image'} />
                                            )}
                                    </span>
                                    <span className="item-title searchable">{item.title}</span>
                                </div>
                            ))}
                        </div>
                        <div className="source-products dest">
                            {!randomizeDestProducts && exceedMaxDestProducts && <div style={{color: '#ff5959'}}>
                                Per your slider settings, only the first {maxProducts} will be displayed.
                            </div>}


                            <div className="dest-products-title">Destination products: <span className="field-tip dest-tip">(products which will appear in the offer)</span><span className="randomize-dest">
                            <Checkbox label="Randomize" checked={randomizeDestProducts} onChange={onRandomizeChange} />
                            </span></div>
                            { destProducts && destProducts.map((item, index) => (
                                <div className={`product-container`} key={item.id}>
                                    {!randomizeDestProducts && <span className="arrows">
                                        {index > 0 ? <span className={'caret-up'} onClick={moveDestItemUp(index)}>
                                            <Icon source={CaretUpMinor} /></span>
                                            : null}
                                        {index < destProducts.length - 1 ?
                                            <span className={'caret-down'} onClick={moveDestItemDown(index)}>
                                                <Icon source={CaretDownMinor} /></span>
                                            : null}
                                    </span>}
                                    {item.dynamic ?
                                    <div className="collection-item-wrapper">
                                        <div className="collection-item-name">{displayFilterTypeAndValue(item.filter1_type, item.filter1_value)}</div>
                                        {item.filter2_type && <div>, {displayFilterTypeAndValue(item.filter2_type, item.filter2_value)}</div>}
                                        { (item.filter1_type === "all") ? "" : <span className="collection-item-products">({item.count} products)</span>}
                                        <span className="dest-float-right">
                                            <span className="select-variants-button">
                                                <Button onClick={sortDestProducts(item.id)}>Sort</Button>
                                            </span>
                                            <span onClick={removeDestItem(item.id)} className={'remove-item'}>
                                                <Icon source={DeleteMajor} />
                                            </span>
                                        </span>
                                    </div> :
                                    <div className="dest-item-wrapper">
                                        <span className="image-wrapper">
                                        {item.image_src ? (
                                            <div className="collection-item-image"
                                                 style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                        ) : (
                                            <NoImage itemId={item.id} klassName={'item-image'} />
                                        )}
                                        </span>
                                        <span className="item-title product">{item.title}</span>
                                        <span className="dest-float-right">
                                            {item.variants && item.variants.length > 1 && <span className="select-variants-button">
                                                <Button onClick={onChooseDestVariants(item.id)}>Variants</Button>
                                            </span>}
                                            <span onClick={removeDestItem(item.id)} className={'remove-item'}>
                                                <Icon source={DeleteMajor} />
                                            </span>
                                        </span>
                                    </div>}
                                </div>))}
                        </div>
                    </div>
                    <Modal open={sortDestProductsModalOpen} onClose={closeSortDestProductsModal}
                           title="Sort destination products"
                           primaryAction={{
                               content: 'Done',
                               onAction: sortDestProductsDone,
                           }}
                           secondaryActions={[
                               {
                                   content: 'Cancel',
                                   onAction: closeSortDestProductsModal,
                               },
                           ]}>
                        <Modal.Section>
                            {tempSortDestItem && tempSortDestItem.products && tempSortDestItem.products.map((item, index) =>
                            <div key={item.id} className="collection-item-wrapper">
                                <span className="arrows-wrapper">
                            {index > 0 && <span className={'caret-up'} onClick={moveTempDestItemUp(index)}>
                                    <Icon source={CaretUpMinor} /></span>}
                            {index < tempSortDestItem.products.length - 1 &&
                                <span className={'caret-down'} onClick={moveTempDestItemDown(index)}>
                                        <Icon source={CaretDownMinor} /></span>}
                                </span>
                                {item.image_src ? (
                                    <div className="collection-item-image" style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                ) : (
                                    <NoImage itemId={item.id} klassName={'item-image'} />
                                )}
                                <span>{item.title}</span>

                            </div>)}

                        </Modal.Section>
                    </Modal>
                    <Modal open={isChooseDestVariantsModalOpen} onClose={closeChooseDestVariantsModal}
                           title="Choose destination variants"
                           primaryAction={{
                               content: 'Done',
                               onAction: chooseDestVariantsDone,
                           }}
                           secondaryActions={[
                               {
                                   content: 'Cancel',
                                   onAction: closeChooseDestVariantsModal,
                               },
                           ]}>
                        <Modal.Section>
                            {tempVariants && tempVariants.map(item =>
                            <div className="collection-item-wrapper" key={item.id}>
                                <Checkbox label="" checked={item.is_shown} onChange={onDestVariantChecked(item.id)} />
                                {item.image_src ? (
                                    <div className="collection-item-image" style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                ) : (
                                    <NoImage itemId={item.id} klassName={'item-image'} />
                                )}
                                <span>{item.title}</span>

                            </div>)}

                        </Modal.Section>
                    </Modal>
                </div>
            }
            {
                isShowChooseMappingName &&
                <div className="mapping-table">
                    <span className="navigation-button">
                        <Button onClick={cancel}>Cancel</Button>
                    </span>
                    <span className="navigation-button">
                        <Button onClick={showMappingRules}>Back</Button>
                    </span>
                    <span className="navigation-button">
                        <Button onClick={onSave} loading={isSavingMapping} primary>Save</Button>
                    </span>
                    {errorEmptyName && <div><TextStyle variation="negative">Name can't be empty</TextStyle></div>}
                    <TextField autoComplete={false} label="Name:" value={mappingName}
                        onChange={onChangeMappingName} />
                    {saveError && <div><TextStyle variation="negative">Something went wrong</TextStyle></div>}
                    <div className='mapping-title-locale-wrapper'>
                    <div className='mapping-title-wrapper'>
                    { products.mappingUseTexts ?
                        <div>
                            <TextField autoComplete={false} label="Title:"
                                       value={products.mappingTexts[products.locale].title} onChange={changeMappingText('title')} />
                            <div className="field-tip">* Overrides slider settings title on product pages</div>
                            <TextField autoComplete={false} label="Subtitle:"
                                       value={products.mappingTexts[products.locale].subtitle} onChange={changeMappingText('subtitle')} />
                            <div className="field-tip">* Overrides slider settings subtitle on product pages</div>
                        </div> :  <div>
                            <TextField autoComplete={false} label="Title:"
                                       value={mappingTitle} onChange={onChangeMappingTitle} />
                            <div className="field-tip">* Overrides slider settings title on product pages</div>
                            <TextField autoComplete={false} label="Subtitle:"
                                       value={mappingSubtitle} onChange={onChangeMappingSubtitle} />
                            <div className="field-tip">* Overrides slider settings subtitle on product pages</div>
                        </div>}</div>
                        <div className='mapping-locale-wrapper'>
                            <Select label={'Translate texts'} onChange={mappingUseTextsChange}
                                value={products.mappingUseTexts ? 'yes' : 'no'} options={mappingUseTextsOptions} />
                            {products.mappingUseTexts && <Select options={products.locales} onChange={onLocaleChange} value={products.locale} />}
                        </div>

                    </div>
                    <TextField autoComplete={false} label="Discount on destination products in %:" type="number"
                               value={destinationDiscount && destinationDiscount.toString()} onChange={onDestinationDiscountChange} />
                    <div className="field-tip">* Discount is applied only if slider item is bought together with main product</div>
                    <div style={{marginTop: '15px'}}>
                        <Card>
                            <div style={{padding: '10px'}}>
                                <Checkbox label="Minimum slider items purchased for discount"
                                          checked={minItemsForDiscountEnabled}
                                          onChange={onMinItemsForDiscountEnabledChanged} />
                                {minItemsForDiscountEnabled &&
                                <TextField autoComplete={false} label="Quantity:" type="number"
                                           value={minItemsForDiscount && minItemsForDiscount.toString()} onChange={onMinItemsForDiscountChange} />
                                }
                            </div>
                        </Card>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <Card>
                            <div style={{padding: '10px'}}>
                                <Checkbox label="Maximum slider items purchased for discount"
                                          checked={maxItemsForDiscountEnabled}
                                          onChange={onMaxItemsForDiscountEnabledChanged} />
                                {maxItemsForDiscountEnabled &&
                                <TextField autoComplete={false} label="Quantity:" type="number"
                                           value={maxItemsForDiscount && maxItemsForDiscount.toString()} onChange={onMaxItemsForDiscountChange} />
                                }
                            </div>
                        </Card>
                    </div>

                </div>
            }
            {
                isShowIncluded &&
                <div className="mapping-table">
                    <div>
                        <span className="navigation-button">
                            <Button onClick={backToMain}>Back</Button>
                        </span>
                        <span className="navigation-button">
                            <Button onClick={onSaveIncluded} loading={isSavingIncluded} primary>Save</Button>
                        </span>
                    </div>
                    {saveError ? <div><TextStyle variation="negative">Something went wrong</TextStyle></div> : null}
                    <div className="search-products">
                        <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                            label="Add products to be limited:"
                            value={searchBoxText} onChange={onChangeIncludedSearchText}
                            onFocus={onFocusIncludedSearchText} />
                        {showSearchSpinner ? <div className="fetch-search-wrapper"><span
                            className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                                size="small" /></span></div> : null}
                        {searchError ? <TextStyle variation="negative">Something went wrong</TextStyle> : null}
                        {searchedProducts && searchedProducts.map((item) => (
                            <div className={'product-container searchable'} key={item.id}
                                onClick={addIncludedItem(item.id)}>
                                <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image"
                                            style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                            <NoImage itemId={item.id} klassName={'item-image'} />
                                        )}
                                </span>
                                <span className="item-title-wrapper">
                                    <span className="item-title searchable">{item.title}</span>
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="source-products">
                        <div className="source-products-title">Limited products:</div>
                        {includedProducts && includedProducts.map((item) => (
                            <div className={'product-container'} key={item.id}>
                                <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image"
                                            style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                            <NoImage itemId={item.id} klassName={'item-image'} />
                                        )}
                                </span>
                                <span className="limited-title-wrapper">
                                    <span className="item-title product">{item.title}</span>
                                </span>
                                <span onClick={removeIncludedItem(item.id)} className="remove-item">
                                    <Icon source={DeleteMajor} />
                                </span>
                            </div>))}
                    </div>
                </div>
            }
            {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast} />}
            {dynamicToastIsActive && <Toast content={dynamicToastContent} error={dynamicToastError}
                                            onDismiss={onDismissDynamicToast} />}
            {videoModal('Product Mapping Tutorial', products.videoModals.productMapping, toggleVideo('productMapping'),
                'https://public.zoorix.com/videos/product-mappings-porster.png', 'https://public.zoorix.com/videos/product-mappings-tutorial.mp4')}
            {videoModal('Exlude Items Tutorial', products.videoModals.excludeItems, toggleVideo('excludeItems'),
                'https://public.zoorix.com/videos/exlude-items-poster.png', 'https://public.zoorix.com/videos/exclude-items.mp4')}
            {videoModal('Import Export Tutorial', products.videoModals.importExport, toggleVideo('importExport'),
                'https://public.zoorix.com/videos/import-export-poster.png', 'https://public.zoorix.com/videos/import-export-turotial.mp4')}
        </Frame>
    );
};


export default ProductsSlider;
