import React from 'react'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AppProvider } from "@shopify/polaris";
import enTranslations from '@shopify/polaris/locales/en.json';
import {Provider as AppBridgeProvider} from '@shopify/app-bridge-react';
import useShopifyAppBridge from '../actions/useShopifyAppBridge';
import { getSessionToken } from '@shopify/app-bridge-utils';

const App = React.lazy(() => import('./App'));
const VisibleRedeem = React.lazy(() => import("./VisibleRedeem"));
const VisibleFeedback = React.lazy(() => import("./feedback/VisibleFeedback"));
const VisibleUnsubscribed = React.lazy(() => import("./VisibleUnsubscribed"));
const VisibleSubscribed = React.lazy(() => import("./VisibleSubscribed"));
const VisibleFeedbackLandingPage = React.lazy(() => import("./feedback/VisibleFeedbackLandingPage"));
const VisiblePartners = React.lazy(() => import("./partners/VisiblePartners"));
const VisibleRef = React.lazy(() => import("./partners/VisibleRef"));
const VisiblePartnersAdmin = React.lazy(() => import("./partners/VisiblePartnersAdmin"));
const VisibleSupportFeedback = React.lazy(() => import("./feedback/VisibleSupportFeedback"));
const VisibleInstall = React.lazy(() => import("./VisibleInstall"));
const VisibleAppV2 = React.lazy(() => import("./v2/VisibleAppV2"));
const VisibleRefBrand = React.lazy(() => import("./partners/VisibleRefBrand"));
const ExitIframe = React.lazy(() => import("./ExitIframe"));
const PrivacyPolicy = React.lazy(() => import("../components/PrivacyPolicy"));
const WarrantyInfo = React.lazy(() => import("../components/WarrantyInfo"));

const config = {
    apiKey: '878ee40e7b3300a1eea9cbb74df480fb',
    host: new URLSearchParams(window.location.search).get("host") || btoa('localhost'),
    forceRedirect: false
};

const Root = ({ store }) => {
    const AppBridgeInstance =  useShopifyAppBridge(config.apiKey, config.host);
    console.log(AppBridgeInstance,'AppBridgeInstance');
    useEffect(() => {
        if (AppBridgeInstance) {
            const fetchSessionToken = async () => {
                try {
                    const token = await getSessionToken(AppBridgeInstance);
                    console.log(token, 'token');
                } catch (error) {
                    console.error('Error fetching session token:', error);
                }
            };
            fetchSessionToken();
        }
    }, [AppBridgeInstance]);

    return (
    <AppBridgeProvider config={config}>
    <Provider store={store}>
        <Router>
            <AppProvider i18n={enTranslations}>
                <Switch>
                    <Route path="/v2/:shop/:token" component={VisibleAppV2} store={store}/>
                    <Route path="/install" component={VisibleInstall} store={store}/>
                    <Route path="/partners" component={VisiblePartners} store={store}/>
                    <Route path="/partners_admin/:sub/:secret" component={VisiblePartnersAdmin} store={store}/>
                    <Route path="/ref/:code/:brand" component={VisibleRefBrand} store={store}/>
                    <Route path="/ref/:code" component={VisibleRef} store={store}/>
                    <Route path="/support_feedback/:rating" component={VisibleSupportFeedback} store={store}/>
                    <Route path="/feedback_lp/:token" component={VisibleFeedbackLandingPage} store={store}/>
                    <Route path="/feedback/:token/:rating" component={VisibleFeedback} store={store}/>
                    <Route path="/unsubscribed_from_app/:app/:shop_id" component={VisibleUnsubscribed} store={store}/>
                    <Route path="/unsubscribed/:token" component={VisibleUnsubscribed} store={store}/>
                    <Route path="/subscribed_to_app/:app/:shop_id" component={VisibleSubscribed} store={store}/>
                    <Route path="/subscribed/:token" component={VisibleSubscribed} store={store}/>
                    <Route path="/rdm/:redeem_token" component={VisibleRedeem} store={store}/>
                    <Route path="/:storeName/:token/create_mapping/:action/:product_id" component={App} store={store} />
                    <Route path="/sub_app/:app_type/:storeName/:token" component={App} store={store} />
                    <Route path="/home/index" component={App} store={store}/>
                    <Route path="/bla" component={App} store={store}/>
                    <Route path="/:storeName/:token" component={App} store={store} />
                    <Route path="/front/exit_iframe" component={ExitIframe} />
                    <Route exact path="/privacy" component={PrivacyPolicy} />
                    <Route exact path="/warranty-info" component={WarrantyInfo} />
                </Switch>
            </AppProvider>
        </Router>
    </Provider>
    </AppBridgeProvider>
    );
};

Root.propTypes = {
    store: PropTypes.object.isRequired
};

export default Root
