import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";

import {
    CLOSE_REDEEM_MODAL,
    INIT_REDEEM,
    INIT_REDEEM_FAILURE,
    PRE_INIT_REDEEM, REDEEM_ACTION_FAILURE, REDEEM_ACTION_START, REDEEM_ACTION_SUCCESS, REDEEM_IMAGE_UPLOAD_FAILURE,
    REDEEM_IMAGE_UPLOAD_SUCCESS,
    REDEEM_ITEM, REDEEM_NO_IMAGE, REDEEM_REQUEST_BODY_CHANGE
} from "../actions/redeem";

const redeem = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case PRE_INIT_REDEEM:
            newState = _cloneDeep(state);
            newState.initialized = true;
            break;
        case INIT_REDEEM:
            newState = _cloneDeep(state);
            newState.lineItems = _cloneDeep(action.lineItems);
            newState.currency = action.currency;
            break;
        case INIT_REDEEM_FAILURE:
            newState = _cloneDeep(state);
            newState.failInit = true;
            break;
        case REDEEM_ITEM:
            newState = _cloneDeep(state);
            newState.itemId = action.id;
            const item = _find(newState.lineItems, {id: action.id});
            newState.showUploadedImage = item.has_image;
            newState.requestBody = item.request_body;
            newState.redeemModalOpen = true;
            break;
        case CLOSE_REDEEM_MODAL:
            newState = _cloneDeep(state);
            newState.redeemModalOpen = false;
            newState.itemId = null;
            newState.showUploadedImage = false;
            newState.imageUploadError = false;
            break;
        case REDEEM_IMAGE_UPLOAD_SUCCESS:
            newState = _cloneDeep(state);
            newState.showUploadedImage = true;
            newState.imageUploadError = false;
            break;
        case REDEEM_IMAGE_UPLOAD_FAILURE:
            newState = _cloneDeep(state);
            newState.imageUploadError = true;
            break;
        case REDEEM_REQUEST_BODY_CHANGE:
            newState = _cloneDeep(state);
            newState.requestBody = action.text;
            break;
        case REDEEM_ACTION_START:
            newState = _cloneDeep(state);
            newState.redeemSpinner = true;
            newState.noImageError = false;
            break;
        case REDEEM_NO_IMAGE:
            newState = _cloneDeep(state);
            newState.noImageError = true;
            break;
        case REDEEM_ACTION_FAILURE:
            newState = _cloneDeep(state);
            newState.redeemSpinner = false;
            newState.redeemError = true;
            break;
        case REDEEM_ACTION_SUCCESS:
            newState = _cloneDeep(state);
            newState.redeemSpinner = false;
            newState.redeemError = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default redeem;
