import fetch from "cross-fetch";
import {host, postPayload} from "./data";
import {bundlesSetPagesShown} from "./productsBundle";
import {volumeSetPagesShown} from "./productsVolume";
import {mixAndMatchSetState} from "./mixAndMatch";
import {bogoSetDisplay} from "./bogo";

export const LOAD_PAGES_SUCCESS = 'LOAD_PAGES_SUCCESS';
export const PAGES_POPULATE_OFFERS = 'PAGES_POPULATE_OFFERS';
export const CREATE_PAGE = 'CREATE_PAGE';
export const MANAGE_PAGES = 'MANAGE_PAGES';
export const PAGES_LOADING_OFFERS = 'PAGES_LOADING_OFFERS';
export const EDIT_PAGE = 'EDIT_PAGE';
export const PAGE_DELETING = 'PAGE_DELETING';
export const PAGES_MODE_OFFER_UP = 'PAGES_MODE_OFFER_UP';
export const PAGES_MODE_OFFER_DOWN = 'PAGES_MODE_OFFER_DOWN';
export const PAGES_DELETE_OFFER = 'PAGES_DELETE_OFFER';
export const PAGES_SELECT_OFFER = 'PAGES_SELECT_OFFER';
export const PAGES_SHOW_COMPONENT = 'PAGES_SHOW_COMPONENT';
export const PAGES_SET_CONTEXT = 'PAGES_SET_CONTEXT';
export const PAGE_STRING_CHANGE = 'PAGE_STRING_CHANGE';
export const PAGE_SAVING = 'PAGE_SAVING';

export const createPage = () => ({
  type: CREATE_PAGE,
});

export const pageSaving = bool => ({
  type: PAGE_SAVING,
  bool,
});

export const editPage = id => ({
  type: EDIT_PAGE,
  id,
});

export const pageDeleting = (id, bool) => ({
  type: PAGE_DELETING,
  id,
  bool,
});

export const pagesModeOfferUp = id => ({
  type: PAGES_MODE_OFFER_UP,
  id,
});

export const pagesModeOfferDown = id => ({
  type: PAGES_MODE_OFFER_DOWN,
  id,
});

export const pagesDeleteOffer = index => ({
  type: PAGES_DELETE_OFFER,
  index,
});

export const pagesSelectOffer = offer => ({
  type: PAGES_SELECT_OFFER,
  offer,
});

export const pagesShowComponent = text => ({
  type: PAGES_SHOW_COMPONENT,
  text,
});

export const pagesSetContext = text => ({
  type: PAGES_SET_CONTEXT,
  text,
});

export const pageStringChange = (attr, text) => ({
  type: PAGE_STRING_CHANGE,
  attr,
  text,
});

export const managePages = () => ({
  type: MANAGE_PAGES,
});

export const pagesLoadingOffers = bool => ({
  type: PAGES_LOADING_OFFERS,
  bool,
});

export const loadPagesSuccess = json => ({
  type: LOAD_PAGES_SUCCESS,
  pages: json.pages,
  json,
});

export const pagesPopulateOffers = json => ({
  type: PAGES_POPULATE_OFFERS,
  json,
});

export const loadPages = () => async (dispatch, getState) => {
  const state = getState();
  const response = await fetch(`https://${host}/pages/get_all?shop_name=${state.data.shopName}&token=${state.data.token}`)
  if (response.ok) {
    const json = await response.json();
    dispatch(loadPagesSuccess(json));
  } else {
    // do nothing
  }
};

export const createOrManagePages = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(bundlesSetPagesShown());
  dispatch(volumeSetPagesShown());
  dispatch(mixAndMatchSetState('pages'));
  dispatch(bogoSetDisplay('pages'));
  if (state.pages.pages.length === 0) {
    dispatch(pagesShowComponent('edit_multi_offer_page'));
    dispatch(createPage());
  } else {
    dispatch(pagesShowComponent('manage'));
    dispatch(managePages())
  }
};

export const pagesLoadOffers = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(pagesLoadingOffers(true));
  const res = await postPayload('data/get_all_offer_data_for_search', {}, state);
  if (res.ok) {
    const json = await res.json();
    dispatch(pagesPopulateOffers(json));
  } else {
    console.log('pagesLoadOffers failed');
  }
  dispatch(pagesLoadingOffers(false));
};

export const savePage = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(pageSaving(true));
  const res = await postPayload('pages/save', {page: state.pages.page}, state);
  if (res.ok) {
    const json = await res.json();
    dispatch(loadPagesSuccess(json));
    dispatch(pagesShowComponent('manage'));
  } else {
    console.log('savePage failed');
  }
  dispatch(pageSaving(false));
};

export const deletePage = id => async (dispatch, getState) => {
  const state = getState();
  dispatch(pageDeleting(id, true));
  const res = await postPayload('pages/delete', {id}, state);
  if (res.ok) {
    const json = await res.json();
    dispatch(loadPagesSuccess(json));
  } else {
    console.log('deletePage failed');
  }
  dispatch(pageDeleting(id, false));
};
