import fetch from "cross-fetch";
import {host} from "./data";
import {refreshSyncStatus} from "./status";

export const INIT_BUNDLE_SETTINGS = 'INIT_BUNDLE_SETTINGS';
export const ACTIVATE_TOAST = 'ACTIVATE_TOAST';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const RESTORE_BUNDLE_TO_DEFAULTS = 'RESTORE_BUNDLE_TO_DEFAULTS';
export const SAVE_BUNDLE_SETTINGS_PROCESSING = 'SAVE_BUNDLE_SETTINGS_PROCESSING';
export const SAVE_BUNDLE_SETTINGS_ERROR = 'SAVE_BUNDLE_SETTINGS_ERROR';
export const BUNDLE_TITLE_ALIGN = 'BUNDLE_TITLE_ALIGN';
export const BUNDLE_COMPARE_PRICE_SIZE_CHANGE = 'BUNDLE_COMPARE_PRICE_SIZE_CHANGE';
export const BUNDLE_PRICE_SIZE_CHANGE = 'BUNDLE_PRICE_SIZE_CHANGE';
export const BUNDLE_TITLE_FONT_SIZE_CHANGE = 'BUNDLE_TITLE_FONT_SIZE_CHANGE';
export const BUNDLE_FONT_FAMILY_CHANGE = 'BUNDLE_FONT_FAMILY_CHANGE';
export const BUNDLE_NAVIGATE_TO_CHANGE = 'BUNDLE_NAVIGATE_TO_CHANGE';
export const BUNDLE_CURRENCY_POSITION_CHANGE = 'BUNDLE_CURRENCY_POSITION_CHANGE';
export const BUNDLE_SHOW_SAVE_CHANGE = 'BUNDLE_SHOW_SAVE_CHANGE';
export const BUNDLE_DESELECT_BUNDLE_CHECKBOXES_CHANGE = 'BUNDLE_DESELECT_BUNDLE_CHECKBOXES_CHANGE';
export const BUNDLE_SETTINGS_STRING_CHANGE = 'BUNDLE_SETTINGS_STRING_CHANGE';
export const BUNDLE_TITLE_FONT_WEIGHT_CHANGE = 'BUNDLE_TITLE_FONT_WEIGHT_CHANGE';
export const ADD_SELECTED_TO_CART_TEXT_COLOR_CHANGE = 'ADD_SELECTED_TO_CART_TEXT_COLOR_CHANGE';
export const ADD_SELECTED_TO_CART_BUTTON_COLOR_CHANGE = 'ADD_SELECTED_TO_CART_BUTTON_COLOR_CHANGE';
export const TOTAL_PRICE_TEXT_COLOR_CHANGE = 'TOTAL_PRICE_TEXT_COLOR_CHANGE';
export const BUNDLE_COMPARE_PRICE_COLOR_CHANGE = 'BUNDLE_COMPARE_PRICE_COLOR_CHANGE';
export const BUNDLE_PRICE_COLOR_CHANGE = 'BUNDLE_PRICE_COLOR_CHANGE';
export const BUNDLE_TITLE_COLOR_CHANGE = 'BUNDLE_TITLE_COLOR_CHANGE';
export const BUNDLE_ITEM_PRICE_COLOR_CHANGE = 'BUNDLE_ITEM_PRICE_COLOR_CHANGE';
export const BUNDLE_ITEM_NAME_COLOR_CHANGE = 'BUNDLE_ITEM_NAME_COLOR_CHANGE';
export const HIDE_BUNDLE_COMPARE_PRICE_CHANGE = 'HIDE_BUNDLE_COMPARE_PRICE_CHANGE';
export const SUM_COMPARE_PRICES_CHANGE = 'SUM_COMPARE_PRICES_CHANGE';
export const SHOW_BUNDLE_ITEM_COMPARE_CHANGE = 'SHOW_BUNDLE_ITEM_COMPARE_CHANGE';
export const BUNDLE_MAIN_ENABLED_CHANGE = 'BUNDLE_MAIN_ENABLED_CHANGE';
export const BUNDLE_BOTTOM_ENABLED_CHANGE = 'BUNDLE_BOTTOM_ENABLED_CHANGE';
export const INFO_POPUP_BUNDLE_CHANGE = 'INFO_POPUP_BUNDLE_CHANGE';
export const BUNDLE_QUICK_VIEW_ENABLED_CHANGE = 'BUNDLE_QUICK_VIEW_ENABLED_CHANGE';
export const BUNDLE_PRODUCT_DESC_ON_QUICK_VIEW_CHANGE = 'BUNDLE_PRODUCT_DESC_ON_QUICK_VIEW_CHANGE';
export const BUNDLE_CUT_ZEROS_CHANGE = 'BUNDLE_CUT_ZEROS_CHANGE';
export const BUNDLE_REFRESH_ON_CART_CHANGE = 'BUNDLE_REFRESH_ON_CART_CHANGE';
export const REGENERATE_AUTO_BUNDLES_CHANGE = 'REGENERATE_AUTO_BUNDLES_CHANGE';
export const SAVE_BUNDLE_SETTINGS_SUCCESS = 'SAVE_BUNDLE_SETTINGS_SUCCESS';

export const initBundleSettings = json => ({
    type: INIT_BUNDLE_SETTINGS,
    settings: json.bundle_settings,
    planName: json.plan_name,
});

export const activateToast = () => ({
    type: ACTIVATE_TOAST,
});

export const saveBundleSettingsSuccess = () => ({
    type: SAVE_BUNDLE_SETTINGS_SUCCESS,
});

export const dismissToast = () => ({
    type: DISMISS_TOAST,
});

export const restoreBundleToDefaults = () => ({
    type: RESTORE_BUNDLE_TO_DEFAULTS,
});

export const saveBundleSettingsProcessing = () => ({
    type: SAVE_BUNDLE_SETTINGS_PROCESSING,
});

export const saveBundleSettingsError = () => ({
    type: SAVE_BUNDLE_SETTINGS_ERROR,
});

export const bundleTitleAlign = text => ({
    type: BUNDLE_TITLE_ALIGN,
    text,
});

export const bundleComparePriceSizeChange = text => ({
    type: BUNDLE_COMPARE_PRICE_SIZE_CHANGE,
    text,
});

export const bundlePriceSizeChange = text => ({
    type: BUNDLE_PRICE_SIZE_CHANGE,
    text,
});

export const bundleTitleFontSizeChange = text => ({
    type: BUNDLE_TITLE_FONT_SIZE_CHANGE,
    text,
});

export const bundleFontFamilyChange = text => ({
    type: BUNDLE_FONT_FAMILY_CHANGE,
    text,
});

export const bundleNavigateToChange = text => ({
    type: BUNDLE_NAVIGATE_TO_CHANGE,
    text,
});

export const bundleCurrencyPositionChange = text => ({
    type: BUNDLE_CURRENCY_POSITION_CHANGE,
    text,
});

export const bundleShowSaveChange = text => ({
    type: BUNDLE_SHOW_SAVE_CHANGE,
    text,
});

export const bundleDeselectBundleCheckboxesChange = text => ({
    type: BUNDLE_DESELECT_BUNDLE_CHECKBOXES_CHANGE,
    text,
});

export const bundleSettingsStringChange = (attr, text) => ({
    type: BUNDLE_SETTINGS_STRING_CHANGE,
    attr,
    text,
});

export const bundleTitleFontWeightChange = weight => ({
    type: BUNDLE_TITLE_FONT_WEIGHT_CHANGE,
    weight,
});

export const addSelectedToCartTextColorChange = hex => ({
    type: ADD_SELECTED_TO_CART_TEXT_COLOR_CHANGE,
    hex,
});

export const addSelectedToCartButtonColorChange = hex => ({
    type: ADD_SELECTED_TO_CART_BUTTON_COLOR_CHANGE,
    hex,
});

export const totalPriceTextColorChange = hex => ({
    type: TOTAL_PRICE_TEXT_COLOR_CHANGE,
    hex,
});

export const bundleComparePriceColorChange = hex => ({
    type: BUNDLE_COMPARE_PRICE_COLOR_CHANGE,
    hex,
});

export const bundlePriceColorChange = hex => ({
    type: BUNDLE_PRICE_COLOR_CHANGE,
    hex,
});

export const bundleTitleColorChange = hex => ({
    type: BUNDLE_TITLE_COLOR_CHANGE,
    hex,
});

export const bundleItemPriceColorChange = hex => ({
    type: BUNDLE_ITEM_PRICE_COLOR_CHANGE,
    hex,
});

export const bundleItemNameColorChange = hex => ({
    type: BUNDLE_ITEM_NAME_COLOR_CHANGE,
    hex,
});

export const hideBundleComparePriceChange = bool => ({
    type: HIDE_BUNDLE_COMPARE_PRICE_CHANGE,
    bool,
});

export const sumComparePricesChange = bool => ({
    type: SUM_COMPARE_PRICES_CHANGE,
    bool,
});

export const showBundleItemCompareChange = bool => ({
    type: SHOW_BUNDLE_ITEM_COMPARE_CHANGE,
    bool,
});

export const BundleMainEnabledChange = bool => ({
    type: BUNDLE_MAIN_ENABLED_CHANGE,
    bool,
});

export const bundleBottomEnabledChange = bool => ({
    type: BUNDLE_BOTTOM_ENABLED_CHANGE,
    bool,
});

export const infoPopupBundleChange = bool => ({
    type: INFO_POPUP_BUNDLE_CHANGE,
    bool,
});

export const bundleQuickViewEnabledChange = bool => ({
    type: BUNDLE_QUICK_VIEW_ENABLED_CHANGE,
    bool,
});

export const bundleProductDescOnQuickViewChange = bool => ({
    type: BUNDLE_PRODUCT_DESC_ON_QUICK_VIEW_CHANGE,
    bool,
});

export const bundleCutZerosChange = bool => ({
    type: BUNDLE_CUT_ZEROS_CHANGE,
    bool,
});

export const bundleRefreshOnCartChange = bool => ({
    type: BUNDLE_REFRESH_ON_CART_CHANGE,
    bool,
});

export const regenerateAutoBundlesChange = bool => ({
    type: REGENERATE_AUTO_BUNDLES_CHANGE,
    bool,
});

export function saveBundleSettings() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saveBundleSettingsProcessing());
        return fetch(`https://${host}/bundle/save_widget`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    settings: state.settingsBundle.settings,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(saveBundleSettingsSuccess());
                    dispatch(activateToast());
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(saveBundleSettingsError());
                }
            });

    }
}


