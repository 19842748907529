import { useEffect, useState } from 'react';
import createApp from '@shopify/app-bridge';

function useShopifyAppBridge( apiKey, host) {
    const [appBridge, setAppBridge] = useState(null);
  
    useEffect(() => {
      if (host && apiKey) {
        const app =  createApp({
          apiKey: apiKey,
          host: host,
          forceRedirect: false,
        });
        setAppBridge(app);
      }
    }, [apiKey, host]);
  
    return appBridge;
  }
  
  export default useShopifyAppBridge;
  