import {postPayload} from "./data";

export const INIT_BOGO = 'INIT_BOGO';
export const INIT_BOGO_SETTINGS = 'INIT_BOGO_SETTINGS';
export const BOGO_OPEN_SHORT_CODE_MODAL = 'BOGO_OPEN_SHORT_CODE_MODAL';
export const POPULATE_BOGO_OFFERS = 'POPULATE_BOGO_OFFERS';
export const BOGO_CREATE_OFFER = 'BOGO_CREATE_OFFER';
export const BOGO_SET_SEARCHED_PRODUCTS = 'BOGO_SET_SEARCHED_PRODUCTS';
export const LOADING_BOGOS = 'LOADING_BOGOS';
export const CREATING_BOGO = 'CREATING_BOGO';
export const SAVING_BOGO = 'SAVING_BOGO';
export const BOGO_DISMISS_TOAST = 'BOGO_DISMISS_TOAST';
export const BOGO_CONFIRM_PRODUCT_GROUP = 'BOGO_CONFIRM_PRODUCT_GROUP';
export const BOGO_VALIDATE_OFFER = 'BOGO_VALIDATE_OFFER';
export const BOGO_PUSH_SOURCE_PRODUCT_GROUP = 'BOGO_PUSH_SOURCE_PRODUCT_GROUP';
export const EDIT_BOGO = 'EDIT_BOGO';
export const BOGO_DELETE_OFFER = 'BOGO_DELETE_OFFER';
export const BOGO_SET_OFFER_TO_DELETE = 'BOGO_SET_OFFER_TO_DELETE';
export const BOGO_SEARCHING_PRODUCT = 'BOGO_SEARCHING_PRODUCT';
export const BOGO_SAVING_SETTINGS = 'BOGO_SAVING_SETTINGS';
export const BOGO_SETTING_COLOR_CHANGE = 'BOGO_SETTING_COLOR_CHANGE';
export const BOGO_TOAST = 'BOGO_TOAST';
export const BOGO_SET_DISPLAY = 'BOGO_SET_DISPLAY';
export const BOGO_DISCOUNT_TYPE_CHANGE = 'BOGO_DISCOUNT_TYPE_CHANGE';
export const BOGO_DISCOUNT_AMOUNT_CHANGE = 'BOGO_DISCOUNT_AMOUNT_CHANGE';
export const BOGO_SET_SEARCH_PRODUCT_TITLE = 'BOGO_SET_SEARCH_PRODUCT_TITLE';
export const BOGO_REMOVE_PRODUCT = 'BOGO_REMOVE_PRODUCT';
export const BOGO_CHANGE_PRODUCT_INIT_QTY = 'BOGO_CHANGE_PRODUCT_INIT_QTY';
export const BOGO_ADD_PRODUCT = 'BOGO_ADD_PRODUCT';
export const BOGO_SET_SELECTED_PRODUCTS = 'BOGO_SET_SELECTED_PRODUCTS';
export const BOGO_MIN_QTY_CHANGE = 'BOGO_MIN_QTY_CHANGE';
export const BOGO_LOCK_MAX_QTY_CHANGE = 'BOGO_LOCK_MAX_QTY_CHANGE';
export const BOGO_MAX_QTY_CHANGE = 'BOGO_MAX_QTY_CHANGE';
export const BOGO_SET_PRODUCT_GROUP_TYPE = 'BOGO_SET_PRODUCT_GROUP_TYPE';
export const BOGO_LOCALE_CHANGE = 'BOGO_LOCALE_CHANGE';
export const BOGO_TEXT_CHANGE = 'BOGO_TEXT_CHANGE';
export const BOGO_OPEN_MODAL = 'BOGO_OPEN_MODAL';
export const BOGO_CHANGE_OFFER_STRING = 'BOGO_CHANGE_OFFER_STRING';
export const BOGO_SETTINGS_TEXT_CHANGE = 'BOGO_SETTINGS_TEXT_CHANGE';
export const BOGO_TRIGGER_LOCATION_CHANGE = 'BOGO_TRIGGER_LOCATION_CHANGE';
export const BOGO_TOGGLING_BOOL = 'BOGO_TOGGLING_BOOL';
export const BOGO_TOGGLE_BOOL = 'BOGO_TOGGLE_BOOL';

export const initBogo = json => ({
    type: INIT_BOGO,
    json,
});

export const initBogoSettings = json => ({
    type: INIT_BOGO_SETTINGS,
    json,
});

export const populateBogoOffers = json => ({
    type: POPULATE_BOGO_OFFERS,
    json,
});

export const bogoCreateOffer = json => ({
    type: BOGO_CREATE_OFFER,
    json,
});

export const bogoSetSearchedProducts = json => ({
    type: BOGO_SET_SEARCHED_PRODUCTS,
    json,
});

export const loadingBogos = bool => ({
    type: LOADING_BOGOS,
    bool,
});

export const creatingBogo = bool => ({
    type: CREATING_BOGO,
    bool,
});

export const savingBogo = bool => ({
    type: SAVING_BOGO,
    bool,
});

export const bogoToast = text => ({
    type: BOGO_TOAST,
    text,
});

export const bogoSetDisplay = text => ({
    type: BOGO_SET_DISPLAY,
    text,
});

export const bogoDiscountTypeChange = text => ({
    type: BOGO_DISCOUNT_TYPE_CHANGE,
    text,
});

export const bogoDiscountAmountChange = text => ({
    type: BOGO_DISCOUNT_AMOUNT_CHANGE,
    text,
});

export const bogoSetSearchProductTitle = text => ({
    type: BOGO_SET_SEARCH_PRODUCT_TITLE,
    text,
});

export const bogoRemoveProduct = id => ({
    type: BOGO_REMOVE_PRODUCT,
    id,
});

export const bogoChangeProductInitQty = (id, text) => ({
    type: BOGO_CHANGE_PRODUCT_INIT_QTY,
    id,
    text,
});

export const bogoAddProduct = node => ({
    type: BOGO_ADD_PRODUCT,
    node,
});

export const bogoMinQtyChange = (idx, text) => ({
    type: BOGO_MIN_QTY_CHANGE,
    idx,
    text,
});

export const bogoLockMaxQtyChange = (idx, text) => ({
    type: BOGO_LOCK_MAX_QTY_CHANGE,
    idx,
    text,
});

export const bogoMaxQtyChange = (idx, text) => ({
    type: BOGO_MAX_QTY_CHANGE,
    idx,
    text,
});

export const bogoSetProductGroupType = (text, idx) => ({
    type: BOGO_SET_PRODUCT_GROUP_TYPE,
    text,
    idx,
});

export const bogoLocaleChange = locale => ({
    type: BOGO_LOCALE_CHANGE,
    locale,
});

export const bogoTextChange = (attr, text) => ({
    type: BOGO_TEXT_CHANGE,
    attr,
    text,
});

export const bogoOpenModal = (modalName, bool) => ({
    type: BOGO_OPEN_MODAL,
    modalName,
    bool,
});

export const bogoChangeOfferString = (attr, text) => ({
    type: BOGO_CHANGE_OFFER_STRING,
    attr,
    text,
});

export const bogoSettingsTextChange = (attr, text) => ({
    type: BOGO_SETTINGS_TEXT_CHANGE,
    attr,
    text,
});

export const bogoTriggerLocationChange = (attr, bool) => ({
    type: BOGO_TRIGGER_LOCATION_CHANGE,
    attr,
    bool,
});

export const bogoTogglingBool = (id, attr, bool) => ({
    type: BOGO_TOGGLING_BOOL,
    id,
    attr,
    bool,
});

export const bogoOpenShortCodeModal = (id, bool) => ({
    type: BOGO_OPEN_SHORT_CODE_MODAL,
    id,
    bool,
});

export const bogoToggleBool = (id, attr) => ({
    type: BOGO_TOGGLE_BOOL,
    id,
    attr,
});

export const bogoDismissToast = () => ({
    type: BOGO_DISMISS_TOAST,
});

export const bogoSetSelectedProducts = () => ({
    type: BOGO_SET_SELECTED_PRODUCTS,
});

export const bogoConfirmProductGroup = () => ({
    type: BOGO_CONFIRM_PRODUCT_GROUP,
});

export const bogoValidateOffer = () => ({
    type: BOGO_VALIDATE_OFFER,
});

export const bogoPushProductGroup = productGroup => ({
    type: BOGO_PUSH_SOURCE_PRODUCT_GROUP,
    productGroup,
});

export const editBogo = id => ({
    type: EDIT_BOGO,
    id,
});

export const bogoDeleteOffer = id => ({
    type: BOGO_DELETE_OFFER,
    id,
});

export const bogoSetOfferToDelete = id => ({
    type: BOGO_SET_OFFER_TO_DELETE,
    id,
});

export const bogoSearchingProduct = bool => ({
    type: BOGO_SEARCHING_PRODUCT,
    bool,
});

export const bogoSavingSettings = bool => ({
    type: BOGO_SAVING_SETTINGS,
    bool,
});

export const bogoSettingColorChange = (attr, hex) => ({
    type: BOGO_SETTING_COLOR_CHANGE,
    attr,
    hex,
});

export const loadBogos = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(loadingBogos(true));
    const resp = await postPayload('bogo/get_admin_offers', {}, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(initBogo(json));
    } else {
        dispatch(bogoToast('Failed to fetch bogos'));
        console.log('loadBogos failed');
    }
    dispatch(loadingBogos(false));
}

export const createBogo = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(creatingBogo(true));
    const resp = await postPayload('bogo/create_offer', {}, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(bogoCreateOffer(json));
    } else {
        dispatch(bogoToast('Failed to create bogo'));
        console.log('createBogo failed');
    }
    dispatch(creatingBogo(false));
}

export const saveBogo = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(savingBogo(true));
    const resp = await postPayload('bogo/save_offer', {offer: state.bogo.offer}, state);
    if (resp.ok) {
        dispatch(bogoSetDisplay('bogos'));
    } else {
        dispatch(bogoToast('Failed to save bogo'));
        console.log('saveBogo failed');
    }
    dispatch(savingBogo(false));
}

export const toggleBogoBool = (id, attr) => async (dispatch, getState) => {
    const state = getState();
    dispatch(bogoTogglingBool(id, attr, true));
    const resp = await postPayload('bogo/toggle_bool', {id, attr}, state);
    if (resp.ok) {
        dispatch(bogoToggleBool(id, attr));
    } else {
        dispatch(bogoToast('Failed to toggle'));
        console.log('toggleBogoBool failed');
    }
    dispatch(bogoTogglingBool(id, attr, false));
}

export const deleteBogo = id => async (dispatch, getState) => {
    const state = getState();
    dispatch(bogoTogglingBool(id, 'deleting', true));
    const resp = await postPayload('bogo/delete_offer', {id}, state);
    if (resp.ok) {
        dispatch(bogoDeleteOffer(id));
        dispatch(bogoOpenModal('delete', false));
    } else {
        dispatch(bogoToast('Failed to delete'));
        console.log('deleteBogo failed');
    }
    dispatch(bogoTogglingBool(id, 'deleting', false));
}

export const dupBogo = id => async (dispatch, getState) => {
    const state = getState();
    dispatch(bogoTogglingBool(id, 'duplicating', true));
    const resp = await postPayload('bogo/dup_offer', {id}, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(populateBogoOffers(json));
    } else {
        dispatch(bogoToast('Failed to duplicate'));
        console.log('dupBogo failed');
    }
    dispatch(bogoTogglingBool(id, 'duplicating', false));
}

export const previewBogo = id => async (dispatch, getState) => {
    const state = getState();
    dispatch(bogoTogglingBool(id, 'previewing', true));
    const resp = await postPayload('bogo/preview', {id}, state);
    if (resp.ok) {
        const json = await resp.json();
        window.open(`https://${state.data.shopName}/products/${json.handle}?zrx_preview_mode=true`, '_blank');
    } else {
        dispatch(bogoToast('Failed to preview'));
        console.log('previewBogo failed');
    }
    dispatch(bogoTogglingBool(id, 'previewing', false));
}

export const bogoMoveItem = (dir, idx) => async (dispatch, getState) => {
    const state = getState();
    dispatch(bogoTogglingBool(idx, 'moving_item', true));
    const offers = state.bogo.offers;
    const dirInt = dir === 'up' ? -1 : 1;
    [offers[idx], offers[idx + dirInt]] = [offers[idx + dirInt], offers[idx]];
    const resp = await postPayload('bogo/set_priority', {
        priorities: offers.map((o, idx) => ({
            id: o.id,
            idx,
        })),
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(populateBogoOffers(json));
    } else {
        console.log('bogoMoveItem failed');
    }
    dispatch(bogoTogglingBool(idx, 'moving_item', false));
}

export const bogoSearchProductTitleChange = text => async (dispatch, getState) => {
    const state = getState();
    dispatch(bogoSearchingProduct(true));
    dispatch(bogoSetSearchProductTitle(text));
    const resp = await postPayload('products/search', {
        text,
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(bogoSetSearchedProducts(json));
    } else {
        dispatch(bogoToast('Search Failed'));
        console.log('bogoSearchProductTitleChange failed');
    }
    dispatch(bogoSearchingProduct(false));
}

export const bogoSaveSettings = text => async (dispatch, getState) => {
    const state = getState();
    dispatch(bogoSavingSettings(true));
    const resp = await postPayload('bogo/save_settings', {
        settings: state.bogo.settings.values,
    }, state);
    if (resp.ok) {
        dispatch(bogoToast('Settings Saved'));
    } else {
        dispatch(bogoToast('Save Failed'));
        console.log('bogoSaveSettings failed');
    }
    dispatch(bogoSavingSettings(false));
}

