import {getInitDataUrl, host} from './data';
import fetch from 'cross-fetch';
import {changeTab, initNav} from "./nav";
import {setLocations} from "./settingsApp";
import {
    autoBundlesStatus,
    back,
    genAutoBundlesSuccess,
    loadPaginatedBundles,
} from "./productsBundle";
import {adminTrack} from "./v2/appV2Wizard";

export const INIT_STATUS = 'INIT_STATUS';
export const ENABLE_ZOORIX = 'ENABLE_ZOORIX';
export const DISABLE_ZOORIX = 'DISABLE_ZOORIX';
export const RECEIVED_ENABLE = 'RECEIVED_ENABLE';
export const RECEIVED_DISABLE_CART = 'RECEIVED_DISABLE_CART';
export const RECEIVED_ENABLE_CART = 'RECEIVED_ENABLE_CART';
export const RECEIVED_DISABLE = 'RECEIVED_DISABLE';
export const TOGGLE_CART = 'TOGGLE_CART';
export const TOGGLE_PRODUCT_BOTTOM_PROCESSING = 'TOGGLE_PRODUCT_BOTTOM_PROCESSING';
export const ACTIVATE_FEEDBACK_POPUP = 'ACTIVATE_FEEDBACK_POPUP';
export const DISMISS_FEEDBACK_POPUP = 'DISMISS_FEEDBACK_POPUP';
export const CLOSE_REVIEW_CARD = 'CLOSE_REVIEW_CARD';
export const CLOSE_PAYMENT_MODAL = 'CLOSE_PAYMENT_MODAL';
export const WAITING_FOR_PRODUCT_PAGE_WIDGET = 'WAITING_FOR_PRODUCT_PAGE_WIDGET';
export const TOGGLE_BUNDLE_PROCESSING = 'TOGGLE_BUNDLE_PROCESSING';
export const TOGGLE_WARRANTY_PROCESSING = 'TOGGLE_WARRANTY_PROCESSING';
export const TOGGLE_CART_DRAWER_PROCESSING = 'TOGGLE_CART_DRAWER_PROCESSING';
export const TOGGLE_VOLUME_DISCOUNT_PROCESSING = 'TOGGLE_VOLUME_DISCOUNT_PROCESSING';
export const HIDE_DEEP_LINK_UI = 'HIDE_DEEP_LINK_UI';
export const TOGGLE_BUNDLE_DONE = 'TOGGLE_BUNDLE_DONE';
export const TOGGLE_WARRANTY_DONE = 'TOGGLE_WARRANTY_DONE';
export const TOGGLE_CART_DRAWER_DONE = 'TOGGLE_CART_DRAWER_DONE';
export const TOGGLE_VOLUME_DISCOUNT_DONE = 'TOGGLE_VOLUME_DISCOUNT_DONE';
export const WAITING_FOR_CART_PAGE_WIDGET = 'WAITING_FOR_CART_PAGE_WIDGET';
export const SHOW_CLOSE_DEEP_LINK_CARD = 'SHOW_CLOSE_DEEP_LINK_CARD';
export const INSTALLATION_STATUS_UPDATE = 'INSTALLATION_STATUS_UPDATE';
export const ONLINE_STORE2 = 'ONLINE_STORE2';
export const RECEIVED_PRODUCT_BOTTOM_CART = 'RECEIVED_PRODUCT_BOTTOM_CART';
export const RESYNC = 'RESYNC';
export const RESYNC_SUCCESS = 'RESYNC_SUCCESS';
export const RESYNC_ERROR = 'RESYNC_ERROR';
export const RESYNC_STATUS = 'RESYNC_STATUS';
export const PRODUCT_INSTALLATION_COMPLETE = 'PRODUCT_INSTALLATION_COMPLETE';
export const CART_INSTALLATION_COMPLETE = 'CART_INSTALLATION_COMPLETE';

const resync = () => ({
    type: RESYNC,
});

const resyncSuccess = (json) => ({
    type: RESYNC_SUCCESS,
    syncStarted: json.syncStarted,
});

const resyncError = () => ({
    type: RESYNC_ERROR,
});

const enableZoorix = () => ({
    type: ENABLE_ZOORIX,
});

const disableZoorix = () => ({
    type: DISABLE_ZOORIX,
});

const receivedEnable = () => ({
    type: RECEIVED_ENABLE,
});

const receivedDisableCart = () => ({
    type: RECEIVED_DISABLE_CART,
});

const receivedEnableCart = () => ({
    type: RECEIVED_ENABLE_CART,
});

const receivedDisable = () => ({
    type: RECEIVED_DISABLE,
});

const toggleCart = () => ({
    type: TOGGLE_CART,
});

export const activateFeedbackPopup = () => ({
    type: ACTIVATE_FEEDBACK_POPUP,
});

export const dismissFeedbackPopup = () => ({
    type: DISMISS_FEEDBACK_POPUP,
});

export const closeReviewCard = () => ({
    type: CLOSE_REVIEW_CARD,
});

export const closePaymentModal = () => ({
    type: CLOSE_PAYMENT_MODAL,
});

const waitingForProductPageWidget = () => ({
    type: WAITING_FOR_PRODUCT_PAGE_WIDGET,
});

const toggleBundleProcessing = () => ({
    type: TOGGLE_BUNDLE_PROCESSING,
});

const toggleWarrantyProcessing = () => ({
    type: TOGGLE_WARRANTY_PROCESSING,
});

const toggleCartDrawerProcessing = () => ({
    type: TOGGLE_CART_DRAWER_PROCESSING,
});

const toggleVolumeDiscountProcessing = () => ({
    type: TOGGLE_VOLUME_DISCOUNT_PROCESSING,
});

const hideDeepLinkUi = () => ({
    type: HIDE_DEEP_LINK_UI,
});

const toggleBundleDone = () => ({
    type: TOGGLE_BUNDLE_DONE,
});

const toggleWarrantyDone = () => ({
    type: TOGGLE_WARRANTY_DONE,
});

const toggleCartDrawerDone = () => ({
    type: TOGGLE_CART_DRAWER_DONE,
});

const toggleVolumeDiscountDone = () => ({
    type: TOGGLE_VOLUME_DISCOUNT_DONE,
});

const waitingForCartPageWidget = () => ({
    type: WAITING_FOR_CART_PAGE_WIDGET,
});

export const showCloseDeepLinkCard = () => ({
    type: SHOW_CLOSE_DEEP_LINK_CARD,
});

export const installationStatusUpdate = data => ({
    type: INSTALLATION_STATUS_UPDATE,
    data,
});

export const onlineStore2 = json => ({
    type: ONLINE_STORE2,
    onlineStore2: json.online_store_2,
});

export const initStatus = (json) => ({
    type: INIT_STATUS,
    enabled: json.recommendationWigdetIsEnabled,
    lastSync: json.lastSync,
    productCount: json.productCount,
    customCollectionsCount: json.customCollectionsCount,
    smartCollectionsCount: json.smartCollectionsCount,
    syncStarted: json.syncStarted,
    cartEnabled: json.upsell.values.cartEnabled,
    bundleEnabled: json.bundle_settings.values.is_enabled,
    leftReview: json.leftReview,
    installation: json.installation,
    trialDaysLeft: json.trial_days_left,
    trialDays: json.trial_days,
    customPackagePrice: json.custom_package_price,
    packageName: json.package_name,
    chargeActive: json.charge_active,
    warrantySettings: json.warranty_settings,
    cartDrawerSettings: json.cart_drawer_settings,
    volumeSettings: json.volume_settings,
    planName: json.plan_name,
    devPlans: json.dev_plans,
    numOfOrders: json.num_of_orders,
    onlineStore2: json.online_store_2,
    appEmbeds: json.app_embeds,
    showInstallZoorixHead: json.admin_settings.show_install_zoorix_head,
});

export const syncStatus = (json) => ({
    type: RESYNC_STATUS,
    lastSync: json.lastSync,
    productCount: json.productCount,
    customCollectionsCount: json.customCollectionsCount,
    smartCollectionsCount: json.smartCollectionsCount,
    syncStarted: json.syncStarted,
    installation: json.installation,
    warrantySettings: json.warranty_settings,
    planName: json.plan_name,
    numOfOrders: json.num_of_orders,
});

const productInstallationComplete = json => ({
    type: PRODUCT_INSTALLATION_COMPLETE,
    productMain: json.product_main,
    productBottom: json.product_bottom,
});

const cartInstallationComplete = json => ({
    type: CART_INSTALLATION_COMPLETE,
    cartTop: json.cart_top,
    cartBottom: json.cart_bottom,
    cartItems: json.cart_items,
});

export function setTimeoutRefreshSyncStatus(dispatch) {
    setTimeout(() => dispatch(refreshSyncStatus()), 5000);
}

export function setTimeoutRefreshAutoBundlesStatus(dispatch) {
    setTimeout(() => dispatch(refreshAutoBundlesStatus()), 5000);
}

function setTimeoutRefreshProductInstallationStatus(dispatch) {
    setTimeout(() => dispatch(refreshProductInstallationStatus()), 10_000);
}

function setTimeoutRefreshCartInstallationStatus(dispatch) {
    setTimeout(() => dispatch(refreshCartInstallationStatus()), 10_000);
}

export function fixProductWidget() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(changeTab('manual-installation'));
        if (!state.status.enabled) {
            dispatch(postEnable(state.data.shopName, state.data.token));
        }
        dispatch(waitingForProductPageWidget());
        setTimeoutRefreshProductInstallationStatus(dispatch);
    }
}

export function fixCartWidget() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(changeTab('manual-installation'));
        if (!state.status.cartEnabled) {
            dispatch(enableCart());
        }
        dispatch(waitingForCartPageWidget());
        setTimeoutRefreshCartInstallationStatus(dispatch);
    }
}

export function createCharge() {
    return (dispatch, getState) => {
        const state = getState();
        fetch(`https://lambda.zoorix.com/admin-track?shop_name=${state.data.shopName}&action_type=APPROVE_PAYMENT`)
            .then(() => {
                fetch(`https://${host}/data/create_charge`,
                    {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            shop_name: state.data.shopName,
                            token: state.data.token,
                        })
                    })
                    .then((response) => {
                        if (response.ok) {
                            response.json().then((json) => {
                                window.open(json.url, '_parent');
                            });
                        }
                    })
            });
    }
}

function refreshProductInstallationStatus() {
    return (dispatch, getState) => {
        const state = getState();
        return fetch(`https://${host}/data/get_installation?shop_name=${state.data.shopName}&token=${state.data.token}`)
            .then(response => response.json())
            .then(json => {
                if ((state.settings.belowAddToCart && !json.product_main) || (state.settings.productBottomEnabled && !json.product_bottom)) {
                    setTimeoutRefreshProductInstallationStatus(dispatch);
                } else {
                    dispatch(productInstallationComplete(json));
                    dispatch(initNav({installation: json}));
                }
            })
    }
}

function refreshCartInstallationStatus() {
    return (dispatch, getState) => {
        const state = getState();
        return fetch(`https://${host}/data/get_installation?shop_name=${state.data.shopName}&token=${state.data.token}`)
            .then(response => response.json())
            .then(json => {
                if ((state.settings.cartTop && !json.cart_top) || (state.settings.cartBottom && !json.cart_bottom) ||
                    !json.cart_items) {
                    setTimeoutRefreshCartInstallationStatus(dispatch);
                } else {
                    dispatch(cartInstallationComplete(json));
                    dispatch(initNav({installation: json}));
                }
            })
    }
}

export function refreshSyncStatus() {
    return (dispatch, getState) => {
        const state = getState();
        return fetch(getInitDataUrl(state.data.shopName, state.data.token, null, state.data.appType))
            .then(response => response.json())
            .then(json => {
                dispatch(initNav(json, state.data.appType));
                dispatch(syncStatus(json));
                if (json.syncStarted || json.installation.product_main === null || json.installation.product_bottom === null ||
                    json.installation.cart_bottom === null || json.installation.cart_top === null ||
                    json.installation.cart_items === null || json.installation.progress < 100 || json.installation.loading_plans) {
                    setTimeoutRefreshSyncStatus(dispatch);
                } else {
                    dispatch(setLocations(json));
                }
            })
    }
}

export function refreshAutoBundlesStatus() {
    return (dispatch, getState) => {
        const state = getState();
        return fetch(`https://${host}/bundle/auto_bundles_status?shop_name=${state.data.shopName}&token=${state.data.token}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then(response => response.json())
            .then(json => {
                dispatch(autoBundlesStatus(json));
                if (json.installation.auto_bundles_ready) {
                    dispatch(refreshSyncStatus());
                    dispatch(genAutoBundlesSuccess());
                    dispatch(loadPaginatedBundles())
                    dispatch(back());
                    // if (json.bundles.filter(b => b.bundle_type === 'auto').length === 0) {
                    //     dispatch(openNoAutoBundlesModal());
                    // }
                } else {
                    setTimeoutRefreshAutoBundlesStatus(dispatch);
                }
            })
    }
}

export function postResync(callback) {
    return async (dispatch, getState) => {
        const state = getState();
        if (state.settings.isPostingResync) {
            return
        }
        dispatch(resync());
        const response = await fetch(`https://${host}/resync`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                })
            });
        if (response.ok) {
            const json = await response.json();
            dispatch(resyncSuccess(json));
            dispatch(refreshSyncStatus());
            if (callback) callback();
        } else {
            dispatch(resyncError())
        }
    }
}

function postEnable() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(enableZoorix());
        return fetch(`https://${host}/modify_recommendation_widget`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    enabled: true,
                })
            })
            .then(() => {
                dispatch(receivedEnable());
                dispatch(refreshSyncStatus());
            })
    }
}

function postDisable() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(disableZoorix());
        return fetch(`https://${host}/modify_recommendation_widget`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    enabled: false,
                })
            })
            .then(() => {
                dispatch(receivedDisable());
                dispatch(refreshSyncStatus());
            })
    }
}

function shouldPost(state) {
    return !state.status.isPosting && !state.status.installingTheme;
}

export function disableCart() {
    return (dispatch, getState) => {
        let state = getState();
        if (!state.status.cartProcessing && !state.status.installingTheme) {
            dispatch(toggleCart());
            return fetch(`https://${host}/modify_recommendation_widget`,
                {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        shop_name: state.data.shopName,
                        token: state.data.token,
                        cart_enabled: false,
                    })
                })
                .then(() => {
                    dispatch(receivedDisableCart());
                    dispatch(refreshSyncStatus());
                })

        }
    }
}

export function enableCart() {
    return (dispatch, getState) => {
        let state = getState();
        if (!state.status.cartProcessing && !state.status.installingTheme) {
            dispatch(toggleCart());
            return fetch(`https://${host}/modify_recommendation_widget`,
                {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        shop_name: state.data.shopName,
                        token: state.data.token,
                        cart_enabled: true,
                    })
                })
                .then(() => {
                    dispatch(receivedEnableCart());
                    dispatch(refreshSyncStatus());
                })

        }
    }
}

export function fixBundleWidget() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(changeTab('manual-installation'));
        if (!state.status.bundleEnabled) {
            dispatch(toggleBundleWidget());
        }
        dispatch(waitingForProductPageWidget());
        setTimeoutRefreshProductInstallationStatus(dispatch);
    }
}

export function fixWarrantyWidget() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(changeTab('manual-installation'));
        if (!state.status.warrantyEnabled) {
            dispatch(toggleWarrantyWidget());
        }
        dispatch(waitingForProductPageWidget());
        setTimeoutRefreshProductInstallationStatus(dispatch);
    }
}

export function toggleBundleWidget() {
    return (dispatch, getState) => {
        let state = getState();
        if (!state.status.bundleProcessing && !state.status.installingTheme) {
            dispatch(toggleBundleProcessing());
            return fetch(`https://${host}/bundle/toggle_widget`,
                {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        shop_name: state.data.shopName,
                        token: state.data.token,
                        app_type: state.data.appType,
                        is_enabled: !state.status.bundleEnabled,
                    })
                })
                .then(() => {
                    dispatch(toggleBundleDone());
                    dispatch(refreshSyncStatus());
                })

        }
    }
}

export function toggleWarrantyWidget() {
    return (dispatch, getState) => {
        let state = getState();
        if (!state.status.warrantyProcessing && !state.status.installingTheme) {
            dispatch(toggleWarrantyProcessing());
            return fetch(`https://${host}/warranty/toggle_widget`,
                {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        shop_name: state.data.shopName,
                        token: state.data.token,
                        is_enabled: !state.status.warrantyEnabled,
                    })
                })
                .then(() => {
                    dispatch(toggleWarrantyDone());
                    dispatch(refreshSyncStatus());
                })
        }
    }
}

export function toggleCartDrawerWidget() {
    return (dispatch, getState) => {
        let state = getState();
        if (!state.status.cartDrawerProcessing && !state.status.installingTheme) {
            dispatch(toggleCartDrawerProcessing());
            return fetch(`https://${host}/cart_drawer/toggle_widget`,
                {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        shop_name: state.data.shopName,
                        token: state.data.token,
                        is_enabled: !state.status.cartDrawerEnabled,
                    })
                })
                .then(() => {
                    dispatch(toggleCartDrawerDone());
                    dispatch(refreshSyncStatus());
                    state = getState();
                    if (state.status.cartDrawerEnabled) dispatch(adminTrack('admin_cart_drawer_enabled', {}));
                })

        }

    }
}

export function toggleVolumeDiscountWidget() {
    return (dispatch, getState) => {
        let state = getState();
        if (!state.status.volumeDiscountProcessing) {
            dispatch(toggleVolumeDiscountProcessing());
            return fetch(`https://${host}/volume_discount/toggle_widget`,
                {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        shop_name: state.data.shopName,
                        token: state.data.token,
                        is_enabled: !state.status.volumeDiscountEnabled,
                    })
                })
                .then(() => {
                    dispatch(toggleVolumeDiscountDone());
                    dispatch(refreshSyncStatus());
                })

        }

    }
}

export function hideDeepLink() {
    return (dispatch, getState) => {
        let state = getState();
        dispatch(hideDeepLinkUi());
        return fetch(`https://${host}/admin_settings/toggle_show_install_zoorix_head`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    bool: false,
                })
            })
            .then(() => {
                // do nothing
            })
    }
}

export function enableZoorixIfNotPosting() {
    return (dispatch, getState) => {
        if (shouldPost(getState())) {
            return dispatch(postEnable())
        }
    }
}

export function disableZoorixIfNotPosting() {
    return (dispatch, getState) => {
        if (shouldPost(getState())) {
            return dispatch(postDisable())
        }
    }
}
