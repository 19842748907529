import fetch from "cross-fetch";
import {host} from "./data";
import {refreshSyncStatus} from "./status";
import {adminTrack} from "./v2/appV2Wizard";

import _map from 'lodash/map';
import _some from 'lodash/some';
import _values from 'lodash/values';

export const INIT_PRODUCTS_WARRANTY = 'INIT_PRODUCTS_WARRANTY';
export const SET_DELETABLE = 'SET_DELETABLE';
export const SET_BULK_WARRANTIES = 'SET_BULK_WARRANTIES';
export const CREATE_WARRANTY = 'CREATE_WARRANTY';
export const INIT_PRODUCTS_WARRANTY_FAILED = 'INIT_PRODUCTS_WARRANTY_FAILED';
export const CREATE_BULK_WARRANTY = 'CREATE_BULK_WARRANTY';
export const EDIT_WARRANTY = 'EDIT_WARRANTY';
export const REMOVE_WARRANTY = 'REMOVE_WARRANTY';
export const REMOVE_BULK_WARRANTY = 'REMOVE_BULK_WARRANTY';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const CLOSE_REMOVE_WARRANTY_MODAL = 'CLOSE_REMOVE_WARRANTY_MODAL';
export const CLOSE_REMOVE_BULK_WARRANTY_MODAL = 'CLOSE_REMOVE_BULK_WARRANTY_MODAL';
export const WARRANTY_REMOVE_FAILURE = 'WARRANTY_REMOVE_FAILURE';
export const BULK_WARRANTY_REMOVE_FAILURE = 'BULK_WARRANTY_REMOVE_FAILURE';
export const WARRANTY_BACK = 'WARRANTY_BACK';
export const WARRANTY_SAVING = 'WARRANTY_SAVING';
export const WARRANTY_SAVE_ERROR = 'WARRANTY_SAVE_ERROR';
export const WARRANTY_SETTINGS_SAVED = 'WARRANTY_SETTINGS_SAVED';
export const WARRANTY_NAME_CHANGE = 'WARRANTY_NAME_CHANGE';
export const PRICE_TYPE_CHANGE = 'PRICE_TYPE_CHANGE';
export const WARRANTY_CHOOSE_PRODUCT = 'WARRANTY_CHOOSE_PRODUCT';
export const WARRANTY_CLOSE_CHOOSE_PRODUCT = 'WARRANTY_CLOSE_CHOOSE_PRODUCT';
export const WARRANTY_START_SEARCH = 'WARRANTY_START_SEARCH';
export const WARRANTY_SEARCH_SUCCESS = 'WARRANTY_SEARCH_SUCCESS';
export const WARRANTY_SEARCH_FAILURE = 'WARRANTY_SEARCH_FAILURE';
export const START_WARRANTY_REMOVE = 'START_WARRANTY_REMOVE';
export const START_BULK_WARRANTY_REMOVE = 'START_BULK_WARRANTY_REMOVE';
export const CHOOSE_WARRANTY_ITEM = 'CHOOSE_WARRANTY_ITEM';
export const UPLOAD_WARRANTY_IMAGE = 'UPLOAD_WARRANTY_IMAGE';
export const UPLOAD_WARRANTY_SUCCESS = 'UPLOAD_WARRANTY_SUCCESS';
export const UPLOAD_WARRANTY_FAILURE = 'UPLOAD_WARRANTY_FAILURE';
export const RESTORE_TO_WARRANTY_DEFAULT_IMAGE = 'RESTORE_TO_WARRANTY_DEFAULT_IMAGE';
export const RESTORE_TO_WARRANTY_ORIGINAL_IMAGE = 'RESTORE_TO_WARRANTY_ORIGINAL_IMAGE';
export const RESTORE_TO_WARRANTY_STORE_IMAGE = 'RESTORE_TO_WARRANTY_STORE_IMAGE';
export const WARRANTY_BAD_NAME_TEMPLATE = 'WARRANTY_BAD_NAME_TEMPLATE';
export const WARRANTY_PRICE_CHANGE = 'WARRANTY_PRICE_CHANGE';
export const WARRANTY_PERCENTAGE_CHANGE = 'WARRANTY_PERCENTAGE_CHANGE';
export const BULK_WARRANTY_PRICE_CHANGE = 'BULK_WARRANTY_PRICE_CHANGE';
export const WARRANTY_TAG_CHANGE = 'WARRANTY_TAG_CHANGE';
export const BULK_PRICE_TYPE_CHANGE = 'BULK_PRICE_TYPE_CHANGE';
export const WARRANTY_NAME_TEMPLATE_CHANGE = 'WARRANTY_NAME_TEMPLATE_CHANGE';
export const BULK_WARRANTY_FILTER_TYPE_CHANGE = 'BULK_WARRANTY_FILTER_TYPE_CHANGE';
export const BULK_WARRANTY_COLLECTION_CHANGE = 'BULK_WARRANTY_COLLECTION_CHANGE';
export const BULK_WARRANTY_UPDATE_COLLECTION_COUNT = 'BULK_WARRANTY_UPDATE_COLLECTION_COUNT';
export const CANCEL_UPLOAD_WARRANTY_IMAGE = 'CANCEL_UPLOAD_WARRANTY_IMAGE';

export const initProductsWarranty = json => ({
    type: INIT_PRODUCTS_WARRANTY,
    warranties: json.warranties,
    currencySymbol: json.currency_symbol,
    tokenId: json.token_id,
    tags: json.tags,
    tagsCount: json.tags_count,
    bulkWarranties: json.bulk_warranties,
    deletableBulkWarranties: json.deletable_bulk_warranties,
    smartCollections: json.smartCollections,
    customCollections: json.customCollections,
});

export const setDeletable = json => ({
    type: SET_DELETABLE,
    deletableBulkWarranties: json,
});

export const setBulkWarranties = json => ({
    type: SET_BULK_WARRANTIES,
    bulkWarranties: json,
});

export const createWarranty = () => ({
    type: CREATE_WARRANTY,
});

export const initProductsWarrantyFailed = () => ({
    type: INIT_PRODUCTS_WARRANTY_FAILED,
});

export const createBulkWarranty = () => ({
    type: CREATE_BULK_WARRANTY,
});

export const editWarranty = id => ({
    type: EDIT_WARRANTY,
    id,
});

export const removeWarranty = id => ({
    type: REMOVE_WARRANTY,
    id,
});

export const removeBulkWarranty = id => ({
    type: REMOVE_BULK_WARRANTY,
    id,
});

export const dismissToast = () => ({
    type: DISMISS_TOAST,
});

export const closeRemoveWarrantyModal = () => ({
    type: CLOSE_REMOVE_WARRANTY_MODAL,
});

export const closeRemoveBulkWarrantyModal = () => ({
    type: CLOSE_REMOVE_BULK_WARRANTY_MODAL,
});

export const warrantyRemoveFailure = () => ({
    type: WARRANTY_REMOVE_FAILURE,
});

export const bulkWarrantyRemoveFailure = () => ({
    type: BULK_WARRANTY_REMOVE_FAILURE,
});

export const warrantyBack = () => ({
    type: WARRANTY_BACK,
});

export const warrantySaving = () => ({
    type: WARRANTY_SAVING,
});

export const warrantySaveError = () => ({
    type: WARRANTY_SAVE_ERROR,
});

export const warrantySettingsSaved = () => ({
    type: WARRANTY_SETTINGS_SAVED,
});

export const warrantyNameChange = name => ({
    type: WARRANTY_NAME_CHANGE,
    name,
});

export const priceTypeChange = text => ({
    type: PRICE_TYPE_CHANGE,
    text,
});

export const warrantyChooseProduct = () => ({
    type: WARRANTY_CHOOSE_PRODUCT,
});

export const warrantyCloseChooseProduct = () => ({
    type: WARRANTY_CLOSE_CHOOSE_PRODUCT,
});

export const warrantyStartSearch = text => ({
    type: WARRANTY_START_SEARCH,
    text,
});

export const warrantySearchSuccess = json => ({
    type: WARRANTY_SEARCH_SUCCESS,
    products: json.products,
    existingIds: json.existing_ids,
    title: json.title,
});

export const warrantySearchFailure = () => ({
    type: WARRANTY_SEARCH_FAILURE,
});

export const startWarrantyRemove = () => ({
    type: START_WARRANTY_REMOVE,
});

export const startBulkWarrantyRemove = () => ({
    type: START_BULK_WARRANTY_REMOVE,
});

export const chooseWarrantyItem = id => ({
    type: CHOOSE_WARRANTY_ITEM,
    id,
});

export const uploadWarrantyImage = () => ({
    type: UPLOAD_WARRANTY_IMAGE,
});

export const uploadWarrantySuccess = () => ({
    type: UPLOAD_WARRANTY_SUCCESS,
});

export const uploadWarrantyFailure = () => ({
    type: UPLOAD_WARRANTY_FAILURE,
});

export const restoreToWarrantyDefaultImage = () => ({
    type: RESTORE_TO_WARRANTY_DEFAULT_IMAGE,
});

export const restoreToWarrantyOriginalImage = () => ({
    type: RESTORE_TO_WARRANTY_ORIGINAL_IMAGE,
});

export const restoreToWarrantyStoreImage = () => ({
    type: RESTORE_TO_WARRANTY_STORE_IMAGE,
});

export const warrantyBadNameTemplate = () => ({
    type: WARRANTY_BAD_NAME_TEMPLATE,
});

export const warrantyPriceChange = num => ({
    type: WARRANTY_PRICE_CHANGE,
    num,
});

export const warrantyPercentageChange = num => ({
    type: WARRANTY_PERCENTAGE_CHANGE,
    num,
});

export const bulkWarrantyPriceChange = num => ({
    type: BULK_WARRANTY_PRICE_CHANGE,
    num,
});

export const warrantyTagChange = text => ({
    type: WARRANTY_TAG_CHANGE,
    text,
});

export const bulkPriceTypeChange = text => ({
    type: BULK_PRICE_TYPE_CHANGE,
    text,
});

export const warrantyNameTemplateChange = text => ({
    type: WARRANTY_NAME_TEMPLATE_CHANGE,
    text,
});

export const bulkWarrantyFilterTypeChange = text => ({
    type: BULK_WARRANTY_FILTER_TYPE_CHANGE,
    text,
});

export const bulkWarrantyCollectionChange = text => ({
    type: BULK_WARRANTY_COLLECTION_CHANGE,
    text,
});

export const bulkWarrantyUpdateCollectionCount = (collectionType, collectionValue, count) => ({
    type: BULK_WARRANTY_UPDATE_COLLECTION_COUNT,
    collectionType,
    collectionValue,
    count,
});

export const cancelUploadWarrantyImage = () => ({
    type: CANCEL_UPLOAD_WARRANTY_IMAGE,
});

export function setTimeoutRefreshDeletableBulkWarranties(dispatch) {
    setTimeout(() => dispatch(refreshDeletable()), 10_000);
}

export function setTimeoutRefreshBulkWarranties(dispatch) {
    setTimeout(() => dispatch(refreshBulkWarranties()), 10_000);
}

export function refreshDeletable() {
    return (dispatch, getState) => {
        const state = getState();
        return fetch(`https://${host}/warranty/get_deletable?shop_name=${state.data.shopName}&token=${state.data.token}`)
            .then(response => response.json())
            .then(json => {
                dispatch(setDeletable(json));
                if (_some(_values(json), v => v === false)) {
                    setTimeoutRefreshDeletableBulkWarranties(dispatch);
                }
            })
    }
}

function getCollectionCount(dispatch, state, collectionType, collectionValue) {
    fetch(`https://${host}/data/get_collection_count?shop_name=${state.data.shopName}&token=${state.data.token}&collection_type=${collectionType}&collection_value=${collectionValue}`)
        .then(response => response.json())
        .then(json => {
            dispatch(bulkWarrantyUpdateCollectionCount(collectionType, collectionValue, json.count));
        })
}

export function bulkWarrantyFilterTypeChangeAction(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(bulkWarrantyFilterTypeChange(text));
        if (text !== 'tag') {
            let collectionValue = state.productsWarranty.bulkWarranty[text === 'smart-collection' ? 'smartCollection' : 'customCollection'];
            if (!Number.isInteger(state.productsWarranty.collectionCount[text][collectionValue])) {
                getCollectionCount(dispatch, state, text, collectionValue);
            }
        }
    }
}

export function bulkWarrantySmartCollectionChangeAction(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(bulkWarrantyCollectionChange(text));
        if (!Number.isInteger(state.productsWarranty.collectionCount['smart-collection'][text])) {
            getCollectionCount(dispatch, state, 'smart-collection', text);
        }
    }
}

export function bulkWarrantyCustomCollectionChangeAction(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(bulkWarrantyCollectionChange(text));
        if (!Number.isInteger(state.productsWarranty.collectionCount['custom-collection'][text])) {
            getCollectionCount(dispatch, state, 'custom-collection', text);
        }
    }
}

export function refreshBulkWarranties() {
    return (dispatch, getState) => {
        const state = getState();
        return fetch(`https://${host}/warranty/get_bulk_warranties?shop_name=${state.data.shopName}&token=${state.data.token}`)
            .then(response => response.json())
            .then(json => {
                dispatch(setBulkWarranties(json));
                if (_some(_map(json, i => i.is_deleting), v => v === true)) {
                    setTimeoutRefreshBulkWarranties(dispatch);
                }
            })
    }
}

export function loadProductWarranty() {
    return (dispatch, getState) => {
        const state = getState();
        if (!state.productsWarranty.ready) {
            fetch(`https://${host}/warranty/get_init_data_for_admin?shop_name=${state.data.shopName}&token=${state.data.token}`)
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(initProductsWarranty(json));
                            if (_some(_map(json.bulk_warranties, i => i.is_deleting), v => v === true)) {
                                setTimeoutRefreshBulkWarranties(dispatch);
                            }
                            if (_some(_values(json.deletable_bulk_warranties), v => v === false)) {
                                setTimeoutRefreshDeletableBulkWarranties(dispatch);
                            }
                        });
                    } else {
                        dispatch(initProductsWarrantyFailed());
                    }
                })
        }
    }
}

export function changeSearchText(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(warrantyStartSearch(text));
        return fetch(`https://${host}/data/search_products`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    title: text,
                    existing_ids: [],
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(warrantySearchSuccess(json)));
                } else {
                    dispatch(warrantySearchFailure());
                }
            });
    }
}

export function removeWarrantyAction() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startWarrantyRemove());
        return fetch(`https://${host}/warranty/delete`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    id: state.productsWarranty.destWarranty,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initProductsWarranty(json));
                        dispatch(closeRemoveWarrantyModal());
                        dispatch(refreshSyncStatus());
                    });
                } else {
                    dispatch(warrantyRemoveFailure());
                }
            });
    }
}

export function removeBulkWarrantyAction() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startBulkWarrantyRemove());
        return fetch(`https://${host}/warranty/delete_bulk`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    id: state.productsWarranty.destBulkWarranty,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initProductsWarranty(json));
                        dispatch(closeRemoveBulkWarrantyModal());
                        dispatch(refreshSyncStatus());
                        setTimeoutRefreshBulkWarranties(dispatch);
                    });
                } else {
                    dispatch(bulkWarrantyRemoveFailure());
                }
            });
    }
}

export function save() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(warrantySaving());
        return fetch(`https://${host}/warranty/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    warranty: state.productsWarranty.warranty,
                    is_warranty_default_image: state.productsWarranty.isWarrantyDefaultImage,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initProductsWarranty(json));
                        dispatch(warrantySettingsSaved());
                        dispatch(refreshSyncStatus());
                        dispatch(adminTrack('admin_warranty_offer_saved', {
                            warranty_name: state.productsWarranty.warranty.name,
                            warranty_id: state.productsWarranty.warranty.id,
                        }));
                    });
                } else {
                    dispatch(warrantySaveError());
                }
            });

    }
}

export function saveBulk() {
    return (dispatch, getState) => {
        const state = getState();
        if (state.productsWarranty.bulkWarranty.warranty_name_template.indexOf('{{ product_name }}') === -1) {
            dispatch(warrantyBadNameTemplate());
            return;
        }
        dispatch(warrantySaving());
        return fetch(`https://${host}/warranty/save_bulk`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    type: state.productsWarranty.bulkWarranty.filter_type,
                    bulk_warranty: state.productsWarranty.bulkWarranty,
                    is_warranty_default_image: state.productsWarranty.isWarrantyDefaultImage,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initProductsWarranty(json));
                        dispatch(warrantySettingsSaved());
                        dispatch(refreshSyncStatus());
                        setTimeoutRefreshDeletableBulkWarranties(dispatch);
                    });
                } else {
                    dispatch(warrantySaveError());
                }
            });

    }
}
