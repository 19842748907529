import { connect } from 'react-redux'
import {globalSendEvent} from "../../actions/global";
import SendEvent from "../../components/global/SendEvent";

const mapStateToProps = state => ({
    state: state,
});

const mapDispatchToProps = (dispatch) => ({
    send: event => dispatch(globalSendEvent(event)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SendEvent);
