import _cloneDeep from "lodash/cloneDeep";
import _remove from "lodash/remove";

import {
    ACTIVATE_GENERIC_TOAST,
    APP_CLICKED, BOGO_CLICKED,
    BUNDLE_CLICKED, CART_DRAWER_CLICKED, CHANGE_INTEGRATIONS_TAB, CHANGE_RECOMMENDED_APPS_TAB, DISMISS_GENERIC_TOAST,
    HANDLE_TAB_CHANGE,
    INIT_NAV, MIX_AND_MATCH_CLICKED, SLIDER_CLICKED,
    tabs, VOLUME_DISCOUNT_CLICKED, WARRANTY_CLICKED
} from "../actions/nav";

const setVisible = (state, attr) => {
    ['isSliderVisible', 'isBundleVisible', 'isWarrantyVisible', 'isAppVisible', 'isCartDrawerVisible',
        'isVolumeVisible', 'isMixAndMatchVisible', 'isBogoVisible'].forEach(a => state[a] = a === attr);
}

const nav = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_NAV:
            newState = _cloneDeep(state);
            let newTabs = _cloneDeep(tabs);
            if (action.installation.product_main &&
                action.installation.product_bottom &&
                action.installation.cart_top &&
                action.installation.cart_bottom &&
                action.installation.cart_items) {

                _remove(newTabs, {id: 'manual-installation'});
            }

            if (!action.installation.installed_warranty) {
                _remove(newTabs, { id: 'warranty-moderation' });
            }

            if (action.packageName === 'beta') {
                _remove(newTabs, { id: 'plans' });
            }

            if (action.appType === 'bundles') {
                _remove(newTabs, { id: 'plans' });
                _remove(newTabs, { id: 'warranty-moderation' });
            }

            if (!action.appType) {
                _remove(newTabs, { id: 'upgrade' });
            }

            newState.tabs = newTabs;
            newState.appType = action.appType;
            newState.integrationsTab = 0;
            newState.recommendedAppsTab = 0;
            break;
        case HANDLE_TAB_CHANGE:
            newState = _cloneDeep(state);
            newState.selected = action.tab;
            if (newState.appType === 'bundles') {
                newState.isBundleVisible = true;
                newState.isAppVisible = false;
            }
            break;
        case CHANGE_INTEGRATIONS_TAB:
            newState = _cloneDeep(state);
            newState.integrationsTab = action.tab;
            break;
        case CHANGE_RECOMMENDED_APPS_TAB:
            newState = _cloneDeep(state);
            newState.recommendedAppsTab = action.tab;
            break;
        case ACTIVATE_GENERIC_TOAST:
            newState = _cloneDeep(state);
            newState.genericToastActive = true;
            newState.genericToastContent = action.content;
            break;
        case DISMISS_GENERIC_TOAST:
            newState = _cloneDeep(state);
            newState.genericToastActive = false;
            break;
        case SLIDER_CLICKED:
            newState = _cloneDeep(state);
            setVisible(newState, 'isSliderVisible');
            break;
        case BUNDLE_CLICKED:
            newState = _cloneDeep(state);
            setVisible(newState, 'isBundleVisible');
            break;
        case WARRANTY_CLICKED:
            newState = _cloneDeep(state);
            setVisible(newState, 'isWarrantyVisible');
            break;
        case CART_DRAWER_CLICKED:
            newState = _cloneDeep(state);
            setVisible(newState, 'isCartDrawerVisible');
            break;
        case VOLUME_DISCOUNT_CLICKED:
            newState = _cloneDeep(state);
            setVisible(newState, 'isVolumeVisible');
            break;
        case MIX_AND_MATCH_CLICKED:
            newState = _cloneDeep(state);
            setVisible(newState, 'isMixAndMatchVisible');
            break;
        case BOGO_CLICKED:
            newState = _cloneDeep(state);
            setVisible(newState, 'isBogoVisible');
            break;
        case APP_CLICKED:
            newState = _cloneDeep(state);
            setVisible(newState, 'isAppVisible');
            break;
        default:
            return state;
    }
    return newState;
};

export default nav;
