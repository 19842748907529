import {
    DISMISS_OUT_OF_DATE_TOAST,
    EMAIL_CHANGE,
    INIT_DATA,
    NAME_CHANGE,
    RECEIVE_DATA,
    SET_CREDS,
    SET_CRISP_LOADED,
    SET_LANDING_PAGE_VISIBLE,
    SET_PASSWORD_ENABLED,
    SET_WIZARD_BOOL,
    SET_ZOORIX_M_INSTALLED,
    UPDATE_ZOORIX_TOAST
} from "../actions/data";
import {PLANS_SET_DISCOUNT} from "../actions/plans";

import _cloneDeep from "lodash/cloneDeep";

const data = (state = { isFetched: false, isFetching: false, ownerName: '', ownerEmail: '', shopName: ''}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_DATA:
            newState = _cloneDeep(state);
            newState.isFetching = true;
            newState.appType = action.appType;
            break;
        case SET_CREDS:
            newState = _cloneDeep(state);
            newState.shopName = action.shopName;
            newState.token = action.token;
            break;
        case SET_PASSWORD_ENABLED:
            newState = _cloneDeep(state);
            newState.passwordEnabled = action.bool;
            break;
        case RECEIVE_DATA:
            newState = _cloneDeep(state);
            newState.zoorixMEnabledLoading = true;
            newState.isFetched = true;
            newState.isFetching = false;
            newState.appType = action.appType;
            newState.ownerName = action.ownerName;
            newState.ownerEmail = action.ownerEmail;
            newState.shopName = action.shopName;
            newState.token = action.token;
            newState.passwordEnabled = action.passwordEnabled;
            // newState.showAdminWizard = action.showAdminWizard;
            newState.token_id = action.token_id;
            newState.upgradeUrl = action.upgradeUrl;
            newState.referralOfferingDiscount = action.referralOfferingDiscount;
            newState.warrantySettings = _cloneDeep(action.json.warranty_settings);
            break;
        case NAME_CHANGE:
            newState = _cloneDeep(state);
            newState.ownerName = action.text;
            break;
        case SET_LANDING_PAGE_VISIBLE:
            newState = _cloneDeep(state);
            newState.landingPageVisible = action.bool;
            break;
        case SET_CRISP_LOADED:
            newState = _cloneDeep(state);
            newState.crispLoaded = action.bool;
            break;
        case PLANS_SET_DISCOUNT:
            newState = _cloneDeep(state);
            newState.referralOfferingDiscount = action.json.ref_discount;
            break;
        case SET_WIZARD_BOOL:
            newState = _cloneDeep(state);
            newState.showAdminWizard = action.bool;
            break;
        case SET_ZOORIX_M_INSTALLED:
            newState = _cloneDeep(state);
            newState.zoorixMEnabled = action.bool;
            newState.zoorixMEnabledLoading = false;
            break;
        case EMAIL_CHANGE:
            newState = _cloneDeep(state);
            newState.ownerEmail = action.text;
            break;
        case UPDATE_ZOORIX_TOAST:
            newState = _cloneDeep(state);
            newState.installUrl = action.installUrl;
            newState.outOfDateToastActive = true;
            break;
        case DISMISS_OUT_OF_DATE_TOAST:
            newState = _cloneDeep(state);
            newState.outOfDateToastActive = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default data;
