import _cloneDeep from "lodash/cloneDeep";

import {
    PARTNERS_CHANGE_PASSWORD_SUCCESS,
    PARTNERS_CHANGING_PASSWORD, PARTNERS_CHECKING_BRAND_AVAILABILITY,
    PARTNERS_COPY_REF_URL_FAILURE,
    PARTNERS_COPY_REF_URL_SUCCESS,
    PARTNERS_DISMISS_TOAST,
    PARTNERS_EMAIL_CHANGED,
    PARTNERS_LOGGING_IN,
    PARTNERS_NAV_TO_DASHBOARD,
    PARTNERS_NAV_TO_FORGOT_PASSWORD,
    PARTNERS_NAV_TO_LOGIN,
    PARTNERS_NAV_TO_SIGN_UP,
    PARTNERS_NAV_TO_VERIFY_CODE,
    PARTNERS_NAV_TO_VERIFY_FORGOT_PASSWORD_CODE,
    PARTNERS_NEW_PASSWORD_CHANGE,
    PARTNERS_OFFERING_VALUE_CHANGE,
    PARTNERS_OLD_PASSWORD_CHANGE,
    PARTNERS_OPEN_CHANGE_PASSWORD_MODAL,
    PARTNERS_PASSWORD_CHANGED,
    PARTNERS_PAYPAL_EMAIL_CHANGE, PARTNERS_SAVING_BRAND,
    PARTNERS_SENDING_FORGOT_PASSWORD_CODE,
    PARTNERS_SET_BRAND,
    PARTNERS_SET_BRAND_AVAILABLE,
    PARTNERS_SET_BRAND_TOO_SHORT, PARTNERS_SET_SAVED_BRAND,
    PARTNERS_SIGNING_UP, PARTNERS_TOAST_STATUS,
    PARTNERS_TRIED_TO_LOGIN,
    PARTNERS_UPDATE_PAYPAL_EMAIL_COMPLETE,
    PARTNERS_UPDATING_PAYPAL_EMAIL,
    PARTNERS_VERIFICATION_CODE_CHANGE,
    PARTNERS_VERIFYING_CODE,
    SET_PARTNER
} from "../actions/partners";

function refUrl(newState) {
    if (newState.savedBrand) {
        return `https://api.zoorix.com/ref/${newState.tinyCodes[newState.offeringValueToCode[newState.offeringValue]]}/${newState.savedBrand}`;
    } else  {
        return `https://api.zoorix.com/ref/${newState.offerings[newState.offeringValueToCode[newState.offeringValue]]}`;
    }
}

const partners = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case PARTNERS_EMAIL_CHANGED:
            newState = _cloneDeep(state);
            newState.email = action.text;
            break;
        case PARTNERS_PASSWORD_CHANGED:
            newState = _cloneDeep(state);
            newState.password = action.text;
            break;
        case PARTNERS_VERIFICATION_CODE_CHANGE:
            newState = _cloneDeep(state);
            newState.verificationCode = action.text;
            break;
        case PARTNERS_OLD_PASSWORD_CHANGE:
            newState = _cloneDeep(state);
            newState.oldPassword = action.text;
            break;
        case PARTNERS_NEW_PASSWORD_CHANGE:
            newState = _cloneDeep(state);
            newState.newPassword = action.text;
            break;
        case PARTNERS_PAYPAL_EMAIL_CHANGE:
            newState = _cloneDeep(state);
            newState.paypalEmail = action.text;
            break;
        case PARTNERS_OFFERING_VALUE_CHANGE:
            newState = _cloneDeep(state);
            newState.offeringValue = action.text;
            newState.refUrl = refUrl(newState);
            break;
        case PARTNERS_SET_BRAND:
            newState = _cloneDeep(state);
            newState.brand = action.text;
            break;
        case PARTNERS_SET_SAVED_BRAND:
            newState = _cloneDeep(state);
            newState.savedBrand = action.text;
            newState.refUrl = refUrl(newState);
            break;
        case PARTNERS_LOGGING_IN:
            newState = _cloneDeep(state);
            newState.loggingIn = action.bool;
            break;
        case PARTNERS_SENDING_FORGOT_PASSWORD_CODE:
            newState = _cloneDeep(state);
            newState.sendingForgotPasswordCode = action.bool;
            break;
        case PARTNERS_SIGNING_UP:
            newState = _cloneDeep(state);
            newState.signingUp = action.bool;
            break;
        case PARTNERS_VERIFYING_CODE:
            newState = _cloneDeep(state);
            newState.verifyingCode = action.bool;
            break;
        case PARTNERS_NAV_TO_SIGN_UP:
            newState = _cloneDeep(state);
            newState.navState = 'login_signup';
            break;
        case PARTNERS_TRIED_TO_LOGIN:
            newState = _cloneDeep(state);
            newState.triedToLogin = true;
            break;
        case PARTNERS_NAV_TO_LOGIN:
            newState = _cloneDeep(state);
            newState.navState = 'login';
            break;
        case PARTNERS_NAV_TO_FORGOT_PASSWORD:
            newState = _cloneDeep(state);
            newState.navState = 'login_forgot_password';
            break;
        case PARTNERS_NAV_TO_VERIFY_FORGOT_PASSWORD_CODE:
            newState = _cloneDeep(state);
            newState.navState = 'login_forgot_password_verify_code';
            newState.password = '';
            newState.verificationCode = '';
            break;
        case PARTNERS_DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastActive = false;
            break;
        case PARTNERS_CHANGE_PASSWORD_SUCCESS:
            newState = _cloneDeep(state);
            newState.toastActive = true;
            newState.toastContent = 'Password Changed';
            break;
        case PARTNERS_TOAST_STATUS:
            newState = _cloneDeep(state);
            newState.toastActive = true;
            newState.toastContent = action.text;
            break;
        case PARTNERS_COPY_REF_URL_SUCCESS:
            newState = _cloneDeep(state);
            newState.toastActive = true;
            newState.toastContent = 'URL Copied';
            break;
        case PARTNERS_COPY_REF_URL_FAILURE:
            newState = _cloneDeep(state);
            newState.toastActive = true;
            newState.toastContent = 'Failed to copy';
            break;
        case PARTNERS_NAV_TO_DASHBOARD:
            newState = _cloneDeep(state);
            newState.navState = 'dashboard';
            break;
        case PARTNERS_OPEN_CHANGE_PASSWORD_MODAL:
            newState = _cloneDeep(state);
            newState.changePasswordModalOpen = action.bool;
            newState.oldPassword = '';
            newState.newPassword = '';
            break;
        case PARTNERS_CHANGING_PASSWORD:
            newState = _cloneDeep(state);
            newState.changingPassword = action.bool;
            break;
        case PARTNERS_UPDATING_PAYPAL_EMAIL:
            newState = _cloneDeep(state);
            newState.updatingPaypalEmail = action.bool;
            break;
        case PARTNERS_SET_BRAND_AVAILABLE:
            newState = _cloneDeep(state);
            newState.brandAvailable = action.bool;
            break;
        case PARTNERS_SET_BRAND_TOO_SHORT:
            newState = _cloneDeep(state);
            newState.brandTooShort = action.bool;
            break;
        case PARTNERS_CHECKING_BRAND_AVAILABILITY:
            newState = _cloneDeep(state);
            newState.checkingBrandAvailability = action.bool;
            break;
        case PARTNERS_SAVING_BRAND:
            newState = _cloneDeep(state);
            newState.savingBrand = action.bool;
            break;
        case PARTNERS_UPDATE_PAYPAL_EMAIL_COMPLETE:
            newState = _cloneDeep(state);
            if (action.result) {
                newState.toastContent = 'Confirmation mail sent, please check your mail';
                newState.paypalEmail = action.result.paypal_email;
            } else {
                newState.toastContent = 'Update failed';
            }
            newState.toastActive = true;
            break;
        case PARTNERS_NAV_TO_VERIFY_CODE:
            newState = _cloneDeep(state);
            newState.navState = 'login_verify_code';
            newState.username = action.username;
            break;
        case SET_PARTNER:
            newState = _cloneDeep(state);
            newState.sub = action.sub;
            newState.secret = action.secret;
            newState.brand = action.brand;
            newState.savedBrand = action.brand;
            newState.brandAvailable = true;
            newState.paypalEmail = action.paypalEmail;
            newState.offerings = _cloneDeep(action.offerings);
            newState.tinyCodes = _cloneDeep(action.tinyCodes);
            newState.package = _cloneDeep(action.package);
            newState.offeringValueToCode = {};
            newState.offeringValueToCommission = {};
            newState.offeringValueToDiscount = {};
            for (let i = 0, com = newState.package.min_commission, dis = newState.package.max_commission;
                    com <= newState.package.max_commission;
                    i += 1, com += newState.package.commission_step, dis -= newState.package.commission_step) {
                newState.offeringValueToCode[i] = `o${com}_${dis}`;
                newState.offeringValueToCommission[i] = com;
                newState.offeringValueToDiscount[i] = dis;
            }
            newState.maxSliderStep = (newState.package.max_commission - newState.package.min_commission) / newState.package.commission_step;
            newState.offeringValue = newState.package.min_commission;
            newState.refUrl = refUrl(newState);
            newState.visits = action.visits;
            newState.referrals = action.referrals;
            newState.totalEarnings = action.totalEarnings;
            newState.totalPaid = action.totalPaid;
            newState.referredStores = _cloneDeep(action.referredStores);
            newState.partnerPayments = _cloneDeep(action.partnerPayments);
            break;
        default:
            return state;
    }
    return newState;
};

export default partners;
