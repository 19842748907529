import _cloneDeep from "lodash/cloneDeep";

import {
    SAVING_VOLUME_WIDGET,
    VOLUME_ADD_TO_CART_BG_COLOR_CHANGE,
    VOLUME_ADD_TO_CART_COLOR_CHANGE,
    VOLUME_ADD_VARIANT_BG_COLOR_CHANGE,
    VOLUME_ADD_VARIANT_TEXT_COLOR_CHANGE,
    VOLUME_POPULAR_BG_COLOR_CHANGE, VOLUME_POPULAR_TEXT_COLOR_CHANGE,
    VOLUME_RESTORE_TO_DEFAULTS, VOLUME_SAVE_AMOUNT_CHANGE,
    VOLUME_SAVE_TEXT_COLOR_CHANGE,
    VOLUME_SELECTED_BG_COLOR_CHANGE, VOLUME_SETTINGS_CHANGE_BOOL, VOLUME_SETTINGS_COLOR_CHANGE,
    VOLUME_SETTINGS_INIT, VOLUME_SETTINGS_STRING_CHANGE, VOLUME_SHOW_TIERS_TABLE_CHANGE,
    VOLUME_SUBTITLE_COLOR_CHANGE,
    VOLUME_SUBTITLE_FONT_SIZE_CHANGE,
    VOLUME_TITLE_ALIGN_CHANGE,
    VOLUME_TITLE_COLOR_CHANGE,
    VOLUME_TITLE_FONT_SIZE_CHANGE, VOLUME_TOTAL_DISCOUNTED_PRICE_COLOR_CHANGE, VOLUME_TOTAL_ORIGINAL_PRICE_COLOR_CHANGE,
    VOLUME_WIDGET_SAVE_FAILURE,
    VOLUME_WIDGET_SAVE_SUCCESS
} from "../actions/settingsVolume";

const methodToText = {
    legacy: 'Legacy Discount Code',
    product_discount: 'Functions Product Discount',
};

const settingsVolume = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case VOLUME_SETTINGS_INIT:
            newState = _cloneDeep(state);
            newState.settings = _cloneDeep(action.volumeSettings.values);
            newState.defaults = _cloneDeep(action.volumeSettings.defaults);
            [].forEach(attr => newState.settings[attr] = newState.settings[attr] || newState.defaults[attr]);
            newState.methodOptions = Object.entries(action.json.volume_settings.methods).map(entry => ({label: methodToText[entry[0]], value: entry[1]}))
            break;
        case VOLUME_RESTORE_TO_DEFAULTS:
            newState = _cloneDeep(state);
            newState.settings = _cloneDeep(newState.defaults);
            break;
        case VOLUME_SETTINGS_CHANGE_BOOL:
            newState = _cloneDeep(state);
            newState.settings[action.attr] = action.val === 'yes';
            break;
        case SAVING_VOLUME_WIDGET:
            newState = _cloneDeep(state);
            newState.saveInProgress = true;
            newState.saveError = false;
            break;
        case VOLUME_WIDGET_SAVE_FAILURE:
            newState = _cloneDeep(state);
            newState.saveInProgress = false;
            newState.saveError = true;
            break;
        case VOLUME_WIDGET_SAVE_SUCCESS:
            newState = _cloneDeep(state);
            newState.saveInProgress = false;
            newState.toastIsActive = true;
            break;
        case VOLUME_ADD_TO_CART_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.add_to_cart_color = action.hex;
            break;
        case VOLUME_ADD_TO_CART_BG_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.add_to_cart_bg_color = action.hex;
            break;
        case VOLUME_TITLE_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.title_color = action.hex;
            break;
        case VOLUME_SUBTITLE_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.subtitle_color = action.hex;
            break;
        case VOLUME_SELECTED_BG_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.selected_bg_color = action.hex;
            break;
        case VOLUME_SAVE_TEXT_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.save_text_color = action.hex;
            break;
        case VOLUME_ADD_VARIANT_BG_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.add_variant_bg_color = action.hex;
            break;
        case VOLUME_ADD_VARIANT_TEXT_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.add_variant_text_color = action.hex;
            break;
        case VOLUME_POPULAR_BG_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.popular_bg_color = action.hex;
            break;
        case VOLUME_POPULAR_TEXT_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.popular_text_color = action.hex;
            break;
        case VOLUME_TOTAL_ORIGINAL_PRICE_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.total_original_price_color = action.hex;
            break;
        case VOLUME_TOTAL_DISCOUNTED_PRICE_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.total_discounted_price_color = action.hex;
            break;
        case VOLUME_SETTINGS_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings[action.attr] = action.hex;
            break;
        case VOLUME_SETTINGS_STRING_CHANGE:
            newState = _cloneDeep(state);
            newState.settings[action.attr] = action.text;
            break;
        case VOLUME_TITLE_ALIGN_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.title_align = action.text;
            break;
        case VOLUME_TITLE_FONT_SIZE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.title_font_size = action.text;
            break;
        case VOLUME_SUBTITLE_FONT_SIZE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.subtitle_font_size = action.text;
            break;
        case VOLUME_SAVE_AMOUNT_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.save_per_item = action.text === 'item';
            newState.settings.show_saved_percentage = action.text === 'percentage';
            break;
        case VOLUME_SHOW_TIERS_TABLE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.show_tiers_table = action.text === 'show_both';
            newState.settings.show_only_table = action.text === 'show_only_table';
            break;
        default:
            return state;
    }
    return newState;
};

export default settingsVolume;
