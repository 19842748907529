import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";

import {
    CREATE_PAGE,
    EDIT_PAGE,
    LOAD_PAGES_SUCCESS, PAGE_DELETING,
    PAGE_SAVING,
    PAGE_STRING_CHANGE, PAGES_DELETE_OFFER,
    PAGES_LOADING_OFFERS,
    PAGES_MODE_OFFER_DOWN,
    PAGES_MODE_OFFER_UP,
    PAGES_POPULATE_OFFERS,
    PAGES_SELECT_OFFER,
    PAGES_SET_CONTEXT,
    PAGES_SHOW_COMPONENT
} from "../actions/pages";

const contextToOfferType = {
    bundles: 'bundle',
    vds: 'vd',
    mnms: 'mnm',
    bogos: 'bogo',
}

const pages = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case LOAD_PAGES_SUCCESS:
            newState = _cloneDeep(state);
            newState.pages = _cloneDeep(action.pages);
            break;
        case PAGES_POPULATE_OFFERS:
            newState = _cloneDeep(state);
            newState.offersForSearch = {};
            ['bundles', 'vds', 'mnms', 'bogos'].forEach((type) => {
                newState.offersForSearch[type] = action.json[type].map(o => ({key: o.id, value: o.name}));
            });
            break;
        case EDIT_PAGE:
            newState = _cloneDeep(state);
            newState.page = _cloneDeep(_find(newState.pages, {id: action.id}));
            break;
        case PAGE_DELETING:
            newState = _cloneDeep(state);
            newState.deletingPage = newState.deletingPage || {};
            newState.deletingPage[action.id] = action.bool;
            break;
        case PAGES_MODE_OFFER_UP:
            newState = _cloneDeep(state);
            {
                const index = _findIndex(newState.page.offers, {id: action.id});
                [newState.page.offers[index - 1], newState.page.offers[index]] =
                  [newState.page.offers[index], newState.page.offers[index - 1]];
            }
            break;
        case PAGES_MODE_OFFER_DOWN:
            newState = _cloneDeep(state);
            {
                const index = _findIndex(newState.page.offers, {id: action.id});
                [newState.page.offers[index + 1], newState.page.offers[index]] =
                  [newState.page.offers[index], newState.page.offers[index + 1]];
            }
            break;
        case PAGES_DELETE_OFFER:
            newState = _cloneDeep(state);
            newState.page.offers.splice(action.index, 1);
            break;
        case PAGES_LOADING_OFFERS:
            newState = _cloneDeep(state);
            newState.loadingOffers = action.bool;
            break;
        case PAGES_SELECT_OFFER:
            newState = _cloneDeep(state);
            newState.page.offers.push({
                id: action.offer.key,
                offer_type: contextToOfferType[newState.context],
            });
            break;
        case CREATE_PAGE:
            newState = _cloneDeep(state);
            newState.page = {
                title: '',
                offers: [],
            };
            break;
        case PAGES_SHOW_COMPONENT:
            newState = _cloneDeep(state);
            newState.visibeComponent = action.text;
            break;
        case PAGES_SET_CONTEXT:
            newState = _cloneDeep(state);
            newState.context = action.text;
            break;
        case PAGE_STRING_CHANGE:
            newState = _cloneDeep(state);
            newState.page[action.attr] = action.text;
            break;
        case PAGE_SAVING:
            newState = _cloneDeep(state);
            newState.pageSaving = action.bool;
            break;
        default:
            return state;
    }
    return newState;
};

export default pages;
