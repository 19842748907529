import _cloneDeep from "lodash/cloneDeep";
import _remove from "lodash/remove";

import {calcLocales} from "../texts";
import {
    FREE_SHIPPING_BAR_ADD_COUNTRY,
    FREE_SHIPPING_BAR_CANCEL_ADDING_NEW_COUNTRY,
    FREE_SHIPPING_BAR_CHANGE_GOAL,
    FREE_SHIPPING_BAR_CHANGE_SETTING_BOOL,
    FREE_SHIPPING_BAR_CHANGE_SETTING_COLOR,
    FREE_SHIPPING_BAR_CHANGE_SETTING_TEXT,
    FREE_SHIPPING_BAR_DISMISS_TOAST, FREE_SHIPPING_BAR_LOCALE_CHANGE,
    FREE_SHIPPING_BAR_REMOVE_GOAL,
    FREE_SHIPPING_BAR_REMOVE_TARGET_COUNTRY,
    FREE_SHIPPING_BAR_SAVING_SETTINGS,
    FREE_SHIPPING_BAR_SAVING_SETTINGS_SUCCESS,
    FREE_SHIPPING_BAR_SELECT_NEW_COUNTRY,
    FREE_SHIPPING_BAR_SELECT_NEW_TARGET_COUNTRY,
    FREE_SHIPPING_BAR_SELECT_TAB, FREE_SHIPPING_BAR_SET_ZOORIX_M_PROMO_BADGE,
    FREE_SHIPPING_BAR_SUBMITTING_SUPPORT,
    FREE_SHIPPING_BAR_SUPPORT_TEXT_CHANGE,
    FREE_SHIPPING_BAR_TEXTS_CHANGE,
    freeShippingBarTabs,
    INITIALIZE_FREE_SHIPPING_BAR,
    SET_FREE_SHIPPING_BAR_DASHBOARD,
    SET_FREE_SHIPPING_BAR_LOCALES,
    SET_FREE_SHIPPING_BAR_SETTINGS,
    SET_FREE_SHIPPING_BAR_STORE_DATA
} from "../../actions/free-shipping-bar/freeShippingBar";

function fillNewSettings(state) {
    ['bar_text_color',
        'bar_amount_color',
        'bar_bg_color',
        'bar_show_shadow',
        'bar_desktop_font_size',
        'bar_mobile_font_size',
        'free_shipping_goal',
        'bar_position',
        'bar_show_on',
        'bar_desktop_placement',
        'bar_mobile_placement',
        'bar_enable_click',
        'enable_initial_goal_nav',
        'initial_goal_nav_url',
        'enable_goal_progress_nav',
        'goal_progress_nav_url',
        'enable_goal_achieved_nav',
        'goal_achieved_nav_url',
        'nav_open_in_new_window',
        'show_button',
        'button_text_color',
        'button_bg_color',
        'button_border_radius',
        'show_on_specific_pages',
        'show_on_home_page',
        'show_on_product_pages',
        'show_on_collection_pages',
        'show_on_cart_page',
        'allow_visitor_to_dismiss_bar',
        'enable_target_countries',
        'target_countries',
    ].forEach(attr => {
        if (state.settings[attr] === null || typeof state.settings[attr] === 'undefined') state.settings[attr] = state.defaults[attr];
    })
}

function fillNewTexts(state) {
    ['initial_goal',
        'goal_progress',
        'goal_achieved',
        'button_initial_goal',
        'button_goal_progress',
        'button_goal_achieved',
    ].forEach(attr => {
        state.shopLocales.forEach(l => {
            const locale = l.locale;
            state.settings.texts[locale] = state.settings.texts[locale] || {};
            if (state.settings.texts[locale][attr] === null || typeof state.settings.texts[locale][attr] === 'undefined') {
                state.settings.texts[locale][attr] = state.defaults.texts[attr];
            }
        })
    })
}

const freeShippingBar = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INITIALIZE_FREE_SHIPPING_BAR:
            newState = _cloneDeep(state);
            newState.initialized = true;
            newState.shop = action.params.shop;
            newState.token = action.params.token;
            newState.selectedTab = 0;
            newState.promoShake = true;
            newState.promoBadge = true;
            newState.storeData = newState.storeData || {
                zoorix_m_installed_and_enabled: true,
            }
            break;
        case FREE_SHIPPING_BAR_SAVING_SETTINGS_SUCCESS:
            newState = _cloneDeep(state);
            newState.toastActive = true;
            newState.toastContent = 'Saved!'
            break;
        case FREE_SHIPPING_BAR_ADD_COUNTRY:
            newState = _cloneDeep(state);
            newState.settings.free_shipping_goal.new = newState.settings.free_shipping_goal.default;
            break;
        case FREE_SHIPPING_BAR_CANCEL_ADDING_NEW_COUNTRY:
            newState = _cloneDeep(state);
            delete newState.settings.free_shipping_goal.new;
            break;
        case FREE_SHIPPING_BAR_SELECT_NEW_COUNTRY:
            newState = _cloneDeep(state);
            newState.settings.free_shipping_goal[action.text] = newState.settings.free_shipping_goal.new;
            delete newState.settings.free_shipping_goal.new;
            break;
        case FREE_SHIPPING_BAR_SELECT_NEW_TARGET_COUNTRY:
            newState = _cloneDeep(state);
            newState.settings.target_countries = newState.settings.target_countries || [];
            if (!newState.settings.target_countries.find(code => code === action.text)) {
                newState.settings.target_countries.push(action.text);
            }
            break;
        case FREE_SHIPPING_BAR_REMOVE_GOAL:
            newState = _cloneDeep(state);
            delete newState.settings.free_shipping_goal[action.text];
            break;
        case FREE_SHIPPING_BAR_REMOVE_TARGET_COUNTRY:
            newState = _cloneDeep(state);
            _remove(newState.settings.target_countries, c => c === action.text);
            break;
        case FREE_SHIPPING_BAR_DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastActive = false;
            break;
        case FREE_SHIPPING_BAR_SELECT_TAB:
            newState = _cloneDeep(state);
            newState.selectedTab = action.index;
            if (newState.promoShake) {
                newState.promoShake = freeShippingBarTabs(newState)[newState.selectedTab].id !== 'zoorix-m';
            }
            break;
        case FREE_SHIPPING_BAR_SAVING_SETTINGS:
            newState = _cloneDeep(state);
            newState.savingSettings = action.bool;
            break;
        case FREE_SHIPPING_BAR_SET_ZOORIX_M_PROMO_BADGE:
            newState = _cloneDeep(state);
            newState.promoBadge = action.bool;
            break;
        case FREE_SHIPPING_BAR_SUBMITTING_SUPPORT:
            newState = _cloneDeep(state);
            newState.submittingSupport = action.bool;
            if (!action.bool) {
                newState.submittedSupport = true;
            }
            break;
        case SET_FREE_SHIPPING_BAR_SETTINGS:
            newState = _cloneDeep(state);
            newState.settings = _cloneDeep(action.json.settings);
            newState.defaults = _cloneDeep(action.json.defaults);
            newState.shopLocales = _cloneDeep(action.json.shop_locales.data.shopLocales);
            fillNewSettings(newState);
            fillNewTexts(newState);
            if (newState.availableLocales) {
                calcLocales(newState, newState.settings.texts);
            }
            break;
        case SET_FREE_SHIPPING_BAR_STORE_DATA:
            newState = _cloneDeep(state);
            newState.storeData = _cloneDeep(action.json);
            break;
        case FREE_SHIPPING_BAR_LOCALE_CHANGE:
            newState = _cloneDeep(state);
            newState.locale = action.locale;
            break;
        case FREE_SHIPPING_BAR_CHANGE_SETTING_TEXT:
            newState = _cloneDeep(state);
            newState.settings[action.attribute] = action.text;
            break;
        case FREE_SHIPPING_BAR_SUPPORT_TEXT_CHANGE:
            newState = _cloneDeep(state);
            newState.storeData[action.attribute] = action.text;
            break;
        case FREE_SHIPPING_BAR_TEXTS_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.texts[newState.locale][action.attribute] = action.text;
            break;
        case FREE_SHIPPING_BAR_CHANGE_SETTING_BOOL:
            newState = _cloneDeep(state);
            newState.settings[action.attribute] = action.text === 'yes';
            break;
        case FREE_SHIPPING_BAR_CHANGE_GOAL:
            newState = _cloneDeep(state);
            newState.settings.free_shipping_goal[action.country] = parseFloat(action.text);
            break;
        case FREE_SHIPPING_BAR_CHANGE_SETTING_COLOR:
            newState = _cloneDeep(state);
            newState.settings[action.attribute] = action.hex;
            break;
        case SET_FREE_SHIPPING_BAR_LOCALES:
            newState = _cloneDeep(state);
            newState.availableLocales = _cloneDeep(action.json.locales.data.availableLocales);
            if (newState.settings) {
                calcLocales(newState, newState.settings.texts);
            }
            newState.countries = action.json.countries.map(c => ({label: c.Name, value: c.Code}));
            break;
        case SET_FREE_SHIPPING_BAR_DASHBOARD:
            newState = _cloneDeep(state);
            newState.dashboardData = action.json.item && {
                views: parseInt(action.json.item.views || '0'),
                goalAchieved: parseInt(action.json.item.goal_achieved || '0'),
                goalProgress: parseInt(action.json.item.goal_progress || '0'),
                clicks: parseInt(action.json.item.clicks || '0'),
            };
            break;
        default:
            return state;
    }
    return newState;
};

export default freeShippingBar;
