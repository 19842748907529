import _cloneDeep from 'lodash/cloneDeep';

import {
    ANALYTICS_DISMISS_TOAST,
    ANALYTICS_EXPORTING,
    ANALYTICS_MINITAB_CLICKED, ANALYTICS_TOAST, ANALYTICS_TOGGLE_BOGO,
    ANALYTICS_TOGGLE_BUNDLE,
    ANALYTICS_TOGGLE_EXPORT_MODAL,
    ANALYTICS_TOGGLE_MAPPING, ANALYTICS_TOGGLE_MIX_AND_MATCH,
    ANALYTICS_TOGGLE_VOLUME,
    DATE_RANGE_CHANGE,
    DATE_RANGE_MONTH_CHANGE,
    DATE_RANGE_PRESET_CHANGE, INIT_ANAL_BOGOS,
    INIT_ANAL_BUNDLES,
    INIT_ANAL_MAPPINGS, INIT_ANAL_MIX_AND_MATCHES,
    INIT_ANAL_VOLUME_DISCOUNTS,
    INIT_ANALYTICS, INIT_ANALYTICS_FAILED,
    OPEN_ORDER,
    SORT_PRODUCTS,
    START_APPLY_DATE_RANGE,
    TOGGLE_SELECT_DATE_ACTIVE,
    UPDATE_ANALYTICS
} from "../actions/analytics";
import {INIT_DATA} from "../actions/data";
import {Image} from "@shopify/polaris";
import React from "react";

function sortAllProducts(newState) {
        const sortedProducts = Object.values(newState.products || {}).sort(getSortFunction(0, 'ascending'));
        newState.sortedProducts = mapProductsToTableCells(sortedProducts, newState.currency);
}

function getSortFunction(index, direction) {
    switch (index) {
        case 0:
            return (v1, v2) => direction === 'ascending' ? (v1.title || '').toLowerCase().localeCompare((v2.title || '').toLowerCase()) : (v2.title || '').toLowerCase().localeCompare((v1.title || '').toLowerCase());
        case 1:
            return (v1, v2) => direction === 'ascending' ? v1.quantity - v2.quantity : v2.quantity - v1.quantity;
        case 2:
            return (v1, v2) => direction === 'ascending' ? v1.total - v2.total : v2.total - v1.total;
        default:
            return
    }
}

function mapProductsToTableCells(sortedProducts, symbol) {
    return sortedProducts.map((p, index) => (
        [(<div className="roi-product-title" key={index}><Image src={p.image_src} height="20px" width="20px" />
            <span className="roi-product-title-span">{p.title}</span></div>),
            p.quantity,
            `${Number(p.total).toFixed(2)} ${symbol}`]));
}

function calcRevenue(newState) {
        newState.revenue = {
            datasets: [
                {
                    label: 'Revenue',
                    backgroundColor: 'rgba(54,125,98,0.4)',
                    borderColor: 'rgba(54,125,98,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(54,125,98,0.8)',
                    hoverBorderColor: 'rgba(54,125,98,1)',
                    data: newState.revenueGraph,
                }
            ]
        }
        newState.orders = {
            datasets: [
                {
                    label: 'Orders',
                    backgroundColor: 'rgba(54,125,98,0.4)',
                    borderColor: 'rgba(54,125,98,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(54,125,98,0.8)',
                    hoverBorderColor: 'rgba(54,125,98,1)',
                    data: newState.ordersGraph,
                }
            ]
        }
}

function parseResponse(json) {
    return Object.entries(json).map(e => ({
        id: e[0],
        name: e[1].name,
        percentage: `${Math.round(e[1].num_orders / e[1].shown * 10000) / 100}%`,
        percentageNum: e[1].num_orders / e[1].shown,
        products: Object.entries(e[1].products).map(p => ({
            id: p[0],
            title: p[1].title,
            count: p[1].count,
            total_sales: p[1].total_sales,
        })).sort((i, j) => j.count - i.count),
    }))
}

function calcDisplayedValues(newState) {
    newState.displayedValues = {
        totalOrders: {
            totalOrders: newState.totalOrders > 0 && Number(newState.totalOrders).toLocaleString('en'),
            zoorixOrdersPercentage: newState.totalOrders > 0 && `${Math.round(newState.zoorixOrders * 10000 / newState.totalOrders) / 100}%`,
            zoorixOrders: newState.zoorixOrders > 0 && Number(newState.zoorixOrders).toLocaleString('en'),
        },
        totalRevenue: {
            totalOrders: `${Number(newState.totalSales).toLocaleString('en')} ${newState.currency}`,
            totalByZoorix: `${Number(newState.zoorixRevenue).toLocaleString('en')} ${newState.currency}`,
            revenuePercentage: newState.totalSales > 0 && `${Math.round(newState.zoorixRevenue * 10000 / newState.totalSales) / 100}%`,
        },
        roi: {
            revenueByZoorixUsd: `$${Number(newState.zoorixRevenueUsd).toLocaleString('en')}`,
            paidToZoorixUsd: `$${Number(Math.round(newState.payments * 100) / 100).toLocaleString('en')}`,
            roi: newState.payments === 0 ? 'Infinity' : `${Math.round(newState.zoorixRevenueUsd / newState.payments * 100)}%`,
        },
        aov: {
            storeAov: newState.totalOrders > 0 && `${Number(Math.round(newState.totalSales / newState.totalOrders * 100) / 100).toLocaleString('en')} ${newState.currency}`,
            zoorixAov: newState.zoorixOrders > 0 && `${Number(Math.round(newState.totalZoorixSales / newState.zoorixOrders * 100) / 100).toLocaleString('en')} ${newState.currency}`,
            aovPercentage: newState.zoorixOrders > 0 && newState.totalOrders > 0 && newState.totalSales > 0 && `${Math.round((newState.totalZoorixSales / newState.zoorixOrders) / (newState.totalSales / newState.totalOrders) * 10000) / 100}%`,
        },
    }
}

function setDateRanges(newState) {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    newState.dateRangeMonth = new Date().getMonth();
    newState.dateRangeYear = new Date().getFullYear();
    newState.dateRangePreset = 'Last 30 days';
    newState.dateRangeSelectedDates = {
        start: thirtyDaysAgo,
        end: new Date(),
    };

}

const analytics = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_DATA:
            newState = _cloneDeep(state);
            newState.isFetching = true;
            newState.isFetchingMappings = true;
            newState.isFetchingBundles = true;
            newState.isFetchingVolumeDiscounts = true;
            newState.isFetchingMixAndMatches = true;
            newState.isFetchingBogos = true;
            setDateRanges(newState);
            break;
        case INIT_ANAL_MAPPINGS:
            newState = _cloneDeep(state);
            newState.isFetchingMappings = false;
            newState.mappingOpen = {};
            newState.mappings = parseResponse(action.json).sort((i, j) => j.percentageNum - i.percentageNum);
            break;
        case INIT_ANAL_BUNDLES:
            newState = _cloneDeep(state);
            newState.appType = action.appType;
            newState.isFetchingBundles = false;
            newState.bundleOpen = {};
            newState.bundles = parseResponse(action.json).sort((i, j) => j.percentageNum - i.percentageNum);
            break;
        case INIT_ANAL_VOLUME_DISCOUNTS:
            newState = _cloneDeep(state);
            newState.isFetchingVolumeDiscounts = false;
            newState.volumeOpen = {};
            newState.volumeDiscounts = parseResponse(action.json).sort((i, j) => j.percentageNum - i.percentageNum);
            break;
        case INIT_ANAL_MIX_AND_MATCHES:
            newState = _cloneDeep(state);
            newState.isFetchingMixAndMatches = false;
            newState.mnmOpen = {};
            newState.mixAndMatches = parseResponse(action.json).sort((i, j) => j.percentageNum - i.percentageNum);
            break;
        case INIT_ANAL_BOGOS:
            newState = _cloneDeep(state);
            newState.isFetchingBogos = false;
            newState.bogoOpen = {};
            newState.bogos = parseResponse(action.json).sort((i, j) => j.percentageNum - i.percentageNum);
            break;
        case INIT_ANALYTICS_FAILED:
            newState = _cloneDeep(state);
            newState.visibleTab = 'failed';
            newState.fetchFailed = true;
            newState.isFetching = false;
            newState.isFetchingDateRange = false;
            newState.isSelectDateActive = false;
            setDateRanges(newState);
            break;
        case INIT_ANALYTICS:
            newState = _cloneDeep(state);
            newState.visibleTab = 'general';
            newState.appType = action.appType;
            newState.preloaded = action.json.preloaded;
            newState.payments = action.payments;
            newState.totalOrders = action.totalOrders;
            newState.zoorixOrders = action.ordersGenByZoorix.length;
            newState.currency = action.currency;
            newState.multiCurrency = action.multiCurrency;
            newState.totalSales = action.totalSales;
            newState.totalZoorixSales = action.totalZoorixSales;
            newState.zoorixRevenue = action.zoorixRevenue;
            newState.zoorixRevenueUsd = action.zoorixRevenueUsd;
            newState.ordersGraph = _cloneDeep(action.ordersGraph);
            newState.revenueGraph = _cloneDeep(action.revenueGraph);
            newState.products = _cloneDeep(action.products);
            sortAllProducts(newState);
            calcRevenue(newState);
            newState.isFetching = false;
            setDateRanges(newState);
            newState.ordersGenByZoorix = _cloneDeep(action.ordersGenByZoorix).sort((o1, o2) => o1.processed_at.localeCompare(o2.processed_at));
            newState.productsGenByZoorix = _cloneDeep(action.productsGenByZoorix);
            newState.orderOpen = {};
            calcDisplayedValues(newState);
            break;
        case UPDATE_ANALYTICS:
            newState = _cloneDeep(state);
            newState.payments = action.payments;
            newState.totalOrders = action.totalOrders;
            newState.zoorixOrders = action.ordersGenByZoorix.length;
            newState.currency = action.currency;
            newState.multiCurrency = action.multiCurrency;
            newState.totalSales = action.totalSales;
            newState.totalZoorixSales = action.totalZoorixSales;
            newState.zoorixRevenue = action.zoorixRevenue;
            newState.zoorixRevenueUsd = action.zoorixRevenueUsd;
            newState.ordersGraph = _cloneDeep(action.ordersGraph);
            newState.revenueGraph = _cloneDeep(action.revenueGraph);
            newState.products = _cloneDeep(action.products);
            newState.isFetchingDateRange = false;
            newState.isSelectDateActive = false;
            sortAllProducts(newState);
            calcRevenue(newState);
            newState.ordersGenByZoorix = _cloneDeep(action.ordersGenByZoorix).sort((o1, o2) => o1.processed_at.localeCompare(o2.processed_at));
            newState.productsGenByZoorix = _cloneDeep(action.productsGenByZoorix);
            calcDisplayedValues(newState);
            break;
        case TOGGLE_SELECT_DATE_ACTIVE:
            newState = _cloneDeep(state);
            newState.isSelectDateActive = !newState.isSelectDateActive;
            calcRevenue(newState); // for some odd reason, this line prevents crash as chartjs
            break;
        case ANALYTICS_TOGGLE_MAPPING:
            newState = _cloneDeep(state);
            newState.mappingOpen[action.id] = !newState.mappingOpen[action.id];
            break;
        case ANALYTICS_TOGGLE_BUNDLE:
            newState = _cloneDeep(state);
            newState.bundleOpen[action.id] = !newState.bundleOpen[action.id];
            break;
        case ANALYTICS_TOGGLE_MIX_AND_MATCH:
            newState = _cloneDeep(state);
            newState.mnmOpen[action.id] = !newState.mnmOpen[action.id];
            break;
        case ANALYTICS_TOGGLE_BOGO:
            newState = _cloneDeep(state);
            newState.bogoOpen[action.id] = !newState.bogoOpen[action.id];
            break;
        case ANALYTICS_TOGGLE_VOLUME:
            newState = _cloneDeep(state);
            newState.volumeOpen[action.id] = !newState.volumeOpen[action.id];
            break;
        case OPEN_ORDER:
            newState = _cloneDeep(state);
            newState.orderOpen[action.id] = !newState.orderOpen[action.id];
            calcRevenue(newState); // for some odd reason, this line prevents crash as chartjs
            break;
        case ANALYTICS_MINITAB_CLICKED:
            newState = _cloneDeep(state);
            newState.visibleTab = action.tabName;
            calcRevenue(newState); // for some odd reason, this line prevents crash as chartjs
            break;
        case ANALYTICS_TOGGLE_EXPORT_MODAL:
            newState = _cloneDeep(state);
            newState.exportModalVisible = !newState.exportModalVisible;
            calcRevenue(newState); // for some odd reason, this line prevents crash as chartjs
            break;
        case ANALYTICS_EXPORTING:
            newState = _cloneDeep(state);
            newState.exporting = action.bool;
            break;
        case START_APPLY_DATE_RANGE:
            newState = _cloneDeep(state);
            newState.isFetchingDateRange = true;
            newState.isFetchingMappings = true;
            newState.isFetchingBundles = true;
            newState.isFetchingVolumeDiscounts = true;
            break;
        case DATE_RANGE_PRESET_CHANGE:
            newState = _cloneDeep(state);
            newState.dateRangePreset = action.preset;
            const startDate = new Date();
            switch (action.preset) {
                case 'Last 7 days':
                    startDate.setDate(startDate.getDate() - 7);
                    break;
                case 'Last 30 days':
                    startDate.setDate(startDate.getDate() - 30);
                    break;
                case 'Last 90 days':
                    startDate.setDate(startDate.getDate() - 90);
                    break;
                default:
                    startDate.setDate(startDate.getDate() - 30);
                    break;
            }
            newState.dateRangeSelectedDates = {
                start: startDate,
                end: new Date(),
            };
            break;
        case DATE_RANGE_CHANGE:
            newState = _cloneDeep(state);
            newState.dateRangeSelectedDates = _cloneDeep(action.range);
            break;
        case ANALYTICS_TOAST:
            newState = _cloneDeep(state);
            newState.toastContent = action.text;
            newState.toastActive = true;
            break;
        case ANALYTICS_DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastActive = false;
            break;
        case DATE_RANGE_MONTH_CHANGE:
            newState = _cloneDeep(state);
            newState.dateRangeMonth = action.month;
            newState.dateRangeYear = action.year;
            break;
        case SORT_PRODUCTS:
            newState = _cloneDeep(state);
            const sortedProducts = Object.values(newState.products || {}).sort(getSortFunction(action.index, action.direction));
            newState.sortedProducts = mapProductsToTableCells(sortedProducts, newState.currency);
            calcRevenue(newState); // for some odd reason, this line prevents crash as chartjs
            break;
        default:
            return state;
    }
    return newState;
};

export default analytics;
