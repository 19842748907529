import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';
import {Modal as PModal, TextField, TextStyle, Icon} from "@shopify/polaris";
import {
  PlayCircleMajor
} from '@shopify/polaris-icons';
import {Modal as AntModal} from 'antd';
import "video-react/dist/video-react.css";
import { Player,BigPlayButton } from 'video-react';
import VisibleSendEvent from "../../../containers/global/VisibleSendEvent";

const imagePerOfferType = {
    cross_sell: 'https://public.zoorix.com/admin/cross-sell-preview.webp',
    bundle: 'https://public.zoorix.com/admin/bundle-preview-image.webp',
    volume_discount: 'https://public.zoorix.com/admin/volume-discount-preview-image.webp',
    mix_and_match: 'https://public.zoorix.com/admin/mix-and-match-preview-image.webp',
}

export const videoModal = (title, modalOpenBool, toggleFunc, poster, source ) => {
   let player = {};
   return  <AntModal visible={modalOpenBool}
                   onCancel={(() => {
                        player.pause();
                        toggleFunc();
                    })}
                   title={title}
                   cancelText={'Close'}
                   okText="Select"
                   className="video-modal"
                   footer={null}
                   width="900px"
            >

                 <Player
                    ref={p => {
                        player = p;
                    }}
                    poster={poster}
                    src={source}
                    playsInline
                    aspectRatio="auto"
                    ><BigPlayButton position="center" />
                </Player>
        </AntModal>
}

const previewImageComponent = (currentOfferType) =>
    <div className='offer-image-preview' style={{ backgroundImage: `url(${imagePerOfferType[currentOfferType]})` }}>
    </div>

const offerTypeComponent = (currentOfferType, offerType, selectOfferType, mainBlock, innerBlock, toggleVideoModal) =>
    <div className={currentOfferType === offerType ? 'offer-type-block selected' : 'offer-type-block not-selected'}
         onClick={selectOfferType(offerType)}>
        <span className={'checkbox'}>
            <div className={'container'}>
                <div className={'round'}>
                    <input type="checkbox" readOnly checked={currentOfferType === offerType} id={offerType}/>
                    <label htmlFor={offerType}></label>
                </div>
            </div>
        </span>
        <div className='blocks-video-wrapper'>
            <div className='blocks-wrapper'>
                <div className={'main-block'}>{mainBlock}</div>
                <div className={'inner-block'}>{innerBlock}</div>
            </div>
          {toggleVideoModal && <div className='video-block' onClick={toggleVideoModal}>
                    <Icon source={PlayCircleMajor} color="base"/>
            </div>}
        </div>
    </div>;

/*const bundleOffer = (currentOfferType, currentBundleType, bundleType, selectBundleType, block) =>
    <div className={'bundle-offer-type'}>
         <span className={'checkbox'}>
                <div className={'container'}>
                    <div className={'round'}>
                        <input type="checkbox" readOnly checked={currentBundleType === bundleType} id={bundleType}/>
                        <label htmlFor={bundleType}></label>
                    </div>
                </div>
            </span>
        <div className={currentOfferType === 'bundle' && currentBundleType === bundleType ? 'selected' : 'not-selected'}
             onClick={selectBundleType(bundleType)}>
            <div className={'sub-offer-type'}>{block}</div>
        </div>
    </div>*/

const AppV2WizardSelectOfferType = ({
    appV2Wizard, selectOfferType, selectBundleType, back, next, toggleContactUsModal,
    sendSupportRequest, data, support, onNameChange, onEmailChanged, onTextChanged, status,
    toggleCrossSellVideoModal, toggleBundleVideoModal, toggleVolumeDiscountVideoModal, toggleMixAndMatchVideoModal
}) =>
    <div className={'app-v2-wizard-select-offer-type'}>
        <VisibleSendEvent stateToEventFunc={() => ({event: 'onboarding_wizard_select_offer_type_shown', props: {}})} />
        <div className={'app-v2-wizard-select-offer-type-content'}>
            <div>
            <div className='select-offer-type-header'>
                <div className='select-offer-type-title-wrapper'>
                    <div className={'select-offer-type-title'}>Select offer type</div>
                    <div className={'select-offer-type-subtitle'}>Select the type of offer to show your customers</div>
                </div>
                <div className={'upper-nav-buttons-wrapper'}>
                    <div onClick={next} className={'app-v2-wizard-next-btn app-v2-wizard-upper-next-btn'}>Next</div>
                </div>
            </div>
            </div>
            <div className='select-offer-type-offers-help-wrapper'>
            <div className={'offers'}>
                {offerTypeComponent(appV2Wizard.offerType, 'cross_sell', selectOfferType, <div className={'offer-type'}>
                        <div className={'title'}>Cross Sell</div>
                        <div>Offer complementary products which bought together with the main product</div>
                    </div>,
                    <div>Example - Offer <span className={'strong'}>products X, Y, Z</span> for&nbsp;
                        <span className={'strong'}>product A</span>
                    </div>,
                    toggleCrossSellVideoModal
                )}
                {offerTypeComponent(appV2Wizard.offerType, 'volume_discount', selectOfferType, <div>
                        <div className={'title'}>Bulk Discount / Volume Discount / Quantity breaks</div>
                        <div>Offer discount based on number of purchased items</div>
                    </div>,
                    <div>Example - Buy X pieces of <span className="strong">Product A</span> and get&nbsp;
                        <span className="strong">20% off</span></div>,
                        toggleVolumeDiscountVideoModal
                )}
                {offerTypeComponent(appV2Wizard.offerType, 'bundle', selectOfferType, <div>
                        <div className={'title'}>Bundle</div>
                        <div>Offer discount when certain products are bought together</div>
                    </div>,
                    <div>Example - Buy <span className="strong">Products A, B & C</span> together and get&nbsp;
                        <span className="strong">20% off</span></div>,
                    toggleBundleVideoModal
                )}
                {/*{offerTypeComponent(appV2Wizard.offerType, 'bogo', selectOfferType, <div>*/}
                {/*        <div className={'title'}>BOGO</div>*/}
                {/*        <div>TBD</div>*/}
                {/*    </div>,*/}
                {/*    <div>Example - Buy <span className="strong">Products A, B & C</span> together and get&nbsp;*/}
                {/*        <span className="strong">Products D, E, F at 20% off</span></div>,*/}
                {/*    null,*/}
                {/*)}*/}
                {status.appEmbeds && offerTypeComponent(appV2Wizard.offerType, 'mix_and_match', selectOfferType, <div>
                    <div className={'title'}>Mix & Match</div>
                    <div>Offer discount based on collections</div>
                </div>, <div>
                    Example - buy X items from <span className="strong">collection A</span> and buy Y items from <span className="strong">collection B</span> and get&nbsp;
                    <span className="strong">15% off</span>
                </div>,
                toggleMixAndMatchVideoModal)}
            </div>
            <div className='offer-image-preview-wrapper'>
                {previewImageComponent(appV2Wizard.offerType)}
            </div>
            </div>
            <div className='need-help-next-wrapper'>
                <div className="need-help-drawing-wrapper">
                    <div className={'need-help-wrapper'}>
                        <div className={'need-help-title'}>Need help?</div>
                        <div className={'need-help-subtitle'}>Reach out and we'll be happy to assist!</div>
                        <span className={'need-help-call-to-action'} onClick={toggleContactUsModal}>Contact Us</span>
                    </div>
                    <div className={'drawings-wrapper'}>
                        <img alt="cat-ball" src="https://public.zoorix.com/images/cat-ball.png" className={'cat-ball'}></img>
                    </div>
                    <img alt="contact-support" src="https://public.zoorix.com/images/contact-support.png" className={'contact-support'}></img>

                </div>
                <div className={'nav-buttons-wrapper'}>
                    <div style={{'display': 'none'}} onClick={back} className={'app-v2-wizard-back-btn'}>Back</div>
                    <div onClick={next} className={'app-v2-wizard-next-btn'}>Next</div>
                </div>
            </div>
        </div>
         <PModal open={appV2Wizard.contactUsModalOpen} onClose={toggleContactUsModal}
               title="Contact Us"
               primaryAction={{
                   content: 'Send',
                   onAction: sendSupportRequest,
                   loading: appV2Wizard.sendingSupportRequest,
               }}
               secondaryActions={[
                   {
                       content: 'Cancel',
                       onAction: toggleContactUsModal,
                   },
               ]}>
            <PModal.Section>
                { support.error &&
                <TextStyle variation="negative">There was an error sending your request. Please try again in a few
                    minutes</TextStyle> }
                <TextField label="Your name *" value={data.ownerName} onChange={onNameChange}/>
                <TextField type="email" label="Your email *" value={data.ownerEmail} onChange={onEmailChanged}/>
                <TextField label="How can we help? *" multiline={5} value={support.text}
                           onChange={onTextChanged} error={support.textError}/>
            </PModal.Section>
        </PModal>

        {videoModal('Cross-Sell Offer Tutorial Video',
                    appV2Wizard.crossSellVideoModalOpen,
                    toggleCrossSellVideoModal,
                   'https://public.zoorix.com/videos/onboarding_cross_sell_poster-x.webp',
                   'https://public.zoorix.com/videos/onboarding_cross_sell.mp4'
                   )}
        {videoModal('Bundle Offer Tutorial Video',
                    appV2Wizard.bundleVideoModalOpen,
                    toggleBundleVideoModal,
                   'https://public.zoorix.com/videos/onboarding_bundle_poster-x.webp',
                   'https://public.zoorix.com/videos/onboarding_bundle_discount.mp4'
                   )}
        {videoModal('Mix And Match Offer',
                    appV2Wizard.mixAndMatchVideoModalOpen,
                    toggleMixAndMatchVideoModal,
                   'https://public.zoorix.com/videos/onboarding_mix_match_poster-x.webp',
                   'https://public.zoorix.com/videos/onboarding_mix_match.mp4'
                   )}
        {videoModal('Volume Discount Offer Tutorial Video',
                    appV2Wizard.volumeDiscountVideoModalOpen,
                    toggleVolumeDiscountVideoModal,
                   'https://public.zoorix.com/videos/onboarding_volume_discount_poster-x.webp',
                   'https://public.zoorix.com/videos/onboarding_volume_discount.mp4'
                   )}

    </div>;

export default AppV2WizardSelectOfferType;
