import _difference from "lodash/difference";
import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";
import _remove from "lodash/remove";
import _find from "lodash/find";
import _take from "lodash/take";
import _keys from "lodash/keys";
import _map from "lodash/map";

import uuid4 from "uuid4";
import {
    ADD_ALL_TO_DEST_BY_FILTER_FAILURE,
    ADD_ALL_TO_DEST_BY_FILTER_SUCCESS,
    ADD_ALL_TO_EXCLUDED_BY_FILTER_FAILURE,
    ADD_ALL_TO_EXCLUDED_BY_FILTER_SUCCESS,
    ADD_ALL_TO_GLOBAL_BY_FILTER_FAILURE,
    ADD_ALL_TO_GLOBAL_BY_FILTER_SUCCESS,
    ADD_ALL_TO_SRC_BY_FILTER_FAILURE,
    ADD_ALL_TO_SRC_BY_FILTER_SUCCESS,
    ADD_ANOTHER_DEST_FILTER,
    ADD_ANOTHER_EXCLUDED_FILTER_CHANGE,
    ADD_ANOTHER_GLOBAL_FILTER_CHANGE,
    ADD_ANOTHER_SRC_FILTER,
    BACK_TO_MAIN,
    CANCEL,
    CHANGE_DEST_FILTER,
    CHANGE_DEST_FILTER2,
    CHANGE_DEST_FILTER_OPTION,
    CHANGE_DEST_FILTER_OPTION2,
    CHANGE_MAPPING_NAME, CHANGE_MAPPING_PREVIEW_MODE,
    CHANGE_MAPPING_SUBTITLE, CHANGE_MAPPING_TEXT,
    CHANGE_MAPPING_TITLE,
    CHANGE_SRC_FILTER,
    CHANGE_SRC_FILTER2,
    CHANGE_SRC_FILTER_OPTION,
    CHANGE_SRC_FILTER_OPTION2,
    CHOOSE_DEST_PRODUCT,
    CHOOSE_DEST_PRODUCTS,
    CHOOSE_DEST_VARIANTS,
    CHOOSE_DEST_VARIANTS_DONE,
    CHOOSE_EXCLUDED_PRODUCT,
    CHOOSE_GLOBAL_PRODUCT,
    CHOOSE_GLOBAL_VARIANTS_DONE,
    CHOOSE_INCLUDED_PRODUCT,
    CHOOSE_MAPPING_NAME,
    CHOOSE_MAPPING_RULES,
    CHOOSE_SOURCE_PRODUCT,
    CHOOSE_SOURCE_VARIANTS,
    CHOOSE_SOURCE_VARIANTS_DONE,
    CLOSE_CHOOSE_DEST_VARIANTS_MODAL,
    CLOSE_CHOOSE_GLOBAL_VARIANTS_MODAL,
    CLOSE_CHOOSE_SOURCE_VARIANTS_MODAL,
    CLOSE_REMOVE_MAPPING_MODAL,
    CLOSE_SORT_DEST_PRODUCTS_MODAL,
    CLOSE_SORT_GLOBAL_PRODUCTS_MODAL,
    CREATE_MAPPING,
    CREATE_MAPPING_FAILURE,
    CREATE_MAPPING_SUCCESS,
    DEST_VARIANT_CHECKED,
    DESTINATION_DISCOUNT_CHANGE,
    DISMISS_TOAST,
    DUP_MAPPING_FAILURE,
    DUP_MAPPING_SUCCESS,
    DUPLICATING_MAPPING,
    DYNAMIC_GROUPING_CHANGE,
    EDIT_MAPPING,
    EDIT_MAPPING_FAILED,
    EDIT_MAPPING_LOADING, EDIT_MAPPING_NAME, EDIT_MAPPING_NAME_SUCCESS,
    EXCLUDED_FILTER2_CHANGE,
    EXCLUDED_FILTER2_OPTION_CHANGE,
    EXCLUDED_FILTER_CHANGE,
    EXCLUDED_FILTER_OPTION_CHANGE, EXPORT_MAPPINGS_STATUS, EXPORTING_MAPPINGS,
    FILTER_MAPPING_BY_PRODUCT,
    GLOBAL_FILTER2_CHANGE,
    GLOBAL_FILTER2_OPTION_CHANGE,
    GLOBAL_FILTER_CHANGE,
    GLOBAL_FILTER_OPTION_CHANGE,
    IMPORT_MAPPINGS_FAILURE,
    IMPORT_STATE,
    INIT_PRODUCTS,
    LOAD_ADMIN_MAPPING_SEARCH, MAPPING_LOCALE_CHANGE, MAPPING_NAME_EDITED,
    MAPPING_SEARCH_BOX_FOCUS,
    MAPPING_SEARCH_TYPE_CHANGE, MAPPING_USE_TEXTS_CHANGE,
    MAPPINGS_REORDER_SUCCESS,
    MAPPINGS_SEARCH_BOX_TEXT_CHANGE,
    MAX_ITEMS_FOR_DISCOUNT_CHANGE,
    MAX_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED,
    MIN_ITEMS_FOR_DISCOUNT_CHANGE,
    MIN_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED,
    MOVE_DEST_ITEM_DOWN,
    MOVE_DEST_ITEM_UP,
    MOVE_GLOBAL_ITEM_DOWN,
    MOVE_GLOBAL_ITEM_UP,
    MOVE_MAPPING_ITEM,
    MOVE_TEMP_DEST_ITEM_DOWN,
    MOVE_TEMP_DEST_ITEM_UP,
    MOVE_TEMP_GLOBAL_ITEM_DOWN,
    MOVE_TEMP_GLOBAL_ITEM_UP,
    NAME_EMPTY,
    ON_CHOOSE_GLOBAL_VARIANTS,
    ON_GLOBAL_VARIANT_CHECKED,
    ON_GLOBALS_ABOVE_PRICE_CHANGE,
    ON_GLOBALS_ABOVE_QUANTITY_CHANGE,
    ON_GLOBALS_BELOW_PRICE_CHANGE,
    ON_GLOBALS_BELOW_QUANTITY_CHANGE,
    ON_MAPPING_ABOVE_PRICE_CHANGE,
    ON_MAPPING_ABOVE_QUANTITY_CHANGE,
    ON_MAPPING_BELOW_PRICE_CHANGE,
    ON_MAPPING_BELOW_QUANTITY_CHANGE,
    ON_SHOW_GLOBALS_ABOVE_PRICE_CHANGED,
    ON_SHOW_GLOBALS_ABOVE_QUANTITY_CHANGED,
    ON_SHOW_GLOBALS_BELOW_PRICE_CHANGED,
    ON_SHOW_GLOBALS_BELOW_QUANTITY_CHANGED,
    ON_SHOW_GLOBALS_ON_CART_CHANGED,
    ON_SHOW_GLOBALS_ON_PRODUCT_CHANGED,
    ON_SHOW_MAPPING_ABOVE_PRICE_CHANGED,
    ON_SHOW_MAPPING_ABOVE_QUANTITY_CHANGED,
    ON_SHOW_MAPPING_BELOW_PRICE_CHANGED,
    ON_SHOW_MAPPING_BELOW_QUANTITY_CHANGED,
    ON_SHOW_MAPPING_ON_CART_CHANGED,
    ON_SHOW_MAPPING_ON_PRODUCT_CHANGED,
    ONLY_SOURCE_FAILURE,
    ONLY_SOURCE_PROCESSING,
    ONLY_SOURCE_SUCCESS, OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT,
    OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT_CHANGE,
    PRODUCT_SLIDER_DISMISS_DYNAMIC_TOAST, PRODUCTS_TOGGLE_VIDEO,
    RANDOMIZE_CHANGE,
    REMOVE_DEST_ITEM,
    REMOVE_EXCLUDED_ITEM,
    REMOVE_GLOBAL_ITEM,
    REMOVE_INCLUDED_ITEM,
    REMOVE_MAPPING_ERROR,
    REMOVE_MAPPING_STARTED,
    REMOVE_MAPPING_SUCCESS,
    REMOVE_PRODUCT_FILTER,
    REMOVE_SOURCE_ITEM,
    SAVE_EXCLUDED_FAILURE,
    SAVE_EXCLUDED_PROCESSING,
    SAVE_EXCLUDED_SUCCESS,
    SAVE_GLOBALS_FAILURE,
    SAVE_GLOBALS_PROCESSING,
    SAVE_GLOBALS_SUCCESS,
    SAVE_INCLUDED_FAILURE,
    SAVE_INCLUDED_PROCESSING,
    SAVE_INCLUDED_SUCCESS,
    SAVE_MAPPING_FAILURE,
    SAVE_MAPPING_PROCESSING,
    SAVE_MAPPING_SUCCESS,
    SEARCH_DEST_SUCCESS,
    SEARCH_EXCLUDED_SUCCESS,
    SEARCH_FAILURE,
    SEARCH_GLOBAL_SUCCESS,
    SEARCH_INCLUDED_SUCCESS,
    SEARCH_SOURCE_SUCCESS,
    SET_ENABLE_MAPPING,
    SET_ENABLE_MAPPING_LOADING,
    SHOW_DEST_PRODUCTS,
    SHOW_EXCLUDED,
    SHOW_GLOBAL,
    SHOW_GLOBAL_RULES,
    SHOW_IMPORT_MAPPINGS,
    SHOW_INCLUDE,
    SHOW_MAPPING,
    SHOW_MAPPING_RULES,
    SHOW_OTHERS_AFTER_GLOBALS_FAILURE,
    SHOW_OTHERS_AFTER_GLOBALS_PROCESSING,
    SHOW_OTHERS_AFTER_GLOBALS_SUCCESS,
    SHOW_REMOVE_MAPPING_MODAL,
    SHOW_SOURCE_PRODUCTS,
    SLIDER_DELETE_GLOBALS,
    SORT_DEST_PRODUCTS,
    SORT_DEST_PRODUCTS_DONE,
    SORT_GLOBAL_PRODUCTS,
    SORT_GLOBAL_PRODUCTS_DONE,
    SOURCE_VARIANT_CHECKED,
    START_ADDING_ALL_TO_DEST_BY_FILTER,
    START_ADDING_ALL_TO_EXCLUDED_BY_FILTER,
    START_ADDING_ALL_TO_GLOBAL_BY_FILTER,
    START_ADDING_ALL_TO_SRC_BY_FILTER,
    START_CREATE_MAPPING,
    START_SEARCH,
    SWITCH_SOURCE_DEST_FAILED,
    SWITCH_SOURCE_DEST_SUCCESS, SWITCHING_MAPPING_PREVIEW,
    SWITCHING_SOURCE_DEST,
    TOGGLE_ADD_ALL_TO_DEST_BY_FILTER_ACTIVE,
    TOGGLE_ADD_ALL_TO_EXCLUDED_BY_FILTER_ACTIVE,
    TOGGLE_ADD_ALL_TO_GLOBAL_BY_FILTER_ACTIVE,
    TOGGLE_ADD_ALL_TO_SRC_BY_FILTER_ACTIVE, TOGGLE_OVERRIDE_EXISTING_MAPPINGS_MODAL
} from "../actions/products";
import {addByFilterOptions} from "../components/products/ProductsSlider";
import {calcLocales} from "./texts";

function handleSrcFilter(newState) {
    handleFilter(newState, 'srcFilter', 'srcFilterOptions', 'srcFilterOption')
}

function handleSrcFilter2(newState) {
    handleFilter(newState, 'srcFilter2', 'srcFilterOptions2', 'srcFilterOption2')
}

function handleDestFilter(newState) {
    handleFilter(newState, 'destFilter', 'destFilterOptions', 'destFilterOption')
}

function handleDestFilter2(newState) {
    handleFilter(newState, 'destFilter2', 'destFilterOptions2', 'destFilterOption2')
}

function handleGlobalFilter(newState) {
    handleFilter(newState, 'globalFilter', 'globalFilterOptions', 'globalFilterOption')
}

function handleExcludedFilter(newState) {
    handleFilter(newState, 'excludedFilter', 'excludedFilterOptions', 'excludedFilterOption')
}

function handleExcludedFilter2(newState) {
    handleFilter(newState, 'excludedFilter2', 'excludedFilterOptions2', 'excludedFilterOption2')
}

function handleGlobalFilter2(newState) {
    handleFilter(newState, 'globalFilter2', 'globalFilterOptions2', 'globalFilterOption2')
}

function handleFilter(newState, filter, filterOptions, filterOption) {
    if (!newState[filter] || newState[filter] === 'product-type') {
        newState[filter] = 'product-type';
        newState[filterOptions] = _cloneDeep(newState.product_types);
    } else if (newState[filter] === 'vendor') {
        newState[filterOptions] = _cloneDeep(newState.vendors);
    } else if (newState[filter] === 'tag') {
        newState[filterOptions] = _cloneDeep(newState.tags);
    } else if (newState[filter] === 'automated-collection') {
        newState[filterOptions] = _cloneDeep(newState.smartCollections);
    } else if (newState[filter] === 'manual-collection') {
        newState[filterOptions] = _cloneDeep(newState.customCollections);
    }
    newState[filterOption] = (newState[filterOptions][0] && newState[filterOptions][0].value) || newState[filterOptions][0];
}

function handleMappingSearch(state) {
    state.mappingsProductFilter = null;
    const results = {};
    const type = state.mappingSearchType;
    if (state.mappingsSearchBoxText) {
        const searchText = state.mappingsSearchBoxText.toLowerCase();
        _keys(state.productSearch).forEach(mapping_id => {
           _keys(state.productSearch[mapping_id][type]).forEach(product_id => {
               let title = state.productSearch[mapping_id][type][product_id].title;
               if (title && title.toLowerCase().indexOf(searchText) >= 0) {
                   if (!results[product_id]) {
                       results[product_id] = _cloneDeep(state.productSearch[mapping_id][type][product_id]);
                       results[product_id].mappingIds = [mapping_id];
                   } else {
                       results[product_id].mappingIds.push(mapping_id);
                   }

               }
           })
        });
        state.mappingsResults = _take(Object.values(results), 10);
    } else {
        state.mappingsResults = null;
    }
}

function filterMappings(state) {
    if (state.mappingsProductFilter) {
        _forEach(state.mappings, mapping => {
            mapping.show = state.mappingsProductFilter.mappingIds.includes(mapping.id);
        });
    } else {
        _forEach(state.mappings, mapping => {
            mapping.show = true;
        });
    }
}

function calcExceedMaxDestProducts(state) {
    let count = 0;
    _forEach(state.destProducts, product => {
        if (product.dynamic) {
            count += product.products.length;
        } else {
            count += 1;
        }
    });
    return count > state.maxProducts;
}

function calcProductsCount(state, collection, variable) {
    let c = 0;
    _forEach(state[collection], p => {
        if (p.dynamic) {
            c += p.products.length;
        } else {
            c += 1;
        }
    })
    state[variable] = c;
}

function calcGlobalProductsCount(state) {
    calcProductsCount(state, 'globalProducts', 'globalProductsLength');
}

function calcExcludedProductsCount(state) {
    calcProductsCount(state, 'excludedProducts', 'excludedProductsLength');
}

const data = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_PRODUCTS:
            newState = _cloneDeep(state);
            newState.ready = true;
            newState.videoModals = {};
            newState.isDynamicGrouping = true;
            newState.editMappingLoading = {};
            newState.enableMappingLoading = {};
            newState.switchingSourceDest = {};
            newState.dupMappingLoading = {};
            newState.switchingPreview = {};
            newState.mappingSearchType = 'source';
            newState.isShowGlobalsOnProduct = action.globalsConfig.on_product_page;
            newState.isShowGlobalsOnCart = action.globalsConfig.on_cart_page;
            newState.isShowGlobalsAbovePrice = action.globalsConfig.only_above_price;
            newState.isShowGlobalsAboveQuantity = action.globalsConfig.only_above_quantity;
            newState.isShowGlobalsBelowPrice = action.globalsConfig.only_below_price;
            newState.isShowGlobalsBelowQuantity = action.globalsConfig.only_below_quantity;
            newState.globalsAbovePrice = action.globalsConfig.above_price;
            newState.globalsBelowPrice = action.globalsConfig.below_price;
            newState.globalsAboveQuantity = action.globalsConfig.above_quantity;
            newState.globalsBelowQuantity = action.globalsConfig.below_quantity;
            newState.mappingDefaults = _cloneDeep(action.mappingDefaults);
            newState.currencySymbol = action.currencySymbol;
            newState.customCollections = _cloneDeep(action.customCollections);
            newState.smartCollections = _cloneDeep(action.smartCollections);
            newState.tags = _cloneDeep(action.tags);
            newState.vendors = _cloneDeep(action.vendors);
            newState.product_types = _cloneDeep(action.product_types);
            newState.isShowOthersAfterGlobals = action.isShowOthersAfterGlobals;
            handleSrcFilter(newState);
            handleSrcFilter2(newState);
            handleDestFilter(newState);
            handleDestFilter2(newState);
            handleGlobalFilter(newState);
            handleGlobalFilter2(newState);
            handleExcludedFilter(newState);
            handleExcludedFilter2(newState);
            newState.isAddAnotherSrcFilter = !!newState.isAddAnotherSrcFilter;
            newState.isAddAnotherDestFilter = !!newState.isAddAnotherDestFilter;
            newState.isAddAnotherGlobalFilter = !!newState.isAddAnotherGlobalFilter;
            newState.isAddAnotherExcludedFilter = !!newState.isAddAnotherExcludedFilter;
            newState.mappings = _cloneDeep(action.mappings);
            filterMappings(newState);
            newState.globalProducts = _cloneDeep(action.globalProducts);
            calcGlobalProductsCount(newState);
            newState.includedProducts = _cloneDeep(action.includedProducts);
            newState.excludedProducts = _cloneDeep(action.excludedProducts);
            calcExcludedProductsCount(newState);
            newState.importMappingsState = _cloneDeep(action.importMappingsState);
            newState.isOnlySource = action.isOnlySource;
            if (newState.createMappingActionInvoked) {
                newState.createMappingActionInvoked = false;
                newState.isShowChooseDestProducts = true;
                newState.isSyncing = false;
            } else  {
                newState.isShowMain = true;
            }
            newState.maxProducts = action.maxProducts;
            newState.exceedMaxDestProducts = calcExceedMaxDestProducts(newState);
            break;
        case DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case PRODUCT_SLIDER_DISMISS_DYNAMIC_TOAST:
            newState = _cloneDeep(state);
            newState.dynamicToastIsActive = false;
            break;
        case SLIDER_DELETE_GLOBALS:
            newState = _cloneDeep(state);
            newState.globalProducts = [];
            calcGlobalProductsCount(newState);
            break;
        case LOAD_ADMIN_MAPPING_SEARCH:
            newState = _cloneDeep(state);
            if (action.data.status === 'done') {
                newState.productSearchDone = true;
                handleMappingSearch(newState);
                filterMappings(newState);
            } else {
                newState.productSearchDone = false;
                if (!newState.productSearch) {
                    newState.productSearch = {};
                }
                if (!newState.productSearch[action.data.mapping_id]) {
                    newState.productSearch[action.data.mapping_id] = {
                        source: {},
                        destination: {},
                    }
                }
                newState.productSearch[action.data.mapping_id][action.data.location][action.data.product_id] =
                    {title: action.data.product_title, image_src: action.data.image_src, id: action.data.product_id};
            }
            break;
        case IMPORT_STATE:
            newState = _cloneDeep(state);
            newState.importMappingsState = _cloneDeep(action.importMappingsState);
            newState.mappings = _cloneDeep(action.mappings);
            filterMappings(newState);
            break;
        case IMPORT_MAPPINGS_FAILURE:
            newState = _cloneDeep(state);
            newState.importMappingsState.status = 'failure';
            break;
        case START_CREATE_MAPPING:
            newState = _cloneDeep(state);
            newState.isFetchingProduct = true;
            newState.createMappingActionInvoked = true;
            break;
        case CREATE_MAPPING_FAILURE:
            newState = _cloneDeep(state);
            newState.isFetchingProduct = false;
            break;
        case CREATE_MAPPING_SUCCESS:
            newState = _cloneDeep(state);
            newState.isFetchingProduct = false;
            newState.mappingId = uuid4();
            newState.sourceProducts = [{id: action.id, title: action.title, image_src: action.image_src}];
            newState.destProducts = [];
            newState.exceedMaxDestProducts = false;
            newState.mappingName = action.title.split(' ').slice(0, 3).join(' ') + ' - Mapping';
            newState.searchedProducts = [];
            newState.isShowMappingOnProduct = action.mapping_defaults.on_product_page;
            newState.isShowMappingOnCart = action.mapping_defaults.on_cart_page;
            newState.isShowMappingAbovePrice = action.mapping_defaults.only_above_price;
            newState.isShowMappingAboveQuantity = action.mapping_defaults.only_above_quantity;
            newState.isShowMappingBelowPrice = action.mapping_defaults.only_below_price;
            newState.isShowMappingBelowQuantity = action.mapping_defaults.only_below_quantity;
            newState.mappingAbovePrice = action.mapping_defaults.above_price;
            newState.mappingBelowPrice = action.mapping_defaults.below_price;
            newState.mappingAboveQuantity = action.mapping_defaults.above_quantity;
            newState.mappingBelowQuantity = action.mapping_defaults.below_quantity;
            break;
        case ONLY_SOURCE_SUCCESS:
            newState = _cloneDeep(state);
            newState.toastIsActive = true;
            newState.isSubmittingOnlyIncluded = false;
            newState.isOnlySource = action.bool;
            break;
        case ON_SHOW_MAPPING_ON_PRODUCT_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowMappingOnProduct = action.bool;
            break;
        case EXPORTING_MAPPINGS:
            newState = _cloneDeep(state);
            newState.exportingMappings = action.bool;
            break;
        case OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT_CHANGE:
            newState = _cloneDeep(state);
            if (action.bool) {
                newState.overrideExisingMappingsOnImportModalOpen = true;
            } else {
                newState.overrideExistingMappingsOnImport = false;
            }
            break;
        case TOGGLE_OVERRIDE_EXISTING_MAPPINGS_MODAL:
            newState = _cloneDeep(state);
            newState.overrideExisingMappingsOnImportModalOpen = action.bool;
            break;
        case OVERRIDE_EXISTING_MAPPINGS_ON_IMPORT:
            newState = _cloneDeep(state);
            newState.overrideExisingMappingsOnImportModalOpen = false;
            newState.overrideExistingMappingsOnImport = true;
            break;
        case ON_SHOW_MAPPING_ON_CART_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowMappingOnCart = action.bool;
            break;
        case ON_SHOW_MAPPING_ABOVE_PRICE_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowMappingAbovePrice = action.bool;
            break;
        case ON_SHOW_MAPPING_BELOW_PRICE_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowMappingBelowPrice = action.bool;
            break;
        case ON_SHOW_MAPPING_ABOVE_QUANTITY_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowMappingAboveQuantity = action.bool;
            break;
        case ON_SHOW_MAPPING_BELOW_QUANTITY_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowMappingBelowQuantity = action.bool;
            break;
        case ON_MAPPING_ABOVE_PRICE_CHANGE:
            newState = _cloneDeep(state);
            newState.mappingAbovePrice = action.num;
            break;
        case ON_MAPPING_BELOW_PRICE_CHANGE:
            newState = _cloneDeep(state);
            newState.mappingBelowPrice = action.num;
            break;
        case ON_MAPPING_ABOVE_QUANTITY_CHANGE:
            newState = _cloneDeep(state);
            newState.mappingAboveQuantity = action.num;
            break;
        case ON_MAPPING_BELOW_QUANTITY_CHANGE:
            newState = _cloneDeep(state);
            newState.mappingBelowQuantity = action.num;
            break;
        case ON_GLOBALS_ABOVE_PRICE_CHANGE:
            newState = _cloneDeep(state);
            newState.globalsAbovePrice = action.num;
            break;
        case ON_GLOBALS_BELOW_PRICE_CHANGE:
            newState = _cloneDeep(state);
            newState.globalsBelowPrice = action.num;
            break;
        case ON_GLOBALS_ABOVE_QUANTITY_CHANGE:
            newState = _cloneDeep(state);
            newState.globalsAboveQuantity = action.num;
            break;
        case ON_GLOBALS_BELOW_QUANTITY_CHANGE:
            newState = _cloneDeep(state);
            newState.globalsBelowQuantity = action.num;
            break;
        case DYNAMIC_GROUPING_CHANGE:
            newState = _cloneDeep(state);
            newState.isDynamicGrouping = action.bool;
            break;
        case MIN_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED:
            newState = _cloneDeep(state);
            newState.minItemsForDiscountEnabled = action.bool;
            break;
        case MAX_ITEMS_FOR_DISCOUNT_ENABLED_CHANGED:
            newState = _cloneDeep(state);
            newState.maxItemsForDiscountEnabled = action.bool;
            break;
        case RANDOMIZE_CHANGE:
            newState = _cloneDeep(state);
            newState.randomizeDestProducts = action.bool;
            break;
        case MAPPING_USE_TEXTS_CHANGE:
            newState = _cloneDeep(state);
            newState.mappingUseTexts = action.bool === 'yes';
            break;
        case PRODUCTS_TOGGLE_VIDEO:
            newState = _cloneDeep(state);
            newState.videoModals[action.videoName] = !newState.videoModals[action.videoName];
            break;
        case SHOW_OTHERS_AFTER_GLOBALS_SUCCESS:
            newState = _cloneDeep(state);
            newState.toastIsActive = true;
            newState.isSubmittingShowOthersAfterGlobals = false;
            newState.isShowOthersAfterGlobals = action.bool;
            break;
        case ON_SHOW_GLOBALS_ON_PRODUCT_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowGlobalsOnProduct = action.bool;
            break;
        case ON_SHOW_GLOBALS_ON_CART_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowGlobalsOnCart = action.bool;
            break;
        case ON_SHOW_GLOBALS_ABOVE_PRICE_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowGlobalsAbovePrice = action.bool;
            break;
        case ON_SHOW_GLOBALS_BELOW_PRICE_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowGlobalsBelowPrice = action.bool;
            break;
        case ON_SHOW_GLOBALS_BELOW_QUANTITY_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowGlobalsBelowQuantity = action.bool;
            break;
        case ON_SHOW_GLOBALS_ABOVE_QUANTITY_CHANGED:
            newState = _cloneDeep(state);
            newState.isShowGlobalsAboveQuantity = action.bool;
            break;
        case ONLY_SOURCE_FAILURE:
            newState = _cloneDeep(state);
            newState.isSubmittingOnlyIncluded = false;
            break;
        case SHOW_OTHERS_AFTER_GLOBALS_FAILURE:
            newState = _cloneDeep(state);
            newState.isSubmittingShowOthersAfterGlobals = false;
            break;
        case CREATE_MAPPING:
            newState = _cloneDeep(state);
            newState.isShowMappings = false;
            newState.isShowChooseSourceProducts = true;
            newState.mappingId = uuid4();
            newState.sourceProducts = [];
            newState.destProducts = [];
            newState.exceedMaxDestProducts = false;
            newState.mappingName = 'Mapping #' + (newState.mappings.length + 1);
            newState.destinationDiscount = 0;
            newState.isShowMappingOnProduct = newState.mappingDefaults.on_product_page;
            newState.isShowMappingOnCart = newState.mappingDefaults.on_cart_page;
            newState.isShowMappingAbovePrice = newState.mappingDefaults.only_above_price;
            newState.isShowMappingAboveQuantity = newState.mappingDefaults.only_above_quantity;
            newState.isShowMappingBelowPrice = newState.mappingDefaults.only_below_price;
            newState.isShowMappingBelowQuantity = newState.mappingDefaults.only_below_quantity;
            newState.mappingAbovePrice = newState.mappingDefaults.above_price;
            newState.mappingBelowPrice = newState.mappingDefaults.below_price;
            newState.mappingAboveQuantity = newState.mappingDefaults.above_quantity;
            newState.mappingBelowQuantity = newState.mappingDefaults.below_quantity;
            break;
        case EDIT_MAPPING:
            newState = _cloneDeep(state);
            newState.editMappingLoading[action.json.mapping.id] = false;
            newState.isShowMappings = false;
            newState.isShowChooseSourceProducts = true;
            newState.mappingId = action.json.mapping.id;
            newState.availableLocales = _cloneDeep(action.json.available_locales.data.availableLocales);
            newState.shopLocales = _cloneDeep(action.json.shop_locales.data.shopLocales);
            const mapping = _cloneDeep(action.json.mapping);
            newState.sourceProducts = _cloneDeep(mapping.sourceProducts2);
            newState.destProducts = _cloneDeep(mapping.destProducts2);
            newState.exceedMaxDestProducts = calcExceedMaxDestProducts(newState);
            newState.mappingName = mapping.name;
            newState.mappingTitle = mapping.title;
            newState.mappingSubtitle = mapping.subtitle;
            newState.mappingUseTexts = mapping.use_texts;
            newState.mappingTexts = _cloneDeep(mapping.texts) || {};
            newState.shopLocales.filter(l => l.published).map(l => l.locale).forEach(l => {
                newState.mappingTexts[l] = newState.mappingTexts[l] || {
                    title: '',
                    subtitle: '',
                }
            });
            calcLocales(newState, newState.mappingTexts);
            newState.destinationDiscount = mapping.destination_discount;
            newState.isShowMappingOnProduct = mapping.on_product_page;
            newState.isShowMappingOnCart = mapping.on_cart_page;
            newState.isShowMappingAbovePrice = mapping.only_above_price;
            newState.isShowMappingAboveQuantity = mapping.only_above_quantity;
            newState.isShowMappingBelowPrice = mapping.only_below_price;
            newState.isShowMappingBelowQuantity = mapping.only_below_quantity;
            newState.mappingAbovePrice = mapping.above_price;
            newState.mappingBelowPrice = mapping.below_price;
            newState.mappingAboveQuantity = mapping.above_quantity;
            newState.mappingBelowQuantity = mapping.below_quantity;
            newState.minItemsForDiscountEnabled = mapping.min_items_for_discount_enabled;
            newState.minItemsForDiscount = mapping.min_items_for_discount;
            newState.maxItemsForDiscountEnabled = mapping.max_items_for_discount_enabled;
            newState.maxItemsForDiscount = mapping.max_items_for_discount;
            newState.randomizeDestProducts = mapping.randomize_dest_products;
            break;
        case REMOVE_SOURCE_ITEM:
            newState = _cloneDeep(state);
            _remove(newState.sourceProducts, (p) => p.id === action.itemId);
            newState.searchBoxText = '';
            newState.searchedProducts = [];
            break;
        case EDIT_MAPPING_FAILED:
            newState = _cloneDeep(state);
            newState.editMappingLoading[action.id] = false;
            break;
        case SWITCH_SOURCE_DEST_FAILED:
            newState = _cloneDeep(state);
            newState.switchingSourceDest[action.id] = false;
            break;
        case SWITCH_SOURCE_DEST_SUCCESS:
            newState = _cloneDeep(state);
            newState.switchingSourceDest[action.id] = false;
            newState.toastIsActive = true;
            {
                const mapping = newState.mappings.find(m => m.id === action.id);
                mapping.preview_handle = action.json.mapping.preview_handle;
            }
            break;
        case EDIT_MAPPING_LOADING:
            newState = _cloneDeep(state);
            newState.editMappingLoading[action.id] = true;
            break;
        case SWITCHING_SOURCE_DEST:
            newState = _cloneDeep(state);
            newState.switchingSourceDest[action.id] = true;
            newState.productSearch = null;
            newState.productSearchDone = false;
            newState.mappingsResults = null;
            break;
        case DUPLICATING_MAPPING:
            newState = _cloneDeep(state);
            newState.dupMappingLoading[action.id] = true;
            break;
        case DUP_MAPPING_SUCCESS:
            newState = _cloneDeep(state);
            newState.dupMappingLoading[action.id] = false;
            newState.mappings = _cloneDeep(action.mappings);
            filterMappings(newState);
            newState.dynamicToastIsActive = true;
            newState.dynamicToastContent = "Mapping duplicated";
            newState.dynamicToastError = false;
            break;
        case DUP_MAPPING_FAILURE:
            newState = _cloneDeep(state);
            newState.dupMappingLoading[action.id] = false;
            newState.dynamicToastIsActive = true;
            newState.dynamicToastContent = "Failed to duplicate";
            newState.dynamicToastError = true;
            break;
        case CHANGE_MAPPING_NAME:
            newState = _cloneDeep(state);
            newState.mappingName = action.text;
            newState.errorEmptyName = false;
            break;
        case CHANGE_MAPPING_TITLE:
            newState = _cloneDeep(state);
            newState.mappingTitle = action.text;
            break;
        case CHANGE_MAPPING_SUBTITLE:
            newState = _cloneDeep(state);
            newState.mappingSubtitle = action.text;
            break;
        case CHANGE_SRC_FILTER:
            newState = _cloneDeep(state);
            newState.srcFilter = action.text;
            handleSrcFilter(newState);
            break;
        case CHANGE_SRC_FILTER2:
            newState = _cloneDeep(state);
            newState.srcFilter2 = action.text;
            handleSrcFilter2(newState);
            break;
        case CHANGE_DEST_FILTER:
            newState = _cloneDeep(state);
            newState.destFilter = action.text;
            handleDestFilter(newState);
            break;
        case CHANGE_DEST_FILTER2:
            newState = _cloneDeep(state);
            newState.destFilter2 = action.text;
            handleDestFilter2(newState);
            break;
        case GLOBAL_FILTER_CHANGE:
            newState = _cloneDeep(state);
            newState.globalFilter = action.text;
            handleGlobalFilter(newState);
            break;
        case EXCLUDED_FILTER_CHANGE:
            newState = _cloneDeep(state);
            newState.excludedFilter = action.text;
            handleExcludedFilter(newState);
            break;
        case GLOBAL_FILTER2_CHANGE:
            newState = _cloneDeep(state);
            newState.globalFilter2 = action.text;
            handleGlobalFilter2(newState);
            break;
        case EXCLUDED_FILTER2_CHANGE:
            newState = _cloneDeep(state);
            newState.excludedFilter2 = action.text;
            handleExcludedFilter2(newState);
            break;
        case CHANGE_SRC_FILTER_OPTION:
            newState = _cloneDeep(state);
            newState.srcFilterOption = action.text;
            break;
        case CHANGE_SRC_FILTER_OPTION2:
            newState = _cloneDeep(state);
            newState.srcFilterOption2 = action.text;
            break;
        case CHANGE_DEST_FILTER_OPTION:
            newState = _cloneDeep(state);
            newState.destFilterOption = action.text;
            break;
        case CHANGE_DEST_FILTER_OPTION2:
            newState = _cloneDeep(state);
            newState.destFilterOption2 = action.text;
            break;
        case GLOBAL_FILTER_OPTION_CHANGE:
            newState = _cloneDeep(state);
            newState.globalFilterOption = action.text;
            break;
        case EXCLUDED_FILTER_OPTION_CHANGE:
            newState = _cloneDeep(state);
            newState.excludedFilterOption = action.text;
            break;
        case GLOBAL_FILTER2_OPTION_CHANGE:
            newState = _cloneDeep(state);
            newState.globalFilterOption2 = action.text;
            break;
        case EXCLUDED_FILTER2_OPTION_CHANGE:
            newState = _cloneDeep(state);
            newState.excludedFilterOption2 = action.text;
            break;
        case MAPPINGS_SEARCH_BOX_TEXT_CHANGE:
            newState = _cloneDeep(state);
            newState.mappingsSearchBoxText = action.text;
            handleMappingSearch(newState);
            filterMappings(newState);
            break;
        case MAPPING_SEARCH_TYPE_CHANGE:
            newState = _cloneDeep(state);
            newState.mappingSearchType = action.text;
            handleMappingSearch(newState);
            filterMappings(newState);
            break;
        case DESTINATION_DISCOUNT_CHANGE:
            newState = _cloneDeep(state);
            newState.destinationDiscount = action.num;
            if (action.num !== '' && action.num < 0) {
                newState.destinationDiscount = 0;
            }
            if (action.num !== '' && action.num > 100) {
                newState.destinationDiscount = 100;
            }
            break;
        case MIN_ITEMS_FOR_DISCOUNT_CHANGE:
            newState = _cloneDeep(state);
            newState.minItemsForDiscount = action.num;
            if (action.num !== '' && action.num < 0) {
                newState.minItemsForDiscount = 0;
            }
            break;
        case MAX_ITEMS_FOR_DISCOUNT_CHANGE:
            newState = _cloneDeep(state);
            newState.maxItemsForDiscount = action.num;
            if (action.num !== '' && action.num < 1) {
                newState.maxItemsForDiscount = 1;
            }
            break;
        case FILTER_MAPPING_BY_PRODUCT:
            newState = _cloneDeep(state);
            newState.mappingsProductFilter = _cloneDeep(_find(newState.mappingsResults, {id: action.id}));
            newState.mappingsResults = null;
            filterMappings(newState);
            break;
        case SORT_DEST_PRODUCTS:
            newState = _cloneDeep(state);
            newState.sortDestProductsModalOpen = true;
            newState.tempSortDestItem = _cloneDeep(_find(newState.destProducts, {id: action.id}));
            break;
        case SORT_GLOBAL_PRODUCTS:
            newState = _cloneDeep(state);
            newState.sortGlobalProductsModalOpen = true;
            newState.tempSortGlobalItem = _cloneDeep(_find(newState.globalProducts, {id: action.id}));
            break;
        case CLOSE_SORT_DEST_PRODUCTS_MODAL:
            newState = _cloneDeep(state);
            newState.sortDestProductsModalOpen = false;
            newState.tempSortDestItem = null;
            break;
        case CLOSE_SORT_GLOBAL_PRODUCTS_MODAL:
            newState = _cloneDeep(state);
            newState.sortGlobalProductsModalOpen = false;
            newState.tempSortGlobalItem = null;
            break;
        case SORT_DEST_PRODUCTS_DONE:
            newState = _cloneDeep(state);
            _find(newState.destProducts, {id: newState.tempSortDestItem.id}).products =
                _cloneDeep(newState.tempSortDestItem.products);
            newState.sortDestProductsModalOpen = false;
            newState.tempSortDestItem = null;
            break;
        case SORT_GLOBAL_PRODUCTS_DONE:
            newState = _cloneDeep(state);
            _find(newState.globalProducts, {id: newState.tempSortGlobalItem.id}).products =
                _cloneDeep(newState.tempSortGlobalItem.products);
            newState.sortGlobalProductsModalOpen = false;
            newState.tempSortGlobalItem = null;
            break;
        case REMOVE_PRODUCT_FILTER:
            newState = _cloneDeep(state);
            handleMappingSearch(newState);
            filterMappings(newState);
            break;
        case MAPPING_SEARCH_BOX_FOCUS:
            newState = _cloneDeep(state);
            handleMappingSearch(newState);
            filterMappings(newState);
            break;
        case TOGGLE_ADD_ALL_TO_SRC_BY_FILTER_ACTIVE:
            newState = _cloneDeep(state);
            newState.addAllToSrcByFilterActive = !newState.addAllToSrcByFilterActive;
            break;
        case TOGGLE_ADD_ALL_TO_GLOBAL_BY_FILTER_ACTIVE:
            newState = _cloneDeep(state);
            newState.addAllToGlobalByFilterActive = !newState.addAllToGlobalByFilterActive;
            break;
        case ADD_ANOTHER_SRC_FILTER:
            newState = _cloneDeep(state);
            newState.isAddAnotherSrcFilter = !newState.isAddAnotherSrcFilter;
            break;
        case ADD_ANOTHER_DEST_FILTER:
            newState = _cloneDeep(state);
            newState.isAddAnotherDestFilter = !newState.isAddAnotherDestFilter;
            break;
        case ADD_ANOTHER_GLOBAL_FILTER_CHANGE:
            newState = _cloneDeep(state);
            newState.isAddAnotherGlobalFilter = action.bool;
            break;
        case ADD_ANOTHER_EXCLUDED_FILTER_CHANGE:
            newState = _cloneDeep(state);
            newState.isAddAnotherExcludedFilter = action.bool;
            break;
        case TOGGLE_ADD_ALL_TO_DEST_BY_FILTER_ACTIVE:
            newState = _cloneDeep(state);
            newState.addAllToDestByFilterActive = !newState.addAllToDestByFilterActive;
            break;
        case TOGGLE_ADD_ALL_TO_EXCLUDED_BY_FILTER_ACTIVE:
            newState = _cloneDeep(state);
            newState.addAllToExcludedByFilterActive = !newState.addAllToExcludedByFilterActive;
            break;
        case START_ADDING_ALL_TO_SRC_BY_FILTER:
            newState = _cloneDeep(state);
            newState.isAddingAllToSrcByFilter = true;
            break;
        case START_ADDING_ALL_TO_DEST_BY_FILTER:
            newState = _cloneDeep(state);
            newState.isAddingAllToDestByFilter = true;
            break;
        case START_ADDING_ALL_TO_GLOBAL_BY_FILTER:
            newState = _cloneDeep(state);
            newState.isAddingAllToGlobalByFilter = true;
            break;
        case START_ADDING_ALL_TO_EXCLUDED_BY_FILTER:
            newState = _cloneDeep(state);
            newState.isAddingAllToExcludedByFilter = true;
            break;
        case ADD_ALL_TO_SRC_BY_FILTER_FAILURE:
            newState = _cloneDeep(state);
            newState.isAddingAllToSrcByFilter = false;
            break;
        case ADD_ALL_TO_DEST_BY_FILTER_FAILURE:
            newState = _cloneDeep(state);
            newState.isAddingAllToDestByFilter = false;
            break;
        case ADD_ALL_TO_GLOBAL_BY_FILTER_FAILURE:
            newState = _cloneDeep(state);
            newState.isAddingAllToGlobalByFilter = false;
            break;
        case ADD_ALL_TO_EXCLUDED_BY_FILTER_FAILURE:
            newState = _cloneDeep(state);
            newState.isAddingAllToExcludedByFilter = false;
            break;
        case ADD_ALL_TO_SRC_BY_FILTER_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.srcFilter === action.filter && newState.srcFilterOption === action.text &&
                newState.srcFilter2 === action.filter2 && newState.srcFilterOption2 === action.text2 &&
                newState.isAddAnotherSrcFilter === action.second_filter_present) {

                newState.isAddingAllToSrcByFilter = false;
                newState.addAllToSrcByFilterActive = false;
                newState.sourceProductsEmptyError = false;
                if (action.isDynamic) {
                    newState.sourceProducts.push({
                        id: action.id,
                        dynamic: true,
                        count: action.count,
                        filter1_type: action.filter,
                        filter1_value: action.text,
                        filter1_type_display: _find(addByFilterOptions, {value: action.filter}).label,
                        filter2_type: action.second_filter_present ? action.filter2 : null,
                        filter2_value: action.second_filter_present ? action.text2 : null,
                        filter2_type_display: action.second_filter_present ? _find(addByFilterOptions, {value: action.filter2}).label : null,
                    })
                } else {
                    _forEach(action.products, product => {
                        if (!_find(newState.sourceProducts, {id: product.id})) {
                            newState.sourceProducts.push(product);
                        }

                    });
                }
            }
            break;
        case ADD_ALL_TO_DEST_BY_FILTER_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.destFilter === action.filter && newState.destFilterOption === action.text &&
                newState.destFilter2 === action.filter2 && newState.destFilterOption2 === action.text2 &&
                newState.isAddAnotherDestFilter === action.second_filter_present) {

                newState.isAddingAllToDestByFilter = false;
                newState.addAllToDestByFilterActive = false;
                newState.destProductsEmptyError = false;
                if (action.isDynamic) {
                    newState.destProducts.push({
                        id: action.id,
                        dynamic: true,
                        products: _cloneDeep(action.products),
                        count: action.count,
                        filter1_type: action.filter,
                        filter1_type_display: _find(addByFilterOptions, {value: action.filter}).label,
                        filter1_value: action.text,
                        filter2_type: action.second_filter_present ? action.filter2 : null,
                        filter2_type_display: action.second_filter_present ? _find(addByFilterOptions, {value: action.filter2}).label : null,
                        filter2_value: action.second_filter_present ? action.text2 : null,
                    });
                } else {
                    _forEach(action.products, product => {
                        if (!_find(newState.destProducts, {id: product.id})) {
                            newState.destProducts.push(product);
                        }
                    });
                }
                newState.exceedMaxDestProducts = calcExceedMaxDestProducts(newState);
            }
            break;
        case ADD_ALL_TO_GLOBAL_BY_FILTER_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.globalFilter === action.filter && newState.globalFilterOption === action.text &&
                newState.globalFilter2 === action.filter2 && newState.globalFilterOption2 === action.text2 &&
                newState.isAddAnotherGlobalFilter === action.second_filter_present) {

                newState.isAddingAllToGlobalByFilter = false;
                newState.addAllToGlobalByFilterActive = false;
                if (action.isDynamic) {
                    newState.globalProducts.push({
                        id: action.id,
                        dynamic: true,
                        products: _cloneDeep(action.products),
                        count: action.count,
                        filter1_type: action.filter,
                        filter1_type_display: _find(addByFilterOptions, {value: action.filter}).label,
                        filter1_value: action.text,
                        filter2_type: action.second_filter_present ? action.filter2 : null,
                        filter2_type_display: action.second_filter_present ? _find(addByFilterOptions, {value: action.filter2}).label : null,
                        filter2_value: action.second_filter_present ? action.text2 : null,
                    });
                } else {
                    _forEach(action.products, product => {
                        if (!_find(newState.globalProducts, {id: product.id})) {
                            newState.globalProducts.push(product);
                        }
                    });
                }
                calcGlobalProductsCount(newState);
            }
            break;
        case ADD_ALL_TO_EXCLUDED_BY_FILTER_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.excludedFilter === action.filter && newState.excludedFilterOption === action.text &&
                newState.excludedFilter2 === action.filter2 && newState.excludedFilterOption2 === action.text2 &&
                newState.isAddAnotherExcludedFilter === action.second_filter_present) {

                newState.isAddingAllToExcludedByFilter = false;
                newState.addAllToExcludedByFilterActive = false;
                if (action.isDynamic) {
                    newState.excludedProducts.push({
                        id: action.id,
                        dynamic: true,
                        products: _cloneDeep(action.products),
                        count: action.count,
                        filter1_type: action.filter,
                        filter1_type_display: _find(addByFilterOptions, {value: action.filter}).label,
                        filter1_value: action.text,
                        filter2_type: action.second_filter_present ? action.filter2 : null,
                        filter2_type_display: action.second_filter_present ? _find(addByFilterOptions, {value: action.filter2}).label : null,
                        filter2_value: action.second_filter_present ? action.text2 : null,
                    });
                } else {
                    _forEach(action.products, product => {
                        if (!_find(newState.excludedProducts, {id: product.id})) {
                            newState.excludedProducts.push(product);
                        }
                    });
                }
                calcExcludedProductsCount(newState);
            }
            break;
        case REMOVE_DEST_ITEM:
            newState = _cloneDeep(state);
            _remove(newState.destProducts, (p) => p.id === action.itemId);
            newState.exceedMaxDestProducts = calcExceedMaxDestProducts(newState);
            newState.searchBoxText = '';
            newState.searchedProducts = [];
            break;
        case REMOVE_GLOBAL_ITEM:
            newState = _cloneDeep(state);
            _remove(newState.globalProducts, (p) => p.id === action.itemId);
            calcGlobalProductsCount(newState);
            newState.searchBoxText = '';
            newState.searchedProducts = [];
            break;
        case REMOVE_INCLUDED_ITEM:
            newState = _cloneDeep(state);
            _remove(newState.includedProducts, (p) => p.id === action.itemId);
            newState.searchBoxText = '';
            newState.searchedProducts = [];
            break;
        case REMOVE_EXCLUDED_ITEM:
            newState = _cloneDeep(state);
            _remove(newState.excludedProducts, (p) => p.id === action.itemId);
            calcExcludedProductsCount(newState);
            newState.searchBoxText = '';
            newState.searchedProducts = [];
            break;
        case SEARCH_SOURCE_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.searchBoxText !== action.title ||
                _difference(action.existingIds, _map(newState.sourceProducts, 'id')).length ||
                _difference(_map(newState.sourceProducts, 'id'), action.existingIds).length) {
                // do nothing
            } else {
                newState.searchedProducts = _cloneDeep(action.products);
                newState.showSearchSpinner = false;
                newState.searchError = false;
            }
            break;
        case SEARCH_GLOBAL_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.searchBoxText !== action.title ||
                _difference(action.existingIds, _map(newState.globalProducts, 'id')).length ||
                _difference(_map(newState.globalProducts, 'id'), action.existingIds).length) {
                // do nothing
            } else {
                newState.searchedProducts = _cloneDeep(action.products);
                newState.showSearchSpinner = false;
                newState.searchError = false;
            }
            break;
        case SEARCH_INCLUDED_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.searchBoxText !== action.title ||
                _difference(action.existingIds, _map(newState.includedProducts, 'id')).length ||
                _difference(_map(newState.includedProducts, 'id'), action.existingIds).length) {
                // do nothing
            } else {
                newState.searchedProducts = _cloneDeep(action.products);
                newState.showSearchSpinner = false;
                newState.searchError = false;
            }
            break;
        case SEARCH_EXCLUDED_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.searchBoxText !== action.title ||
                _difference(action.existingIds, _map(newState.excludedProducts, 'id')).length ||
                _difference(_map(newState.excludedProducts, 'id'), action.existingIds).length) {
                // do nothing
            } else {
                newState.searchedProducts = _cloneDeep(action.products);
                newState.showSearchSpinner = false;
                newState.searchError = false;
            }
            break;
        case SEARCH_DEST_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.searchBoxText !== action.title ||
                _difference(action.existingIds, _map(newState.destProducts, 'id')).length ||
                _difference(_map(newState.destProducts, 'id'), action.existingIds).length) {
                // do nothing
            } else {
                newState.searchedProducts = _cloneDeep(action.products);
                newState.showSearchSpinner = false;
                newState.searchError = false;
            }
            break;
        case CHOOSE_SOURCE_PRODUCT:
            newState = _cloneDeep(state);
            newState.searchBoxText = '';
            newState.sourceProducts.push(_cloneDeep(_find(newState.searchedProducts, {id: action.id})));
            newState.searchedProducts = [];
            newState.sourceProductsEmptyError = false;
            break;
        case CHOOSE_GLOBAL_PRODUCT:
            newState = _cloneDeep(state);
            newState.searchBoxText = '';
            newState.globalProducts.push(_cloneDeep(_find(newState.searchedProducts, {id: action.id})));
            calcGlobalProductsCount(newState);
            newState.searchedProducts = [];
            break;
        case CHOOSE_INCLUDED_PRODUCT:
            newState = _cloneDeep(state);
            newState.searchBoxText = '';
            newState.includedProducts.push(_cloneDeep(_find(newState.searchedProducts, {id: action.id})));
            newState.searchedProducts = [];
            break;
        case CHOOSE_EXCLUDED_PRODUCT:
            newState = _cloneDeep(state);
            newState.searchBoxText = '';
            newState.excludedProducts.push(_cloneDeep(_find(newState.searchedProducts, {id: action.id})));
            calcExcludedProductsCount(newState);
            newState.searchedProducts = [];
            break;
        case CHOOSE_DEST_PRODUCT:
            newState = _cloneDeep(state);
            newState.searchBoxText = '';
            newState.destProducts.push(_cloneDeep(_find(newState.searchedProducts, {id: action.id})));
            newState.exceedMaxDestProducts = calcExceedMaxDestProducts(newState);
            newState.searchedProducts = [];
            newState.destProductsEmptyError = false;
            break;
        case CHOOSE_DEST_PRODUCTS:
            newState = _cloneDeep(state);
            if (newState.sourceProducts && newState.sourceProducts.length > 0) {
                newState.isShowChooseSourceProducts = false;
                newState.isShowChooseDestProducts = true;
                newState.searchedProducts = [];
            } else {
                newState.sourceProductsEmptyError = true;
            }
            break;
        case CHOOSE_MAPPING_RULES:
            newState = _cloneDeep(state);
            if (newState.destProducts && newState.destProducts.length > 0) {
                newState.isShowChooseDestProducts = false;
                newState.isShowChooseMappingRules = true;
            } else {
                newState.destProductsEmptyError = true;
            }
            break;
        case CHOOSE_MAPPING_NAME:
            newState = _cloneDeep(state);
            newState.isShowChooseMappingRules = false;
            newState.isShowChooseMappingName = true;
            break;
        case SHOW_SOURCE_PRODUCTS:
            newState = _cloneDeep(state);
            newState.isShowChooseSourceProducts = true;
            newState.isShowChooseDestProducts = false;
            newState.destProductsEmptyError = false;
            break;
        case SAVE_MAPPING_FAILURE:
            newState = _cloneDeep(state);
            newState.saveError = true;
            newState.isSavingMapping = false;
            break;
        case SAVE_GLOBALS_FAILURE:
            newState = _cloneDeep(state);
            newState.saveError = true;
            newState.isSavingGlobals = false;
            break;
        case SAVE_INCLUDED_FAILURE:
            newState = _cloneDeep(state);
            newState.saveError = true;
            newState.isSavingIncluded = false;
            break;
        case SAVE_EXCLUDED_FAILURE:
            newState = _cloneDeep(state);
            newState.saveError = true;
            newState.isSavingExcluded = false;
            break;
        case SEARCH_FAILURE:
            newState = _cloneDeep(state);
            newState.searchError = true;
            newState.showSearchSpinner = false;
            break;
        case SAVE_MAPPING_PROCESSING:
            newState = _cloneDeep(state);
            newState.saveError = false;
            newState.isSavingMapping = true;
            newState.productSearch = null;
            newState.productSearchDone = false;
            newState.mappingsResults = null;
            break;
        case SAVE_GLOBALS_PROCESSING:
            newState = _cloneDeep(state);
            newState.saveError = false;
            newState.isSavingGlobals = true;
            break;
        case SAVE_INCLUDED_PROCESSING:
            newState = _cloneDeep(state);
            newState.saveError = false;
            newState.isSavingIncluded = true;
            break;
        case SAVE_EXCLUDED_PROCESSING:
            newState = _cloneDeep(state);
            newState.saveError = false;
            newState.isSavingExcluded = true;
            break;
        case NAME_EMPTY:
            newState = _cloneDeep(state);
            newState.errorEmptyName = true;
            break;
        case ONLY_SOURCE_PROCESSING:
            newState = _cloneDeep(state);
            newState.isSubmittingOnlyIncluded = true;
            break;
        case SHOW_OTHERS_AFTER_GLOBALS_PROCESSING:
            newState = _cloneDeep(state);
            newState.isSubmittingShowOthersAfterGlobals = true;
            break;
        case SAVE_MAPPING_SUCCESS:
            newState = _cloneDeep(state);
            newState.mappings = _cloneDeep(action.mappings);
            filterMappings(newState);
            newState.isShowMappings = true;
            newState.isShowChooseDestProducts = false;
            newState.isShowChooseMappingRules = false;
            newState.isShowChooseMappingName = false;
            newState.isShowChooseSourceProducts = false;
            newState.isSavingMapping = false;
            newState.toastIsActive = true;
            break;
        case SAVE_GLOBALS_SUCCESS:
            newState = _cloneDeep(state);
            newState.isShowMain = true;
            newState.isShowGlobalRules = false;
            newState.isSavingGlobals = false;
            newState.isShowGlobal = false;
            newState.toastIsActive = true;
            break;
        case MAPPINGS_REORDER_SUCCESS:
            newState = _cloneDeep(state);
            newState.toastIsActive = true;
            break;
        case SAVE_INCLUDED_SUCCESS:
            newState = _cloneDeep(state);
            newState.isShowMain = true;
            newState.isShowIncluded = false;
            newState.isSavingIncluded = false;
            newState.toastIsActive = true;
            break;
        case SAVE_EXCLUDED_SUCCESS:
            newState = _cloneDeep(state);
            newState.isShowMain = true;
            newState.isShowExcluded = false;
            newState.isSavingExcluded = false;
            newState.toastIsActive = true;
            break;
        case SHOW_DEST_PRODUCTS:
            newState = _cloneDeep(state);
            newState.isShowChooseDestProducts = true;
            newState.isShowChooseMappingRules = false;
            newState.errorEmptyName = false;
            break;
        case SHOW_MAPPING_RULES:
            newState = _cloneDeep(state);
            newState.isShowChooseMappingName = false;
            newState.isShowChooseMappingRules = true;
            newState.errorEmptyName = false;
            break;
        case SHOW_REMOVE_MAPPING_MODAL:
            newState = _cloneDeep(state);
            newState.mappingToRemove = action.id;
            newState.mappingName = _find(newState.mappings, {id: action.id}).name;
            newState.removeMappingModalOpen = true;
            break;
        case CLOSE_REMOVE_MAPPING_MODAL:
            newState = _cloneDeep(state);
            newState.mappingToRemove = null;
            newState.removeMappingModalOpen = false;
            break;
        case REMOVE_MAPPING_STARTED:
            newState = _cloneDeep(state);
            newState.removeMappingSpinner = true;
            newState.productSearch = null;
            newState.productSearchDone = false;
            newState.mappingsResults = null;
            break;
        case REMOVE_MAPPING_ERROR:
            newState = _cloneDeep(state);
            newState.removeMappingSpinner = false;
            newState.removeMappingError = true;
            break;
        case REMOVE_MAPPING_SUCCESS:
            newState = _cloneDeep(state);
            newState.removeMappingSpinner = false;
            newState.removeMappingError = false;
            newState.mappings = _cloneDeep(action.mappings);
            filterMappings(newState);
            newState.mappingToRemove = null;
            newState.removeMappingModalOpen = false;
            break;
        case MOVE_DEST_ITEM_UP:
            newState = _cloneDeep(state);
            [newState.destProducts[action.id - 1], newState.destProducts[action.id]] =
                [newState.destProducts[action.id], newState.destProducts[action.id - 1]];
            break;
        case MOVE_TEMP_DEST_ITEM_UP:
            newState = _cloneDeep(state);
            [newState.tempSortDestItem.products[action.id - 1], newState.tempSortDestItem.products[action.id]] =
                [newState.tempSortDestItem.products[action.id], newState.tempSortDestItem.products[action.id - 1]];
            break;
        case MOVE_TEMP_GLOBAL_ITEM_UP:
            newState = _cloneDeep(state);
            [newState.tempSortGlobalItem.products[action.id - 1], newState.tempSortGlobalItem.products[action.id]] =
                [newState.tempSortGlobalItem.products[action.id], newState.tempSortGlobalItem.products[action.id - 1]];
            break;
        case MOVE_GLOBAL_ITEM_UP:
            newState = _cloneDeep(state);
            [newState.globalProducts[action.id - 1], newState.globalProducts[action.id]] =
                [newState.globalProducts[action.id], newState.globalProducts[action.id - 1]];
            break;
        case MOVE_MAPPING_ITEM:
            newState = _cloneDeep(state);
            if (action.direction === 'up') {
                [newState.mappings[action.index - 1], newState.mappings[action.index]] =
                    [newState.mappings[action.index], newState.mappings[action.index - 1]];
            } else if (action.direction === 'down') {
                [newState.mappings[action.index + 1], newState.mappings[action.index]] =
                    [newState.mappings[action.index], newState.mappings[action.index + 1]];
            }
            break;
        case MOVE_DEST_ITEM_DOWN:
            newState = _cloneDeep(state);
            [newState.destProducts[action.id + 1], newState.destProducts[action.id]] =
                [newState.destProducts[action.id], newState.destProducts[action.id + 1]];
            break;
        case MOVE_TEMP_DEST_ITEM_DOWN:
            newState = _cloneDeep(state);
            [newState.tempSortDestItem.products[action.id + 1], newState.tempSortDestItem.products[action.id]] =
                [newState.tempSortDestItem.products[action.id], newState.tempSortDestItem.products[action.id + 1]];
            break;
        case MOVE_TEMP_GLOBAL_ITEM_DOWN:
            newState = _cloneDeep(state);
            [newState.tempSortGlobalItem.products[action.id + 1], newState.tempSortGlobalItem.products[action.id]] =
                [newState.tempSortGlobalItem.products[action.id], newState.tempSortGlobalItem.products[action.id + 1]];
            break;
        case MOVE_GLOBAL_ITEM_DOWN:
            newState = _cloneDeep(state);
            [newState.globalProducts[action.id + 1], newState.globalProducts[action.id]] =
                [newState.globalProducts[action.id], newState.globalProducts[action.id + 1]];
            break;
        case CHOOSE_DEST_VARIANTS:
            newState = _cloneDeep(state);
            newState.tempVariants = _cloneDeep(_find(newState.destProducts, {id: action.id}).variants);
            newState.tempVariantsId = action.id;
            newState.isChooseDestVariantsModalOpen = true;
            break;
        case CHOOSE_SOURCE_VARIANTS:
            newState = _cloneDeep(state);
            newState.tempVariants = _cloneDeep(_find(newState.sourceProducts, {id: action.id}).variants);
            newState.tempVariantsId = action.id;
            newState.isChooseSourceVariantsModalOpen = true;
            break;
        case ON_CHOOSE_GLOBAL_VARIANTS:
            newState = _cloneDeep(state);
            newState.tempVariants = _cloneDeep(_find(newState.globalProducts, {id: action.id}).variants);
            newState.tempVariantsId = action.id;
            newState.isChooseGlobalVariantsModalOpen = true;
            break;
        case EDIT_MAPPING_NAME:
            newState = _cloneDeep(state);
            newState.editMappings = newState.editMappings || {};
            newState.editMappings[action.id] = true;
            break;
        case EDIT_MAPPING_NAME_SUCCESS:
            newState = _cloneDeep(state);
            newState.editMappings[action.id] = false;
            break;
        case MAPPING_NAME_EDITED:
            newState = _cloneDeep(state);
            {
                const mapping = newState.mappings.find(m => m.id === action.id);
                mapping.name = action.text;
            }
            break;
        case CLOSE_CHOOSE_DEST_VARIANTS_MODAL:
            newState = _cloneDeep(state);
            newState.tempVariants = null;
            newState.isChooseDestVariantsModalOpen = false;
            break;
        case CLOSE_CHOOSE_SOURCE_VARIANTS_MODAL:
            newState = _cloneDeep(state);
            newState.tempVariants = null;
            newState.isChooseSourceVariantsModalOpen = false;
            break;
        case CLOSE_CHOOSE_GLOBAL_VARIANTS_MODAL:
            newState = _cloneDeep(state);
            newState.tempVariants = null;
            newState.isChooseGlobalVariantsModalOpen = false;
            break;
        case CHOOSE_DEST_VARIANTS_DONE:
            newState = _cloneDeep(state);
            _find(newState.destProducts, {id: newState.tempVariantsId}).variants = _cloneDeep(newState.tempVariants);
            newState.tempVariants = null;
            newState.isChooseDestVariantsModalOpen = false;
            break;
        case CHOOSE_SOURCE_VARIANTS_DONE:
            newState = _cloneDeep(state);
            _find(newState.sourceProducts, {id: newState.tempVariantsId}).variants = _cloneDeep(newState.tempVariants);
            newState.tempVariants = null;
            newState.isChooseSourceVariantsModalOpen = false;
            break;
        case CHOOSE_GLOBAL_VARIANTS_DONE:
            newState = _cloneDeep(state);
            _find(newState.globalProducts, {id: newState.tempVariantsId}).variants = _cloneDeep(newState.tempVariants);
            newState.tempVariants = null;
            newState.isChooseGlobalVariantsModalOpen = false;
            break;
        case DEST_VARIANT_CHECKED:
            newState = _cloneDeep(state);
            _find(newState.tempVariants, {id: action.id}).is_shown = action.bool;
            break;
        case SET_ENABLE_MAPPING_LOADING:
            newState = _cloneDeep(state);
            newState.enableMappingLoading[action.id] = action.bool;
            break;
        case SET_ENABLE_MAPPING:
            newState = _cloneDeep(state);
            _find(newState.mappings, {id: action.id}).enabled = action.bool;
            break;
        case SOURCE_VARIANT_CHECKED:
            newState = _cloneDeep(state);
            _find(newState.tempVariants, {id: action.id}).is_shown = action.bool;
            break;
        case ON_GLOBAL_VARIANT_CHECKED:
            newState = _cloneDeep(state);
            _find(newState.tempVariants, {id: action.id}).is_shown = action.bool;
            break;
        case START_SEARCH:
            newState = _cloneDeep(state);
            newState.showSearchSpinner = true;
            newState.searchBoxText = action.text;
            newState.searchedProducts = [];
            break;
        case EXPORT_MAPPINGS_STATUS:
            newState = _cloneDeep(state);
            newState.exportMappingsStatus = action.text;
            break;
        case MAPPING_LOCALE_CHANGE:
            newState = _cloneDeep(state);
            newState.locale = action.text;
            break;
        case CHANGE_MAPPING_TEXT:
            newState = _cloneDeep(state);
            newState.mappingTexts[newState.locale][action.attr] = action.text;
            break;
        case SHOW_MAPPING:
            newState = _cloneDeep(state);
            newState.isShowMappings = true;
            newState.isShowMain = false;
            break;
        case SHOW_IMPORT_MAPPINGS:
            newState = _cloneDeep(state);
            newState.isShowImport = true;
            newState.isShowMain = false;
            break;
        case SHOW_EXCLUDED:
            newState = _cloneDeep(state);
            newState.isShowExcluded = true;
            newState.isShowMain = false;
            break;
        case SHOW_GLOBAL:
            newState = _cloneDeep(state);
            newState.isShowGlobal = true;
            newState.isShowMain = false;
            newState.isShowGlobalRules = false;
            newState.saveError = false;
            break;
        case SHOW_GLOBAL_RULES:
            newState = _cloneDeep(state);
            newState.isShowGlobalRules = true;
            newState.isShowGlobal = false;
            break;
        case SHOW_INCLUDE:
            newState = _cloneDeep(state);
            newState.isShowIncluded = true;
            newState.isShowMain = false;
            break;
        case SWITCHING_MAPPING_PREVIEW:
            newState = _cloneDeep(state);
            newState.switchingPreview[action.id] = action.bool;
            break;
        case CHANGE_MAPPING_PREVIEW_MODE:
            newState = _cloneDeep(state);
            {
                const mapping = newState.mappings.find(m => m.id === action.id);
                mapping.preview_mode = action.bool;
            }
            break;
        case BACK_TO_MAIN:
            newState = _cloneDeep(state);
            newState.isShowMain = true;
            newState.isShowMappings = false;
            newState.isShowGlobal = false;
            newState.isShowIncluded = false;
            newState.isShowExcluded = false;
            newState.isShowImport = false;
            newState.isShowGlobalRules = false;
            newState.saveError = false;
            newState.searchBoxText = '';
            newState.searchedProducts = [];
            break;
        case CANCEL:
            newState = _cloneDeep(state);
            newState.isShowMappings = true;
            newState.isShowChooseSourceProducts = false;
            newState.isShowChooseDestProducts = false;
            newState.isShowChooseMappingRules = false;
            newState.isShowChooseMappingName = false;
            newState.sourceProductsEmptyError = false;
            newState.destProductsEmptyError = false;
            newState.mappingId = null;
            newState.searchBoxText = '';
            newState.searchedProducts = [];
            break;
        default:
            return state;
    }
    return newState;
};

export default data;
