import _cloneDeep from "lodash/cloneDeep";

import {
    ACTIVATE_TOAST,
    ADD_SELECTED_TO_CART_BUTTON_COLOR_CHANGE,
    ADD_SELECTED_TO_CART_TEXT_COLOR_CHANGE,
    BUNDLE_BOTTOM_ENABLED_CHANGE,
    BUNDLE_COMPARE_PRICE_COLOR_CHANGE,
    BUNDLE_COMPARE_PRICE_SIZE_CHANGE,
    BUNDLE_CURRENCY_POSITION_CHANGE,
    BUNDLE_CUT_ZEROS_CHANGE,
    BUNDLE_DESELECT_BUNDLE_CHECKBOXES_CHANGE,
    BUNDLE_FONT_FAMILY_CHANGE,
    BUNDLE_ITEM_NAME_COLOR_CHANGE,
    BUNDLE_ITEM_PRICE_COLOR_CHANGE,
    BUNDLE_MAIN_ENABLED_CHANGE,
    BUNDLE_NAVIGATE_TO_CHANGE,
    BUNDLE_PRICE_COLOR_CHANGE,
    BUNDLE_PRICE_SIZE_CHANGE,
    BUNDLE_PRODUCT_DESC_ON_QUICK_VIEW_CHANGE,
    BUNDLE_QUICK_VIEW_ENABLED_CHANGE,
    BUNDLE_REFRESH_ON_CART_CHANGE,
    BUNDLE_SETTINGS_STRING_CHANGE,
    BUNDLE_SHOW_SAVE_CHANGE,
    BUNDLE_TITLE_ALIGN,
    BUNDLE_TITLE_COLOR_CHANGE,
    BUNDLE_TITLE_FONT_SIZE_CHANGE,
    BUNDLE_TITLE_FONT_WEIGHT_CHANGE,
    DISMISS_TOAST,
    HIDE_BUNDLE_COMPARE_PRICE_CHANGE,
    INFO_POPUP_BUNDLE_CHANGE,
    INIT_BUNDLE_SETTINGS,
    REGENERATE_AUTO_BUNDLES_CHANGE,
    RESTORE_BUNDLE_TO_DEFAULTS,
    SAVE_BUNDLE_SETTINGS_ERROR,
    SAVE_BUNDLE_SETTINGS_PROCESSING,
    SAVE_BUNDLE_SETTINGS_SUCCESS,
    SHOW_BUNDLE_ITEM_COMPARE_CHANGE,
    SUM_COMPARE_PRICES_CHANGE,
    TOTAL_PRICE_TEXT_COLOR_CHANGE,
} from "../actions/settingsBundle";

const methodToText = {
    legacy: 'Legacy Discount Code',
    cart_transform: 'Functions Cart Transform'
};


const settingsBundle = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_BUNDLE_SETTINGS:
            newState = _cloneDeep(state);
            newState.settings = _cloneDeep(action.settings.values);
            newState.defaultSettings = _cloneDeep(action.settings.defaults);
            newState.settings.navigate_to = newState.settings.navigate_to_checkout ? 'checkout' :
                newState.settings.navigate_to_cart ? 'cart' : 'none';
            newState.planName = action.planName;
            ['backend_method'].forEach(attr => newState.settings[attr] = newState.settings[attr] || newState.defaultSettings[attr]);
            newState.methodOptions = Object.entries(action.settings.methods).map(entry => ({label: methodToText[entry[0]], value: entry[1]}))
            break;
        case ACTIVATE_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = true;
            break;
        case SAVE_BUNDLE_SETTINGS_SUCCESS:
            newState = _cloneDeep(state);
            newState.saveInProgress = false;
            break;
        case DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case RESTORE_BUNDLE_TO_DEFAULTS:
            newState = _cloneDeep(state);
            newState.settings = _cloneDeep(newState.defaultSettings);
            newState.settings.navigate_to = newState.settings.navigate_to_checkout ? 'checkout' :
                newState.settings.navigate_to_cart ? 'cart' : 'none';
            break;
        case SAVE_BUNDLE_SETTINGS_PROCESSING:
            newState = _cloneDeep(state);
            newState.saveInProgress = true;
            newState.saveError = false;
            break;
        case SAVE_BUNDLE_SETTINGS_ERROR:
            newState = _cloneDeep(state);
            newState.saveInProgress = false;
            newState.saveError = true;
            break;
        case BUNDLE_TITLE_ALIGN:
            newState = _cloneDeep(state);
            newState.settings.title_align = action.text;
            break;
        case BUNDLE_COMPARE_PRICE_SIZE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_compare_price_size = action.text;
            break;
        case BUNDLE_PRICE_SIZE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_price_size = action.text;
            break;
        case BUNDLE_TITLE_FONT_SIZE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_title_font_size = action.text;
            break;
        case BUNDLE_FONT_FAMILY_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_font_family = action.text;
            break;
        case BUNDLE_NAVIGATE_TO_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.navigate_to = action.text;
            if (action.text === 'checkout') {
                newState.settings.navigate_to_checkout = true;
                newState.settings.navigate_to_cart = false;
            } else if (action.text === 'cart') {
                newState.settings.navigate_to_checkout = false;
                newState.settings.navigate_to_cart = true;
            } else if (action.text === 'none') {
                newState.settings.navigate_to_checkout = false;
                newState.settings.navigate_to_cart = false;
            }
            break;
        case BUNDLE_CURRENCY_POSITION_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_currency_position = action.text;
            break;
        case BUNDLE_SHOW_SAVE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.show_save = action.text === 'enable';
            break;
        case BUNDLE_DESELECT_BUNDLE_CHECKBOXES_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.deselect_bundle_checkboxes = action.text === 'unselected';
            break;
        case BUNDLE_SETTINGS_STRING_CHANGE:
            newState = _cloneDeep(state);
            newState.settings[action.attr] = action.text;
            break;
        case BUNDLE_TITLE_FONT_WEIGHT_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.title_font_weight = action.weight;
            break;
        case ADD_SELECTED_TO_CART_TEXT_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.add_selected_to_cart_text_color = action.hex;
            break;
        case ADD_SELECTED_TO_CART_BUTTON_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.add_selected_to_cart_button_color = action.hex;
            break;
        case TOTAL_PRICE_TEXT_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.total_price_text_color = action.hex;
            break;
        case BUNDLE_COMPARE_PRICE_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_compare_price_color = action.hex;
            break;
        case BUNDLE_PRICE_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_price_color = action.hex;
            break;
        case BUNDLE_TITLE_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_title_color = action.hex;
            break;
        case BUNDLE_ITEM_PRICE_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_item_price_color = action.hex;
            break;
        case BUNDLE_ITEM_NAME_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_item_name_color = action.hex;
            break;
        case HIDE_BUNDLE_COMPARE_PRICE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.hide_bundle_compare_price = action.bool;
            break;
        case SUM_COMPARE_PRICES_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.sum_compare_prices = action.bool;
            break;
        case SHOW_BUNDLE_ITEM_COMPARE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.show_bundle_item_compare = action.bool;
            break;
        case BUNDLE_MAIN_ENABLED_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_main_enabled = action.bool;
            break;
        case BUNDLE_BOTTOM_ENABLED_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.bundle_bottom_enabled = action.bool;
            break;
        case INFO_POPUP_BUNDLE_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.info_popup_bundle = action.bool;
            break;
        case BUNDLE_QUICK_VIEW_ENABLED_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.quick_view_enabled = action.bool;
            break;
        case BUNDLE_PRODUCT_DESC_ON_QUICK_VIEW_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.product_desc_on_quick_view = action.bool;
            break;
        case BUNDLE_CUT_ZEROS_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.cut_zeros = action.bool;
            break;
        case BUNDLE_REFRESH_ON_CART_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.refresh_on_cart = action.bool;
            break;
        case REGENERATE_AUTO_BUNDLES_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.auto_generate_bundles = action.bool;
            break;
        default:
            return state;
    }
    return newState;
};

export default settingsBundle;
