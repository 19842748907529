import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';

import {
    BULK_PRICE_TYPE_CHANGE,
    BULK_WARRANTY_COLLECTION_CHANGE,
    BULK_WARRANTY_FILTER_TYPE_CHANGE,
    BULK_WARRANTY_PRICE_CHANGE,
    BULK_WARRANTY_REMOVE_FAILURE, BULK_WARRANTY_UPDATE_COLLECTION_COUNT,
    CANCEL_UPLOAD_WARRANTY_IMAGE,
    CHOOSE_WARRANTY_ITEM,
    CLOSE_REMOVE_BULK_WARRANTY_MODAL,
    CLOSE_REMOVE_WARRANTY_MODAL,
    CREATE_BULK_WARRANTY,
    CREATE_WARRANTY,
    DISMISS_TOAST,
    EDIT_WARRANTY,
    INIT_PRODUCTS_WARRANTY,
    PRICE_TYPE_CHANGE,
    REMOVE_BULK_WARRANTY,
    REMOVE_WARRANTY,
    RESTORE_TO_WARRANTY_DEFAULT_IMAGE,
    RESTORE_TO_WARRANTY_ORIGINAL_IMAGE,
    RESTORE_TO_WARRANTY_STORE_IMAGE,
    SET_BULK_WARRANTIES,
    SET_DELETABLE,
    START_BULK_WARRANTY_REMOVE,
    START_WARRANTY_REMOVE,
    UPLOAD_WARRANTY_FAILURE,
    UPLOAD_WARRANTY_IMAGE,
    UPLOAD_WARRANTY_SUCCESS,
    WARRANTY_BACK,
    WARRANTY_BAD_NAME_TEMPLATE,
    WARRANTY_CHOOSE_PRODUCT,
    WARRANTY_CLOSE_CHOOSE_PRODUCT,
    WARRANTY_NAME_CHANGE,
    WARRANTY_NAME_TEMPLATE_CHANGE,
    WARRANTY_PERCENTAGE_CHANGE,
    WARRANTY_PRICE_CHANGE,
    WARRANTY_REMOVE_FAILURE,
    WARRANTY_SAVE_ERROR,
    WARRANTY_SAVING,
    WARRANTY_SEARCH_FAILURE,
    WARRANTY_SEARCH_SUCCESS,
    WARRANTY_SETTINGS_SAVED,
    WARRANTY_START_SEARCH,
    WARRANTY_TAG_CHANGE
} from "../actions/productsWarranty";
import uuid4 from "uuid4";

const productsWarranty = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_PRODUCTS_WARRANTY:
            newState = _cloneDeep(state);
            newState.ready = true;
            if (action.warranties) newState.warranties = _cloneDeep(action.warranties);
            if (action.smartCollections) newState.smartCollections = _cloneDeep(action.smartCollections);
            if (action.customCollections) newState.customCollections = _cloneDeep(action.customCollections);
            if (action.currencySymbol) newState.currencySymbol = action.currencySymbol;
            if (action.tokenId) newState.tokenId = action.tokenId;
            if (action.tags) newState.tags = _cloneDeep(action.tags);
            if (action.tagsCount) newState.tagsCount = _cloneDeep(action.tagsCount);
            if (action.bulkWarranties) newState.bulkWarranties = _cloneDeep(action.bulkWarranties);
            if (action.deletableBulkWarranties) newState.deletableBulkWarranties = _cloneDeep(action.deletableBulkWarranties);
            newState.isShowWarranties = true;
            newState.isShowEdit = false;
            newState.isShowBulkEdit = false;
            newState.isSaving = false;
            newState.saveError = false;
            break;
        case SET_DELETABLE:
            newState = _cloneDeep(state);
            newState.deletableBulkWarranties = _cloneDeep(action.deletableBulkWarranties);
            break;
        case SET_BULK_WARRANTIES:
            newState = _cloneDeep(state);
            newState.bulkWarranties = _cloneDeep(action.bulkWarranties);
            break;
        case CREATE_WARRANTY:
            newState = _cloneDeep(state);
            newState.isShowWarranties = false;
            newState.isShowEdit = true;
            newState.warranty = {id: uuid4(), price: '', price_type: 'fixed'};
            newState.isWarrantyDefaultImage = true;
            break;
        case CREATE_BULK_WARRANTY:
            newState = _cloneDeep(state);
            newState.isShowWarranties = false;
            newState.isShowBulkEdit = true;
            newState.bulkWarranty = {id: uuid4(), tag: newState.tags[0], price_type: 'percentage',
                warranty_name_template: 'Warranty for {{ product_name }}', filter_type: 'tag',
                smartCollection: newState.smartCollections[0] && newState.smartCollections[0].value,
                customCollection: newState.customCollections[0] && newState.customCollections[0].value};
            newState.tagCount = newState.tagsCount[newState.tags[0]];
            newState.collectionCount = {
                'smart-collection': {},
                'custom-collection': {},
            };
            newState.isWarrantyDefaultImage = true;
            break;
        case WARRANTY_TAG_CHANGE:
            newState = _cloneDeep(state);
            newState.bulkWarranty.tag = action.text;
            newState.tagCount = newState.tagsCount[action.text];
            break;
        case BULK_PRICE_TYPE_CHANGE:
            newState = _cloneDeep(state);
            newState.bulkWarranty.price_type = action.text;
            break;
        case WARRANTY_NAME_TEMPLATE_CHANGE:
            newState = _cloneDeep(state);
            newState.bulkWarranty.warranty_name_template = action.text;
            break;
        case BULK_WARRANTY_FILTER_TYPE_CHANGE:
            newState = _cloneDeep(state);
            newState.bulkWarranty.filter_type = action.text;
            break;
        case BULK_WARRANTY_COLLECTION_CHANGE:
            newState = _cloneDeep(state);
            if (newState.bulkWarranty.filter_type === 'smart-collection') {
                newState.bulkWarranty.smartCollection = action.text;
            } else if (newState.bulkWarranty.filter_type === 'custom-collection') {
                newState.bulkWarranty.customCollection = action.text;
            }
            break;
        case BULK_WARRANTY_UPDATE_COLLECTION_COUNT:
            newState = _cloneDeep(state);
            newState.collectionCount[action.collectionType][action.collectionValue] = action.count;
            break;
        case EDIT_WARRANTY:
            newState = _cloneDeep(state);
            newState.isShowWarranties = false;
            newState.isShowEdit = true;
            newState.warranty = _cloneDeep(_find(newState.warranties, {id: action.id}));
            if (newState.warranty.percentage !== null) {
                newState.warranty.price_type = 'percentage';
                newState.warranty.price = newState.warranty.percentage;
            } else {
                newState.warranty.price_type = 'fixed';
            }
            newState.imageBackup = newState.warranty.image_src;
            break;
        case REMOVE_WARRANTY:
            newState = _cloneDeep(state);
            newState.removeWarrantyModalOpen = true;
            newState.warrantyName = _find(newState.warranties, {id: action.id}).name;
            newState.destWarranty = action.id;
            break;
        case REMOVE_BULK_WARRANTY:
            newState = _cloneDeep(state);
            newState.removeBulkWarrantyModalOpen = true;
            newState.destBulkWarranty = action.id;
            break;
        case CLOSE_REMOVE_WARRANTY_MODAL:
            newState = _cloneDeep(state);
            newState.removeWarrantyModalOpen = false;
            newState.removeWarrantySpinner = false;
            newState.removeWarrantyError = false;
            break;
        case CLOSE_REMOVE_BULK_WARRANTY_MODAL:
            newState = _cloneDeep(state);
            newState.removeBulkWarrantyModalOpen = false;
            newState.removeBulkWarrantySpinner = false;
            newState.removeBulkWarrantyError = false;
            break;
        case WARRANTY_REMOVE_FAILURE:
            newState = _cloneDeep(state);
            newState.removeWarrantySpinner = false;
            newState.removeWarrantyError = true;
            break;
        case BULK_WARRANTY_REMOVE_FAILURE:
            newState = _cloneDeep(state);
            newState.removeBulkWarrantySpinner = false;
            newState.removeBulkWarrantyError = true;
            break;
        case START_WARRANTY_REMOVE:
            newState = _cloneDeep(state);
            newState.removeWarrantySpinner = true;
            newState.removeWarrantyError = false;
            break;
        case START_BULK_WARRANTY_REMOVE:
            newState = _cloneDeep(state);
            newState.removeBulkWarrantySpinner = true;
            newState.removeBulkWarrantyError = false;
            break;
        case WARRANTY_BACK:
            newState = _cloneDeep(state);
            newState.isShowWarranties = true;
            newState.isShowEdit = false;
            newState.isShowBulkEdit = false;
            newState.warranty = null;
            newState.bulkWarranty = null;
            newState.imageBackup = null;
            break;
        case WARRANTY_SAVING:
            newState = _cloneDeep(state);
            newState.isSaving = true;
            newState.saveError = false;
            newState.errorBadNameTemplate = false;
            break;
        case WARRANTY_SAVE_ERROR:
            newState = _cloneDeep(state);
            newState.saveError = true;
            newState.isSaving = false;
            break;
        case WARRANTY_SETTINGS_SAVED:
            newState = _cloneDeep(state);
            newState.toastIsActive = true;
            newState.imageBackup = null;
            break;
        case WARRANTY_NAME_CHANGE:
            newState = _cloneDeep(state);
            newState.warranty.name = action.name;
            break;
        case PRICE_TYPE_CHANGE:
            newState = _cloneDeep(state);
            newState.warranty.price_type = action.text;
            break;
        case WARRANTY_CHOOSE_PRODUCT:
            newState = _cloneDeep(state);
            newState.chooseProductOpen = true;
            break;
        case WARRANTY_CLOSE_CHOOSE_PRODUCT:
            newState = _cloneDeep(state);
            newState.chooseProductOpen = false;
            break;
        case DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case WARRANTY_START_SEARCH:
            newState = _cloneDeep(state);
            newState.showSearchSpinner = true;
            newState.searchBoxText = action.text;
            newState.searchedProducts = [];
            break;
        case WARRANTY_SEARCH_SUCCESS:
            newState = _cloneDeep(state);
            if (newState.searchBoxText !== action.title) {
                // do nothing
            } else {
                newState.searchedProducts = _cloneDeep(action.products);
                newState.showSearchSpinner = false;
                newState.searchError = false;
            }
            break;
        case WARRANTY_SEARCH_FAILURE:
            newState = _cloneDeep(state);
            newState.searchError = true;
            newState.showSearchSpinner = false;
            break;
        case UPLOAD_WARRANTY_IMAGE:
            newState = _cloneDeep(state);
            newState.isUploading = true;
            break;
        case CANCEL_UPLOAD_WARRANTY_IMAGE:
            newState = _cloneDeep(state);
            newState.isUploading = false;
            break;
        case UPLOAD_WARRANTY_SUCCESS:
            newState = _cloneDeep(state);
            newState.isUploading = false;
            newState.uploadError = false;
            if (newState.warranty) newState.warranty.image_src = null;
            if (newState.bulkWarranty) newState.bulkWarranty.image_src = null;
            newState.isWarrantyDefaultImage = false;
            break;
        case UPLOAD_WARRANTY_FAILURE:
            newState = _cloneDeep(state);
            newState.uploadError = true;
            break;
        case RESTORE_TO_WARRANTY_ORIGINAL_IMAGE:
            newState = _cloneDeep(state);
            newState.warranty.image_src = newState.imageBackup;
            break;
        case RESTORE_TO_WARRANTY_STORE_IMAGE:
            newState = _cloneDeep(state);
            newState.warranty.image_src = null;
            newState.isWarrantyDefaultImage = true;
            break;
        case WARRANTY_BAD_NAME_TEMPLATE:
            newState = _cloneDeep(state);
            newState.errorBadNameTemplate = true;
            break;
        case RESTORE_TO_WARRANTY_DEFAULT_IMAGE:
            newState = _cloneDeep(state);
            newState.isWarrantyDefaultImage = true;
            break;
        case WARRANTY_PRICE_CHANGE:
            newState = _cloneDeep(state);
            newState.warranty.price = action.num;
            if (action.num !== '' && action.num < 0) {
                newState.warranty.price = 0;
            }
            break;
        case WARRANTY_PERCENTAGE_CHANGE:
            newState = _cloneDeep(state);
            newState.bulkWarranty.percentage = action.num;
            if (action.num !== '' && action.num < 0) {
                newState.bulkWarranty.percentage = 0;
            }
            break;
        case BULK_WARRANTY_PRICE_CHANGE:
            newState = _cloneDeep(state);
            newState.bulkWarranty.price = action.num;
            if (action.num !== '' && action.num < 0) {
                newState.bulkWarranty.price = 0;
            }
            break;
        case CHOOSE_WARRANTY_ITEM:
            newState = _cloneDeep(state);
            const selectedItem = _find(newState.searchedProducts, {id: action.id});
            const newProduct = {
                image_src: selectedItem.image_src,
                shopify_product_id: selectedItem.id,
                title: selectedItem.title,
            };
            newState.warranty.product = newProduct;
            newState.warranty.name = `Warranty for ${newProduct.title}`;
            newState.chooseProductOpen = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default productsWarranty;
