import fetch from "cross-fetch";
import {host} from "./data";
import {refreshSyncStatus} from "./status";

export const VOLUME_SETTINGS_INIT = 'VOLUME_SETTINGS_INIT';
export const VOLUME_ADD_TO_CART_COLOR_CHANGE = 'VOLUME_ADD_TO_CART_COLOR_CHANGE';
export const VOLUME_ADD_TO_CART_BG_COLOR_CHANGE = 'VOLUME_ADD_TO_CART_BG_COLOR_CHANGE';
export const VOLUME_TITLE_COLOR_CHANGE = 'VOLUME_TITLE_COLOR_CHANGE';
export const VOLUME_SUBTITLE_COLOR_CHANGE = 'VOLUME_SUBTITLE_COLOR_CHANGE';
export const VOLUME_SELECTED_BG_COLOR_CHANGE = 'VOLUME_SELECTED_BG_COLOR_CHANGE';
export const VOLUME_SAVE_TEXT_COLOR_CHANGE = 'VOLUME_SAVE_TEXT_COLOR_CHANGE';
export const VOLUME_ADD_VARIANT_BG_COLOR_CHANGE = 'VOLUME_ADD_VARIANT_BG_COLOR_CHANGE';
export const VOLUME_ADD_VARIANT_TEXT_COLOR_CHANGE = 'VOLUME_ADD_VARIANT_TEXT_COLOR_CHANGE';
export const VOLUME_POPULAR_BG_COLOR_CHANGE = 'VOLUME_POPULAR_BG_COLOR_CHANGE';
export const VOLUME_POPULAR_TEXT_COLOR_CHANGE = 'VOLUME_POPULAR_TEXT_COLOR_CHANGE';
export const VOLUME_TOTAL_ORIGINAL_PRICE_COLOR_CHANGE = 'VOLUME_TOTAL_ORIGINAL_PRICE_COLOR_CHANGE';
export const VOLUME_TOTAL_DISCOUNTED_PRICE_COLOR_CHANGE = 'VOLUME_TOTAL_DISCOUNTED_PRICE_COLOR_CHANGE';
export const VOLUME_SETTINGS_COLOR_CHANGE = 'VOLUME_SETTINGS_COLOR_CHANGE';
export const VOLUME_SETTINGS_STRING_CHANGE = 'VOLUME_SETTINGS_STRING_CHANGE';
export const VOLUME_TITLE_ALIGN_CHANGE = 'VOLUME_TITLE_ALIGN_CHANGE';
export const VOLUME_TITLE_FONT_SIZE_CHANGE = 'VOLUME_TITLE_FONT_SIZE_CHANGE';
export const VOLUME_SUBTITLE_FONT_SIZE_CHANGE = 'VOLUME_SUBTITLE_FONT_SIZE_CHANGE';
export const VOLUME_SAVE_AMOUNT_CHANGE = 'VOLUME_SAVE_AMOUNT_CHANGE';
export const VOLUME_SHOW_TIERS_TABLE_CHANGE = 'VOLUME_SHOW_TIERS_TABLE_CHANGE';
export const SAVING_VOLUME_WIDGET = 'SAVING_VOLUME_WIDGET';
export const VOLUME_WIDGET_SAVE_FAILURE = 'VOLUME_WIDGET_SAVE_FAILURE';
export const VOLUME_WIDGET_SAVE_SUCCESS = 'VOLUME_WIDGET_SAVE_SUCCESS';
export const VOLUME_RESTORE_TO_DEFAULTS = 'VOLUME_RESTORE_TO_DEFAULTS';
export const VOLUME_SETTINGS_CHANGE_BOOL = 'VOLUME_SETTINGS_CHANGE_BOOL';

export const volumeSettingsInit = json => ({
    type: VOLUME_SETTINGS_INIT,
    volumeSettings: json.volume_settings,
    json,
});

export const volumeAddToCartColorChange = hex => ({
    type: VOLUME_ADD_TO_CART_COLOR_CHANGE,
    hex,
});

export const volumeAddToCartBgColorChange = hex => ({
    type: VOLUME_ADD_TO_CART_BG_COLOR_CHANGE,
    hex,
});

export const volumeTitleColorChange = hex => ({
    type: VOLUME_TITLE_COLOR_CHANGE,
    hex,
});

export const volumeSubtitleColorChange = hex => ({
    type: VOLUME_SUBTITLE_COLOR_CHANGE,
    hex,
});

export const volumeSelectedBgColorChange = hex => ({
    type: VOLUME_SELECTED_BG_COLOR_CHANGE,
    hex,
});

export const volumeSaveTextColorChange = hex => ({
    type: VOLUME_SAVE_TEXT_COLOR_CHANGE,
    hex,
});

export const volumeAddVariantBgColorChange = hex => ({
    type: VOLUME_ADD_VARIANT_BG_COLOR_CHANGE,
    hex,
});

export const volumeAddVariantTextColorChange = hex => ({
    type: VOLUME_ADD_VARIANT_TEXT_COLOR_CHANGE,
    hex,
});

export const volumePopularBgColorChange = hex => ({
    type: VOLUME_POPULAR_BG_COLOR_CHANGE,
    hex,
});

export const volumePopularTextColorChange = hex => ({
    type: VOLUME_POPULAR_TEXT_COLOR_CHANGE,
    hex,
});

export const volumeTotalOriginalPriceColorChange = hex => ({
    type: VOLUME_TOTAL_ORIGINAL_PRICE_COLOR_CHANGE,
    hex,
});

export const volumeTotalDiscountedPriceColorChange = hex => ({
    type: VOLUME_TOTAL_DISCOUNTED_PRICE_COLOR_CHANGE,
    hex,
});

export const volumeSettingsColorChange = (attr, hex) => ({
    type: VOLUME_SETTINGS_COLOR_CHANGE,
    attr,
    hex,
});

export const volumeSettingsStringChange = (attr, text) => ({
    type: VOLUME_SETTINGS_STRING_CHANGE,
    attr,
    text,
});

export const volumeTitleAlignChange = text => ({
    type: VOLUME_TITLE_ALIGN_CHANGE,
    text,
});

export const volumeTitleFontSizeChange = text => ({
    type: VOLUME_TITLE_FONT_SIZE_CHANGE,
    text,
});

export const volumeSubtitleFontSizeChange = text => ({
    type: VOLUME_SUBTITLE_FONT_SIZE_CHANGE,
    text,
});

export const volumeSaveAmountChange = text => ({
    type: VOLUME_SAVE_AMOUNT_CHANGE,
    text,
});

export const volumeShowTiersTableChange = text => ({
    type: VOLUME_SHOW_TIERS_TABLE_CHANGE,
    text,
});

export const savingVolumeWidget = () => ({
    type: SAVING_VOLUME_WIDGET,
});

export const volumeWidgetSaveFailure = () => ({
    type: VOLUME_WIDGET_SAVE_FAILURE,
});

export const volumeWidgetSaveSuccess = () => ({
    type: VOLUME_WIDGET_SAVE_SUCCESS,
});

export const volumeRestoreToDefaults = () => ({
    type: VOLUME_RESTORE_TO_DEFAULTS,
});

export const volumeSettingsChangeBool = (attr, val) => ({
    type: VOLUME_SETTINGS_CHANGE_BOOL,
    attr,
    val,
});

export function saveWidget() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(savingVolumeWidget());
        return fetch(`https://${host}/volume_discount/save_widget`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    settings: state.settingsVolume.settings,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(volumeWidgetSaveSuccess());
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(volumeWidgetSaveFailure());
                }
            });

    }
}


