import React from 'react';
import {Badge } from '@shopify/polaris';
import {initPlans} from "../plans";
import {postPayload} from "../data";

import _remove from "lodash/remove";

export const INITIALIZE_FREE_SHIPPING_BAR = 'INITIALIZE_FREE_SHIPPING_BAR';
export const FREE_SHIPPING_BAR_SELECT_TAB = 'FREE_SHIPPING_BAR_SELECT_TAB';
export const FREE_SHIPPING_BAR_DISMISS_TOAST = 'FREE_SHIPPING_BAR_DISMISS_TOAST';
export const SET_FREE_SHIPPING_BAR_DASHBOARD = 'SET_FREE_SHIPPING_BAR_DASHBOARD';
export const SET_FREE_SHIPPING_BAR_LOCALES = 'SET_FREE_SHIPPING_BAR_LOCALES';
export const SET_FREE_SHIPPING_BAR_SETTINGS = 'SET_FREE_SHIPPING_BAR_SETTINGS';
export const SET_FREE_SHIPPING_BAR_STORE_DATA = 'SET_FREE_SHIPPING_BAR_STORE_DATA';
export const FREE_SHIPPING_BAR_SAVING_SETTINGS = 'FREE_SHIPPING_BAR_SAVING_SETTINGS';
export const FREE_SHIPPING_BAR_SUBMITTING_SUPPORT = 'FREE_SHIPPING_BAR_SUBMITTING_SUPPORT';
export const FREE_SHIPPING_BAR_CHANGE_SETTING_COLOR = 'FREE_SHIPPING_BAR_CHANGE_SETTING_COLOR';
export const FREE_SHIPPING_BAR_CHANGE_SETTING_BOOL = 'FREE_SHIPPING_BAR_CHANGE_SETTING_BOOL';
export const FREE_SHIPPING_BAR_CHANGE_SETTING_TEXT = 'FREE_SHIPPING_BAR_CHANGE_SETTING_TEXT';
export const FREE_SHIPPING_BAR_SUPPORT_TEXT_CHANGE = 'FREE_SHIPPING_BAR_SUPPORT_TEXT_CHANGE';
export const FREE_SHIPPING_BAR_CHANGE_GOAL = 'FREE_SHIPPING_BAR_CHANGE_GOAL';
export const FREE_SHIPPING_BAR_SELECT_NEW_COUNTRY = 'FREE_SHIPPING_BAR_SELECT_NEW_COUNTRY';
export const FREE_SHIPPING_BAR_SELECT_NEW_TARGET_COUNTRY = 'FREE_SHIPPING_BAR_SELECT_NEW_TARGET_COUNTRY';
export const FREE_SHIPPING_BAR_REMOVE_GOAL = 'FREE_SHIPPING_BAR_REMOVE_GOAL';
export const FREE_SHIPPING_BAR_REMOVE_TARGET_COUNTRY = 'FREE_SHIPPING_BAR_REMOVE_TARGET_COUNTRY';
export const FREE_SHIPPING_BAR_TEXTS_CHANGE = 'FREE_SHIPPING_BAR_TEXTS_CHANGE';
export const FREE_SHIPPING_BAR_LOCALE_CHANGE = 'FREE_SHIPPING_BAR_LOCALE_CHANGE';
export const FREE_SHIPPING_BAR_SET_ZOORIX_M_PROMO_BADGE = 'FREE_SHIPPING_BAR_SET_ZOORIX_M_PROMO_BADGE';
export const FREE_SHIPPING_BAR_SAVING_SETTINGS_SUCCESS = 'FREE_SHIPPING_BAR_SAVING_SETTINGS_SUCCESS';
export const FREE_SHIPPING_BAR_ADD_COUNTRY = 'FREE_SHIPPING_BAR_ADD_COUNTRY';
export const FREE_SHIPPING_BAR_CANCEL_ADDING_NEW_COUNTRY = 'FREE_SHIPPING_BAR_CANCEL_ADDING_NEW_COUNTRY';

export const initializeFreeShippingBar = params => ({
    type: INITIALIZE_FREE_SHIPPING_BAR,
    params,
});

export const freeShippingBarSelectTab = index => ({
    type: FREE_SHIPPING_BAR_SELECT_TAB,
    index,
});

export const freeShippingBarDismissToast = () => ({
    type: FREE_SHIPPING_BAR_DISMISS_TOAST,
});

export const freeShippingBarSavingSettingsSuccess = () => ({
    type: FREE_SHIPPING_BAR_SAVING_SETTINGS_SUCCESS,
});

export const freeShippingBarAddCountry = () => ({
    type: FREE_SHIPPING_BAR_ADD_COUNTRY,
});

export const freeShippingBarCancelAddingNewCountry = () => ({
    type: FREE_SHIPPING_BAR_CANCEL_ADDING_NEW_COUNTRY,
});

export const setFreeShippingBarDashboard = json => ({
    type: SET_FREE_SHIPPING_BAR_DASHBOARD,
    json,
});

export const setFreeShippingBarLocales = json => ({
    type: SET_FREE_SHIPPING_BAR_LOCALES,
    json,
});

export const setFreeShippingBarSettings = json => ({
    type: SET_FREE_SHIPPING_BAR_SETTINGS,
    json,
});

export const setFreeShippingBarStoreData = json => ({
    type: SET_FREE_SHIPPING_BAR_STORE_DATA,
    json,
});

export const freeShippingBarSavingSettings = bool => ({
    type: FREE_SHIPPING_BAR_SAVING_SETTINGS,
    bool,
});

export const freeShippingBarSubmittingSupport = bool => ({
    type: FREE_SHIPPING_BAR_SUBMITTING_SUPPORT,
    bool,
});

export const freeShippingBarChangeSettingColor = (attribute, hex) => ({
    type: FREE_SHIPPING_BAR_CHANGE_SETTING_COLOR,
    attribute,
    hex,
});

export const freeShippingBarChangeSettingBool = (attribute, text) => ({
    type: FREE_SHIPPING_BAR_CHANGE_SETTING_BOOL,
    attribute,
    text,
});

export const freeShippingBarChangeSettingText = (attribute, text) => ({
    type: FREE_SHIPPING_BAR_CHANGE_SETTING_TEXT,
    attribute,
    text,
});

export const freeShippingBarSupportTextChange = (attribute, text) => ({
    type: FREE_SHIPPING_BAR_SUPPORT_TEXT_CHANGE,
    attribute,
    text,
});

export const freeShippingBarChangeGoal = (country, text) => ({
    type: FREE_SHIPPING_BAR_CHANGE_GOAL,
    country,
    text,
});

export const freeShippingBarSelectNewCountry = text => ({
    type: FREE_SHIPPING_BAR_SELECT_NEW_COUNTRY,
    text,
});

export const freeShippingBarSelectNewTargetCountry = text => ({
    type: FREE_SHIPPING_BAR_SELECT_NEW_TARGET_COUNTRY,
    text,
});

export const freeShippingBarRemoveGoal = text => ({
    type: FREE_SHIPPING_BAR_REMOVE_GOAL,
    text,
});

export const freeShippingBarRemoveTargetCountry = text => ({
    type: FREE_SHIPPING_BAR_REMOVE_TARGET_COUNTRY,
    text,
});

export const freeShippingBarTextsChange = (attribute, text) => ({
    type: FREE_SHIPPING_BAR_TEXTS_CHANGE,
    attribute,
    text,
});

export const freeShippingBarLocaleChange = locale => ({
    type: FREE_SHIPPING_BAR_LOCALE_CHANGE,
    locale,
});

export const freeShippingBarSetZoorixMPromoBadge = bool => ({
    type: FREE_SHIPPING_BAR_SET_ZOORIX_M_PROMO_BADGE,
    bool,
});

function fetchSettings(dispatch, getState) {
    const state = getState();
    postFreeShippingBarPayload('free_shipping_bar_admin/fetch_settings', {}, state)
        .then(response => response.json())
        .then(json => {
            if (json.settings) {
                dispatch(setFreeShippingBarSettings(json));
            } else {
                setTimeout(() => fetchSettings(dispatch, getState), 5000);
            }
        });
}

function fetchStoreData(dispatch, getState) {
    const state = getState();
    postFreeShippingBarPayload('free_shipping_bar_admin/fetch_store_data', {}, state)
        .then(response => response.json())
        .then(json => {
            if (json.plan_name) {
                dispatch(setFreeShippingBarStoreData(json));
            } else {
                setTimeout(() => fetchStoreData(dispatch, getState), 5000);
            }
        });
}


const postFreeShippingBarPayload = async (path, payload, state) => {
    return postPayload(path, payload, {
        data: {
            shopName: state.freeShippingBar.shop,
            token: state.freeShippingBar.token,
        }
    })
}

export function initFreeShippingBar(params) {
    return (dispatch, getState) => {
        let state = getState();
        if (state.freeShippingBar.initialized) return;
        dispatch(initializeFreeShippingBar(params));
        state = getState();
        dispatch(initPlans());
        postFreeShippingBarPayload('free_shipping_bar_admin/fetch_dashboard', {}, state)
            .then(response => response.json())
            .then(json => dispatch(setFreeShippingBarDashboard(json)));
        postFreeShippingBarPayload('free_shipping_bar_admin/fetch_available_locales', {}, state)
            .then(response => response.json())
            .then(json => dispatch(setFreeShippingBarLocales(json)));
        setTimeout(() => fetchSettings(dispatch, getState), 0);
        setTimeout(() => fetchStoreData(dispatch, getState), 0);
    }
}

export function freeShippingBarSaveSettings() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(freeShippingBarSavingSettings(true));
        const resp = await postFreeShippingBarPayload('free_shipping_bar_admin/save_widget',
            {
                settings: state.freeShippingBar.settings,
            }, state);
        if (resp.ok) {
            dispatch(freeShippingBarSavingSettings(false));
            dispatch(freeShippingBarSavingSettingsSuccess());
        } else {
            console.log('freeShippingBarSaveSettings error');
        }
    }
}

export function freeShippingBarSaveCode() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(freeShippingBarSavingSettings(true));
        const resp = await postFreeShippingBarPayload('free_shipping_bar_admin/save_custom_css',
            {
                custom_css: state.freeShippingBar.settings.custom_css,
                custom_js: state.freeShippingBar.settings.custom_js,
            }, state);
        if (resp.ok) {
            dispatch(freeShippingBarSavingSettings(false));
            dispatch(freeShippingBarSavingSettingsSuccess());
        } else {
            console.log('freeShippingBarSaveCode error');
        }
    }
}

export function freeShippingBarSupportSend() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(freeShippingBarSubmittingSupport(true));
        const resp = await postFreeShippingBarPayload('free_shipping_bar_admin/submit_support',
            {
                store_data: state.freeShippingBar.storeData,
            }, state);
        if (resp.ok) {
            dispatch(freeShippingBarSubmittingSupport(false));
        } else {
            console.log('freeShippingBarSupportSend error');
        }
    }
}

export function freeShippingBarChangeSaveTexts() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(freeShippingBarSavingSettings(true));
        const resp = await postFreeShippingBarPayload('free_shipping_bar_admin/save_texts',
            {
                texts: state.freeShippingBar.settings.texts,
            }, state);
        if (resp.ok) {
            dispatch(freeShippingBarSavingSettings(false));
            dispatch(freeShippingBarSavingSettingsSuccess());
        } else {
            console.log('freeShippingBarChangeSaveTexts error');
        }
    }
}

export function upgradeFreeShippingBarToZoorixM() {
    return (dispatch, getState) => {
        const state = getState();
        window.open(state.freeShippingBar.storeData.upgrade_url, '_blank');
        dispatch(freeShippingBarSetZoorixMPromoBadge(false));
    }
}

export const freeShippingBarTabs = state => {
    const tabs = [
        {
            id: 'dashboard',
            content: 'Dashboard',
        },
        {
            id: 'settings',
            content: 'Settings',
        },
        {
            id: 'texts',
            content: 'Texts',
        },
        {
            id: 'css-editor',
            content: 'Code Editor',
        },
        {
            id: 'support',
            content: 'Support',
        },
        {
            id: 'faq',
            content: 'FAQ',
        },
        {
            id: 'zoorix-m',
            content: <span>
                <div className={`free-shipping-bar-zoorix-promo ${state.promoShake ? 'zrx-free-shipping-bar-shake' : ''}`}>
                    Zoorix - Cross Sell & Bundles{state.promoBadge && <Badge status="new"></Badge>}
                </div>
            </span>,
        },
    ];

    if (state.storeData && state.storeData.zoorix_m_installed_and_enabled) {
        _remove(tabs, {id: 'zoorix-m'});
    }

    return tabs;
}
