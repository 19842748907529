import {postPayload} from "./data";
import _findIndex from "lodash/findIndex";

export const INIT_MIX_AND_MATCH = 'INIT_MIX_AND_MATCH';
export const MIX_AND_MATCH_LOAD_OFFERS = 'MIX_AND_MATCH_LOAD_OFFERS';
export const MIX_AND_MATCH_SET_SEARCHED_COLLECTIONS = 'MIX_AND_MATCH_SET_SEARCHED_COLLECTIONS';
export const MIX_AND_MATCH_SET_SEARCHED_PRODUCTS = 'MIX_AND_MATCH_SET_SEARCHED_PRODUCTS';
export const MIX_AND_MATCH_SET_SEARCHED_PARENT_PRODUCTS = 'MIX_AND_MATCH_SET_SEARCHED_PARENT_PRODUCTS';
export const MIX_AND_MATCH_SET_PARENT_PRODUCT = 'MIX_AND_MATCH_SET_PARENT_PRODUCT';
export const MIX_AND_MATCH_CREATE_OFFER = 'MIX_AND_MATCH_CREATE_OFFER';
export const MIX_AND_MATCH_SAVING_SETTINGS = 'MIX_AND_MATCH_SAVING_SETTINGS';
export const MIX_AND_MATCH_SAVING_PROMO_TITLE = 'MIX_AND_MATCH_SAVING_PROMO_TITLE';
export const MIX_AND_MATCH_TOAST = 'MIX_AND_MATCH_TOAST';
export const MIX_AND_MATCH_NUM_OF_SEARCHED_PRODUCTS_CHANGE = 'MIX_AND_MATCH_NUM_OF_SEARCHED_PRODUCTS_CHANGE';
export const MIX_AND_MATCH_SET_SEARCH_COLLECTION_TITLE = 'MIX_AND_MATCH_SET_SEARCH_COLLECTION_TITLE';
export const MIX_AND_MATCH_SET_SEARCH_PRODUCT_TITLE = 'MIX_AND_MATCH_SET_SEARCH_PRODUCT_TITLE';
export const MIX_AND_MATCH_SET_SEARCH_PARENT_PRODUCT_TITLE = 'MIX_AND_MATCH_SET_SEARCH_PARENT_PRODUCT_TITLE';
export const MIX_AND_MATCH_SELECT_PARENT_PRODUCT = 'MIX_AND_MATCH_SELECT_PARENT_PRODUCT';
export const MIX_AND_MATCH_OFFER_NAME_CHANGE = 'MIX_AND_MATCH_OFFER_NAME_CHANGE';
export const MIX_AND_MATCH_DISCOUNT_TITLE_CHANGE = 'MIX_AND_MATCH_DISCOUNT_TITLE_CHANGE';
export const MIX_AND_MATCH_DISCOUNT_TYPE_CHANGE = 'MIX_AND_MATCH_DISCOUNT_TYPE_CHANGE';
export const MIX_AND_MATCH_DISCOUNT_AMOUNT_CHANGE = 'MIX_AND_MATCH_DISCOUNT_AMOUNT_CHANGE';
export const MIX_AND_MATCH_NAV_TO_CHANGE = 'MIX_AND_MATCH_NAV_TO_CHANGE';
export const MIX_AND_MATCH_DELETE_TIER = 'MIX_AND_MATCH_DELETE_TIER';
export const MIX_AND_MATCH_OPEN_PARENT_PRODUCT_MODAL = 'MIX_AND_MATCH_OPEN_PARENT_PRODUCT_MODAL';
export const MIX_AND_MATCH_STRING_CHANGE = 'MIX_AND_MATCH_STRING_CHANGE';
export const MIX_AND_MATCH_TIER_MIN_QTY_CHANGE = 'MIX_AND_MATCH_TIER_MIN_QTY_CHANGE';
export const MIX_AND_MATCH_TIER_DISCOUNT_AMOUNT_CHANGE = 'MIX_AND_MATCH_TIER_DISCOUNT_AMOUNT_CHANGE';
export const MIX_AND_MATCH_TIER_DISCOUNT_TYPE_CHANGE = 'MIX_AND_MATCH_TIER_DISCOUNT_TYPE_CHANGE';
export const MIX_AND_MATCH_TRIGGER_DISPLAY_OPTIONS_CHANGE = 'MIX_AND_MATCH_TRIGGER_DISPLAY_OPTIONS_CHANGE';
export const MIX_AND_MATCH_LOCALE_CHANGE = 'MIX_AND_MATCH_LOCALE_CHANGE';
export const MIX_AND_MATCH_DISMISS_TOAST = 'MIX_AND_MATCH_DISMISS_TOAST';
export const MIX_AND_MATCH_TOGGLE_SEARCH_COLLECTIONS_MODAL = 'MIX_AND_MATCH_TOGGLE_SEARCH_COLLECTIONS_MODAL';
export const MIX_AND_MATCH_OPEN_SEARCH_COLLECTIONS_MODAL = 'MIX_AND_MATCH_OPEN_SEARCH_COLLECTIONS_MODAL';
export const MIX_AND_MATCH_OPEN_ADD_PRODUCT_GROUP_MODAL = 'MIX_AND_MATCH_OPEN_ADD_PRODUCT_GROUP_MODAL';
export const MIX_AND_MATCH_EDIT_PRODUCT_GROUP = 'MIX_AND_MATCH_EDIT_PRODUCT_GROUP';
export const MIX_AND_MATCH_CREATING_OFFER = 'MIX_AND_MATCH_CREATING_OFFER';
export const MIX_AND_MATCH_SAVING_OFFER = 'MIX_AND_MATCH_SAVING_OFFER';
export const MIX_AND_MATCH_SEARCHING_COLLECTION = 'MIX_AND_MATCH_SEARCHING_COLLECTION';
export const MIX_AND_MATCH_SEARCHING_PRODUCT = 'MIX_AND_MATCH_SEARCHING_PRODUCT';
export const MIX_AND_MATCH_DELETING_OFFER = 'MIX_AND_MATCH_DELETING_OFFER';
export const MIX_AND_MATCH_SELECT_COLLECTION = 'MIX_AND_MATCH_SELECT_COLLECTION';
export const MIX_AND_MATCH_ADD_PRODUCT_GROUP = 'MIX_AND_MATCH_ADD_PRODUCT_GROUP';
export const MIX_AND_MATCH_MIN_QTY_CHANGE = 'MIX_AND_MATCH_MIN_QTY_CHANGE';
export const MIX_AND_MATCH_MIN_QTY_FOR_BUYING_CHANGE = 'MIX_AND_MATCH_MIN_QTY_FOR_BUYING_CHANGE';
export const MIX_AND_MATCH_MAX_QTY_FOR_BUYING_CHANGE = 'MIX_AND_MATCH_MAX_QTY_FOR_BUYING_CHANGE';
export const MIX_AND_MATCH_PRODUCT_GROUP_STRING_CHANGE = 'MIX_AND_MATCH_PRODUCT_GROUP_STRING_CHANGE';
export const MIX_AND_MATCH_LIMIT_NUM_ITEMS_CHANGE = 'MIX_AND_MATCH_LIMIT_NUM_ITEMS_CHANGE';
export const MIX_AND_MATCH_BOOL_CHANGE = 'MIX_AND_MATCH_BOOL_CHANGE';
export const MIX_AND_MATCH_DELETE_PRODUCT_GROUP = 'MIX_AND_MATCH_DELETE_PRODUCT_GROUP';
export const MIX_AND_MATCH_REMOVE_PRODUCT = 'MIX_AND_MATCH_REMOVE_PRODUCT';
export const MIX_AND_MATCH_ADD_PRODUCT = 'MIX_AND_MATCH_ADD_PRODUCT';
export const MIX_AND_MATCH_EDIT_OFFER = 'MIX_AND_MATCH_EDIT_OFFER';
export const MIX_AND_MATCH_OPEN_DELETE_OFFER_MODAL = 'MIX_AND_MATCH_OPEN_DELETE_OFFER_MODAL';
export const MIX_AND_MATCH_OPEN_SHORT_CODE_MODAL = 'MIX_AND_MATCH_OPEN_SHORT_CODE_MODAL';
export const MIX_AND_MATCH_PREVIEW_OFFER_LOADING = 'MIX_AND_MATCH_PREVIEW_OFFER_LOADING';
export const SWITCHING_MNM_PREVIEW = 'SWITCHING_MNM_PREVIEW';
export const MNM_DUPLICATING_OFFER = 'MNM_DUPLICATING_OFFER';
export const MNM_MOVING_OFFER_DOWN = 'MNM_MOVING_OFFER_DOWN';
export const MNM_MOVING_OFFER_UP = 'MNM_MOVING_OFFER_UP';
export const CHANGE_MNM_PREVIEW_MODE = 'CHANGE_MNM_PREVIEW_MODE';
export const MIX_AND_MATCH_DELETE_TRIGGER_PRODUCT_GROUP = 'MIX_AND_MATCH_DELETE_TRIGGER_PRODUCT_GROUP';
export const MIX_AND_MATCH_LOAD_OFFERS_ERROR = 'MIX_AND_MATCH_LOAD_OFFERS_ERROR';
export const MIX_AND_MATCH_ADD_TIER = 'MIX_AND_MATCH_ADD_TIER';
export const MIX_AND_MATCH_SET_STATE = 'MIX_AND_MATCH_SET_STATE';
export const MIX_AND_MATCH_SETTINGS_TEXT_CHANGE = 'MIX_AND_MATCH_SETTINGS_TEXT_CHANGE';
export const MIX_AND_MATCH_SETTINGS_BOOL_CHANGE = 'MIX_AND_MATCH_SETTINGS_BOOL_CHANGE';
export const MIX_AND_MATCH_OFFER_TEXT_CHANGE = 'MIX_AND_MATCH_OFFER_TEXT_CHANGE';
export const MIX_AND_MATCH_TRIGGER_LOCATION_CHANGE = 'MIX_AND_MATCH_TRIGGER_LOCATION_CHANGE';
export const MIX_AND_MATCH_SETTING_COLOR_CHANGE = 'MIX_AND_MATCH_SETTING_COLOR_CHANGE';
export const MIX_AND_MATCH_QUANTITY_MODIFIER_CHANGE = 'MIX_AND_MATCH_QUANTITY_MODIFIER_CHANGE';

export const initMixAndMatch = (json, eligibilityJson) => ({
    type: INIT_MIX_AND_MATCH,
    json,
    eligibilityJson,
});

export const mixAndMatchLoadOffers = json => ({
    type: MIX_AND_MATCH_LOAD_OFFERS,
    json,
});

export const mixAndMatchSetSearchedCollections = json => ({
    type: MIX_AND_MATCH_SET_SEARCHED_COLLECTIONS,
    json,
});

export const mixAndMatchSetSearchedProducts = json => ({
    type: MIX_AND_MATCH_SET_SEARCHED_PRODUCTS,
    json,
});

export const mixAndMatchSetSearchedParentProducts = json => ({
    type: MIX_AND_MATCH_SET_SEARCHED_PARENT_PRODUCTS,
    json,
});

export const mixAndMatchSetParentProduct = json => ({
    type: MIX_AND_MATCH_SET_PARENT_PRODUCT,
    json,
});

export const mixAndMatchCreateOffer = json => ({
    type: MIX_AND_MATCH_CREATE_OFFER,
    json,
});

export const mixAndMatchSavingSettings = bool => ({
    type: MIX_AND_MATCH_SAVING_SETTINGS,
    bool,
});

export const mixAndMatchSavingPromoTitle = bool => ({
    type: MIX_AND_MATCH_SAVING_PROMO_TITLE,
    bool,
});

export const mixAndMatchDismissToast = () => ({
    type: MIX_AND_MATCH_DISMISS_TOAST,
});

export const mixAndMatchToggleSearchCollectionsModal = () => ({
    type: MIX_AND_MATCH_TOGGLE_SEARCH_COLLECTIONS_MODAL,
});

export const mixAndMatchOpenSearchCollectionsModal = modalType => ({
    type: MIX_AND_MATCH_OPEN_SEARCH_COLLECTIONS_MODAL,
    modalType,
});

export const mixAndMatchOpenAddProductGroupModal = (modalType, open) => ({
    type: MIX_AND_MATCH_OPEN_ADD_PRODUCT_GROUP_MODAL,
    modalType,
    open,
});

export const mixAndMatchEditProductGroup = idx => ({
    type: MIX_AND_MATCH_EDIT_PRODUCT_GROUP,
    idx,
});

export const mixAndMatchCreatingOffer = bool => ({
    type: MIX_AND_MATCH_CREATING_OFFER,
    bool,
});

export const mixAndMatchSavingOffer = bool => ({
    type: MIX_AND_MATCH_SAVING_OFFER,
    bool,
});

export const mixAndMatchSearchingCollection = bool => ({
    type: MIX_AND_MATCH_SEARCHING_COLLECTION,
    bool,
});

export const mixAndMatchSearchingProduct = bool => ({
    type: MIX_AND_MATCH_SEARCHING_PRODUCT,
    bool,
});

export const mixAndMatchDeletingOffer = bool => ({
    type: MIX_AND_MATCH_DELETING_OFFER,
    bool,
});

export const mixAndMatchSelectCollection = (id, title) => ({
    type: MIX_AND_MATCH_SELECT_COLLECTION,
    id,
    title,
});

export const mixAndMatchAddProductGroup = () => ({
    type: MIX_AND_MATCH_ADD_PRODUCT_GROUP,
});

export const mixAndMatchMinQtyChange = (idx, num) => ({
    type: MIX_AND_MATCH_MIN_QTY_CHANGE,
    idx,
    num,
});

export const mixAndMatchMinQtyForBuyingChange = (idx, text) => ({
    type: MIX_AND_MATCH_MIN_QTY_FOR_BUYING_CHANGE,
    idx,
    text,
});

export const mixAndMatchMaxQtyForBuyingChange = (idx, text) => ({
    type: MIX_AND_MATCH_MAX_QTY_FOR_BUYING_CHANGE,
    idx,
    text,
});

export const mixAndMatchProductGroupStringChange = (idx, attr, text) => ({
    type: MIX_AND_MATCH_PRODUCT_GROUP_STRING_CHANGE,
    idx,
    attr,
    text,
});

export const mixAndMatchLimitNumItemsChange = (idx, text) => ({
    type: MIX_AND_MATCH_LIMIT_NUM_ITEMS_CHANGE,
    idx,
    text,
});

export const mixAndMatchBoolChange = (attr, text) => ({
    type: MIX_AND_MATCH_BOOL_CHANGE,
    attr,
    text,
});

export const mixAndMatchDeleteProductGroup = id => ({
    type: MIX_AND_MATCH_DELETE_PRODUCT_GROUP,
    id,
});

export const mixAndMatchRemoveProduct = id => ({
    type: MIX_AND_MATCH_REMOVE_PRODUCT,
    id,
});

export const mixAndMatchAddProduct = node => ({
    type: MIX_AND_MATCH_ADD_PRODUCT,
    node,
});

export const mixAndMatchEditOffer = id => ({
    type: MIX_AND_MATCH_EDIT_OFFER,
    id,
});

export const mixAndMatchOpenDeleteOfferModal = (id, bool) => ({
    type: MIX_AND_MATCH_OPEN_DELETE_OFFER_MODAL,
    id,
    bool,
});

export const mixAndMatchOpenShortCodeModal = (id, bool) => ({
    type: MIX_AND_MATCH_OPEN_SHORT_CODE_MODAL,
    id,
    bool,
});

export const mixAndMatchPreviewOfferLoading = (id, bool) => ({
    type: MIX_AND_MATCH_PREVIEW_OFFER_LOADING,
    id,
    bool,
});

export const switchingMnmPreview = (id, bool) => ({
    type: SWITCHING_MNM_PREVIEW,
    id,
    bool,
});

export const mnmDuplicatingOffer = (id, bool) => ({
    type: MNM_DUPLICATING_OFFER,
    id,
    bool,
});

export const mnmMovingOfferDown = (id, bool) => ({
    type: MNM_MOVING_OFFER_DOWN,
    id,
    bool,
});

export const mnmMovingOfferUp = (id, bool) => ({
    type: MNM_MOVING_OFFER_UP,
    id,
    bool,
});

export const changeMnmPreviewMode = (id, bool) => ({
    type: CHANGE_MNM_PREVIEW_MODE,
    id,
    bool,
});

export const mixAndMatchDeleteTriggerProductGroup = id => ({
    type: MIX_AND_MATCH_DELETE_TRIGGER_PRODUCT_GROUP,
    id,
});

export const mixAndMatchLoadOffersError = () => ({
    type: MIX_AND_MATCH_LOAD_OFFERS_ERROR,
});

export const mixAndMatchAddTier = () => ({
    type: MIX_AND_MATCH_ADD_TIER,
});

export const mixAndMatchSetState = state => ({
    type: MIX_AND_MATCH_SET_STATE,
    state,
});

export const mixAndMatchToast = text => ({
    type: MIX_AND_MATCH_TOAST,
    text,
});

export const MixAndMatchNumOfSearchedProductsChange = text => ({
    type: MIX_AND_MATCH_NUM_OF_SEARCHED_PRODUCTS_CHANGE,
    text,
});

export const mixAndMatchSetSearchCollectionTitle = text => ({
    type: MIX_AND_MATCH_SET_SEARCH_COLLECTION_TITLE,
    text,
});

export const mixAndMatchSetSearchProductTitle = text => ({
    type: MIX_AND_MATCH_SET_SEARCH_PRODUCT_TITLE,
    text,
});

export const mixAndMatchSetSearchParentProductTitle = text => ({
    type: MIX_AND_MATCH_SET_SEARCH_PARENT_PRODUCT_TITLE,
    text,
});

export const mixAndMatchSelectParentProduct = node => ({
    type: MIX_AND_MATCH_SELECT_PARENT_PRODUCT,
    node,
});

export const mixAndMatchOfferNameChange = text => ({
    type: MIX_AND_MATCH_OFFER_NAME_CHANGE,
    text,
});

export const mixAndMatchDiscountTitleChange = text => ({
    type: MIX_AND_MATCH_DISCOUNT_TITLE_CHANGE,
    text,
});

export const mixAndMatchDiscountTypeChange = text => ({
    type: MIX_AND_MATCH_DISCOUNT_TYPE_CHANGE,
    text,
});

export const mixAndMatchDiscountAmountChange = text => ({
    type: MIX_AND_MATCH_DISCOUNT_AMOUNT_CHANGE,
    text,
});

export const mixAndMatchNavToChange = text => ({
    type: MIX_AND_MATCH_NAV_TO_CHANGE,
    text,
});

export const mixAndMatchDeleteTier = idx => ({
    type: MIX_AND_MATCH_DELETE_TIER,
    idx,
});

export const mixAndMatchOpenParentProductModal = bool => ({
    type: MIX_AND_MATCH_OPEN_PARENT_PRODUCT_MODAL,
    bool,
});

export const mixAndMatchStringChange = (attr, text) => ({
    type: MIX_AND_MATCH_STRING_CHANGE,
    attr,
    text,
});

export const mixAndMatchTierMinQtyChange = (idx, num) => ({
    type: MIX_AND_MATCH_TIER_MIN_QTY_CHANGE,
    idx,
    num,
});

export const mixAndMatchTierDiscountAmountChange = (idx, num) => ({
    type: MIX_AND_MATCH_TIER_DISCOUNT_AMOUNT_CHANGE,
    idx,
    num,
});

export const mixAndMatchTierDiscountTypeChange = (idx, text) => ({
    type: MIX_AND_MATCH_TIER_DISCOUNT_TYPE_CHANGE,
    idx,
    text,
});

export const mixAndMatchTriggerDisplayOptionsChange = text => ({
    type: MIX_AND_MATCH_TRIGGER_DISPLAY_OPTIONS_CHANGE,
    text,
});

export const mixAndMatchLocaleChange = locale => ({
    type: MIX_AND_MATCH_LOCALE_CHANGE,
    locale,
});

export const mixAndMatchSettingsTextChange = (attr, text) => ({
    type: MIX_AND_MATCH_SETTINGS_TEXT_CHANGE,
    attr,
    text,
});

export const mixAndMatchSettingsBoolChange = (attr, bool) => ({
    type: MIX_AND_MATCH_SETTINGS_BOOL_CHANGE,
    attr,
    bool,
});

export const mixAndMatchOfferTextChange = (attr, text) => ({
    type: MIX_AND_MATCH_OFFER_TEXT_CHANGE,
    attr,
    text,
});

export const mixAndMatchTriggerLocationChange = (attr, bool) => ({
    type: MIX_AND_MATCH_TRIGGER_LOCATION_CHANGE,
    attr,
    bool,
});

export const mixAndMatchSettingColorChange = (attr, hex) => ({
    type: MIX_AND_MATCH_SETTING_COLOR_CHANGE,
    attr,
    hex,
});

export const mixAndMatchQuantityModifierChange = (text) => ({
    type: MIX_AND_MATCH_QUANTITY_MODIFIER_CHANGE,
    text,
});

export const mixAndMatchSaveSettings = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(mixAndMatchSavingSettings(true));
    const resp = await postPayload('mix_and_match/save_settings', {
        settings: state.mixAndMatch.settings.values,
    }, state)
    if (resp.ok) {
        dispatch(mixAndMatchToast('Settings Saved'));
    } else {
        dispatch(mixAndMatchToast('Save Failed'));
    }
    dispatch(mixAndMatchSavingSettings(false));
}

export const mixAndMatchSavePromoPageTitle = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(mixAndMatchSavingPromoTitle(true));
    const resp = await postPayload('mix_and_match/update_promo_page_title', {
        title: state.mixAndMatch.settings.values.mix_and_match_promo_page_title,
    }, state)
    if (resp.ok) {
        dispatch(mixAndMatchToast('Title Saved'));
    } else {
        dispatch(mixAndMatchToast('Title Save Failed'));
    }
    dispatch(mixAndMatchSavingPromoTitle(false));
}

export const loadProductMixAndMatch = () => async (dispatch, getState) => {
    const state = getState();
    if (state.mixAndMatch.offersReady) return;
    const resp = await postPayload('mix_and_match/get_admin_offers', {}, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(mixAndMatchLoadOffers(json));
        dispatch(mixAndMatchSetState('offer_list'));
    } else {
        dispatch(mixAndMatchLoadOffersError());
    }
}

export const mixAndMatchFetchTranslationsAndLocales = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(mixAndMatchCreatingOffer(true));
    const resp = await postPayload('mix_and_match/create_offer', {
        fetch_available_locales: !state.mixAndMatch.availableLocales,
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(mixAndMatchCreateOffer(json));
        dispatch(mixAndMatchSetState('edit_offer'));
    } else {
        dispatch(mixAndMatchToast('Create Failed'));
    }
    dispatch(mixAndMatchCreatingOffer(false));
}

export const mixAndMatchSaveOffer = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(mixAndMatchSavingOffer(true));
    if (state.mixAndMatch.offer.offer.discount.type === 'tiered') {
        state.mixAndMatch.offer.offer.discount.tiers = state.mixAndMatch.offer.offer.discount.tiers.sort((a, b) => a.min_qty - b.min_qty);
    }
    const resp = await postPayload('mix_and_match/save_offer', {
        offer: state.mixAndMatch.offer,
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(mixAndMatchToast('Offer Saved'));
        dispatch(mixAndMatchLoadOffers(json));
        dispatch(mixAndMatchSetState('offer_list'));
    } else {
        dispatch(mixAndMatchToast('Save Failed'));
    }
    dispatch(mixAndMatchSavingOffer(false));
}

export const mixAndMatchDeleteOffer = id => async (dispatch, getState) => {
    const state = getState();
    dispatch(mixAndMatchDeletingOffer(true));
    const resp = await postPayload('mix_and_match/delete_offer', {
        id,
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(mixAndMatchToast('Offer Deleted'));
        dispatch(mixAndMatchLoadOffers(json));
    } else {
        dispatch(mixAndMatchToast('Delete Failed'));
    }
    dispatch(mixAndMatchOpenDeleteOfferModal(null, false));
    dispatch(mixAndMatchDeletingOffer(false));
}

export const mixAndMatchToggleEnabled = id => async (dispatch, getState) => {
    const state = getState();
    const resp = await postPayload('mix_and_match/toggle_enabled', {
        id,
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(mixAndMatchToast('Offer Saved'));
        dispatch(mixAndMatchLoadOffers(json));
    } else {
        dispatch(mixAndMatchToast('Save Failed'));
    }
}

export const mixAndMatchPreviewOffer = id => async (dispatch, getState) => {
    const state = getState();
    dispatch(mixAndMatchPreviewOfferLoading(id, true));
    const resp = await postPayload('mix_and_match/preview', {id}, state);
    if (resp.ok) {
        const json = await resp.json();
        window.open(`https://${state.data.shopName}/products/${json.handle}?zrx_preview_mode=true`, '_blank');
    } else {
        dispatch(mixAndMatchToast('Preview Failed'));
    }
    dispatch(mixAndMatchPreviewOfferLoading(id, false));
}

export const mixAndMatchSwitchPreview = id => async (dispatch, getState) => {
    const state = getState();
    const offer = state.mixAndMatch.offers.find(o => o.id === id);
    dispatch(switchingMnmPreview(id, true));
    const previewMode = !offer.preview_mode;
    const response = await postPayload('mix_and_match/switch_preview', {
        preview_mode: previewMode,
        id,
    }, state);
    if (response.ok) {
        dispatch(changeMnmPreviewMode(id, previewMode))
        dispatch(switchingMnmPreview(id, false));
        await postPayload('admin_v2/create_store_js', {}, state);
    } else {
        console.log('mixAndMatchSwitchPreview failed');
    }
}

export const mixAndMatchDupOffer = id => async (dispatch, getState) => {
    const state = getState();
    dispatch(mnmDuplicatingOffer(id, true));
    const response = await postPayload('mix_and_match/dup_offer', {
        id,
    }, state);
    if (response.ok) {
        const json = await response.json();
        dispatch(mnmDuplicatingOffer(id, false));
        dispatch(mixAndMatchToast('Offer duplicated'));
        dispatch(mixAndMatchLoadOffers(json));
    } else {
        console.log('mixAndMatchDupOffer failed');
    }
}

export const mixAndMatchMoveOfferDown = id => async (dispatch, getState) => {
    const state = getState();
    dispatch(mnmMovingOfferDown(id, true));
    const offers = state.mixAndMatch.offers.filter(o => o.offer_type === 'functions');
    const idx = _findIndex(offers, {id});
    [offers[idx], offers[idx + 1]] = [offers[idx + 1], offers[idx]];
    const response = await postPayload('mix_and_match/set_priority', {
        priorities: offers.map((o, idx) => ({
            id: o.id,
            idx,
        })),
    }, state);
    if (response.ok) {
        const json = await response.json();
        dispatch(mixAndMatchLoadOffers(json));
    } else {
        console.log('mixAndMatchMoveOfferDown failed');
    }
    dispatch(mnmMovingOfferDown(id, false));
}

export const mixAndMatchMoveOfferUp = id => async (dispatch, getState) => {
    const state = getState();
    dispatch(mnmMovingOfferUp(id, true));
    const offers = state.mixAndMatch.offers.filter(o => o.offer_type === 'functions');
    const idx = _findIndex(offers, {id});
    [offers[idx], offers[idx - 1]] = [offers[idx - 1], offers[idx]];
    const response = await postPayload('mix_and_match/set_priority', {
        priorities: offers.map((o, idx) => ({
            id: o.id,
            idx,
        })),
    }, state);
    if (response.ok) {
        const json = await response.json();
        dispatch(mixAndMatchLoadOffers(json));
    } else {
        console.log('mixAndMatchMoveOfferUp failed');
    }
    dispatch(mnmMovingOfferUp(id, false));
}

export const mixAndMatchSearchCollectionTitleChange = text => async (dispatch, getState) => {
    const state = getState();
    dispatch(mixAndMatchSearchingCollection(true));
    dispatch(mixAndMatchSetSearchCollectionTitle(text));
    const resp = await postPayload('collections/search', {
        text,
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(mixAndMatchSetSearchedCollections(json));
    } else {
        dispatch(mixAndMatchToast('Search Failed'));
    }
    dispatch(mixAndMatchSearchingCollection(false));
}

export const mixAndMatchSearchProductTitleChange = text => async (dispatch, getState) => {
    const state = getState();
    dispatch(mixAndMatchSearchingProduct(true));
    dispatch(mixAndMatchSetSearchProductTitle(text));
    const resp = await postPayload('products/search', {
        text,
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(mixAndMatchSetSearchedProducts(json));
    } else {
        dispatch(mixAndMatchToast('Search Failed'));
    }
    dispatch(mixAndMatchSearchingProduct(false));
}

export const mixAndMatchSearchParentProductTitleChange = text => async (dispatch, getState) => {
    const state = getState();
    dispatch(mixAndMatchSearchingProduct(true));
    dispatch(mixAndMatchSetSearchParentProductTitle(text));
    const resp = await postPayload('products/search', {
        text,
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(mixAndMatchSetSearchedParentProducts(json));
    } else {
        dispatch(mixAndMatchToast('Search Failed'));
    }
    dispatch(mixAndMatchSearchingProduct(false));
}

export const mixAndMatchLoadParentProduct = () => async (dispatch, getState) => {
    const state = getState();
    const id = state.mixAndMatch.offer.parent_product_id;
    if (!id) return;
    const resp = await postPayload('products/get_product_by_id', {
        id,
    }, state);
    if (resp.ok) {
        const json = await resp.json();
        dispatch(mixAndMatchSetParentProduct(json));
    } else {
        dispatch(mixAndMatchToast('Load Parent Product Failed'));
    }
}
