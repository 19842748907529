import _cloneDeep from "lodash/cloneDeep";
import _assign from "lodash/assign";

import {
    ACTIVATE_TOAST,
    AUTO_PLAY,
    AUTO_PLAY_INTERVAL,
    BELOW_ADD_TO_CART,
    BORDER,
    CART_POSITION_CHANGE,
    COMPARE_PRICE_COLOR,
    COMPARE_PRICE_FONT_SIZE,
    COMPARE_PRICE_FONT_WEIGHT,
    CURRENCY_CUT_ZEROS,
    CURRENCY_POSITION,
    CUSTOM_CURRENCY,
    DISMISS_TOAST,
    HIDE_PRODUCT_LINK,
    NAV_CIRCLE,
    NAV_COLOR,
    PRICE_COLOR,
    PRICE_FONT_SIZE,
    PRICE_FONT_WEIGHT,
    PRODUCT_BOTTOM_ENABLED,
    PRODUCT_LINK_COLOR,
    PRODUCT_LINK_FONT_SIZE,
    PRODUCT_LINK_FONT_WEIGHT,
    PRODUCT_LINK_UNDERLINE,
    PRODUCT_LOCATION_CHANGE,
    RECOMMENDATION_WIDGET_INIT,
    RECOMMENDATION_WIDGET_MAX_PRODUCTS,
    REFRESH_AFTER_ADD_TO_CART_CHANGE,
    RESTORE_TO_DEFAULTS,
    SAVE_SETTINGS_ERROR,
    SAVE_SETTINGS_INIT,
    SAVE_SETTINGS_SUCCESS,
    SELECTION_COLOR,
    SHOW_QUANTITY_MODIFIER,
    SHUFFLE_PRODUCTS,
    SUB_TITLE_COLOR,
    SUB_TITLE_FONT_SIZE,
    SUB_TITLE_FONT_WEIGHT,
    TITLE_ALIGN,
    TITLE_COLOR,
    TITLE_FONT_SIZE,
    TITLE_FONT_WEIGHT,
    ADD_TO_CART_BUTTON_COLOR,
    ADD_TO_CART_BUTTON_TEXT_COLOR,
    ADD_TO_CART_BUTTON_BOTTOM_WIDGET,
    ADD_TO_CART_BUTTON_CART_WIDGET,
    ADD_TO_CART_BUTTON_MAIN_WIDGET,
    ADD_TO_CART_BUTTON_FONT_SIZE,
    QUICK_VIEW,
    IMAGE_BACKGROUND,
    PRODUCT_DESC_ON_QUICK_VIEW,
    NUM_OF_SLIDES_MOBILE,
    NUM_OF_SLIDES_DESKTOP,
    SLIDER_ITEM_BACKGROUND,
    TITLE_ON_TOP,
    HIDE_COMPARE_TO,
    QUICK_VIEW_ON_IMAGE_CLICKED,
    SELECTION_SHAPE,
    SLIDER_FONT_FAMILY,
    SHOW_QUANTITY_MODAL,
    INFO_POPUP_SLIDER,
    SLIDER_NAVIGATE_TO_CHANGE,
    RECOMMEND_WITH_ORDERS,
    SHOW_SLIDER_VARIANTS,
    SLIDER_DISCOUNT_CHANGE,
    COLLAPSE_BACKGROUND_COLOR,
    NAVIGATE_TO_PRODUCT_PAGE,
    SLIDER_SHOW_AFTER_ITEM_ADDED,
    TAKE_ONLY_FIRST_MAPPING_CHANGE,
    SLIDER_SHOW_DISCOUNTED_PRICES_CHANGE,
    SLIDER_CART_POPUP_CHANGE,
    SLIDER_DISPLAY_OPTIONS_CHANGE,
    SLIDER_CART_DRAWER_DISCOUNT_CHANGE,
    SLIDER_CART_DRAWER_NUM_OF_SLIDES_CHANGE,
    SLIDER_ONLY_ON_SOURCE_PRODUCTS_CHANGE,
    SLIDER_SHOW_OTHERS_AFTER_GLOBALS_CHANGED, SLIDER_SETTINGS_BOOL_CHANGE,
} from "../actions/settings";

const settings = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case RECOMMENDATION_WIDGET_INIT:
            newState = {};
            newState.title = action.title;
            newState.subTitle = action.subTitle;
            newState.maxProducts = action.maxProducts;
            _assign(newState, _cloneDeep(action.upsell.values));
            newState.values = _cloneDeep(action.upsell.values);
            newState.defaults = _cloneDeep(action.upsell.defaults);
            newState.cartPosition = newState.cartTop ? (newState.cartBottom ? 'both' : 'top') : 'bottom';
            newState.navigateTo = newState.navToCheckout ? 'checkout' : newState.navToCart ? 'cart' : 'none';
            newState.autoPlayInterval = newState.autoPlayInterval / 1000;
            newState.planName = action.planName;
            break;
        case PRODUCT_LINK_UNDERLINE:
            newState = _cloneDeep(state);
            newState.productLinkUnderline = action.bool;
            break;
        case SHOW_QUANTITY_MODIFIER:
            newState = _cloneDeep(state);
            newState.showQuantityModifier = action.bool;
            break;
        case CURRENCY_CUT_ZEROS:
            newState = _cloneDeep(state);
            newState.currencyCutZeros = action.bool;
            break;
        case SHUFFLE_PRODUCTS:
            newState = _cloneDeep(state);
            newState.shuffleProducts = action.bool;
            break;
        case HIDE_PRODUCT_LINK:
            newState = _cloneDeep(state);
            newState.hideProductLink = action.bool;
            break;
        case BORDER:
            newState = _cloneDeep(state);
            newState.border = action.bool;
            break;
        case NAV_CIRCLE:
            newState = _cloneDeep(state);
            newState.navCircle = action.bool;
            break;
        case HIDE_COMPARE_TO:
            newState = _cloneDeep(state);
            newState.hideCompareTo = action.bool;
            break;
        case QUICK_VIEW_ON_IMAGE_CLICKED:
            newState = _cloneDeep(state);
            newState.quickViewOnImageClicked = action.bool;
            break;
        case SHOW_SLIDER_VARIANTS:
            newState = _cloneDeep(state);
            newState.showSliderVariants = action.text === 'show';
            break;
        case AUTO_PLAY:
            newState = _cloneDeep(state);
            newState.autoPlay = action.bool;
            break;
        case BELOW_ADD_TO_CART:
            newState = _cloneDeep(state);
            newState.belowAddToCart = action.bool;
            break;
        case PRODUCT_BOTTOM_ENABLED:
            newState = _cloneDeep(state);
            newState.productBottomEnabled = action.bool;
            break;
        case REFRESH_AFTER_ADD_TO_CART_CHANGE:
            newState = _cloneDeep(state);
            newState.refreshAfterAddToCart = action.bool;
            break;
        case PRODUCT_DESC_ON_QUICK_VIEW:
            newState = _cloneDeep(state);
            newState.productDescOnQuickView = action.bool;
            break;
        case INFO_POPUP_SLIDER:
            newState = _cloneDeep(state);
            newState.infoPopupSlider = action.bool;
            break;
        case RECOMMEND_WITH_ORDERS:
            newState = _cloneDeep(state);
            newState.recommendWithOrders = action.bool;
            break;
        case TAKE_ONLY_FIRST_MAPPING_CHANGE:
            newState = _cloneDeep(state);
            newState.takeOnlyFirstMapping = action.text === 'first';
            break;
        case ADD_TO_CART_BUTTON_COLOR:
            newState = _cloneDeep(state);
            newState.addToCartButtonColor = action.hex;
            break;
        case ADD_TO_CART_BUTTON_TEXT_COLOR:
            newState = _cloneDeep(state);
            newState.addToCartButtonTextColor = action.hex;
            break;
        case SLIDER_ITEM_BACKGROUND:
            newState = _cloneDeep(state);
            newState.sliderItemBackground = action.hex;
            break;
        case ADD_TO_CART_BUTTON_FONT_SIZE:
            newState = _cloneDeep(state);
            newState.addToCartButtonFontSize = action.size;
            break;
        case ADD_TO_CART_BUTTON_MAIN_WIDGET:
            newState = _cloneDeep(state);
            newState.addToCartButtonMainWidget = action.bool;
            break;
        case ADD_TO_CART_BUTTON_BOTTOM_WIDGET:
            newState = _cloneDeep(state);
            newState.addToCartButtonBottomWidget = action.bool;
            break;
        case ADD_TO_CART_BUTTON_CART_WIDGET:
            newState = _cloneDeep(state);
            newState.addToCartButtonCartWidget = action.bool;
            break;
        case NAVIGATE_TO_PRODUCT_PAGE:
            newState = _cloneDeep(state);
            newState.navigateToProductPage = action.bool;
            break;
        case SLIDER_SHOW_AFTER_ITEM_ADDED:
            newState = _cloneDeep(state);
            newState.sliderShowAfterItemAdded = action.bool;
            break;
        case SLIDER_ONLY_ON_SOURCE_PRODUCTS_CHANGE:
            newState = _cloneDeep(state);
            newState.onlyOnSourceProducts = action.bool;
            break;
        case SLIDER_SHOW_OTHERS_AFTER_GLOBALS_CHANGED:
            newState = _cloneDeep(state);
            newState.showOthersAfterGlobals = action.bool;
            break;
        case SLIDER_SETTINGS_BOOL_CHANGE:
            newState = _cloneDeep(state);
            newState.values[action.attr] = action.bool === 'yes';
            break;
        case TITLE_FONT_WEIGHT:
            newState = _cloneDeep(state);
            newState.titleFontWeight = action.weight;
            break;
        case SUB_TITLE_FONT_WEIGHT:
            newState = _cloneDeep(state);
            newState.subTitleFontWeight = action.weight;
            break;
        case PRICE_FONT_WEIGHT:
            newState = _cloneDeep(state);
            newState.priceFontWeight = action.weight;
            break;
        case COMPARE_PRICE_FONT_WEIGHT:
            newState = _cloneDeep(state);
            newState.comparePriceFontWeight = action.weight;
            break;
        case PRODUCT_LINK_FONT_WEIGHT:
            newState = _cloneDeep(state);
            newState.productLinkFontWeight = action.weight;
            break;
        case CURRENCY_POSITION:
            newState = _cloneDeep(state);
            newState.currencyPosition = action.position;
            break;
        case TITLE_ALIGN:
            newState = _cloneDeep(state);
            newState.titleAlign = action.position;
            break;
        case TITLE_FONT_SIZE:
            newState = _cloneDeep(state);
            newState.titleFontSize = action.size;
            break;
        case SUB_TITLE_FONT_SIZE:
            newState = _cloneDeep(state);
            newState.subTitleFontSize = action.size;
            break;
        case PRICE_FONT_SIZE:
            newState = _cloneDeep(state);
            newState.priceFontSize = action.size;
            break;
        case COMPARE_PRICE_FONT_SIZE:
            newState = _cloneDeep(state);
            newState.comparePriceFontSize = action.size;
            break;
        case PRODUCT_LINK_FONT_SIZE:
            newState = _cloneDeep(state);
            newState.productLinkFontSize = action.size;
            break;
        case TITLE_COLOR:
            newState = _cloneDeep(state);
            newState.titleColor = action.hex;
            break;
        case COLLAPSE_BACKGROUND_COLOR:
            newState = _cloneDeep(state);
            newState.collapseBackgroundColor = action.hex;
            break;
        case SUB_TITLE_COLOR:
            newState = _cloneDeep(state);
            newState.subTitleColor = action.hex;
            break;
        case PRICE_COLOR:
            newState = _cloneDeep(state);
            newState.priceColor = action.hex;
            break;
        case COMPARE_PRICE_COLOR:
            newState = _cloneDeep(state);
            newState.comparePriceColor = action.hex;
            break;
        case PRODUCT_LINK_COLOR:
            newState = _cloneDeep(state);
            newState.productLinkColor = action.hex;
            break;
        case QUICK_VIEW:
            newState = _cloneDeep(state);
            newState.quickView = action.bool;
            break;
        case TITLE_ON_TOP:
            newState = _cloneDeep(state);
            newState.titleOnTop = action.bool;
            break;
        case SHOW_QUANTITY_MODAL:
            newState = _cloneDeep(state);
            newState.showQuantityModal = action.bool;
            break;
        case IMAGE_BACKGROUND:
            newState = _cloneDeep(state);
            newState.imageBackground = action.text;
            break;
        case SELECTION_COLOR:
            newState = _cloneDeep(state);
            newState.selectionColor = action.hex;
            break;
        case NAV_COLOR:
            newState = _cloneDeep(state);
            newState.navColor = action.hex;
            break;
        case CART_POSITION_CHANGE:
            newState = _cloneDeep(state);
            newState.cartPosition = action.position;
            if (action.position === 'top') {
                newState.cartTop = true;
                newState.cartBottom = false;
            } else if (action.position === 'bottom') {
                newState.cartTop = false;
                newState.cartBottom = true;
            } else if (action.position === 'both') {
                newState.cartTop = true;
                newState.cartBottom = true;
            }
            break;
        case SLIDER_NAVIGATE_TO_CHANGE:
            newState = _cloneDeep(state);
            newState.navigateTo = action.text;
            if (action.text === 'checkout') {
                newState.navToCheckout = true;
                newState.navToCart = false;
            } else if (action.text === 'cart') {
                newState.navToCheckout = false;
                newState.navToCart = true;
            } else if (action.text === 'none') {
                newState.navToCheckout = false;
                newState.navToCart = false;
            }
            break;
        case SLIDER_SHOW_DISCOUNTED_PRICES_CHANGE:
            newState = _cloneDeep(state);
            newState.showDiscountedPrices = action.text === 'show';
            break;
        case SLIDER_CART_POPUP_CHANGE:
            newState = _cloneDeep(state);
            newState.cartPopup = action.text === 'cart_popup';
            break;
        case SLIDER_DISPLAY_OPTIONS_CHANGE:
            newState = _cloneDeep(state);
            newState.collapseMain = action.text === 'collapse';
            newState.plainList = action.text === 'plain_list';
            break;
        case SLIDER_CART_DRAWER_DISCOUNT_CHANGE:
            newState = _cloneDeep(state);
            newState.cartDrawerDiscount = action.text === 'enable';
            break;
        case PRODUCT_LOCATION_CHANGE:
            newState = _cloneDeep(state);
            newState.productLocation = action.position;
            break;
        case ACTIVATE_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = true;
            break;
        case DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case RESTORE_TO_DEFAULTS:
            newState = _cloneDeep(state);
            _assign(newState, _cloneDeep(state.defaults));
            newState.values = _cloneDeep(state.defaults);
            newState.navigateTo = newState.navToCheckout ? 'checkout' : newState.navToCart ? 'cart' : 'none';
            newState.autoPlayInterval = newState.autoPlayInterval / 1000;
            break;
        case CUSTOM_CURRENCY:
            newState = _cloneDeep(state);
            newState.customCurrency = action.text;
            break;
        case SELECTION_SHAPE:
            newState = _cloneDeep(state);
            newState.selectionShape = action.text;
            break;
        case SLIDER_FONT_FAMILY:
            newState = _cloneDeep(state);
            newState.sliderFontFamily = action.text;
            break;
        case RECOMMENDATION_WIDGET_MAX_PRODUCTS:
            newState = _cloneDeep(state);
            newState.maxProducts = action.num;
            break;
        case AUTO_PLAY_INTERVAL:
            newState = _cloneDeep(state);
            newState.autoPlayInterval = action.num;
            break;
        case NUM_OF_SLIDES_MOBILE:
            newState = _cloneDeep(state);
            newState.numOfSlidesMobile = action.num;
            if (action.num !== '' && action.num < 1) {
                newState.numOfSlidesMobile = 1;
            }
            if (action.num !== '' && action.num > 3) {
                newState.numOfSlidesMobile = 3;
            }
            break;
        case NUM_OF_SLIDES_DESKTOP:
            newState = _cloneDeep(state);
            newState.numOfSlidesDesktop = action.num;
            if (action.num !== '' && action.num < 1) {
                newState.numOfSlidesDesktop = 1;
            }
            if (action.num !== '' && action.num > 7) {
                newState.numOfSlidesDesktop = 7;
            }
            break;
        case SLIDER_DISCOUNT_CHANGE:
            newState = _cloneDeep(state);
            newState.sliderDiscount = action.num;
            if (action.num !== '' && action.num < 0) {
                newState.sliderDiscount = 0;
            }
            if (action.num !== '' && action.num > 100) {
                newState.sliderDiscount = 100;
            }
            break;
        case SLIDER_CART_DRAWER_NUM_OF_SLIDES_CHANGE:
            newState = _cloneDeep(state);
            newState.cartDrawerNumOfSlides = parseInt(action.num);
            if (newState.cartDrawerNumOfSlides < 1) {
                newState.cartDrawerNumOfSlides = 1;
            }
            break;
        case SAVE_SETTINGS_INIT:
            newState = _cloneDeep(state);
            newState.isSubmitting = true;
            break;
        case SAVE_SETTINGS_ERROR:
            newState = _cloneDeep(state);
            newState.isSubmitting = false;
            newState.submissionError = true;
            break;
        case SAVE_SETTINGS_SUCCESS:
            newState = _cloneDeep(state);
            newState.isSubmitting = false;
            newState.submissionError = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default settings;
