// https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
import React, {useEffect, useState} from 'react';

const SendEvent = ({ state, stateToEventFunc, send }) => {

    const [sent, setSent] = useState(false);

    useEffect(() => {
        if (sent) return;
        setSent(true);
        send(stateToEventFunc(state));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <span />;
}

export default SendEvent;
