import _cloneDeep from "lodash/cloneDeep";

const global = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case 'blablabla':
            newState = _cloneDeep(state);
            break;
        default:
            return state;
    }
    return newState;
};

export default global;
