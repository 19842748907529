import React from 'react';
import {createRoot} from 'react-dom/client';
// Temp
import './css/main.scss';
import './App.css';
import './index.css';
import '@shopify/polaris/dist/styles.css';
import 'antd/dist/antd.css';
//
import 'babel-polyfill'
import * as serviceWorker from './serviceWorker';
import Root from './containers/Root'
import configureStore from "./configureStore";
import {onLCP} from 'web-vitals';
import {adminTrack} from "./actions/v2/appV2Wizard";

// import * as Sentry from "@sentry/react";

// if (process.env.NODE_ENV === 'production') {
//     Sentry.init({
//         dsn: "https://bf80a22489a84381892cbae9da4bac00:2f028451aa2b43f89465422eb196596c@sentry.io/1889974",
//         release: "my-project-name@" + process.env.npm_package_version,
//     });
// }

const store = configureStore();
window.Zoorix = window.Zoorix || {};
window.Zoorix.store = store;
const lcpTimeout = window.setTimeout(() => {
    store.dispatch(adminTrack('admin_web_vitals_lcp_timeout_reached', {}));
}, 30_000);
onLCP(event => {
    window.clearTimeout(lcpTimeout);
    console.log('LCP:', event);
    let className;
    try {
        className = event.entries[0].element.className;
    } catch (e) {
        className = null;
    }
    store.dispatch(adminTrack('admin_web_vitals_lcp', {
        lcp_value: event.value,
        lcp_class_name: className,
    }))
});

const root = createRoot(document.getElementById('root'))
root.render(<Root store={store} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// window.addEventListener('message', event => console.log(event));
