import fetch from "cross-fetch";
import {host} from "./data";
import {sendingSupportRequest, toggleContactUsModal} from "./v2/appV2Wizard";
import {activateGenericToast} from "./nav";

export const TEXT_CHANGE = 'TEXT_CHANGE';
export const SUBMITTED = 'SUBMITTED';
export const SUPPORT_UPLOAD_SUCCESS = 'SUPPORT_UPLOAD_SUCCESS';
export const SUBMIT_ERROR = 'SUBMIT_ERROR';

export const textChange = text => ({
    type: TEXT_CHANGE,
    text
});

export const submitted = () => ({
    type: SUBMITTED,
});

export const supportUploadSuccess = (res, json) => ({
    type: SUPPORT_UPLOAD_SUCCESS,
    res,
    json,
});

export const submitError = (connectionError, textLengthError) => ({
    type: SUBMIT_ERROR,
    connectionError,
    textLengthError,
});

export function submit() {
    return (dispatch, getState) => {
        const state = getState();
        if (!state.support.text || state.support.text.length < 10) {
            return dispatch(submitError(false, true))
        }
        dispatch(sendingSupportRequest(true));
        return fetch(`https://${host}/support`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    from: state.data.ownerEmail,
                    name: state.data.ownerName,
                    text: state.support.text,
                    image_key: state.support.imageKey,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(submitted());
                    dispatch(toggleContactUsModal());
                    dispatch(activateGenericToast('Support Request Sent'));
                } else {
                    dispatch(submitError(true, false))
                }
                dispatch(sendingSupportRequest(false));
            });
    }
}
